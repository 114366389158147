import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import useToxicSpeciesForm from './hooks/useToxicSpeciesForm';
import { ToxicSpeciesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const ToxicSpeciesForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateToxicSpecies,
        canUpdateToxicSpecies,
        canDeleteToxicSpecies,
        termSet,
    } = useToxicSpeciesForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ToxicSpeciesFormDefs.Toxic_Species}
                />
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateToxicSpecies}
            displayUpdateButton={canUpdateToxicSpecies}
            displayDeleteButton={canDeleteToxicSpecies}
            readOnly={id && !canUpdateToxicSpecies}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ToxicSpeciesFormDefs.Species_Code}
                                />
                            }
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={30}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ToxicSpeciesFormDefs.Description}
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default ToxicSpeciesForm;
