import {
    ContractsForm,
    ContractsGrid,
    ContractCoverageGrid,
    DepreciationEntriesGrid,
    DisposalEntriesGrid,
    FixedAssetRegisterForm,
    FixedAssetRegisterGrid,
    FixedAssetScheduleGrid,
    AssetEntriesGrid,
    FunctionCodesGrid,
} from './termDefs.types';

export const FixedAssetRegisterGridDefs: FixedAssetRegisterGrid = {
    AssetNumber: 'fixed-asset-register-asset-number',
    AssetCode: 'fixed-asset-register-asset-code',
    AssetName: 'fixed-asset-register-asset-name',
    Description: 'fixed-asset-register-description',
    AssetStatus: 'fixed-asset-register-asset-status',
    AssetTypeBook: 'fixed-asset-register-asset-type-book',
    AssetTypeTax: 'fixed-asset-register-asset-type-tax',
    Building: 'fixed-asset-register-building',
    AcquireDate: 'fixed-asset-register-acquire-date',
    InServiceDate: 'fixed-asset-register-in-service-date',
    TotalCost: 'fixed-asset-register-total-cost',
};

export const FixedAssetScheduleGridDefs: FixedAssetScheduleGrid = {
    Selected: 'fixed-asset-schedule-grid-selected',
    DepreciationMethodType:
        'fixed-asset-schedule-grid-depreciation-method-type',
    FixedAssetType: 'fixed-asset-schedule-fixed-asset-type',
    Date: 'fixed-asset-schedule-grid-date',
    Locked: 'fixed-asset-schedule-grid-locked',
    AssetCost: 'fixed-asset-schedule-grid-asset-cost',
    AccumulatedDepreciation:
        'fixed-asset-schedule-grid-accumulated-depreciation',
    RemainingValue: 'fixed-asset-schedule-grid-remaining-value',
    PopUpDepreciationMethodTypes: 'schedule-options-depreciation-method-types',
    IntervalDateBegin: 'schedule-options-interval-date-begin',
    IntervalDateEnd: 'schedule-options-interval-date-end',
    AssetTypes: 'schedule-options-asset-types',
    GetSchedules: 'schedule-options-get-schedules',
    Title: 'schedule-options-title',
    DepreciationMethodTypeAssetCode: 'depreciation-method-type-asset-code',
    DepreciationMethodTypeRecoveryInterval:
        'depreciation-method-type-recovery-interval',
    DepreciationMethodTypeAssetCost: 'depreciation-method-type-asset-cost',
    DepreciationMethodTypeAccumulatedDepreciation:
        'depreciation-method-type-accumulated-depreciation',
    DepreciationMethodTypeRemainingValue:
        'depreciation-method-type-remaining-value',
    DepreciationMethodTypeLocked: 'depreciation-method-type-locked',
    FixedAssetTypeDate: 'fixed-asset-type-date',
    FixedAssetTypeLocked: 'fixed-asset-type-locked',
    FixedAssetTypeAssetCost: 'fixed-asset-type-asset-cost',
    FixedAssetTypeAccumulatedDepreciation:
        'fixed-asset-type-accumulated-depreciation',
    FixedAssetTypeRemainingValue: 'fixed-asset-type-remaining-value',
    DateFixedAssetType: 'date-fixed-asset-type',
    DateLocked: 'date-locked',
    DateAssetCost: 'date-asset-cost',
    DateAccumulatedDepreciation: 'date-accumulated-depreciation',
    MultiGridSelected: 'multi-grid-selected',
    MultiGridAssetCode: 'multi-grid-asset-code',
    MultiGridRecoveryInterval: 'multi-grid-recovery-interval',
    MultiGridCost: 'multi-grid-cost',
    MultiGridAccumulatedDepreciation: 'multi-grid-accumulated-depreciation',
    MultiGridRemainingValue: 'multi-grid-remaining-value',
    MultiGridLocked: 'multi-grid-locked',
};

export const DepreciationEntriesGridDefs: DepreciationEntriesGrid = {
    Selected: 'depreciation-entries-main-grid-selected',
    Depreciation_Method_Type:
        'depreciation-entries-main-grid-depreciation-method-type',
    Fixed_Asset_Type: 'depreciation-entries-main-grid-fixed-asset-type',
    Date: 'depreciation-entries-main-grid-date',
    Locked: 'depreciation-entries-main-grid-locked',
    Account_Number: 'depreciation-entries-main-grid-account-number',
    Entry_Description: 'depreciation-entries-main-grid-entry-description',
    Debit: 'depreciation-entries-main-grid-debit',
    Credit: 'depreciation-entries-main-grid-credit',
    Options_Title: 'depreciation-entries-options-title',
    Options_Depreciation_Method_Type:
        'depreciation-entries-options-depreciation-method-type',
    Options_Interval_Date_Begin:
        'depreciation-entries-options-interval-date-begin',
    Options_Interval_Date_End: 'depreciation-entries-options-interval-date-end',
    Options_Asset_Type: 'depreciation-entries-options-asset-type',
    Options_Get_Depreciation_Entries:
        'depreciation-entries-options-get-depreciation-entries',
    Actions_Title: 'depreciation-entries-actions-title',
    Actions_Unlock_Entries: 'depreciation-entries-actions-unlock-entries',
    Actions_Lock_Entries: 'depreciation-entries-actions-lock-entries',
    Actions_Process_Changes: 'depreciation-entries-actions-process-changes',
    Toolbar_Actions: 'depreciation-entries-toolbar-actions',
    Toolbar_Options: 'depreciation-entries-toolbar-options',
    Toolbar_Download_Entries: 'depreciation-entries-toolbar-download-entries',
    Depreciation_Method_Type_Asset_Code:
        'depreciation-entries-depreciation-method-type-asset-code',
    Depreciation_Method_Type_Interval:
        'depreciation-entries-depreciation-method-type-interval',
    Depreciation_Method_Type_Locked:
        'depreciation-entries-depreciation-method-type-locked',
    Depreciation_Method_Type_Account_Number:
        'depreciation-entries-depreciation-method-type-account-number',
    Depreciation_Method_Type_Account_Name:
        'depreciation-entries-depreciation-method-type-account-name',
    Depreciation_Method_Type_Debit:
        'depreciation-entries-depreciation-method-type-debit',
    Depreciation_Method_Type_Credit:
        'depreciation-entries-depreciation-method-type-credit',
    Fixed_Asset_Type_Date: 'depreciation-entries-fixed-asset-type-date',
    Fixed_Asset_Type_Locked: 'depreciation-entries-fixed-asset-type-locked',
    Fixed_Asset_Type_Account_Number:
        'depreciation-entries-fixed-asset-type-account-number',
    Fixed_Asset_Type_Entry_Description:
        'depreciation-entries-fixed-asset-type-entry-description',
    Fixed_Asset_Type_Debit: 'depreciation-entries-fixed-asset-type-debit',
    Fixed_Asset_Type_Credit: 'depreciation-entries-fixed-asset-type-credit',
    Date_Fixed_Asset_Type: 'depreciation-entries-date-fixed-asset-type',
    Multi_Grid_Selected: 'depreciation-entries-multi-grid-selected',
    Multi_Grid_Asset_Code: 'depreciation-entries-multi-grid-asset-code',
    Multi_Grid_Interval: 'depreciation-entries-multi-grid-interval',
    Multi_Grid_Locked: 'depreciation-entries-multi-grid-locked',
    Multi_Grid_Account_Number: 'depreciation-entries-multi-grid-account-number',
    Multi_Grid_Account_Name: 'depreciation-entries-multi-grid-account-name',
    Multi_Grid_Debit: 'depreciation-entries-multi-grid-debit',
    Multi_Grid_Credit: 'depreciation-entries-multi-grid-credit',
    Download_Entries_Title: 'download-entries-title',
    Download_Entries_Date: 'download-entries-date',
    Download_Entries_Account_Number: 'download-entries-account-number',
    Download_Entries_Entry_Description: 'download-entries-entry-description',
    Download_Entries_Debit: 'download-entries-debit',
    Download_Entries_Credit: 'download-entries-credit',
};

export const FixedAssetRegisterFormDefs: FixedAssetRegisterForm = {
    Code: 'fixed-asset-register-form-code',
    Name: 'fixed-asset-register-form-name',
    Description: 'fixed-asset-register-form-description',
    Status: 'fixed-asset-register-form-status',
    AcquireDate: 'fixed-asset-register-form-acquire-date',
    InServiceDate: 'fixed-asset-register-form-in-service-date',
    DisposalDate: 'fixed-asset-register-form-disposal-date',
    AssetTypeBook: 'fixed-asset-register-form-asset-type-book',
    AssetTypeTax: 'fixed-asset-register-form-asset-type-tax',
    References: 'fixed-asset-register-form-references',
    Building: 'fixed-asset-register-form-building',
    ManufacturerSerial: 'fixed-asset-register-form-manufacturer-serial',
    PurchaseOrder: 'fixed-asset-register-form-purchase-order',
    SupplierInvoice: 'fixed-asset-register-form-supplier-invoice',
    CapitalProject: 'fixed-asset-register-form-capital-project',
    Costs: 'fixed-asset-register-form-costs',
    Contracts: 'fixed-asset-register-form-contracts',
    SchedulesAndEntries: 'fixed-asset-register-form-schedules-entries',
    DepreciationSchedules: 'fixed-asset-register-form-depreciation-schedules',
    DepreciationEntries: 'fixed-asset-register-form-depreciation-entries',
    DisposalEntry: 'fixed-asset-register-form-disposal-entry',
    AssetEntry: 'fixed-asset-register-form-asset-entry',
    FixedAssetCode: 'fixed-asset-register-fixed-asset-code',
    ContractNumber: 'contracts-grid-number',
    ContractName: 'contracts-grid-name',
    ContractDescription: 'contracts-grid-description',
    ContractType: 'contracts-grid-type',
    CostsCode: 'costs-grid-code',
    CostsAmount: 'costs-grid-amount',
    DepreciationSchedulesRecoveryInterval:
        'depreciation-schedules-grid-recovery-interval',
    DepreciationSchedulesDate: 'depreciation-schedules-grid-date',
    DepreciationSchedulesPercentage: 'depreciation-schedules-grid-percentage',
    DepreciationSchedulesAssetCost: 'depreciation-schedules-grid-asset-cost',
    DepreciationSchedulesAccumulatedDepreciation:
        'depreciation-schedules-grid-accumulated-depreciation',
    DepreciationSchedulesRemainingValue:
        'depreciation-schedules-grid-remaining-value',
    DepreciationSchedulesLocked: 'depreciation-schedules-grid-locked',
    DepreciationEntriesRecoveryInterval:
        'depreciation-entries-grid-recovery-interval',
    DepreciationEntriesDate: 'depreciation-entries-grid-date',
    DepreciationEntriesAccountNumber:
        'depreciation-entries-grid-account-number',
    DepreciationEntriesDebit: 'depreciation-entries-grid-debit',
    DepreciationEntriesCredit: 'depreciation-entries-grid-credit',
    DepreciationEntriesLocked: 'depreciation-entries-grid-locked',
    DisposalEntryDisposalDate: 'disposal-entry-grid-disposal-date',
    DisposalEntryAccountNumber: 'disposal-entry-grid-account-number',
    DisposalEntryEntryDescription: 'disposal-entry-grid-entry-description',
    DisposalEntryDebit: 'disposal-entry-grid-debit',
    DisposalEntryCredit: 'disposal-entry-grid-credit',
    DisposalEntryLocked: 'disposal-entry-grid-locked',
    AssetEntryDate: 'asset-entry-grid-entry-date',
    AssetEntryAccountNumber: 'asset-entry-grid-account-number',
    AssetEntryDescription: 'asset-entry-grid-entry-description',
    AssetEntryDebit: 'asset-entry-grid-debit',
    AssetEntryCredit: 'asset-entry-grid-credit',
    AssetEntryLocked: 'asset-entry-grid-locked',
    DepreciationEntriesEntryDescription:
        'depreciation-entries-grid-entry-description',
};
export const ContractsFormDefs: ContractsForm = {
    Number: 'contracts-form-number',
    Name: 'contracts-form-name',
    Description: 'contracts-form-description',
    Provider: 'contracts-form-provider',
    Type: 'contracts-form-contract-type',
    Start_Date: 'contracts-form-start-date',
    End_Date: 'contracts-form-end-date',
    Amount: 'contracts-form-contract-amount',
    Title: 'contracts-form-title',
};

export const DisposalEntriesGridDefs: DisposalEntriesGrid = {
    Selected: 'disposal-entries-grid-selected',
    Disposal_Date: 'disposal-entries-grid-disposal-date',
    Fixed_Asset_Type: 'disposal-entries-grid-fixed-asset-type',
    Locked: 'disposal-entries-grid-locked',
    Account_Number: 'disposal-entries-grid-account-number',
    Entry_Description: 'disposal-entries-grid-entry-description',
    Debit: 'disposal-entries-grid-debit',
    Credit: 'disposal-entries-grid-credit',
    Asset_Code: 'disposal-entries-grid-asset-code',
    Date: 'disposal-entries-grid-date',
    Toolbar_Options: 'disposal-entries-grid-toolbar-options',
    Toolbar_Actions: 'disposal-entries-grid-toolbar-actions',
    Toolbar_Download_Entries: 'disposal-entries-grid-toolbar-download-entries',
    Actions_Title: 'disposal-entries-grid-actions-title',
    Actions_Unlock_Entries: 'disposal-entries-grid-actions-unlock-entries',
    Actions_Lock_Entries: 'disposal-entries-grid-actions-lock-entries',
    Actions_Process_Changes: 'disposal-entries-grid-actions-changes',
    Options_Title: 'disposal-entries-grid-options-title',
    Options_Asset_Types: 'disposal-entries-grid-options-asset-types',
    Options_Disposal_Date_Begin:
        'disposal-entries-grid-options-disposal-date-begin',
    Options_Disposal_Date_End:
        'disposal-entries-grid-options-disposal-date-end',
    Options_Get_Disposal_Entries:
        'disposal-entries-grid-options-get-disposal-entries',
    Download_Entries_Title: 'disposal-entries-grid-download-entries-title',
    Back: 'disposal-entries-grid-toolbar-back',
};
export const AssetEntriesGridDefs: AssetEntriesGrid = {
    Selected: 'asset-entries-grid-selected',
    Date: 'asset-entries-grid-date',
    Fixed_Asset_Type: 'asset-entries-grid-fixed-asset-type',
    Locked: 'asset-entries-grid-locked',
    Account_Number: 'asset-entries-grid-account-number',
    Entry_Description: 'asset-entries-grid-entry-description',
    Debit: 'asset-entries-grid-debit',
    Credit: 'asset-entries-grid-credit',
    Asset_Code: 'asset-entries-grid-asset-code',
    Account_Name: 'asset-entries-grid-account-name',
    Asset_Type: 'asset-entries-grid-asset-type',
    Entry_Date: 'asset-entries-grid-entry-date',
    Toolbar_Back: 'asset-entries-grid-toolbar-Back',
    Toolbar_Actions: 'asset-entries-grid-toolbar-actions',
    Toolbar_Options: 'asset-entries-grid-toolbar-options',
    Toolbar_Download_Entries: 'asset-entries-grid-toolbar-download-entries',
    Actions_Title: 'asset-entries-grid-actions-title',
    Actions_Unlock_Entries: 'asset-entries-grid-actions-unlock-entries',
    Actions_Lock_Entries: 'asset-entries-grid-actions-lock-entries',
    Actions_Changes: 'asset-entries-grid-actions-changes',
    Options_Title: 'asset-entries-grid-options-title',
    Options_Asset_Types: 'asset-entries-grid-options-asset-types',
    Options_Interval_Date_Begin:
        'asset-entries-grid-options-disposal-date-begin',
    Options_Interval_Date_End: 'asset-entries-grid-options-disposal-date-end',
    Options_Get_Disposal_Entries:
        'asset-entries-grid-options-get-disposal-entries',
    Download_Entries_Title: 'asset-entries-grid-download-entries-title',
};
export const ContractsGridDefs: ContractsGrid = {
    Number: 'fixed-asset-contracts-grid-number',
    Name: 'fixed-asset-contracts-grid-name',
    Description: 'fixed-asset-contracts-grid-description',
    Provider: 'fixed-asset-contracts-grid-provider',
    Type: 'fixed-asset-contracts-grid-type',
    Start_Date: 'fixed-asset-contracts-grid-start-date',
    End_Date: 'fixed-asset-contracts-grid-end-date',
    Amount: 'fixed-asset-contracts-grid-amount',
};
export const ContractCoverageGridDefs: ContractCoverageGrid = {
    Contract_Type: 'contract-coverage-contract-type',
    Contract_Number: 'contract-coverage-contract-number',
    Contract_Name: 'contract-coverage-contract-name',
    Provider: 'contract-coverage-provider',
    Start_Date: 'contract-coverage-start-date',
    End_Date: 'contract-coverage-end-date',
    Contract_Amount: 'contract-coverage-contract-amount',
    Asset_Cost: 'contract-coverage-asset-cost',
    Remaining_Value: 'contract-coverage-remaining-value',
    Asset_Code: 'contract-coverage-asset-code',
    Asset_Name: 'contract-coverage-asset-name',
    Asset_Status: 'contract-coverage-asset-status',
    Asset_Type: 'contract-coverage-asset-type',
    Acquire_Date: 'contract-coverage-acquire-date',
    In_Service_Date: 'contract-coverage-in-service-date',
    Disposal_Date: 'contract-coverage-disposal-date',
    Options_Contract_Types: 'contract-coverage-options-contract-types',
    Options_Contract_Date_Begin:
        'contract-coverage-options-contract-date-begin',
    Options_Contract_Date_End: 'contract-coverage-options-contract-date-end',
    Options_Remaining: 'contract-coverage-options-remaining',
    Options_Get_Contracts: 'contract-coverage-options-get-contracts',
    Options_Title: 'contract-coverage-options-title',
    Toolbar_Options: 'contract-coverage-toolbar-options',
};
export const FunctionCodesGridDefs: FunctionCodesGrid = {
    Function_Code: 'function-codes-grid-function-code',
    Number: 'function-codes-grid-account-number',
    Name: 'function-codes-grid-account-name',
};
