import React, { useContext, useEffect, useState } from 'react';
import { isJsonString, isNilOrEmpty } from '../../../utils/objectUtils';
import { editableGridCellSelect } from '../../grids/columns/editableGridCellSelect';
import useBaseForm from '../../../containers/form/hooks/useBaseForm';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { v4 as uuidv4 } from 'uuid';
import useGetBusinessEntities from '../../../containers/administration/hooks/useGetBusinessEntities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';

const useSequenceBase = ({
    sequenceQuery,
    sequenceMutation,
    parameterQuery,
    useParent,
    parentQuery,
    closePath,
}: any) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: sequence, isLoading: isLoadingSequence } = sequenceQuery();
    const [updateSequence] = sequenceMutation();
    const { data: parameters, isLoading: isLoadingParameters } =
        parameterQuery();
    const { data: parents, isLoading: isLoadingParents } = parentQuery();
    const [sequenceNumber, setSequenceNumber] = useState<any>('');
    const [sequencePrefix, setSequencePrefix] = useState('');
    const [sequenceSuffix, setSequenceSuffix] = useState('');
    const [parsedPrefixes, setParsedPrefixes] = useState(null);
    const [parsedSuffixes, setParsedSuffixes] = useState(null);
    const [paddingChars, setPaddingChars] = useState('');
    const { businessEntities, isLoading: isLoadingBusinessEnts } =
        useGetBusinessEntities({
            businessEntityId: user?.activeBusinessEntityId?.toString(),
            subscriberId: user?.subscriber?.id.toString(),
        });
    const [sequencePreview, setSequencePreview] = useState('');

    const [prefixColDefs, setPrefixColDefs] = useState(null);

    const blankSequence: any = {
        nextNumber: '',
        paddingLength: '',
        paddingChar: '',
        step: '1',
        autoReset: '0',
        prefixSegments: null,
        suffixSegments: null,
        useParent: false,
        parentId: null,
    };

    const { settings } = useContext(SettingsContext);
    const { data: sharedTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );

    const {
        fields,
        setValues,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: closePath,
        blankEntity: blankSequence,
        activeEntity: sequence,
        getDescription: () => {
            return `Sequence ${fields.id}`;
        },
        createEntity: () => {
            return null as MutationActionCreatorResult<any>;
        },
        updateEntity: () => {
            if (!fields?.useParent) {
                fields.parentId = null;
            }

            const stringifiedPrefixes = JSON.stringify(
                JSON.parse(fields?.prefixSegments).map((prefix: any) => {
                    return {
                        type: prefix.type,
                        value: prefix.value,
                    };
                })
            );

            const stringifiedSuffixes = JSON.stringify(
                JSON.parse(fields?.suffixSegments).map((suffix: any) => {
                    return {
                        type: suffix.type,
                        value: suffix.value,
                    };
                })
            );
            return updateSequence({
                id: fields?.id as unknown as number,
                postBody: {
                    ...fields,
                    prefixSegments: stringifiedPrefixes,
                    suffixSegments: stringifiedSuffixes,
                },
            });
        },
    });

    useEffect(() => {
        if (!isNilOrEmpty(fields?.prefixSegments)) {
            let prefixes = '';

            for (const prefix of JSON.parse(fields?.prefixSegments)) {
                prefixes += prefix.value;
            }

            setSequencePrefix(prefixes);
        }

        if (!isNilOrEmpty(fields?.suffixSegments)) {
            let suffixes = '';

            for (const suffix of JSON.parse(fields?.suffixSegments)) {
                suffixes += suffix.value;
            }

            setSequenceSuffix(suffixes);
        }
    }, [fields]);

    const calculatePreview = () => {
        let paddingLength: any = '';
        if (!isNilOrEmpty(fields.paddingLength)) {
            let arrLength =
                fields.paddingLength - fields.nextNumber?.toString().length;

            if (arrLength > 0) {
                paddingLength = new Array(arrLength)
                    .fill(fields.paddingChar)
                    .join('');
            }
        }

        setPaddingChars(paddingLength);

        const newSequenceNumber = fields.nextNumber?.toString();

        setSequenceNumber(newSequenceNumber);
    };

    useEffect(() => {
        setSequencePreview(
            sequencePrefix + paddingChars + sequenceNumber + sequenceSuffix
        );
    }, [
        sequenceNumber,
        sequenceSuffix,
        sequencePrefix,
        paddingChars,
        fields.paddingLength,
        fields.nextNumber,
        fields.paddingChar,
    ]);

    useEffect(() => {
        calculatePreview();
    }, [fields.nextNumber, fields.paddingLength, fields.paddingChar]);
    const handlePreviewNext = () => {
        setSequenceNumber((curr: any) => {
            let next = parseInt(curr) + parseInt(fields.step);
            return next?.toString();
        });
    };
    const handlePrefixGridEdits = (editedRows: any) => {
        const updatedFields: any = [...parsedPrefixes];
        editedRows?.forEach((row: any) => {
            const index = parsedPrefixes.findIndex(
                (prefix: any) => row.id === prefix.id
            );
            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });

        setValues({
            prefixSegments: JSON.stringify(
                updatedFields.filter((row: any) => row.rowStatus !== 'deleted')
            ),
        });
    };

    const handleSuffixGridEdits = (editedRows: any) => {
        const updatedFields: any = [...parsedSuffixes];
        editedRows?.forEach((row: any) => {
            const index = parsedSuffixes.findIndex(
                (prefix: any) => row.id === prefix.id
            );
            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });

        setValues({
            suffixSegments: JSON.stringify(
                updatedFields.filter((row: any) => row.rowStatus !== 'deleted')
            ),
        });
    };

    useEffect(() => {
        if (!isNilOrEmpty(sequence)) {
            setParsedPrefixes(
                isJsonString(sequence?.prefixSegments)
                    ? JSON.parse(sequence?.prefixSegments).map(
                          (prefix: any) => {
                              return {
                                  type: prefix.type,
                                  value: prefix.value,
                                  id: uuidv4(),
                              };
                          }
                      )
                    : []
            );

            setParsedSuffixes(
                isJsonString(sequence?.suffixSegments)
                    ? JSON.parse(sequence?.suffixSegments).map(
                          (suffix: any) => {
                              return {
                                  type: suffix.type,
                                  value: suffix.value,
                                  id: uuidv4(),
                              };
                          }
                      )
                    : []
            );
        }
    }, [sequence]);

    const prefixCellOptions = [
        {
            label: 'Text',
            value: 'text',
        },
        {
            label: 'Year(4-digit)',
            value: 'yearFour',
        },
        {
            label: 'Year(2-digit)',
            value: 'yearTwo',
        },
        {
            label: 'Month',
            value: 'month',
        },
        {
            label: 'Month (0-padded)',
            value: 'monthZero',
        },
        {
            label: 'Day',
            value: 'day',
        },
        {
            label: 'Day (0-padded)',
            value: 'dayZero',
        },
    ];

    useEffect(() => {
        if (!isNilOrEmpty(sequence) && !isLoadingParameters) {
            const withParams = [...prefixCellOptions, ...parameters];

            const colDefs = [
                {
                    field: 'dragRow',
                    headerName: '',
                    rowDrag: true,
                    filter: false,
                    minWidth: 50,
                    maxWidth: 50,
                },
                {
                    field: 'deleteColumn',
                    headerName: '',
                    minWidth: 50,
                    hide: false,
                    filter: false,
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    ...editableGridCellSelect(withParams),
                    editable: true,
                },
                {
                    field: 'value',
                    headerName: 'Value',
                    editable: true,
                    valueFormatter: handleValueFormatting,
                },
            ];
            setPrefixColDefs(colDefs);
        }
    }, [sequence, parameters]);

    const handleValueFormatting = (params: any) => {
        switch (params.data.type) {
            case 'yearFour':
                params.data.value = new Date().getFullYear()?.toString();
                break;
            case 'yearTwo':
                params.data.value = new Date()
                    .getFullYear()
                    ?.toString()
                    .match(/\d{2}$/);
                break;
            case 'month':
                params.data.value = (new Date().getMonth() + 1)?.toString();
                break;
            case 'monthZero':
                params.data.value = (new Date().getMonth() + 1)
                    ?.toString()
                    .padStart(2, '0');
                break;
            case 'day':
                params.data.value = new Date().getDate()?.toString();
                break;
            case 'dayZero':
                params.data.value = new Date()
                    .getDate()
                    ?.toString()
                    .padStart(2, '0');
                break;
            default:
                break;
        }

        for (const param of parameters) {
            if (param.value === params.data.type) {
                params.data.value = `{${param.parameterValue}}`;
            }
        }
    };

    const handleRowDrag = (params: any, type: string) => {
        let newOrder: any = [];
        //update field values with the new order
        params.api.forEachNode((node: any) => {
            newOrder.push(node.data);
        });

        setValues({ [type]: JSON.stringify(newOrder) });
    };

    return {
        fields,
        handlePrefixGridEdits,
        handleSuffixGridEdits,
        prefixColDefs,
        handleFieldChange,
        sequencePreview,
        handlePreviewNext,
        onUpdate,
        closeForm,
        parsedPrefixes,
        parsedSuffixes,
        handleRowDrag,
        businessEntities,
        setValues,
        parents,
        sharedTermSet,
    };
};
export default useSequenceBase;
