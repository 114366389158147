import { useNavigate } from 'react-router-dom';
import { useGetAllContractsQuery } from '../../../services/accounting/accounting.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { useContext } from 'react';
import SettingsContext from '../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';

const UseContractGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: contractList, isLoading: isLoadingContracts } =
        useGetAllContractsQuery();
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.CONTRACTS_GRID,
              }
            : skipToken
    );
    const canCreateContract = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.CREATE
    );
    const canViewContract = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(`/accounting/contract/${row.id}/${RoutingValues.update}`);
        } else {
            navigate(
                `/accounting/contract/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    return {
        contractList,
        redirectToForm,
        isLoadingContracts,
        canCreateContract,
        canViewContract,
        termSet,
    };
};

export default UseContractGrid;
