import useBaseForm from '../../form/hooks/useBaseForm';
import {
    useGetFunctionCodeQuery,
    useUpdateFunctionCodeMutation,
} from '../../../services/accounting/accounting.service';
import { useEffect, useState } from 'react';
import { GridReadyEvent, RowNode } from 'ag-grid-community';
import {
    GL_AccountFunction,
    GL_Account,
} from '../../../types/Accounting.types';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FunctionGroupValues } from '../../../types/Shared.types';

const useAccountFunctionCodeForm = (id: string, group: string) => {
    const user = useSelector((state: RootState) => state.user);
    const [gridApi, setGridApi] = useState(null);
    const [isLoadingSelectedAccounts, setIsLoadingSelectedAccounts] =
        useState(false);

    const { data: activeFunctionCode, isLoading } = useGetFunctionCodeQuery({
        id,
        group,
    });
    const [updateFunctionCode] = useUpdateFunctionCodeMutation();

    const canUpdateFunctionCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        group === FunctionGroupValues.accounting
            ? PERMISSIONS.ACCOUNTING.FUNCTION_CODES.EDIT
            : PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.EDIT
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        setIsLoadingSelectedAccounts(true);

        if (activeFunctionCode && gridApi) {
            if (activeFunctionCode.availableAccounts) {
                gridApi.setDatasource(activeFunctionCode.availableAccounts);
            }
            gridApi.forEachNode((node: RowNode) => {
                const currentAccount: GL_Account = node.data;
                node.setSelected(
                    activeFunctionCode.functions.some(
                        (aFunction: GL_AccountFunction) =>
                            aFunction.accountId === currentAccount.id
                    )
                );
            });
        }

        setIsLoadingSelectedAccounts(false);
    }, [activeFunctionCode, gridApi]);

    const { fields, formMethods, onUpdate, closeForm } = useBaseForm({
        closePath:
            group === FunctionGroupValues.accounting
                ? `/accounting/functionCode/${FunctionGroupValues.accounting}`
                : `/accounting/fixedAssets/functionCode/${FunctionGroupValues.fixedAssets}`,
        blankEntity: {},
        activeEntity: activeFunctionCode,
        getDescription: () => {
            return group === FunctionGroupValues.accounting
                ? `Accounting Function Code ${fields.code}`
                : `Fixed Asset Function Code ${fields.code}`;
        },
        createEntity: undefined,
        updateEntity: () => {
            const functionList: GL_AccountFunction[] = gridApi
                ?.getSelectedRows()
                .map((account: GL_Account) => {
                    return {
                        accountId: account.id,
                        functionCodeId: activeFunctionCode.id,
                    };
                });

            return updateFunctionCode({
                data: {
                    id: id as unknown as number,
                    postBody: { ...fields, functions: functionList },
                },
                group: group,
            });
        },
        deleteEntity: undefined,
    });

    return {
        activeFunctionCode,
        fields,
        formMethods,
        onUpdate,
        onGridReady,
        closeForm,
        isLoading,
        isLoadingSelectedAccounts,
        canUpdateFunctionCode,
    };
};

export default useAccountFunctionCodeForm;
