import { useNavigate } from 'react-router-dom';
import {
    useGetAllDepreciationMethodsQuery,
    useUpdateListDepreciationMethodsMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import React, { useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
    FA_DepreciationMethod,
    FA_DepreciationMethodCost,
} from '../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

const UseFixedAssetDepreciationMethodGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: methodList, isLoading: isLoadingMethods } =
        useGetAllDepreciationMethodsQuery();

    const [updateDepreciationMethods] =
        useUpdateListDepreciationMethodsMutation();

    const canCreateDepreciationMethod = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.CREATE
    );
    const canViewDepreciationMethod = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW
    );

    const handleGridEdits = (editedRows: FA_DepreciationMethod[]) => {
        updateDepreciationMethods(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/depreciationMethod/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/depreciationMethod/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    const getCostCodeValue = (methodCosts: FA_DepreciationMethodCost[]) => {
        return isNilOrEmpty(methodCosts)
            ? ''
            : methodCosts
                  .map(
                      (dmCost: FA_DepreciationMethodCost) =>
                          dmCost.costCode.code
                  )
                  .join(', ');
    };

    const CostCodeColumn = (params: ICellRendererParams) => {
        return <span>{params.value}</span>;
    };

    useEffect(() => {
        let maxValue = 1;
        if (methodList && methodList.length > 0) {
            const data = _.maxBy(
                methodList,
                (depreciationMethod: FA_DepreciationMethod) =>
                    depreciationMethod.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, methodList);

    return {
        methodList,
        isLoadingMethods,
        redirectToForm,
        CostCodeColumn,
        getCostCodeValue,
        canCreateDepreciationMethod,
        canViewDepreciationMethod,
        handleGridEdits,
    };
};

export default UseFixedAssetDepreciationMethodGrid;
