import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import useToxicTestForm from './hooks/useToxicTestForm';
import { ToxicTestFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const ToxicTestForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingToxicTest,
        canCreateToxicTest,
        canUpdateToxicTest,
        canDeleteToxicTest,
        termSet,
    } = useToxicTestForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ToxicTestFormDefs.Toxic_Test}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingToxicTest}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateToxicTest}
            displayUpdateButton={canUpdateToxicTest}
            displayDeleteButton={canDeleteToxicTest}
            readOnly={id && !canUpdateToxicTest}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ToxicTestFormDefs.Code}
                                />
                            }
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={10}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={ToxicTestFormDefs.Description}
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};
export default ToxicTestForm;
