import useDepreciationSummaryGrid from './hooks/useDepreciationSummaryGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import React from 'react';
import DepreciationSummaryModal from './DepreciationSummaryModal';
import { Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';

const DepreciationSummaryGrid = () => {
    const {
        showOptionsModal,
        setShowOptionsModal,
        setAssetEntryList,
        mainEntryRows,
        canViewDepreciationSummaryReport,
    } = useDepreciationSummaryGrid();

    const reportEntryColumnDefs: ColDef[] = [
        {
            field: 'asset.ledgerAssetType.code',
            headerName: 'Asset Type',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.number',
            headerName: 'Asset No',
            sort: 'asc',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.code',
            headerName: 'Asset Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.name',
            headerName: 'Asset Name',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'asset.inServiceDate',
            headerName: 'In Service Date',
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'asset.totalCost',
            headerName: 'Total Cost',
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.remainingValue',
            headerName: 'Beginning Value',
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.depreciationValue',
            headerName: 'Depreciation',
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'asset.endingValue',
            headerName: 'Ending Value',
            ...DefaultColumnTypes.Currency,
        },
    ];

    return (
        <React.Fragment>
            <DepreciationSummaryModal
                open={showOptionsModal}
                onDepreciationSummaryLoaded={(assetDisposal) =>
                    setAssetEntryList(assetDisposal)
                }
                onClose={() => setShowOptionsModal(false)}
            />

            <BaseFormGrid
                title={'Depreciation Summary Report'}
                defaultColDef={{ flex: 1 }}
                displayGrid={canViewDepreciationSummaryReport}
                isLoading={false}
                rowData={mainEntryRows}
                columnDefs={reportEntryColumnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }
                displayExportDataButton={true}
            />
        </React.Fragment>
    );
};

export default DepreciationSummaryGrid;
