import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    useDeleteGridLayoutMutation,
    useUpdateEntitySettingMutation,
} from '../../../services/organizations/organizations.service';

const useEditModal = ({ settingValue, handleClose }: any) => {
    const [inputValue, setInputValue] = useState('');
    const user = useSelector((state: RootState) => state.user);

    const [deleteGridLayout] = useDeleteGridLayoutMutation();
    const [updateEntitySetting] = useUpdateEntitySettingMutation();

    useEffect(() => {
        setInputValue(settingValue?.subKey);
    }, [settingValue]);

    const handleChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const handleDelete = () => {
        deleteGridLayout(settingValue?.id);
        handleClose();
    };

    const handleSave = () => {
        const args = {
            id: settingValue?.id,
            postBody: {
                subKey: inputValue,
            },
        };
        updateEntitySetting(args);
        handleClose();
    };

    return { inputValue, handleChange, handleDelete, user, handleSave };
};

export default useEditModal;
