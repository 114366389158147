import React, { useState } from 'react';
import SdsSequences from './sequenceComponents/SdsSequences';
import { Card, CardContent, CardHeader } from '@mui/material';
import SelectDropdown from '../../../components/form/selectDropdown/SelectDropdown';
import RDFormulaSequences from './sequenceComponents/RDFormulaSequences';

//here we have our dropdown selections of all of our sequence implementations
const SEQUENCES = [
    {
        label: 'Safety Data Sheet',
        value: '0',
        component: <SdsSequences />,
    },
    {
        label: 'R&D Formula',
        value: '1',
        component: <RDFormulaSequences />,
    },
];

const RenderSelectedSequence = ({ selectedSequence }: any) => {
    return SEQUENCES.find(
        (sequence: any) => sequence.value === selectedSequence
    ).component;
};

const Sequences = () => {
    const [selectedSequence, setSelectedSequence] = useState('0');

    return (
        <Card>
            <CardHeader
                title={
                    <SelectDropdown
                        placeholder="Sequence Select..."
                        value={selectedSequence}
                        options={SEQUENCES}
                        onChange={(value: any) => setSelectedSequence(value)}
                    />
                }
            />
            <CardContent>
                <RenderSelectedSequence selectedSequence={selectedSequence} />
            </CardContent>
        </Card>
    );
};

export default Sequences;
