import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import EditableBaseFormGrid from '../../../components/grids/EditableBaseFormGrid';
import { HazardClassificationMapping } from '../../../types/formulation';
import useHazardClassificationMappingGrid from './hooks/useHazardClassificationMappingGrid';
import TranslatableText from '../../../components/i18n/TranslatableText';
import { HazardClassMappingGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const HazardClassificationMappingGrid = () => {
    const {
        isLoadingLocaleGrps,
        isLoadingHazardClassMappings,
        columnDefs,
        hazardClassMappingList,
        handleUpdate,
        termSet,
    } = useHazardClassificationMappingGrid();

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <EditableBaseFormGrid<HazardClassificationMapping>
            displayGrid={true}
            dataKey={'hazardClasificationId'}
            isLoading={
                columnDefs == null ||
                isLoadingLocaleGrps ||
                isLoadingHazardClassMappings
            }
            rowData={hazardClassMappingList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={
                            HazardClassMappingGridDefs.Hazard_Class_Mapping
                        }
                    />
                ) as unknown as string
            }
            displayToolbar={false}
            displayCreateNewButton={false}
            displayExportDataButton={false}
            displayResetStateButton={false}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}
            isEditable={true}
            onSave={handleUpdate}></EditableBaseFormGrid>
    );
};

export default HazardClassificationMappingGrid;
