import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllFixedAssetsQuery } from '../../../../services/fixedAssets/fixedAssets.service';
import { FA_Asset } from '../../../../types/FixedAsset.types';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CreateIcon from '@mui/icons-material/Create';
import { SelectionChangedEvent } from 'ag-grid-community';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { RoutingValues } from '../../../../types/Shared.types';
import OptionsMenuList from '../modals/OptionsMenuList';
import { OptionItem } from '../modals/hooks/useOptionsMenuList';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';

export enum FixedAssetGridMode {
    Normal = 0,
    CreateSchedule = 1,
    CreateDepreciation = 2,
    CreateDisposalEntry = 3,
    CreateAssetEntry = 4,
}

const useFixedAssetGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_GRID,
              }
            : skipToken
    );

    const navigate = useNavigate();
    const [gridMode, setGridMode] = useState<FixedAssetGridMode>(
        FixedAssetGridMode.Normal
    );

    const [showCreateScheduleModal, setShowCreateScheduleModal] =
        useState<boolean>(false);
    const [scheduleStartDate] = useState<Date>(new Date());

    const [showCreateEntryModal, setShowCreateEntryModal] =
        useState<boolean>(false);

    const [showCreateDisposalModal, setShowCreateDisposalModal] =
        useState<boolean>(false);

    const [showCreateAssetEntryModal, setShowCreateAssetEntryModal] =
        useState<boolean>(false);

    const [selectedIdList, setSelectedIdList] = useState<bigint[]>([]);
    const [hasSelected, setHasSelected] = useState<boolean>(false);

    const [currentGridProps, setCurrentGridProps] = useState<any>(null);

    const { data: assetList, isLoading: isLoadingAssets } =
        useGetAllFixedAssetsQuery();

    const canCreateAssetRecords = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.CREATE
    );
    const canViewAssetRecords = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW
    );
    const canImportAssetRecords = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.IMPORT
    );
    const canCreateDepreciationSchedules = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.CREATE
    );
    const canCreateDepreciationEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.CREATE
    );

    const canCreateDisposalEntry = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.CREATE
    );

    const canCreateAssetEntries = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.CREATE
    );

    useEffect(() => {
        if (gridMode === FixedAssetGridMode.Normal) {
            setCurrentGridProps(null);
        }
    }, [gridMode]);

    const gridModes = () => {
        return [
            {
                id: 1,
                gridMode: FixedAssetGridMode.CreateSchedule,
                canDisplay: canCreateDepreciationSchedules,
                createText: 'Add Schedules',
                exitText: 'Exit Schedule',
            },
            {
                id: 2,
                gridMode: FixedAssetGridMode.CreateDepreciation,
                canDisplay: canCreateDepreciationEntries,
                createText: 'Add Depreciation Entries',
                exitText: 'Exit Depreciation Entry',
            },
            {
                id: 3,
                gridMode: FixedAssetGridMode.CreateDisposalEntry,
                canDisplay: canCreateDisposalEntry,
                createText: 'Add Disposal Entries',
                exitText: 'Exit Disposal Entry',
            },
            {
                id: 4,
                gridMode: FixedAssetGridMode.CreateAssetEntry,
                canDisplay: canCreateAssetEntries,
                createText: 'Add Asset Entries',
                exitText: 'Exit Asset Entry',
            },
        ];
    };

    const redirectToForm = (row: FA_Asset) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/fixedAsset/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/fixedAsset/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const idList: bigint[] = event.api
            .getSelectedRows()
            .map((fixedAsset: FA_Asset) => fixedAsset.id);

        setSelectedIdList(idList);
        setHasSelected(!isNilOrEmpty(idList));
    };

    const getOption = (option: any): OptionItem => {
        return {
            id: option.id,
            labelOption: option.createText,
            canDisplay: option.canDisplay,
        };
    };
    const getOptions = gridModes().map((option) => getOption(option));

    const showOptionsMenuList =
        getOptions.filter((option) => option.canDisplay)?.length > 0;

    const onClickSelection = (event: any) => {
        if (event.target.id) {
            const id = Number(event.target.id);
            const gridChange = gridModes().find((x) => x.id === id);
            setGridMode(gridChange.gridMode);
            setCurrentGridProps(gridChange);
        }
    };

    const handleCreated = () => {
        setGridMode(FixedAssetGridMode.Normal);
    };

    const assetToolbar = () => {
        return (
            <React.Fragment>
                {gridMode === FixedAssetGridMode.Normal &&
                showOptionsMenuList ? (
                    <OptionsMenuList
                        listOptions={getOptions}
                        iconMenu={<CreateIcon style={{ color: '#00A84E' }} />}
                        onClickOption={onClickSelection}></OptionsMenuList>
                ) : null}
                {gridMode !== FixedAssetGridMode.Normal ? (
                    <Button
                        variant="text"
                        size="small"
                        onClick={handleCreated}
                        startIcon={
                            <HighlightOffIcon style={{ color: '#FF0000' }} />
                        }>
                        {currentGridProps.exitText}
                    </Button>
                ) : null}
                {gridMode !== FixedAssetGridMode.Normal ? (
                    <Button
                        variant="text"
                        size="small"
                        disabled={!hasSelected}
                        onClick={() =>
                            gridMode === FixedAssetGridMode.CreateSchedule
                                ? setShowCreateScheduleModal(true)
                                : gridMode ===
                                  FixedAssetGridMode.CreateDepreciation
                                ? setShowCreateEntryModal(true)
                                : gridMode ===
                                  FixedAssetGridMode.CreateAssetEntry
                                ? setShowCreateAssetEntryModal(true)
                                : setShowCreateDisposalModal(true)
                        }
                        key={currentGridProps.id}
                        startIcon={<AddIcon style={{ color: '#00A84E' }} />}>
                        {currentGridProps.createText}
                    </Button>
                ) : null}
            </React.Fragment>
        );
    };

    return {
        assetList,
        isLoadingAssets,
        redirectToForm,
        onSelectionChanged,
        assetToolbar,
        gridMode,
        handleCreated,
        selectedIdList,
        setSelectedIdList,
        hasSelected,
        scheduleStartDate,
        showCreateScheduleModal,
        setShowCreateScheduleModal,
        showCreateEntryModal,
        setShowCreateEntryModal,
        showCreateDisposalModal,
        setShowCreateDisposalModal,
        showCreateAssetEntryModal,
        setShowCreateAssetEntryModal,
        canCreateAssetRecords,
        canViewAssetRecords,
        canImportAssetRecords,
        termSet,
    };
};

export default useFixedAssetGrid;
