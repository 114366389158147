import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection9_2Form from './subSections/subSection9_2/SubSection9_2Form';
import SdsPropertyGrid from './subSections/subSection9_1/SdsPropertyGrid';
import { SdsHeader } from '../../../../../../types/formulation';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsPhysicalAndChemicalProperForm = (
    props: ChildEditableGridProps<SdsHeader> &
        SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Physical_Chemical}
                                />
                            ) as unknown as string
                        }>
                        <SdsPropertyGrid {...props} />
                        <SubSection9_2Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsPhysicalAndChemicalProperForm;
