import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { SelectionOption } from '../../../../../types/Shared.types';
import { SafetyDataSheetSectionHeader } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateSafetyDataSheetSectionHeaderMutation,
    useDeleteSafetyDataSheetSectionHeaderMutation,
    useGetSafetyDataSheetSectionHeaderQuery,
    useUpdateSafetyDataSheetSectionHeaderMutation,
} from '../../../../../services/formulation/safetyDataSheetSectionHeaders/safetyDataSheetSectionHeaders.service';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';
import { openModalConfirmBasicWithMessage } from '../../../../../store/uiElements';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface SafetyDataSheetSectionHeaderFormProps {
    id: bigint;
    headerId: string;
    localeGroupId: bigint;
    currentLangId: bigint;
    defaultLangId: bigint;
    showOnSds: boolean;
    section: number;
    subSection: number;
    subSectionLetter?: string;
    localeGroupOptions: SelectionOption[];
    allowDelete: boolean;
    safetyDataSheetSectionHeaderKeyList: Array<string>;
    handleSetPreviousTab(): void;
    handleSetIsFormDirty(formDirty: boolean): void;
    cancelNewLanguage(closeForm: boolean): void;
    setDisableLangButton(disableButton: boolean): void;
}

const useSafetyDataSheetSectionHeaderForm = ({
    id,
    currentLangId,
    defaultLangId,
    headerId,
    localeGroupId,
    showOnSds,
    section,
    subSection,
    subSectionLetter,
    localeGroupOptions,
    handleSetPreviousTab,
    safetyDataSheetSectionHeaderKeyList,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: SafetyDataSheetSectionHeaderFormProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const navigate = useNavigate();

    const {
        data: currentSdsHeader,
        isFetching: isLoadingSafetyDataSheetSectionHeader,
    } = useGetSafetyDataSheetSectionHeaderQuery(id ? id.toString() : skipToken);

    const [createSafetyDataSheetSectionHeader] =
        useCreateSafetyDataSheetSectionHeaderMutation();
    const [updateSafetyDataSheetSectionHeader] =
        useUpdateSafetyDataSheetSectionHeaderMutation();
    const [deleteSafetyDataSheetSectionHeader] =
        useDeleteSafetyDataSheetSectionHeaderMutation();

    const canCreateSdsHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.CREATE
    );
    const canEditSdsHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.EDIT
    );
    const canDeleteSdsHeader = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.SAFETY_DATA_SHEET_SECTION_HEADER.DELETE
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_SECTION_HEADERS_FORM,
              }
            : skipToken
    );

    const afterCreateRecord = () => {
        handleSetIsFormDirty(false);
        navigate(
            `/formulation/sdsSectionHeader/${fields.headerId}/${fields.localeGroupId}`
        );
    };

    const afterDeleteRecord = () => {
        handleSetIsFormDirty(false);
        if (currentLangId === defaultLangId) {
            navigate(`/formulation/sdsSectionHeader`);
        } else {
            handleSetPreviousTab();
        }
    };

    const afterUpdateRecord = () => {
        handleSetIsFormDirty(false);
    };

    const getDefaultLocaleGroup = (): SelectionOption => {
        return localeGroupOptions?.find((localeGroup: any) =>
            (localeGroup.label as string).includes('US')
        );
    };

    const blankSdsHeader: SafetyDataSheetSectionHeader = {
        headerId: headerId,
        localeGroupId: localeGroupId,
        langId: currentLangId,
        sectionText: null,
        showOnSds: showOnSds != null ? showOnSds : false,
        section: section ? section : 0,
        subSection: subSection ? subSection : 0,
        subSectionLetter: subSectionLetter,
        businessEntityId: user?.businessEntity?.id,
    };

    const {
        fields,
        formMethods,
        setValues,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
    } = useBaseForm({
        blankEntity: blankSdsHeader,
        activeEntity: id ? currentSdsHeader : undefined,
        getDescription: () => {
            const currentLocaleGrp = getCurrentLocaleGroup();
            return `SDS Section Header ${fields.headerId} with Locale Group ${currentLocaleGrp}`;
        },
        createEntity: () => {
            const postBody: SafetyDataSheetSectionHeader = {
                headerId: fields.headerId,
                localeGroupId: fields.localeGroupId,
                langId: fields.langId,
                businessEntityId: fields.businessEntityId,
                sectionText: fields.sectionText,
                showOnSds: fields.showOnSds,
                section: fields.section,
                subSection: fields.subSection,
                subSectionLetter: fields.subSectionLetter,
            };
            return createSafetyDataSheetSectionHeader({ postBody });
        },
        updateEntity: () => {
            return updateSafetyDataSheetSectionHeader({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteSafetyDataSheetSectionHeader(id.toString());
        },
        afterCreate: afterCreateRecord,
        afterDelete: afterDeleteRecord,
        afterUpdate: afterUpdateRecord,
    });

    // since localeGroupId is required, if none is set we can infer the form is in 'new' state
    // when localeGroupOptions loads, we get the default value, set it in the blankSdsHeader and then reset form
    // watching the field.localeGroupId is needed for when user clicks cancel to reset form
    useEffect(() => {
        if (
            localeGroupOptions &&
            isNilOrEmpty(localeGroupId) &&
            isNilOrEmpty(fields.localeGroupId)
        ) {
            const defaultLocaleGroupId = getDefaultLocaleGroup()?.value;
            if (defaultLocaleGroupId) {
                blankSdsHeader.localeGroupId = defaultLocaleGroupId;
            }
            if (defaultLocaleGroupId) {
                setValues(blankSdsHeader);
            }
        }
    }, [localeGroupId, localeGroupOptions, fields.localeGroupId]);

    useEffect(() => {
        if (id && currentSdsHeader && id === currentSdsHeader.id) {
            if (getFieldsChanged(currentSdsHeader)) {
                handleSetIsFormDirty(true);
            } else {
                handleSetIsFormDirty(false);
            }
        } else if (!id) {
            handleSetIsFormDirty(true);
        }
    }, [fields]);

    useEffect(() => {
        setDisableLangButton(id ? false : true);
    }, [id]);

    const getFieldsChanged = (
        currentEntity: SafetyDataSheetSectionHeader
    ): boolean => {
        if (fields && currentEntity && currentEntity.id === fields.id) {
            return (
                fields.headerId !== currentEntity.headerId ||
                fields.localeGroupId !== currentEntity.localeGroupId ||
                fields.sectionText !== currentEntity.sectionText ||
                fields.section !== currentEntity.section ||
                fields.subSection !== currentEntity.subSection ||
                fields.showOnSds !== currentEntity.showOnSds
            );
        }
    };

    const handleCancelForm = () => {
        if (!id) {
            cancelNewLanguage(currentLangId === defaultLangId);
        } else {
            setValues(currentSdsHeader);
        }
    };

    const getCurrentLocaleGroup = () => {
        return localeGroupOptions?.find(
            (current) => current.value === fields.localeGroupId
        ).label;
    };

    const showExistingSdsHeader = () => {
        handleSetIsFormDirty(false);
        navigate(
            `/formulation/sdsSectionHeader/${fields.headerId}/${fields.localeGroupId}`
        );
    };

    const handleHeaderKeyOnBlur = () => {
        if (
            !id &&
            currentLangId &&
            safetyDataSheetSectionHeaderKeyList &&
            fields?.headerId &&
            fields?.localeGroupId
        ) {
            const headerIdLocaleGroupId = `${fields.headerId}${fields.localeGroupId}`;
            const currentHeaderKey = safetyDataSheetSectionHeaderKeyList.find(
                (current) => current === headerIdLocaleGroupId
            );
            if (currentHeaderKey) {
                const currentLocaleGrp = getCurrentLocaleGroup();
                const message = `SDS Section Header with Header ID ${fields.headerId} and Locale Group ID ${currentLocaleGrp} already exists.\nDo you want to show it?`;
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: showExistingSdsHeader,
                    })
                );
            }
        }
    };

    return {
        fields,
        formMethods,
        isLoadingSafetyDataSheetSectionHeader,
        canCreateSdsHeader,
        canEditSdsHeader,
        canDeleteSdsHeader,
        handleFieldChange,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        handleHeaderKeyOnBlur,
        termSet,
    };
};

export default useSafetyDataSheetSectionHeaderForm;
