import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { SdsHeader } from '../../../../../../types/formulation';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import SubSection2_1Form from './subSections/subSection2_1/SubSection2_1Form';
import SubSection2_3Form from './subSections/subSection2_3/SubSection2_3Form';
import SubSection2_4Form from './subSections/subSection2_4/SubSection2_4Form';
import SubSection2_2Form from './subSections/subSection2_2/SubSection2_2Form';
import { ChildFormProps } from '../../../../../form/helpers/ChildFormProps';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsHazardInformationForm = (
    props: SdsBlobSectionFormProps<SdsHeader> &
        ChildEditableGridProps<SdsHeader> &
        ChildFormProps<SdsHeader>
) => {
    const { parentData, displayGridButtons, permissions, helpers } = props;

    const { isParentLoading } = parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Hazard_Identification}
                                />
                            ) as unknown as string
                        }>
                        <SubSection2_1Form {...props} termSet={props.termSet} />
                        <SubSection2_2Form
                            termSet={props.termSet}
                            parentData={parentData}
                            displayGridButtons={displayGridButtons}
                            permissions={permissions}
                            helpers={helpers}
                        />
                        <SubSection2_3Form {...props} termSet={props.termSet} />
                        <SubSection2_4Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsHazardInformationForm;
