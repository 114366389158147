import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import useFixedAssetDepreciationMethodTypeForm from './hooks/useFixedAssetDepreciationMethodTypeForm';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { DEPRECIATION_METHOD_TYPE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';

const FixedAssetDepreciationMethodTypeForm = () => {
    const { id, type } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        canCreateDepreciationMethodType,
        canUpdateDepreciationMethodType,
        canDeleteDepreciationMethodType,
    } = useFixedAssetDepreciationMethodTypeForm(id);

    return (
        <BaseForm
            title={'Fixed Asset Depreciation Method Type'}
            formMethods={formMethods}
            isLoading={isLoadingType}
            isNew={type === RoutingValues.create}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={DEPRECIATION_METHOD_TYPE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateDepreciationMethodType}
            displayUpdateButton={canUpdateDepreciationMethodType}
            displayDeleteButton={canDeleteDepreciationMethodType}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Depreciation Method Type Code"
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label="Sort Order"
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label="Journal Posting"
                            control={
                                <Checkbox
                                    id="postsJournalEntries"
                                    checked={fields.postsJournalEntries}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label="Predefined Schedule"
                            control={
                                <Checkbox
                                    id="predefinedSchedule"
                                    checked={fields.predefinedSchedule}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetDepreciationMethodTypeForm;
