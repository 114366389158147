import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useBusinessEntityGrid from './hooks/useBusinessEntityGrid';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { BusinessEntityGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';
const BusinessEntityGrid = () => {
    const {
        breadcrumbs,
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        viewEntitiesColumn,
        navigateToPreviousEntity,
        onSortChanged,
        onFirstDataRendered,
        canCreateBusinessEntities,
        termSet,
    } = useBusinessEntityGrid();

    return (
        <>
            {!isLoading ? (
                <>
                    <Breadcrumbs maxItems={3} aria-label="breadcrumb">
                        {breadcrumbs.map((breadcrumb: any, index: number) => {
                            if (index === breadcrumbs.length - 1) {
                                return (
                                    <Typography
                                        color="text.primary"
                                        key={breadcrumb?.id}>
                                        {breadcrumb.name}
                                    </Typography>
                                );
                            }

                            return (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    key={breadcrumb.id}
                                    onClick={() => {
                                        navigateToPreviousEntity(breadcrumb);
                                    }}>
                                    <div style={{ cursor: 'pointer' }}>
                                        {breadcrumb.name}
                                    </div>
                                </Link>
                            );
                        })}
                    </Breadcrumbs>

                    <AgGrid
                        testId="business-entity-list-grid"
                        rowData={rowData}
                        displayToolbar={true}
                        onCreateNew={(row) => redirectToForm(row)}
                        displayCreateNewButton={canCreateBusinessEntities}
                        onRowClicked={({ data }) => redirectToForm(data)}
                        onFirstDataRendered={onFirstDataRendered}
                        onSortChanged={onSortChanged}
                        onGridReady={onGridReady}
                        frameworkComponents={{
                            viewEntities: viewEntitiesColumn,
                        }}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            unSortIcon: true,
                            filter: true,
                            floatingFilter: true,
                            wrapText: true,
                        }}>
                        <AgGridColumn
                            filter={false}
                            sortable={false}
                            field="id"
                            headerName=""
                            width={55}
                            cellRenderer="viewEntities"
                        />
                        <AgGridColumn
                            flex={1}
                            filter={'agTextColumnFilter'}
                            minWidth={250}
                            field="name"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={BusinessEntityGridDefs.Name}
                                    />
                                );
                            }}
                        />
                        <AgGridColumn
                            minWidth={200}
                            filter={'agTextColumnFilter'}
                            field="description"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={
                                            BusinessEntityGridDefs.Description
                                        }
                                    />
                                );
                            }}
                        />
                        <AgGridColumn
                            minWidth={250}
                            filter={'agTextColumnFilter'}
                            field="subscriber.name"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={
                                            BusinessEntityGridDefs.Subscriber
                                        }
                                    />
                                );
                            }}
                        />
                        <AgGridColumn
                            minWidth={200}
                            filter={'agDateColumnFilter'}
                            filterParams={dateValueComparator}
                            field="createdAt"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={
                                            BusinessEntityGridDefs.Date_Created
                                        }
                                    />
                                );
                            }}
                            valueFormatter={dateFormatter}
                        />
                        <AgGridColumn
                            minWidth={200}
                            filter={'agDateColumnFilter'}
                            filterParams={dateValueComparator}
                            field="updatedAt"
                            headerComponentFramework={(props: any) => {
                                return (
                                    <CustomHeader
                                        {...props}
                                        termSet={termSet}
                                        termKey={
                                            BusinessEntityGridDefs.Last_Update_Date
                                        }
                                    />
                                );
                            }}
                            valueFormatter={dateFormatter}
                        />
                    </AgGrid>
                </>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default BusinessEntityGrid;
