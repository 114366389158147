import React from 'react';
import { useParams } from 'react-router-dom';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import useSdsForm from './hooks/useSdsForm';
import SdsHeaderForm from './sections/sdsHeader/SdsHeaderForm';
import SdsIdentificationForm from './sections/sdsIdentification/SdsIdentificationForm';
import SdsHazardInformationForm from './sections/sdsHazardInformation/SdsHazardInformationForm';
import SdsCasCompositionForm from './sections/sdsCasComposition/SdsCasCompositionForm';
import SdsExposureControlsForm from './sections/sdsExposures/subSections/SdsExposureControlsForm';
import SdsPhysicalAndChemicalProperForm from './sections/sdsPhysicalAndChemicalProper/SdsPhysicalAndChemicalProperForm';
import SdsToxicologicalForm from './sections/sdsToxicological/SdsToxicologicalForm';
import SdsEcologicalInformationForm from './sections/sdsEcologicalInformation/SdsEcologicalInformationForm';
import SdsRegulatoryInformationForm from './sections/sdsRegulatoryInformation/SdsRegulatoryInformationForm';
import SdsFirstAidMeasuresForm from './sections/sdsFirstAidMeasures/SdsFirstAidMeasuresForm';
import SdsFirefightingMeasuresForm from './sections/sdsFirefighting/SdsFirefightingMeasuresForm';
import SdsAccidentalReleaseForm from './sections/sdsAccidentalRelease/SdsAccidentalReleaseForm';
import SdsHandlingStorageForm from './sections/sdsHandlingStorage/SdsHandlingStorageForm';
import SdsStabilityReactivity from './sections/sdsStabilityReactivity/SdsStabilityReactivity';
import SdsDisposalConsiderationsForm from './sections/sdsDisposalConsiderations/SdsDisposalConsiderationsForm';
import SdsTransportForm from './sections/sdsTransport/SdsTransportForm';
import { SDSFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const SdsForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        hasEntityChanges,
        headerHook,
        onCreate,
        onDelete,
        onUpdate,
        closeForm,
        childForms,
        allFormMethods,
        display,
        children,
        termSet,
    } = useSdsForm(id);

    const {
        displayCreateNewButton,
        displayUpdateButton,
        displayDeleteButton,
        readOnly,
    } = display;

    const { editableGridProps, childFormProps, blobMethods } = children;

    const blobProps = {
        permissions: childFormProps.permissions,
        blobMethods,
    };

    const editableGridAndBlobProps = {
        ...editableGridProps,
        ...blobProps,
    };

    const childFormAndBlobProps = {
        ...childFormProps,
        ...blobProps,
    };

    const editableGridChildFormsBlobProps = {
        ...editableGridProps,
        ...blobProps,
        helpers: { ...editableGridProps.helpers, ...childFormProps.helpers },
    };

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={SDSFormDefs.Safety_Data_Sheet_Maintenance}
                />
            }
            formMethods={allFormMethods}
            isLoading={headerHook.isLoadingSdsRecord}
            isNew={!id}
            hasEntityChanges={hasEntityChanges}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={displayCreateNewButton}
            displayUpdateButton={displayUpdateButton}
            displayDeleteButton={displayDeleteButton}
            readOnly={readOnly}
            showOnRevision={true}
            entityUuid={fields?.uuid}
            childForms={childForms}>
            {/*Primary Information*/}
            <SdsHeaderForm {...headerHook} termSet={termSet} />
            {/*Section 1*/}
            <SdsIdentificationForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 2*/}
            <SdsHazardInformationForm
                {...editableGridChildFormsBlobProps}
                termSet={termSet}
            />
            {/*Section 3*/}
            <SdsCasCompositionForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 4*/}
            <SdsFirstAidMeasuresForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 5*/}
            <SdsFirefightingMeasuresForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 6*/}
            <SdsAccidentalReleaseForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 7*/}
            <SdsHandlingStorageForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 8*/}
            <SdsExposureControlsForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 9*/}
            <SdsPhysicalAndChemicalProperForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 10*/}
            <SdsStabilityReactivity
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 11*/}
            <SdsToxicologicalForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 12*/}
            <SdsEcologicalInformationForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 13*/}
            <SdsDisposalConsiderationsForm
                {...childFormAndBlobProps}
                termSet={termSet}
            />
            {/*Section 14*/}
            <SdsTransportForm {...childFormAndBlobProps} termSet={termSet} />
            {/*Section 15*/}
            <SdsRegulatoryInformationForm
                {...editableGridAndBlobProps}
                termSet={termSet}
            />
            {/*Section 16*/}
            {/*Please Add Section 16 Component Here!*/}
        </BaseForm>
    );
};
export default SdsForm;
