import React, { useContext } from 'react';
import { isFeatureFlagEnabled } from '../../../utils/featureFlag/featureFlagUtil';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import SelectInput from '../../../components/form/formInputs/SelectInput/SelectInput';
import SettingsContext from '../../../contexts/settings.context';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';
import BaseFormToolbar from '../../../components/form/baseForm/BaseFormToolbar';
import useSettings from '../hooks/useSettings';
import {
    useGetCountriesForSettingsQuery,
    useGetStatesForDropdownQuery,
} from '../../../services/organizations/organizations.service';

const BusinessEntitySettings = () => {
    const { handleChange, handleSaveSettings, navigate, user, sharedTermSet } =
        useSettings();

    const { settings } = useContext(SettingsContext);

    const { data: countries } = useGetCountriesForSettingsQuery();
    const { data: states } = useGetStatesForDropdownQuery();

    return (
        <Card>
            <CardHeader
                sx={{ paddingLeft: '0px', paddingRight: '0px' }}
                title={
                    <>
                        <BaseFormToolbar
                            isNew={false}
                            onCancel={() => navigate(-1)}
                            displayUpdateButton={true}
                            onUpdate={handleSaveSettings}
                            termSet={sharedTermSet}
                        />
                    </>
                }
            />
            <CardContent>
                {isFeatureFlagEnabled(
                    user?.enabledFeatureFlags,
                    'settings.entitySettings.businessEntitySettings'
                ) && (
                    <>
                        <FormPartial title={'Business Entity Settings'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultLanguage'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultLanguage
                                        }
                                        label="Default Language"
                                        options={[
                                            { label: 'English', value: 'eng' },
                                            { label: 'Spanish', value: 'esp' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'timeZone'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.timeZone
                                        }
                                        label="Time Zone"
                                        options={[
                                            { label: 'EST', value: 'est' },
                                            { label: 'PST', value: 'pst' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'dateFormat'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.dateFormat
                                        }
                                        label="Date Format"
                                        options={[
                                            {
                                                label: 'MM/DD/YYYY',
                                                value: 'MM/DD/YYYY',
                                            },
                                            {
                                                label: 'YYYY/MM/DD',
                                                value: 'YYYY/MM/DD',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'numberFormat'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.numberFormat
                                        }
                                        label="Number Format"
                                        options={[
                                            { label: '0.00', value: '0.00' },
                                            { label: '0,00', value: '0,00' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'displayPreference'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.displayPreference
                                        }
                                        label="Display Name Preference"
                                        options={[
                                            {
                                                label: 'First Last',
                                                value: 'firstLast',
                                            },
                                            {
                                                label: 'Preferred Last',
                                                value: 'prefLast',
                                            },
                                            {
                                                label: 'Last, First',
                                                value: 'lastFirst',
                                            },
                                            {
                                                label: 'Prefix First Middle Last Suffix, “Preferred"',
                                                value: 'prefixFMLSuffix',
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        id={'erpStartDate'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.erpStartDate
                                        }
                                        label="ERP Start Date"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultCountry'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultCountry
                                        }
                                        label="Default Country"
                                        options={countries}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        id={'defaultState'}
                                        onChange={(event: any) =>
                                            handleChange(
                                                event,
                                                'businessEntitySettings'
                                            )
                                        }
                                        fullWidth
                                        value={
                                            settings.businessEntitySettings
                                                ?.defaultState
                                        }
                                        label="Default State"
                                        options={states}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessEntitySettings;
