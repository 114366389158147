export const PLATFORM_DEFS = {
    USER_FORM: 'user-form',
    USER_GRID: 'user-grid',
    USER_SETTINGS: 'user-settings',
    BUSINESS_ENTITY_GRID: 'business-entity-grid',
    SUBSCRIBER_GRID: 'subscriber-grid',
    SUBSCRIBER_FORM: 'subscriber-form',
    BUSINESS_ENTITY_FORM: 'business-entity-form',
    BUILDINGS_GRID: 'buildings-grid',
    BUILDINGS_FORM: 'buildings-form',
    GROUPS_GRID: 'groups-grid',
    ROLES_GRID: 'roles-grid',
    GROUPS_FORM: 'groups-form',
    ROLES_FORM: 'roles-form',
    REVISION_HISTORY_FORM: 'revision-history-form',
    LANGUAGES_GRID: 'languages-grid',
    DEPARTMENT_CODES_FORM: 'department-codes-form',
    DEPARTMENT_CODES_GRID: 'department-codes-grid',
    PERMISSIONS_GRID: 'permissions-grid',
    FEATURE_FLAGS_GRID: 'feature-flags-grid',
    SHARED_COMPONENTS: 'shared-components',
};
