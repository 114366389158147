import React from 'react';
import AgGrid from '../../components/grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useBuildingsGrid from './hooks/useBuildingsGrid';
import { BuildingsGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';

const BuildingsGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFilterChanged,
        onFirstDataRendered,
        termSet,
    } = useBuildingsGrid();

    return (
        <>
            {!isLoading ? (
                <AgGrid
                    testId="buildings-list-grid"
                    dataKey="id"
                    rowData={rowData}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onFilterChanged={onFilterChanged}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        floatingFilter: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        field="code"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Building_Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="name"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Building_Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="supplierId"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Supplier_Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="customerId"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Customer_Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.street"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Street}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.city"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.City}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.county"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.County}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.stateCode.name"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.State}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.postalCode"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Postal_Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="address.countryCode.name"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Country_Code}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        field="note"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={BuildingsGridDefs.Note}
                                />
                            );
                        }}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default BuildingsGrid;
