import React, { useContext, useEffect, useState } from 'react';
import {
    HazardClassificationMapping,
    LocaleGroup,
} from '../../../../types/formulation';
import DefaultColumnTypes from '../../../../components/grids/columns/Column.constants';
import { ColDef } from 'ag-grid-community';
import { useListLocaleGroupByActiveQuery } from '../../../../services/formulation/localeGroup/localeGroup.service';
import {
    useListhazardClassMappingsQuery,
    useUpdatehazardClassMappingsMutation,
} from '../../../../services/formulation/hazardClassificationMapping/hazardClassificationMapping.service';
import { cloneDeep } from 'lodash';
import useEditableBaseGrid from '../../../grid/hooks/useEditableBaseGrid';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../components/grids/CustomHeader';
import { HazardClassMappingGridDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';

const useHazardClassificationMappingGrid = () => {
    const { isLoading: isLoadingLocaleGrps, data: localeGrpList } =
        useListLocaleGroupByActiveQuery(true);

    const {
        isLoading: isLoadingHazardClassMappings,
        data: hazardClassMappingData,
    } = useListhazardClassMappingsQuery();

    const [updateHazardClassMappings] = useUpdatehazardClassMappingsMutation();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.HAZARD_CLASS_MAPPING_GRID,
              }
            : skipToken
    );

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);
    const [hazardClassMappingList, setHazardClassMappingList] =
        useState<HazardClassificationMapping[]>(null);

    const { onUpdate } = useEditableBaseGrid<HazardClassificationMapping[]>({
        getDescription: () => 'Hazard Class Mappings',
        updateEntity: (mappings) => {
            const postBody = mappings;
            return updateHazardClassMappings({ postBody });
        },
    });

    // Method to create dynamic columns based on active Locale Groups
    const buildLocaleGrpColumns = (): ColDef[] => {
        const localGrpColums: ColDef[] = [];
        localeGrpList
            .filter((current: any) => current.active === true)
            .forEach((current: any) => {
                const colDef: ColDef = {
                    field: `${current.groupId}${current.id}Value`, //This should match the respective value property in HazardClassificationMapping objects. Example USA19Value
                    headerName: current.groupId,
                    minWidth: DefaultColumnTypes.MediumText.minWidth,
                    filter: DefaultColumnTypes.MediumText.filter,
                    editable: true,
                };
                localGrpColums.push(colDef);
            });
        return localGrpColums;
    };

    const handleUpdate = (records: HazardClassificationMapping[]) => {
        if (records) {
            onUpdate(records);
        }
    };

    useEffect(() => {
        if (localeGrpList) {
            const localeGrpColums = buildLocaleGrpColumns();
            setColumnDefs([...staticColumnDefs, ...localeGrpColums]);
        }
    }, [isLoadingLocaleGrps, termSet]);

    //Static colums, this will not change in the grid
    const staticColumnDefs = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassMappingGridDefs.Hazard_Code}
                    />
                );
            },
            minWidth: DefaultColumnTypes.ShortText.minWidth,
            filter: DefaultColumnTypes.ShortText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={HazardClassMappingGridDefs.Description}
                    />
                );
            },
            minWidth: DefaultColumnTypes.MediumText.minWidth,
            filter: DefaultColumnTypes.MediumText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    useEffect(() => {
        if (hazardClassMappingData) {
            setHazardClassMappingList(cloneDeep(hazardClassMappingData));
        }
    }, [hazardClassMappingData]);

    return {
        columnDefs,
        hazardClassMappingList,
        isLoadingLocaleGrps,
        isLoadingHazardClassMappings,
        handleUpdate,
        termSet,
    };
};

export default useHazardClassificationMappingGrid;
