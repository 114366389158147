import React, { FocusEventHandler, useEffect } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { MaxLengthValidator } from '../../../../validators/maxLength.validator';
import { MinLengthValidator } from '../../../../validators/minLength.validator';
import _ from 'lodash';

export type TextInputProps = {
    minLength?: number;
    maxLength?: number;
} & InputBaseProps;
const TextInput = ({ onBlur, ...props }: TextInputProps) => {
    useEffect(() => {
        if (props.formMethods) {
            addValidators({ onBlur, ...props });
        }
    }, []);

    const handlerOnBlur = (onBlur: FocusEventHandler, event: any) => {
        event.target.value = event.target.value.trim();
        if (props.onChange) {
            props.onChange(event);
        }

        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <InputBase
            onBlur={(event) => handlerOnBlur(onBlur, event)}
            {...props}
        />
    );
};

const addValidators = (props: TextInputProps) => {
    if (props.maxLength) {
        props.formMethods?.addValidator(
            props.id,
            _.isObject(props.label) ? (
                <>{props.label}</>
            ) : (
                props.label.toString()
            ),
            MaxLengthValidator(props.maxLength)
        );
    }

    if (props.minLength) {
        props.formMethods?.addValidator(
            props.id,
            _.isObject(props.label) ? (
                <>{props.label}</>
            ) : (
                props.label.toString()
            ),
            MinLengthValidator(props.minLength)
        );
    }
};

export default TextInput;
