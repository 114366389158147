import React from 'react';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import useGhsPrecautionaryPhraseForm, {
    PrecautionaryPhraseFormProps,
} from './hooks/useGhsPrecautionaryPhraseForm';
import { GhsPrecautionaryPhrasesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const GhsPrecautionaryPhraseForm = ({
    id,
    currentLangId,
    phraseIdList,
    allowDelete,
    currentPhraseId,
    currentStatementType,
    currentUserInputNeeded,
    defaultLangId,
    handleSetPreviousTab,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: PrecautionaryPhraseFormProps) => {
    const {
        fields,
        formMethods,
        statementTypeOptionsList,
        isLoadingStatementTypes,
        isLoadingPhraseRecord,
        handleFieldChange,
        handlePhraseIdOnBlur,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        canCreatePrecautionaryPhrase,
        canUpdatePrecautionaryPhrase,
        canDeletePrecautionaryPhrase,
        termSet,
    } = useGhsPrecautionaryPhraseForm({
        id,
        currentLangId,
        currentPhraseId,
        currentStatementType,
        allowDelete,
        phraseIdList,
        currentUserInputNeeded,
        defaultLangId,
        handleSetPreviousTab,
        handleSetIsFormDirty,
        cancelNewLanguage,
        setDisableLangButton,
    });

    return (
        <BaseForm
            title={''}
            formMethods={formMethods}
            isLoading={isLoadingPhraseRecord || isLoadingStatementTypes}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={allowDelete ? onDelete : undefined}
            onCancel={handleCancelForm}
            displayCreateButton={canCreatePrecautionaryPhrase}
            displayUpdateButton={canUpdatePrecautionaryPhrase}
            displayDeleteButton={canDeletePrecautionaryPhrase && allowDelete}
            readOnly={id && !canUpdatePrecautionaryPhrase}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsPrecautionaryPhrasesFormDefs.Phrase_ID
                                    }
                                    termSet={termSet}
                                />
                            }
                            id="phraseId"
                            value={fields.phraseId}
                            disabled={!!id || !!currentPhraseId}
                            required={true}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={20}
                            onBlur={handlePhraseIdOnBlur}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsPrecautionaryPhrasesFormDefs.User_Input_Needed
                                    }
                                    termSet={termSet}
                                />
                            }
                            control={
                                <Checkbox
                                    id="userInputNeeded"
                                    checked={fields.userInputNeeded}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            id="phraseText"
                            value={fields.phraseText}
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsPrecautionaryPhrasesFormDefs.Text
                                    }
                                    termSet={termSet}
                                />
                            }
                            maxLength={250}
                            multiline={true}
                            rows={3}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termKey={
                                        GhsPrecautionaryPhrasesFormDefs.Statement_Type
                                    }
                                    termSet={termSet}
                                />
                            }
                            id="statementTypeId"
                            required={true}
                            value={
                                fields.statementTypeId == null
                                    ? ''
                                    : fields.statementTypeId
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statementTypeOptionsList}
                            disabled={isLoadingStatementTypes}
                            SelectProps={{
                                readOnly: id && !canUpdatePrecautionaryPhrase,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default GhsPrecautionaryPhraseForm;
