import React from 'react';
import {
    useCreateCostCodeMutation,
    useDeleteCostCodeMutation,
    useGetCostCodeQuery,
    useUpdateCostCodeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FA_AssetCostCode } from '../../../types/FixedAsset.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';

const UseFixedAssetCostCodeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const costCode = useSelector((state: RootState) => state.fixedAssets);
    const { data: activeCostCode, isLoading: isLoadingCostCode } =
        useGetCostCodeQuery(id === RoutingValues.newId ? skipToken : id);
    const [createCostCode] = useCreateCostCodeMutation();
    const [updateCostCode] = useUpdateCostCodeMutation();
    const [deleteCostCode] = useDeleteCostCodeMutation();

    const canCreateAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.CREATE
    );
    const canUpdateAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.EDIT
    );
    const canDeleteAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.DELETE
    );

    const blankCostCode: FA_AssetCostCode = {
        code: '',
        sortOrder: costCode.sortOrderSet.maxValue,
        isAdd: true,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/costCode',
        blankEntity: blankCostCode,
        activeEntity: activeCostCode,
        getDescription: () => {
            return `Fixed Asset Cost Code ${fields.code}`;
        },
        createEntity: () => {
            return createCostCode({ postBody: fields });
        },
        updateEntity: () => {
            return updateCostCode({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteCostCode(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingCostCode,
        canCreateAssetCostCode,
        canUpdateAssetCostCode,
        canDeleteAssetCostCode,
    };
};

export default UseFixedAssetCostCodeForm;
