import React from 'react';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import BaseGrid from '../../../../../../../../../components/grids/BaseGrid';
import DatacorLogoSpinner from '../../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useSdsGhsPrecautionaryPhrasesGrid from './hooks/useSdsGhsPrecautionaryPhrasesGrid';
import PhraseVerbiageMessageModal from '../../../../../../../shared/phraseVerbiageMessageModal/PhraseVerbiageMessageModal';
import GhsPrecautionaryPhrasesSelectionModal from '../../../../../../../shared/multiSelectModals/ghsPrecautionaryPhrases/GhsPrecautionaryPhrasesSelectionModal';
import { ChildEditableGridProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { SdsHeader } from '../../../../../../../../../types/formulation';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../../../../../../components/i18n/TranslatableText';

const SdsGhsPrecautionaryPhrasesGrid = (
    props: ChildEditableGridProps<SdsHeader>
) => {
    const {
        columnDefs,
        sdsPrecautionaryPhrases,
        ghsPrecautionaryPhraseList,
        showMultiSelectGridModal,
        isLoadingPrecautionaryPhraseList,
        showVerbiageMessage,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        handleSaveGridEdits,
        handleRowValidations,
        handleShowVerbiageMessage,
        termSet,
    } = useSdsGhsPrecautionaryPhrasesGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !columnDefs || isLoadingPrecautionaryPhraseList ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                dataKey={'phraseId'}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sdsSortOrder'}
                rowData={sdsPrecautionaryPhrases}
                columnDefs={columnDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={showAddButton}
                displayExportDataButton={false}
                displayResetStateButton={false}
                defaultColDef={defaultColumnDefinitionOptions}
                sizeColumnsToFit={true}
                isEditable={isGridEditable}
                onCreateNew={() => handleSetShowMultiSelectGridModal(true)}
                onHandleGridEdits={(records) => handleSaveGridEdits(records)}
                createRow={false}
                onHandleRowValidations={handleRowValidations}
                gridButtonTitle={'GHS Precautionary Phrases'}></BaseGrid>
            <GhsPrecautionaryPhrasesSelectionModal
                openModal={showMultiSelectGridModal}
                onClose={() => handleSetShowMultiSelectGridModal(false)}
                onSelected={handleSelectedRecords}
                precautionaryPhraseList={ghsPrecautionaryPhraseList}
            />
            <PhraseVerbiageMessageModal
                showModal={showVerbiageMessage}
                handleClose={() => handleShowVerbiageMessage(false)}
                messageText={
                    (
                        <TranslatableText
                            termSet={termSet}
                            termKey={
                                SDSFormDefs.GHS_Precautionary_Phrases_Selection_Product_Specific_Verbiage
                            }
                        />
                    ) as unknown as string
                }
            />
        </>
    );
};

export default SdsGhsPrecautionaryPhrasesGrid;
