import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import './LoaderButton.css';
import { ButtonProps } from '@mui/material/Button';

interface LoaderButtonProps extends ButtonProps {
    /** determines whether to show the button or progress bar */
    isLoading?: boolean;
    className?: string;
    /** onClick handler for button action */
    onClick?: any;
    disabled?: boolean;
    children?: any;
}

export default function LoaderButton({
    isLoading,
    size = 'small',
    className = '',
    onClick = null,
    disabled = false,
    type = 'button',
    ...props
}: LoaderButtonProps) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            onClick={onClick}
            disabled={disabled || isLoading}
            type={type ? type : null}
            {...props}>
            {isLoading ? <CircularProgress size={20} /> : <>{props.children}</>}
        </Button>
    );
}
