import React, { useContext } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { Regulation } from '../../../../../types/formulation';
import { useGetStatesForDropdownQuery } from '../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateRegulationMutation,
    useDeleteRegulationMutation,
    useGetRegulationQuery,
    useUpdateRegulationMutation,
} from '../../../../../services/formulation/regulations/regulations.service';
import { useGetRegulationBodiesForDropDownQuery } from '../../../../../services/formulation/regulations/body/regulationBody.service';
import { useGetRegulationSectionsForDropDownQuery } from '../../../../../services/formulation/regulations/section/regulationSection.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useRegulationForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: currentRegulationRecord, isLoading: isLoadingRegulation } =
        useGetRegulationQuery(id ? id : skipToken);
    const {
        data: regulationBodyOptions,
        isLoading: isLoadingRegulationBodyOptions,
    } = useGetRegulationBodiesForDropDownQuery();
    const {
        data: regulationSectionOptions,
        isLoading: isLoadingRegulationSectionOptions,
    } = useGetRegulationSectionsForDropDownQuery();
    const { data: stateOptions, isLoading: isLoadingStateOptions } =
        useGetStatesForDropdownQuery();

    const [createRegulation] = useCreateRegulationMutation();
    const [updateRegulation] = useUpdateRegulationMutation();
    const [deleteRegulation] = useDeleteRegulationMutation();

    const canCreateRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.CREATE
    );

    const canUpdateRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.EDIT
    );

    const canDeleteRegulation = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.DELETE
    );

    const blankRegulationRecord: Regulation = {
        code: '',
        title: '',
        regulationBodyId: null,
        regulationSectionId: null,
        stateCodeId: null,
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.REGULATIONS_FORM,
              }
            : skipToken
    );

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/formulation/regulations',
        reroute: true,
        blankEntity: blankRegulationRecord,
        activeEntity: currentRegulationRecord,
        getDescription: () => {
            return `Regulation ${fields.code}`;
        },
        createEntity: () => {
            return createRegulation({ postBody: fields });
        },
        updateEntity: () => {
            return updateRegulation({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteRegulation(id);
        },
    });

    return {
        fields,
        formMethods,
        regulationBodyOptions,
        regulationSectionOptions,
        stateOptions,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingRegulation,
        isLoadingRegulationBodyOptions,
        isLoadingRegulationSectionOptions,
        isLoadingStateOptions,
        canCreateRegulation,
        canUpdateRegulation,
        canDeleteRegulation,
        termSet,
    };
};

export default useRegulationForm;
