import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoaderButton from '../LoaderButton';
import BaseGrid from './BaseGrid';
import { BaseGridProps } from './Grids.propTypes';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import { EditableGridEntity } from '../../types/Shared.types';
import useEditableBaseFormGrid from './hooks/useEditableBaseFormGrid';

export type EditableBaseFormGridProps<TModel> = {
    onSave: (rows: TModel[]) => void;
    title?: string;
    isLoading?: boolean;
} & BaseGridProps;

const EditableBaseFormGrid = <TModel extends EditableGridEntity>(
    props: EditableBaseFormGridProps<TModel>
) => {
    props = { domLayout: 'normal', paginationPageSize: 30, ...props };

    const { hasChanges, handleGridEdits, handleSaveButtonClick } =
        useEditableBaseFormGrid(props);

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                title={props.title}
                sx={{ display: props.title ? 'block' : 'none' }}
            />
            {props.isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid
                        container
                        flexDirection={'row'}
                        spacing={2}
                        className="form-header-body"
                        sx={{ marginTop: '0.1em' }}>
                        <LoaderButton
                            className="form-header-button"
                            type="submit"
                            variant="text"
                            data-testid="saveCreateButton"
                            onClick={handleSaveButtonClick}
                            disabled={!hasChanges}>
                            <SaveIcon style={{ color: '#00A84E' }} />
                            <div className="form-header-button-text">Save</div>
                        </LoaderButton>
                    </Grid>
                    <CardContent className={'full-size-item'}>
                        <BaseGrid
                            gridClass="full-size-item"
                            isEditable={true}
                            onHandleGridEdits={(rows) => handleGridEdits(rows)}
                            {...props}
                        />
                    </CardContent>
                </>
            )}
        </Card>
    );
};

export default EditableBaseFormGrid;
