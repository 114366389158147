import { useEffect, useState } from 'react';
import { EditableBaseFormGridProps } from '../EditableBaseFormGrid';
import { EditableGridEntity } from '../../../types/Shared.types';
import useVerificationChangesHelper from '../../../containers/form/hooks/verification/useVerificationChangesHelper';
import { cloneDeep } from 'lodash';

const useEditableBaseFormGrid = <TModel extends EditableGridEntity>(
    props: EditableBaseFormGridProps<TModel>
) => {
    const { onSave, rowData, isLoading } = props;

    const [currentRowData, setCurrentRowData] = useState<TModel[]>(null);

    const { verifyChanges } = useVerificationChangesHelper({
        blankEntity: rowData,
        executeVerification: true,
    });

    const { notificationMethods, editableBaseFormGrid } = verifyChanges;

    const { hasCurrentRecordChanged, clearChangesHistory, setOriginalEntity } =
        editableBaseFormGrid;

    const { notifyEditableBaseFormGridChanges } = notificationMethods;

    const handleGridEdits = (rows: TModel[]) => {
        const currentRows = rows?.filter((row) => row.rowStatus);
        notifyEditableBaseFormGridChanges(currentRows);
        setCurrentRowData(currentRows);
    };

    const refreshData = () => {
        const updatedFields = [...rowData];

        currentRowData?.forEach((row) => {
            const index = rowData.findIndex(
                (oldRow) => oldRow.rowId === row.rowId
            );

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });

        setOriginalEntity(updatedFields);
    };

    const handleSaveButtonClick = () => {
        onSave(currentRowData);
        refreshData();
        clearChangesHistory();
    };

    useEffect(() => {
        if (!isLoading && rowData) {
            setOriginalEntity(cloneDeep(rowData));
        }
    }, [isLoading && rowData]);

    return {
        hasChanges: hasCurrentRecordChanged(),
        handleGridEdits,
        handleSaveButtonClick,
    };
};

export default useEditableBaseFormGrid;
