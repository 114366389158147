import { useParams } from 'react-router-dom';
import useAccountFunctionCodeForm from './hooks/useAccountFunctionCodeForm';
import { Grid } from '@mui/material';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import BaseGrid from '../../components/grids/BaseGrid';
import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { FunctionGroupValues } from '../../types/Shared.types';
import { ACCOUNT_FUNCTION_CODE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';

const AccountFunctionCodeForm = () => {
    const { id, group } = useParams<ParamTypes>();
    const {
        activeFunctionCode,
        fields,
        formMethods,
        onUpdate,
        onGridReady,
        closeForm,
        isLoading,
        isLoadingSelectedAccounts,
        canUpdateFunctionCode,
    } = useAccountFunctionCodeForm(id, group);

    return (
        <BaseForm
            title={
                group === FunctionGroupValues.accounting
                    ? 'Accounting Function Code'
                    : 'Fixed Assets Function Code'
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={false}
            onUpdate={onUpdate}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={ACCOUNT_FUNCTION_CODE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={false}
            displayUpdateButton={canUpdateFunctionCode}
            displayDeleteButton={false}>
            <FormPartial>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextInput
                            fullWidth
                            label="Function Code"
                            id="code"
                            value={fields.code}
                            variant={'standard'}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            value={fields.description}
                            multiline={true}
                            variant={'standard'}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial title="Accounts">
                {isLoadingSelectedAccounts ? (
                    <DatacorLogoSpinner />
                ) : (
                    <BaseGrid
                        rowData={activeFunctionCode?.availableAccounts || []}
                        displayGrid={true}
                        onGridReady={onGridReady}
                        rowMultiSelectWithClick={true}
                        rowStyle={{ cursor: 'pointer' }}
                        rowSelection={'multiple'}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            unSortIcon: true,
                            filter: true,
                            wrapText: true,
                            floatingFilter: true,
                        }}>
                        <AgGridColumn
                            headerName="Selected"
                            {...DefaultColumnTypes.CheckboxSelection}
                        />
                        <AgGridColumn
                            field="number"
                            headerName="Number"
                            {...DefaultColumnTypes.ShortText}
                        />
                        <AgGridColumn
                            minWidth={200}
                            field="name"
                            headerName="Name"
                            {...DefaultColumnTypes.MediumText}
                        />
                    </BaseGrid>
                )}
            </FormPartial>
        </BaseForm>
    );
};

export default AccountFunctionCodeForm;
