import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { SdsHeader } from '../../../../../../types/formulation';
import SubSection3_1Form from './subSections/subSection3_1/SubSection3_1Form';
import SdsCasCompositionGrid from './SdsCasCompositionGrid';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsCasCompositionForm = (
    props: ChildEditableGridProps<SdsHeader> &
        SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading, currentParentRecord, handleFieldChange } =
        props.parentData;

    return (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SDSFormDefs.Composition_Ingredient_Information}
                    />
                ) as unknown as string
            }>
            <FormPartial
                title={
                    (
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={SDSFormDefs.Chemical_Composition}
                        />
                    ) as unknown as string
                }>
                <>
                    {isParentLoading ? (
                        <DatacorLogoSpinner />
                    ) : (
                        <>
                            <CheckBoxInput
                                id="substance"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Substance}
                                        />
                                    ) as unknown as string
                                }
                                checked={currentParentRecord.substance}
                                onChange={handleFieldChange}
                            />
                            <SdsCasCompositionGrid {...props} />
                            <br />
                            <SubSection3_1Form
                                {...props}
                                termSet={props.termSet}
                            />
                        </>
                    )}
                </>
            </FormPartial>
        </FormPartial>
    );
};

export default SdsCasCompositionForm;
