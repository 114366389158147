import { Grid } from '@mui/material';
import React from 'react';
import useSdsPictogramForm from './hooks/useSdsPictogramForm';
import DatacorLogoSpinner from '../../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { SdsHeader } from '../../../../../../../../../types/formulation';
import { ChildFormProps } from '../../../../../../../../form/helpers/ChildFormProps';
import CheckBoxInput from '../../../../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import AutoCompleteInput from '../../../../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import TranslatableText from '../../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsPictogramForm = (props: ChildFormProps<SdsHeader>) => {
    const {
        fields,
        formMethods,
        defaultSignalWordId,
        handlePropertyChanged,
        signalWordOptions,
        isLoadingSignalWord,
        dropdownOptions,
        setDropdownOptions,
    } = useSdsPictogramForm(props);

    const { isParentLoading } = props.parentData;

    const { disabledField } = props.permissions;

    return (
        <>
            {isParentLoading || isLoadingSignalWord ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="explosive"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Explosive}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.explosive}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="flammable"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Flammable}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.flammable}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="oxidizing"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Oxidizing}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.oxidizing}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="compressedGas"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Compressed_Gas}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.compressedGas}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="toxic"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Toxic}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.toxic}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="corrosive"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Corrosive}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.corrosive}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="irritant"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Irritant}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.irritant}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckBoxInput
                                id="healthHazard"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Health_Hazard}
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.healthHazard}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckBoxInput
                                id="environmentDamaging"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={
                                                SDSFormDefs.Environment_Damaging
                                            }
                                        />
                                    ) as unknown as string
                                }
                                checked={fields.environmentDamaging}
                                onChange={handlePropertyChanged}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <AutoCompleteInput
                                id="sdsPictogramSignalWordId"
                                inputFieldName="signalWordInputValue"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={props.termSet}
                                            termKey={SDSFormDefs.Signal_Word}
                                        />
                                    ) as unknown as string
                                }
                                defaultOption={defaultSignalWordId}
                                onHandlePropertyChanged={handlePropertyChanged}
                                options={signalWordOptions}
                                setDropdownOptions={setDropdownOptions}
                                value={dropdownOptions?.selectedSignalWord}
                                inputValue={
                                    dropdownOptions?.signalWordInputValue
                                }
                                isLoading={isLoadingSignalWord}
                                formMethods={formMethods}
                                required={true}
                                disabled={disabledField}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default SdsPictogramForm;
