import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    organizationsApi,
    useGetBusinessEntitiesByIdQuery,
    useGetSubscribersForDropdownQuery,
    useGetUserQuery,
    User,
    useUpdateUserMutation,
} from '../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import SelectDropdown from '../form/selectDropdown/SelectDropdown';
import { Grid } from '@mui/material';
import {
    handleUpdateUserBusinessEntity,
    handleUpdateUserFeatureFlags,
    handleUpdateUserSubscriber,
    refreshToken,
} from '../../store/user';
import { BaseUpdateEntityArgs } from '../../services/serviceInterfaces';
import ExpandableDropdown from '../form/expandableDropdown/ExpandableDropdown';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import { useApplyCompanyAndZoneMutation } from '../../services/users/users.service';
import useGetBusinessEntities from '../../containers/administration/hooks/useGetBusinessEntities';
import { handleInvalidateTags } from '../../utils/services/invalidateTags';
import { useGetEnabledBusinessEntityFeatureFlagsQuery } from '../../services/access/access.service';
import { SharedComponentsDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../i18n/TranslatableText';
import { PLATFORM_DEFS } from '../../constants/i18n/translations/termSetDefinitions/platform';
import SettingsContext from '../../contexts/settings.context';
import { useGetTermSetQuery } from '../../services/i18n/i18n.service';

const BusinessEntitySelect = () => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );
    const user = useSelector((state: RootState) => state.user);
    const userBusinessEntity = useSelector(
        (state: RootState) => state.user.businessEntity
    );
    const userSubscriber = useSelector(
        (state: RootState) => state.user.subscriber
    );

    const [applyCompanyAndZone] = useApplyCompanyAndZoneMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    const { data: businessEntity, isLoading: isLoadingBusinessEntity } =
        useGetBusinessEntitiesByIdQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    const { businessEntities, isLoading } = useGetBusinessEntities({
        subscriberId: user?.subscriber?.id.toString(),
        businessEntityId: user?.businessEntity?.id.toString(),
    });

    const { data: subscriberOptions, isLoading: isLoadingSubscriberOptions } =
        useGetSubscribersForDropdownQuery();
    const [loading, setLoading] = useState(false);
    const shouldLoad = isLoadingSubscriberOptions;
    const [value, setValue] = useState(null);
    const [updateUser] = useUpdateUserMutation();
    const { data: featureFlags, isLoading: isLoadingFeatureFlags } =
        useGetEnabledBusinessEntityFeatureFlagsQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    useEffect(() => {
        if (featureFlags) {
            dispatch(handleUpdateUserFeatureFlags(featureFlags));
        }

        handleRefresh();

        if (featureFlags) {
            dispatch(handleUpdateUserFeatureFlags(featureFlags));
        }
    }, [userBusinessEntity, featureFlags, userSubscriber]);

    const handleRefresh = async () => {
        //updateCompanyAndZone();
        await dispatch(refreshToken());
        await handleInvalidateTags(dispatch);
    };

    const getCurrentValue = () => {
        if (!subscriberOptions) {
            return;
        }
        const option = subscriberOptions.find(
            (subscriber: any) => subscriber.value === user?.subscriber?.id
        );
        return option?.value || value;
    };

    const handleUpdateSubscriber = async (selectedValue: any) => {
        setValue(selectedValue);
        setLoading(true);
        const businessEnts: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntities.initiate(
                selectedValue
            )
        );

        const subscriber: any = await dispatch(
            organizationsApi.endpoints.getSubscriber.initiate(selectedValue)
        );

        const { data } = businessEnts;
        const { data: subscriberData } = subscriber;

        const body: BaseUpdateEntityArgs<User> = {
            id: user.userId as unknown as number,
            ignoreRevision: 'true',
            postBody: {
                activeWebBusinessEntityId:
                    data[0]?.id || user?.businessEntity?.id,
            },
        };

        //TODO: determine if this is the best solution
        const urlParams = location.pathname.split('/');
        // if the last index of the params is a number
        // then we are inside of an update form
        if (Number(urlParams.at(-1)) || urlParams.at(-1) === 'new') {
            //remove the number from the route
            urlParams.pop();
            const newLocation = urlParams.join('/');
            //redirect to the appropriate grid page
            navigate(newLocation);
        }

        await updateUser(body);

        await dispatch(
            handleUpdateUserSubscriber(
                subscriberData,
                data[0] || user?.businessEntity
            )
        );

        setLoading(false);
    };

    const handleUpdateBusinessEntity = async (selectedValue: any) => {
        const body: BaseUpdateEntityArgs<User> = {
            id: user.userId as unknown as number,
            ignoreRevision: 'true',
            postBody: {
                activeWebBusinessEntityId:
                    selectedValue || user?.businessEntity?.id,
            },
        };

        const businessEntity: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntity.initiate(selectedValue)
        );

        //TODO: determine if this is the best solution
        const urlParams = location.pathname.split('/');
        // if the last index of the params is a number
        // then we are inside of an update form
        if (Number(urlParams.at(-1)) || urlParams.at(-1) === 'new') {
            //remove the number from the route
            urlParams.pop();
            const newLocation = urlParams.join('/');
            //redirect to the appropriate grid page
            navigate(newLocation);
        }
        const { data: businessEntityData } = businessEntity;

        await updateUser(body);
        await dispatch(handleUpdateUserBusinessEntity(businessEntityData));
    };

    const updateCompanyAndZone = async () => {
        const companyId = user?.subscriber?.dynamoKey;
        const zoneId = user?.businessEntity?.dynamoKey;

        if (companyId && zoneId) {
            await applyCompanyAndZone({
                companyId,
                zoneId,
            });
        }

        await dispatch(refreshToken());
    };

    return (
        <div>
            {!shouldLoad && (
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end">
                    <Grid item xs={2} md={1}>
                        {''}
                    </Grid>
                    <Grid item xs={4} md={5} lg={4}>
                        {user.isDatacorAdmin || user.isDatacorUser ? (
                            <SelectDropdown
                                value={getCurrentValue()}
                                options={subscriberOptions}
                                onChange={handleUpdateSubscriber}
                                id="subscriberSelect"
                            />
                        ) : (
                            <p
                                style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    textAlign: 'right',
                                }}>
                                {(subscriberOptions &&
                                    subscriberOptions
                                        ?.find(
                                            (subscriber: any) =>
                                                subscriber.value ===
                                                user?.subscriber?.id
                                        )
                                        ?.label.toString()) ||
                                    ''}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={4} md={5} lg={4}>
                        {!loading ? (
                            <ExpandableDropdown
                                size="small"
                                valueSelected={handleUpdateBusinessEntity}
                                defaultValue={Number(user?.businessEntity?.id)}
                                items={businessEntities}
                                idKey="id"
                                label={
                                    (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SharedComponentsDefs.Business_Entity_Select
                                            }
                                        />
                                    ) as unknown as string
                                }
                                labelKey="name"
                                id="businessEntityId"
                                disableClearable
                            />
                        ) : (
                            <DatacorLogoSpinner size={'40px'} />
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default BusinessEntitySelect;
