import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { GL_AccountFunction } from '../../../types/Accounting.types';
import { useListFunctionCodeByGroupQuery } from '../../../services/accounting/accounting.service';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    FunctionGroupValues,
    RoutingValues,
} from '../../../types/Shared.types';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/accounting';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';

const useAccountFunctionCodeGrid = (group: string) => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: functionCodeList, isLoading } =
        useListFunctionCodeByGroupQuery(group);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FUNCTION_CODES_GRID,
              }
            : skipToken
    );
    const canViewFunctionCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        group === FunctionGroupValues.accounting
            ? PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW
            : PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW
    );

    const redirectToForm = (id: bigint) => {
        navigate(
            group === FunctionGroupValues.accounting
                ? `/accounting/functionCode/${group}/${id}`
                : `/accounting/fixedAssets/functionCode/${group}/${id}`
        );
    };

    const getAccountNumberValue = (accountFunctions: GL_AccountFunction[]) => {
        return isNilOrEmpty(accountFunctions)
            ? ''
            : accountFunctions.map(
                  (aFunction: GL_AccountFunction) => aFunction.account?.number
              );
    };

    const AccountNumberColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: GL_AccountFunction) => {
                    return (
                        <div key={aFunction.account?.number}>
                            {aFunction.account?.number}
                        </div>
                    );
                })}
            </div>
        );
    };

    const getAccountNameValue = (accountFunctions: GL_AccountFunction[]) => {
        return isNilOrEmpty(accountFunctions)
            ? ''
            : accountFunctions.map(
                  (aFunction: GL_AccountFunction) => aFunction.account?.name
              );
    };

    const AccountNameColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: GL_AccountFunction) => {
                    return (
                        <div key={aFunction.account?.number}>
                            {aFunction.account?.name}
                        </div>
                    );
                })}
            </div>
        );
    };

    return {
        functionCodeList,
        isLoading,
        AccountNumberColumn,
        AccountNameColumn,
        getAccountNumberValue,
        getAccountNameValue,
        redirectToForm,
        canViewFunctionCode,
        termSet,
    };
};

export default useAccountFunctionCodeGrid;
