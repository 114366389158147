import React from 'react';
import {
    useCreateUnitOfMeasureMutation,
    useGetUnitOfMeasureQuery,
    useGetUnitTypesForDropdownQuery,
    useUpdateUnitOfMeasureMutation,
    useDeleteUnitOfMeasureMutation,
    useListUnitOfMeasureGlobalDropdownQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { UnitOfMeasure } from '../../../types/Setup.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

const UseUnitOfMeasureForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: activeUnitOfMeasure, isLoading: isLoadingUnitOfMeasure } =
        useGetUnitOfMeasureQuery(id ? id : skipToken);
    const [createUnitOfMeasure] = useCreateUnitOfMeasureMutation();
    const [updateUnitOfMeasure] = useUpdateUnitOfMeasureMutation();
    const [deleteUnitOfMeasure] = useDeleteUnitOfMeasureMutation();

    const { data: unitTypeOptions, isLoading: isLoadingUnitTypes } =
        useGetUnitTypesForDropdownQuery();

    const {
        data: globalUnitOfMeasureOptions,
        isLoading: isLoadingGlobalUnitOfMeasureOptions,
    } = useListUnitOfMeasureGlobalDropdownQuery();

    const canCreateUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.CREATE
    );
    const canUpdateUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.EDIT
    );
    const canDeleteUnitOfMeasure = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.DELETE
    );

    const blankUnitOfMeasure: UnitOfMeasure = {
        unitCode: '',
        description: '',
        decimals: null,
        unitTypeId: null,
        unitOfMeasureGlobalId: null,
        unitOfMeasureGlobalFactor: null,
        businessEntityId: user?.businessEntity?.id,
    };

    console.log(user);
    console.log(user?.businessEntity);
    console.log(user?.businessEntity?.id);

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/setup/unitOfMeasure',
        reroute: true,
        blankEntity: blankUnitOfMeasure,
        activeEntity: activeUnitOfMeasure,
        getDescription: () => {
            return `Unit of Measure ${fields.unitCode}`;
        },
        createEntity: () => {
            return createUnitOfMeasure({ postBody: fields });
        },
        updateEntity: () => {
            return updateUnitOfMeasure({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteUnitOfMeasure(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingUnitOfMeasure,
        isLoadingUnitTypes,
        isLoadingGlobalUnitOfMeasureOptions,
        unitTypeOptions,
        canCreateUnitOfMeasure,
        canDeleteUnitOfMeasure,
        canUpdateUnitOfMeasure,
        globalUnitOfMeasureOptions,
    };
};

export default UseUnitOfMeasureForm;
