import React from 'react';
import {
    Timeline,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';
import { Typography, Grid, Button } from '@mui/material';
import useInvoiceHistoryTab from '../hooks/Tabs/useInvoiceHistoryTab';
import { dateFormatter } from '../../../utils/formattingUtils';
import UpdateIcon from '@mui/icons-material/Update';
import AuditDialog from '../../../components/apAutomation/AuditDialog';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { UserList } from '../../../utils/users/userList';

interface InvoiceHistoryTabProps {
    documentId: string;
}

const InvoiceHistoryTab = ({ documentId }: InvoiceHistoryTabProps) => {
    const {
        shouldLoad,
        activeDocument,
        auditData,
        open,
        handleOpen,
        handleClose,
        audit,
        usersList,
    } = useInvoiceHistoryTab(documentId);

    const getToleranceCheckInfo = () => {
        if (activeDocument.toleranceChecks) {
            return activeDocument.toleranceChecks.map((tolerance: any) => (
                <Grid
                    item
                    xs={4}
                    key={`${tolerance.value}-${tolerance.type.value}`}>
                    <div>
                        <span>
                            <b>Criteria:</b> {tolerance.criteria.value}
                        </span>
                        <br />
                        <br />
                        <span>
                            <b>Cost Category:</b>{' '}
                            {tolerance.poReceiptCostType.value}
                        </span>
                        <br />
                        <br />
                        <span>
                            <b>Tolerance:</b> {tolerance.type.value} -{' '}
                            {tolerance.value}
                        </span>
                    </div>
                </Grid>
            ));
        }
    };

    return (
        <div>
            <AuditDialog open={open} onClose={handleClose} audit={audit} />
            <div>
                <h3>Invoice Information</h3>
                <b>Uploader:</b> {activeDocument.uploader}
                <br />
                <b>Upload Date: </b> {dateFormatter(activeDocument.dateCreated)}
                <br />
            </div>

            <div>
                <h3>Tolerance Checks On Upload</h3>
                <Grid container spacing={2}>
                    {getToleranceCheckInfo()}
                </Grid>
                <br />
            </div>

            <div>
                <h3>Voucher Information</h3>
                {activeDocument.voucherNumber ? (
                    <>
                        Voucher Number: {activeDocument.voucherNumber}
                        <br />
                        Vouchered on:
                        {dateFormatter(activeDocument.lastUpdateDate)}
                    </>
                ) : (
                    'This invoice has not been vouchered yet...'
                )}
                <br />
            </div>
            <div style={{ paddingTop: '30x' }}>
                <h3>Timeline</h3>
                {!shouldLoad ? (
                    <Timeline position="left">
                        {auditData.length > 0 ? (
                            auditData.map((audit: any, index: number) => (
                                <Button
                                    style={{ textTransform: 'none' }}
                                    variant="text"
                                    size="small"
                                    key={audit.PK}
                                    onClick={(e) => handleOpen(audit)}>
                                    <TimelineItem sx={{ px: 6, width: '100%' }}>
                                        <TimelineOppositeContent
                                            sx={{ py: '18px', px: 2 }}>
                                            <Typography>
                                                {dateFormatter(
                                                    audit.dateCreated
                                                )}
                                                <br />
                                                {
                                                    new UserList(
                                                        usersList
                                                    ).getUserFromUsername(
                                                        audit.username
                                                    )?.email
                                                }
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot>
                                                <UpdateIcon />
                                            </TimelineDot>
                                            {index !== auditData.length - 1 && (
                                                <TimelineConnector />
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent
                                            sx={{ py: '18px', px: 2 }}>
                                            <Typography>Update</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Button>
                            ))
                        ) : (
                            <>No timeline data available...</>
                        )}
                    </Timeline>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </div>
        </div>
    );
};

export default InvoiceHistoryTab;
