export const FORMULATION_DEFS = {
    CAS_MASTER_FORM: 'cas-master-form',
    CAS_MASTER_GRID: 'cas-master-grid',
    ITEM_MASTER_GRID: 'item-master-grid',
    RD_FORMULAS_GRID: 'rd-formulas-grid',
    ITEM_MASTER_FORM: 'item-master-form',
    RD_FORMULAS_FORM: 'rd-formulas-form',
    SDS_FORM: 'sds-form',
    GHS_HAZARD_PHRASES_GRID: 'ghs-hazard-phrases-grid',
    GHS_HAZARD_PHRASES_FORM: 'ghs-hazard-phrases-form',
    GHS_PRECAUTIONARY_PHRASES_GRID: 'ghs-precautionary-phrases-grid',
    GHS_PRECAUTIONARY_PHRASES_FORM: 'ghs-precautionary-phrases-form',
    REGULATIONS_GRID: 'regulations-grid',
    REGULATIONS_FORM: 'regulations-form',
    FORMULA_PROPERTIES_GRID: 'formula-properties-grid',
    FORMULA_PROPERTIES_FORM: 'formula-properties-form',
    TOXIC_SPECIES_GRID: 'toxic-species-grid',
    TOXIC_SPECIES_FORM: 'toxic-species-form',
    TOXIC_TEST_GRID: 'toxic-test-grid',
    TOXIC_TEST_FORM: 'toxic-test-form',
    EXPOSURE_AGENCIES_GRID: 'exposure-agencies-grid',
    EXPOSURE_AGENCIES_FORM: 'exposure-agencies-form',
    SAFETY_DATA_SHEET_GRID: 'safety-data-sheet-grid',
    SDS_SECTION_HEADERS_GRID: 'sds-section-headers-grid',
    SDS_SECTION_HEADERS_FORM: 'sds-section-headers-form',
    LOCALE_GROUPS_GRID: 'locale-groups-grid',
    LOCALE_GROUPS_FORM: 'locale-groups-form',
    LOCALES_GRID: 'locales-grid',
    HAZARD_CLASS_MAPPING_GRID: 'hazard-class-mapping-grid',
    FORMULATION_SHARED_COMPONENTS: 'formulation-shared-components',
};
