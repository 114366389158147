import { useState } from 'react';
import { FormMethods } from '../../../libs/hooksLib';
import { ChildFormHook } from '../helpers/ChildFormHook';

const useChildFormHelper = () => {
    const [childrenSections, setChildrenSections] = useState(
        new Map<string, ChildFormHook>()
    );

    const [childrenFormHooks, setChildrenFormHooks] = useState<ChildFormHook[]>(
        []
    );

    const [allChildrenFormMethods, setAllChildrenFormMethods] = useState<
        FormMethods[]
    >([]);

    const registerChildForm = (
        childFormName: string,
        hook: ChildFormHook
    ): void => {
        const newChildrenFormHooks: ChildFormHook[] = [];
        const newAllChildrenFormMethods: FormMethods[] = [];

        childrenSections.set(childFormName, hook);
        setChildrenSections(childrenSections);

        childrenSections.forEach((value) => {
            newChildrenFormHooks.push(value);
            newAllChildrenFormMethods.push(value.formMethods);
        });

        setChildrenFormHooks(newChildrenFormHooks);
        setAllChildrenFormMethods(newAllChildrenFormMethods);
    };

    return {
        childrenFormHooks,
        allChildrenFormMethods,
        registerChildForm,
    };
};

export default useChildFormHelper;
