import {
    RDFormulasForm,
    CasMasterForm,
    ItemMasterForm,
    ItemMasterGrid,
    CasMasterGrid,
    RDFormulasGrid,
    ExposureAgenciesForm,
    ExposureAgenciesGrid,
    ToxicTestForm,
    ToxicTestGrid,
    ToxicSpeciesForm,
    ToxicSpeciesGrid,
    FormulaPropertiesForm,
    FormulaPropertiesGrid,
    RegulationsForm,
    RegulationsGrid,
    GhsPrecautionaryPhrasesForm,
    GhsPrecautionaryPhrasesGrid,
    GhsHazardPhrasesForm,
    GhsHazardPhrasesGrid,
    SafetyDataSheetGrid,
    SDSForm,
    SdsSectionHeadersGrid,
    SdsSectionHeadersForm,
    HazardClassMappingGrid,
    LocaleGroupsGrid,
    LocaleGroupsForm,
    LocalesGrid,
    FormulationSharedComponents,
} from './termDefs.types';

export const ItemMasterGridDefs: ItemMasterGrid = {
    Item_ID: 'item-master-grid-item-id',
    Item_Name: 'item-master-grid-item-name',
    Version: 'item-master-grid-version',
    Description_1: 'item-master-grid-description-one',
    Description_2: 'item-master-grid-description-two',
    Status: 'item-master-grid-status',
    Inventory_Type: 'item-master-grid-inventory-type',
    UPC: 'item-master-grid-upc',
    Stock_Unit: 'item-master-grid-stock-unit',
    Cost: 'item-master-grid-cost',
};

export const CasMasterGridDefs: CasMasterGrid = {
    CAS_No: 'cas-master-grid-cas-no',
    Description: 'cas-master-grid-description',
    Chemical_Update: 'cas-master-grid-chemical-update',
    Last_Chemical_Update: 'cas-master-grid-last-chemical-update',
    Index_No: 'cas-master-grid-index-no',
    EC_No: 'cas-master-grid-ec-no',
    Reach_No: 'cas-master-grid-reach-no',
    Trade_Secret: 'cas-master-grid-trade-secret',
};

export const CasMasterFormDefs: CasMasterForm = {
    Cas_Maintenance: 'cas-master-cas-maintenance',
    Cas_No: 'cas-master-form-cas-no',
    Chemical_Name: 'cas-master-form-chemical-name',
    Chemical_Update: 'cas-master-form-chemical-update',
    Last_Chemical_Update: 'cas-master-form-last-chemical-update',
    Additional_Identification: 'cas-master-additional-identification',
    Index_No: 'cas-master-form-index-no',
    Ec_No: 'cas-master-form-ec-no',
    Reach_No: 'cas-master-form-reach-no',
    Classification: 'cas-master-form-classification',
    Molecular_Formula: 'cas-master-form-molecular-formula',
    Show_On_SDS: 'cas-master-form-show-on-sds',
    If_Weight_Is_Greater_Than: 'cas-master-form-if-weight-is-greater-than',
    Trade_Secret: 'cas-master-form-trade-secret',
    Global_Harmonization: 'cas-master-form-global-harmonization',
    Ghs_Pictograms: 'cas-master-form-ghs-pictograms',
    Explosive: 'cas-master-form-explosive',
    Flammable: 'cas-master-form-flammable',
    Oxidizing: 'cas-master-form-oxidizing',
    Compressed_Gas: 'cas-master-form-compressed-gas',
    Toxic: 'cas-master-form-toxic',
    Corrosive: 'cas-master-form-corrosive',
    Irritant: 'cas-master-form-irritant',
    Health_Hazard: 'cas-master-form-health-hazard',
    Environment_Damaging: 'cas-master-form-environment-damaging',
    Signal_Word: 'cas-master-form-signal-word',
    Ghs_Hazard_Phrases: 'cas-master-form-ghs-hazard-phrases',
    Ghs_Hazard_Phrases_Phrase_ID: 'cas-master-form-ghs-hazard-phrase-id',
    Ghs_Hazard_Phrases_Phrase_text: 'cas-master-form-ghs-hazard-phrase-text',
    Ghs_Hazard_Phrases_Hazard_Type: 'cas-master-form-ghs-hazard-hazard-type',
    Ghs_Hazard_Phrases_Language: 'cas-master-form-ghs-hazard-language',
    Ghs_Hazard_Phrases_Phrase_Selection:
        'cas-master-form-ghs-hazard-phrase-selection',
    Ghs_Hazard_Phrases_Phrases_Selection_Phrase_ID:
        'cas-master-form-ghs-hazard-selection-phrase-id',
    Ghs_Hazard_Phrases_Phrases_Selection_text:
        'cas-master-form-ghs-hazard-selection-text',
    Ghs_Hazard_Phrases_Product_Specific_Verbiage:
        'cas-master-form-ghs-hazard-product-specific-verbiage',
    Ghs_Precautionary_Phrases: 'cas-master-form-ghs-precautionary-phrases',
    Ghs_Precautionary_Code: 'cas-master-form-ghs-precautionary-code',
    Ghs_Precautionary_Phrase_Text:
        'cas-master-form-ghs-precautionary-phrase-text',
    Ghs_Precautionary_Statement_Type:
        'cas-master-form-ghs-precautionary-statement-type',
    Ghs_Precautionary_Language: 'cas-master-form-ghs-precautionary-language',
    Ghs_Precautionary_Phrases_Selection:
        'cas-master-form-ghs-precautionary-phrases-selection',
    Ghs_Precautionary_Phrases_Selection_Phrase_ID:
        'cas-master-form-ghs-precautionary-phrase-selection-phrase-id',
    Ghs_Precautionary_Phrases_Selection_Text:
        'cas-master-form-ghs-precautionary-phrase-selection-text',
    Ghs_Precautionary_Phrases_Product_Specific_Verbiage:
        'cas-master-form-ghs-precautionary-product-specific-verbiage',
    Exposure_Limits: 'cas-master-form-exposure-limits',
    Exposure_Limits_Agency_Code: 'cas-master-form-exposure-agency-code',
    Exposure_Limits_Description: 'cas-master-form-exposure-description',
    Exposure_Limits_Value: 'cas-master-form-exposure-value',
    Exposure_Limits_Form: 'cas-master-form-exposure-form',
    Exposure_Limits_Notes: 'cas-master-form-exposure-notes',
    Exposure_Limits_Agency_Selection_Exposure_Agency_Code:
        'cas-master-form-exposure-agency-selection-exposure-agency-code',
    Exposure_Limits_Agency_Selection_Description:
        'cas-master-form-exposure-agency-selection-description',
    Exposure_Limits_Enter_Agency_Code:
        'cas-master-form-exposure-enter-agency-code',
    Exposure_Limits_Enter_Exposure_Value:
        'cas-master-form-exposure-enter-exposure-value',
    Exposure_Limits_Enter_Exposure_Form:
        'cas-master-form-exposure-enter-exposure-form',
    Exposure_Limits_Enter_Notes: 'cas-master-form-exposure-enter-notes',
    Properties_Text: 'cas-master-form-properties',
    Property_Code: 'cas-master-form-property-code',
    Property_Name: 'cas-master-form-property-name',
    Property_Value: 'cas-master-form-value',
    Property_UOM: 'cas-master-form-uom',
    Property_Properties_Selection: 'cas-master-form-properties-selection',
    Property_Selection_Property_Code:
        'cas-master-form-properties-selection-property-code',
    Property_Selection_Property_Name:
        'cas-master-form-properties-selection-property-name',
    Property_Selection_Default_Value:
        'cas-master-form-properties-selection-default-value',
    Property_Selection_UOM: 'cas-master-form-uom-selection-uom',
    Property_Selection_Description: 'cas-master-form-uom-selection-description',
    Toxicity_Text: 'cas-master-form-toxicity',
    Toxicity_Route: 'cas-master-form-toxicity-route',
    Toxicity_Test_Type: 'cas-master-form-toxicity-test-type',
    Toxicity_Test_Description: 'cas-master-form-toxicity-test-description',
    Toxicity_Species: 'cas-master-form-toxicity-species',
    Toxicity_Species_Description:
        'cas-master-form-toxicity-species-description',
    Toxicity_Effective_Dose: 'cas-master-form-toxicity-effective-dose',
    Toxicity_Exposure_Time: 'cas-master-form-toxicity-exposure-time',
    Toxicity_Notes: 'cas-master-form-toxicity-notes',
    Toxicity_Enter_Route: 'cas-master-form-toxicity-enter-route',
    Toxicity_Enter_Test_Type: 'cas-master-form-toxicity-enter-test-type',
    Toxicity_Enter_Species: 'cas-master-form-toxicity-enter-species',
    Toxicity_Enter_Effective_Dose:
        'cas-master-form-toxicity-enter-effective-dose',
    Toxicity_Enter_Exposure_Time:
        'cas-master-form-toxicity-enter-exposure-time',
    Toxicity_Enter_Notes: 'cas-master-form-toxicity-enter-notes',
    Toxicity_Test_Type_Form_Toxic_Test_Code:
        'cas-master-form-toxicity-test-type-form-toxic-test-code',
    Toxicity_Test_Type_Form_Description:
        'cas-master-form-toxicity-test-type-form-description',
    Toxicity_Species_Form_Toxic_Specie_Code:
        'cas-master-form-toxicity-species-form-toxic-specie-code',
    Toxicity_Species_Form_Description:
        'cas-master-form-toxicity-species-form-description',
    Ecotoxicity_Text: 'cas-master-form-ecotoxicity',
    Ecotoxicity_Test_Type: 'cas-master-form-ecotoxicity-test-type',
    Ecotoxicity_Test_Description:
        'cas-master-form-ecotoxicity-test-description',
    Ecotoxicity_Species: 'cas-master-form-ecotoxicity-species',
    Ecotoxicity_Species_Description:
        'cas-master-form-ecotoxicity-species-description',
    Ecotoxicity_Effective_Dose: 'cas-master-form-ecotoxicity-effective-dose',
    Ecotoxicity_Exposure_Time: 'cas-master-form-ecotoxicity-exposure-time',
    Ecotoxicity_Notes: 'cas-master-form-ecotoxicity-notes',
    Ecotoxicity_Source: 'cas-master-form-ecotoxicity-source',
    Ecotoxicity_Method: 'cas-master-form-ecotoxicity-method',
    Ecotoxicity_Class_Of_Species:
        'cas-master-form-ecotoxicity-class-of-species',
    Ecotoxicity_Enter_Test_Type: 'cas-master-form-ecotoxicity-enter-test-type',
    Ecotoxicity_Enter_Species: 'cas-master-form-ecotoxicity-enter-species',
    Ecotoxicity_Enter_Effective_Dose:
        'cas-master-form-ecotoxicity-enter-effective-dose',
    Ecotoxicity_Enter_Exposure_Time:
        'cas-master-form-ecotoxicity-enter-exposure-time',
    Ecotoxicity_Enter_Notes: 'cas-master-form-ecotoxicity-enter-notes',
    Ecotoxicity_Enter_Source: 'cas-master-form-ecotoxicity-enter-source',
    Ecotoxicity_Enter_Method: 'cas-master-form-ecotoxicity-enter-method',
    Ecotoxicity_Enter_Class_Of_Species:
        'cas-master-form-ecotoxicity-enter-class-of-species',
    Ecotoxicity_Test_Type_Form_Toxic_Test_Code:
        'cas-master-form-ecotoxicity-test-type-form-toxic-test-code',
    Ecotoxicity_Test_Type_Form_Description:
        'cas-master-form-ecotoxicity-test-type-form-description',
    Ecotoxicity_Species_Form_Toxic_Species_Code:
        'cas-master-form-ecotoxicity-species-form-toxic-specie-code',
    Ecotoxicity_Species_Form_Description:
        'cas-master-form-ecotoxicity-species-form-description',
    Regulations_Text: 'cas-master-form-regulations',
    Regulations_Code: 'cas-master-form-regulations-code',
    Regulations_Title: 'cas-master-form-regulations-title',
    Regulations_On_List: 'cas-master-form-regulations-on-list',
    Regulations_Import_Manufacture_Limit:
        'cas-master-form-regulations-import-manufacture-limit',
    Regulations_Threshold: 'cas-master-form-regulations-threshold',
    Regulations_Notes: 'cas-master-form-regulations-notes',
    Regulations_Regulation_Body: 'cas-master-form-regulations-regulation-body',
    Regulations_Section: 'cas-master-form-regulations-section',
    Regulations_State_Province: 'cas-master-form-regulations-state-province',
    Regulations_Country: 'cas-master-form-regulations-country',
    Regulations_Selection_Text: 'cas-master-form-regulations-selection-text',
    Regulations_Selection_Code: 'cas-master-form-regulations-selection-code',
    Regulations_Selection_Title: 'cas-master-form-regulations-selection-title',
    Regulations_Selection_Regulation_Body:
        'cas-master-form-regulations-selection-regulation-body',
    Regulations_Selection_Section:
        'cas-master-form-regulations-selection-section',
    Regulations_Selection_State_Province:
        'cas-master-form-regulations-selection-state-province',
    Regulations_State_Form_UOM: 'cas-master-form-regulations-state-form-uom',
    Regulations_State_Form_Description:
        'cas-master-form-regulations-state-form-description',
    Regulations_Country_Form_Name:
        'cas-master-form-regulations-country-form-name',
};
export const RDFormulasGridDefs: RDFormulasGrid = {
    Formula_Code: 'rd-formulas-grid-formula-code',
    Formula_Description: 'rd-formulas-grid-formula-description',
    Product_Code: 'rd-formulas-grid-product-code',
    Formula_Status: 'rd-formulas-grid-formula-status',
    Template: 'rd-formulas-grid-template',
    Approved_By: 'rd-formulas-grid-approved-by',
    Approved_Date: 'rd-formulas-grid-approved-date',
};
export const ItemMasterFormDefs: ItemMasterForm = {
    Item_Master_Detail: 'item-master-form-item-master-detail',
    Item_ID: 'item-master-form-item-id',
    Item_Name: 'item-master-form-item-name',
    Version: 'item-master-form-version',
    Description_one: 'item-master-form-description-one',
    Description_two: 'item-master-form-description-two',
    Status: 'item-master-form-status',
    Inventory_Type: 'item-master-form-inventory-type',
    UPC: 'item-master-form-upc',
    Unit_Of_Measure: 'item-master-form-unit-of-measure',
    Stock_Unit: 'item-master-form-stock-unit',
    Specific_Gravity: 'item-master-form-specific-gravity',
    Density: 'item-master-form-density',
    Weight_Unit: 'item-master-form-weight-unit',
    Stock_Weight: 'item-master-form-stock-weight',
    Volume_Unit: 'item-master-form-volume-unit',
    Stock_Volume: 'item-master-form-stock-volume',
    Cost_Text: 'item-master-form-cost-text',
    Cost: 'item-master-form-cost',
    Properties_Text: 'item-master-form-properties-text',
    Property_ID: 'item-master-form-properties-property-id',
    Property_Name: 'item-master-form-properties-property-name',
    Property_Value: 'item-master-form-properties-value',
    Property_UOM: 'item-master-form-properties-uom',
    Properties_Selection: 'item-master-form-properties-selection',
    Properties_Selection_Code:
        'item-master-form-properties-selection-property-code',
    Properties_Selection_Name:
        'item-master-form-properties-selection-property-name',
    Properties_Selection_Default_Value:
        'item-master-form-properties-selection-default-value',
    Properties_UOM_Form_UOM: 'item-master-form-properties-uom-form-uom',
    Properties_UOM_Form_Description:
        'item-master-form-properties-uom-form-description',
    Chemical_Composition_Text: 'item-master-form-chemical-composition-text',
    Chemical_Composition_CAS_No: 'item-master-form-chemical-composition-cas-no',
    Chemical_Composition_CAS_Description:
        'item-master-form-chemical-composition-cas-description',
    Chemical_Composition_Lower_Value:
        'item-master-form-chemical-composition-lower-value',
    Chemical_Composition_Upper_Value:
        'item-master-form-chemical-composition-upper-value',
    Chemical_Composition_EC_No: 'item-master-form-chemical-composition-ec-no',
    Chemical_Composition_Reach_No:
        'item-master-form-chemical-composition-reach-no',
    Chemical_Composition_Classification:
        'item-master-form-chemical-composition-classification',
    Chemical_Composition_Molecular_Formula:
        'item-master-form-chemical-composition-molecular-formula',
    Chemical_Composition_Selection_CAS_Number:
        'item-master-form-chemical-composition-selection-cas-number',
    Chemical_Composition_Selection_CAS_Description:
        'item-master-form-chemical-composition-selection-cas-description',
    Chemical_Composition_Selection_EC_No:
        'item-master-form-chemical-composition-selection-ec-no',
    Chemical_Composition_Selection_Reach_No:
        'item-master-form-chemical-composition-selection-reach-no',
    Chemical_Composition_Selection_Classification:
        'item-master-form-chemical-composition-selection-classification',
    Chemical_Composition_Selection_Molecular_Formula:
        'item-master-form-chemical-composition-selection-molecular-formula',
    Dot_Proper_Shipping_Name_Text:
        'item-master-form-dot-proper-shipping-name-text',
    Proper_Shipping_Name_Code: 'item-master-form-proper-shipping-name-code',
    Dot_Proper_Shipping_Name: 'item-master-form-dot-proper-shipping-name',
    RQ_Requirement: 'item-master-form-dot-rq-requirement',
    RQ_UOM: 'item-master-form-dot-rq-uom',
    Freight_Class: 'item-master-form-dot-freight-class',
    NMFC: 'item-master-form-dot-nmfc',
    Marine_Pollutant: 'item-master-form-dot-marine-pollutant',
};
export const RDFormulasFormDefs: RDFormulasForm = {
    RD_Formula_Detail_Text: 'rd-formulas-form-rd-formula-detail',
    Formula_Code: 'rd-formulas-form-formula-code',
    Formula_Description: 'rd-formulas-form-formula-description',
    Formula_Status: 'rd-formulas-form-formula-status',
    Product_Code: 'rd-formulas-form-product-code',
    Product_Selection_Product_Code:
        'rd-formulas-form-product-selection-product-code',
    Product_Selection_Product_Name:
        'rd-formulas-form-product-selection-product-name',
    Template: 'rd-formulas-form-template',
    Record_Publishing_Dates: 'rd-formulas-form-record-publishing-dates',
    Product_Date: 'rd-formulas-form-product-date',
    Properties_Date: 'rd-formulas-form-properties-date',
    Formula_Configuration: 'rd-formulas-form-formula-configuration',
    Max_Pilot_Qty: 'rd-formulas-form-max-pilot-qty',
    Trade_Name: 'rd-formulas-form-trade-name',
    UOM: 'rd-formulas-form-uom',
    UOM_Selection_Unit_Code: 'rd-formulas-form-uom-selection-unit-code',
    UOM_Selection_Description: 'rd-formulas-form-uom-selection-description',
    Approval_Type: 'rd-formulas-form-approval-type',
    Approved_Date: 'rd-formulas-form-approved-date',
    Approved_By: 'rd-formulas-form-approved-by',
    Approved_By_Selection_User: 'rd-formulas-form-approved-by-selection-user',
    Approved_By_Selection_Email: 'rd-formulas-form-approved-by-selection-email',
    Supersedes: 'rd-formulas-form-supersedes',
    Superseded_By: 'rd-formulas-form-superseded-by',
    Additional_Text: 'rd-formulas-form-additional-text',
    CAS_Compositions_Text: 'rd-formulas-form-cas-compositions',
    CAS_No: 'rd-formulas-form-cas-no',
    CAS_Description: 'rd-formulas-form-cas-description',
    Component: 'rd-formulas-form-component',
    Lower_Operator: 'rd-formulas-form-lower-operator',
    Lower_Percent: 'rd-formulas-form-lower-percent',
    Upper_Operator: 'rd-formulas-form-upper-operator',
    Upper_Percent: 'rd-formulas-form-upper-percent',
    EC_No: 'rd-formulas-form-ec-no',
    Reach_No: 'rd-formulas-form-reach-no',
    Classification: 'rd-formulas-form-classification',
    Trade_Secret: 'rd-formulas-form-trade-secret',
    CAS_Selection_CAS_Number: 'rd-formulas-form-cas-selection-cas-number',
    CAS_Selection_CAS_Description:
        'rd-formulas-form-cas-selection-cas-description',
    CAS_Selection_EC_No: 'rd-formulas-form-cas-selection-ec-no',
    CAS_Selection_Reach_No: 'rd-formulas-form-cas-selection-reach-no',
    CAS_Selection_Classification:
        'rd-formulas-form-cas-selection-classification',
    CAS_Selection_Molecular_Formula:
        'rd-formulas-form-cas-selection-molecular-formula',
    Component_Selection_Item_ID: 'rd-formulas-form-component-selection-item-id',
    Component_Selection_Item_Name:
        'rd-formulas-form-component-selection-item-name',
    Component_Selection_Version: 'rd-formulas-form-component-selection-version',
    Component_Selection_Description_One:
        'rd-formulas-form-component-selection-description-one',
    Component_Selection_Description_Two:
        'rd-formulas-form-component-selection-description-two',
    Component_Selection_Status: 'rd-formulas-form-component-selection-status',
    Component_Selection_Inventory_Type:
        'rd-formulas-form-component-selection-inventory-type',
    Component_Selection_UPC: 'rd-formulas-form-component-selection-upc',
    Component_Selection_Cost: 'rd-formulas-form-component-selection-cost',
    Component_Text: 'rd-formulas-form-formula-components',
    Component_Step: 'rd-formulas-form-step',
    Component_Type: 'rd-formulas-form-component-type',
    Component_Name: 'rd-formulas-form-formula-component',
    Component_Formula_Qty: 'rd-formulas-form-component-formula-qty',
    Component_Uom: 'rd-formulas-form-component-uom',
    Component_Uom_Cost: 'rd-formulas-form-component-uom-cost',
    Component_Lock_Qty: 'rd-formulas-form-component-lock-qty',
    Component_Scrap_Pct: 'rd-formulas-form-component-scrap-percentage',
    Component_Weight_Pct: 'rd-formulas-form-component-weight-percentage',
    Component_Volume_Pct: 'rd-formulas-form-component-volume-percentage',
    Component_Unit_Cost: 'rd-formulas-form-component-unit-cost',
    Component_Boilerplate_Note: 'rd-formulas-form-component-boilerplate-note',
    Component_Stock_Uom: 'rd-formulas-form-component-stock-uom',
    Component_Stock_Uom_Conv: 'rd-formulas-form-component-stock-uom-conversion',
    Component_Specific_Gravity: 'rd-formulas-form-component-specific-gravity',
    Component_Density: 'rd-formulas-form-component-density',
    Component_Stock_Cost: 'rd-formulas-form-component-stock-cost',
    Component_Extended_Cost: 'rd-formulas-form-component-extended-cost',
    Component_Stock_Qty: 'rd-formulas-form-component-stock-qty',
    Component_Weight_Kg: 'rd-formulas-form-component-weight-kg',
    Component_Volume_Litres: 'rd-formulas-form-component-volume-litres',
    Component_Conversion_Method: 'rd-formulas-form-component-conversion-method',
    Component_Enter_Step: 'rd-formulas-form-enter-step',
    Component_Enter_Component_Type: 'rd-formulas-form-enter-component-type',
    Component_Enter: 'rd-formulas-form-formula-enter-component',
    Component_Enter_Formula_Qty: 'rd-formulas-form-component-enter-formula-qty',
    Component_Enter_Uom: 'rd-formulas-form-component-enter-uom',
    Component_Enter_Uom_Cost: 'rd-formulas-form-component-enter-uom-cost',
    Component_Enter_Lock_Qty: 'rd-formulas-form-component-enter-lock-qty',
    Component_Enter_Scrap_Pct:
        'rd-formulas-form-component-enter-scrap-percentage',
    Component_Enter_Boilerplate:
        'rd-formulas-form-component-enter-boilerplate-note',
    Component_Enter_Stock_Uom: 'rd-formulas-form-component-enter-stock-uom',
    Component_Enter_Stock_Cost: 'rd-formulas-form-component-enter-stock-cost',
    Component_Selection_Code: 'rd-formulas-form-component-selection-code',
    Component_Selection_Description:
        'rd-formulas-form-component-selection-description',
    Equation_Text: 'rd-formulas-form-formula-equations',
    Component_Totals_Text: 'rd-formulas-form-formula-totals',
    Component_Calculated_Totals_Text:
        'rd-formulas-form-formula-calculated-totals',
    Component_Component_Cost_Totals_Text:
        'rd-formulas-form-formula-component-cost-totals',
    Component_Totals_Yield_Text:
        'rd-formulas-form-formula-component-totals-yield',
    Component_Totals_Specific_Gravity_Text:
        'rd-formulas-form-formula-component-totals-specific-gravity',
    Component_Totals_Total: 'rd-formulas-form-formula-component-totals-total',
    Component_Totals_Unit: 'rd-formulas-form-formula-component-totals-unit',
    Component_Totals_Manual_Yield:
        'rd-formulas-form-formula-component-totals-manual-yield-pct',
    Component_Totals_Calculated_Yield:
        'rd-formulas-form-formula-component-totals-calculated-yield-pct',
    Component_Totals_Unit_Cost:
        'rd-formulas-form-formula-component-totals-unit-cost',
    Component_Totals_Net_Cost:
        'rd-formulas-form-formula-component-totals-net-cost',
    Component_Totals_Extended_Cost:
        'rd-formulas-form-formula-component-totals-extended-cost',
    Component_Totals_Net: 'rd-formulas-form-formula-component-totals-net',
    Component_Totals_Yield_Override:
        'rd-formulas-form-formula-component-totals-yield-calc-override',
};

export const SafetyDataSheetGridDefs: SafetyDataSheetGrid = {
    SDS_Code: 'sds-grid-sds-code',
    SDS_Description: 'sds-grid-sds-description',
    Item_Code: 'sds-grid-item-code',
    Status: 'sds-grid-status',
    Revision_Number: 'sds-grid-revision-number',
    Revision_Date: 'sds-grid-revision-date',
    Language: 'sds-grid-language',
    Locale_Group: 'sds-grid-locale-group',
};

export const SDSFormDefs: SDSForm = {
    Safety_Data_Sheet_Maintenance: 'sds-form-safety-data-sheet-maintenance',
    SDS_Code: 'sds-form-sds-code',
    SDS_Description: 'sds-form-sds-description',
    Item_Code: 'sds-form-item-code',
    Item_Code_Popup_Item_Code: 'sds-form-item-code-popup-item-code',
    Item_Code_Popup_Item_Name: 'sds-form-item-code-popup-item-name',
    Template: 'sds-form-template',
    Status: 'sds-form-status',
    Revision_Number: 'sds-form-revision-number',
    Revision_Date: 'sds-form-revision-date',
    Language: 'sds-form-language',
    Locale_Group: 'sds-form-locale-group',
    Locale_Group_Popup_Locale_Group_ID:
        'sds-form-locale-group-popup-locale-group-id',
    Locale_Group_Popup_Description: 'sds-form-locale-group-popup-description',
    Identification: 'sds-form-identification',
    Product_Identifier: 'sds-form-product-identifier',
    Product_Name: 'sds-form-product-name',
    CAS_No: 'sds-form-cas-no',
    CAS_No_Popup_CAS_No: 'sds-form-cas-no-popup-cas-no',
    CAS_No_Popup_Chemical_Name: 'sds-form-cas-no-popup-chemical-name',
    Registration_Number: 'sds-form-registration-number',
    Additional_Code: 'sds-form-additional-code',
    Synonyms: 'sds-form-synonyms',
    Relevant_Identified_Uses: 'sds-form-relevant-identified-uses',
    Product_Use_Description: 'sds-form-product-use-description',
    Certified: 'sds-form-certified',
    Certification_Date: 'sds-form-certification-date',
    Certification_User: 'sds-form-certification-user',
    Certification_User_Popup_User: 'sds-form-certification-user-popup-user',
    Certification_User_Popup_Email: 'sds-form-certification-user-popup-email',
    Formula_Code: 'sds-form-formula-code',
    Formula_Code_Popup_Formula_Code: 'sds-form-formula-code-popup-formula-code',
    Formula_Code_Popup_Formula_Description:
        'sds-form-formula-code-popup-formula-description',
    Details_Of_The_Supplier: 'sds-form-details-of-the-supplier',
    Supplier_Name: 'sds-form-supplier-name',
    Phone_Number: 'sds-form-phone-number',
    Email: 'sds-form-email',
    Website: 'sds-form-website',
    Additional_Text_One: 'sds-form-additional-text-one',
    Emergency_Telephone_Information: 'sds-form-emergency-telephone-information',
    Emergency_Contact_Name: 'sds-form-emergency-contact-name',
    Phone_Number_One: 'sds-form-phone-number-one',
    Phone_Number_One_Description: 'sds-form-phone-number-one-description',
    Phone_Number_Two: 'sds-form-phone-number-two',
    Phone_Number_Two_Description: 'sds-form-phone-number-two-description',
    Hazard_Identification: 'sds-form-hazard-identification',
    Classification_Of_Substances_Or_Mixtures:
        'sds-form-classification-of-substances-or-mixtures',
    Classification: 'sds-form-classification',
    Label_Elements: 'sds-form-label-elements',
    GHS_Pictogram: 'sds-form-ghs-pictogram',
    Explosive: 'sds-form-explosive',
    Flammable: 'sds-form-flammable',
    Oxidizing: 'sds-form-oxidizing',
    Compressed_Gas: 'sds-form-compressed-gas',
    Toxic: 'sds-form-toxic',
    Corrosive: 'sds-form-corrosive',
    Irritant: 'sds-form-irritant',
    Health_Hazard: 'sds-form-health-hazard',
    Environment_Damaging: 'sds-form-environment-damaging',
    Signal_Word: 'sds-form-signal-word',
    GHS_Hazard_Phrases: 'sds-form-ghs-hazard-phrases',
    GHS_Hazard_Phrases_Code: 'sds-form-ghs-hazard-phrases-code',
    GHS_Hazard_Phrases_Phrase: 'sds-form-ghs-hazard-phrases-phrase',
    GHS_Hazard_Phrases_Hazard_Type: 'sds-form-ghs-hazard-phrases-hazard-type',
    GHS_Hazard_Phrases_Language: 'sds-form-ghs-hazard-phrases-language',
    GHS_Hazard_Phrases_Selection_Product_Specific_Verbiage:
        'sds-form-ghs-hazard-phrase-product-specific-verbiage',
    GHS_Precautionary_Phrases: 'sds-form-ghs-precautionary-phrases',
    GHS_Precautionary_Phrases_Code: 'sds-form-ghs-precautionary-phrases-code',
    GHS_Precautionary_Phrases_Phrase_Text:
        'sds-form-ghs-precautionary-phrases-phrase-text',
    GHS_Precautionary_Phrases_Statement_Type:
        'sds-form-ghs-precautionary-phrases-statement-type',
    GHS_Precautionary_Phrases_Language:
        'sds-form-ghs-precautionary-phrases-language',
    GHS_Precautionary_Phrases_Selection_Product_Specific_Verbiage:
        'sds-form-ghs-precautionary-phrase-product-specific-verbiage',
    Other_Hazards_Text: 'sds-form-other-hazards-text',
    Other_Hazards_One: 'sds-form-other-hazards-one',
    Unknown_Acute_Toxicity_Text: 'sds-form-unknown-acute-toxicity-text',
    Unknown_Acute_Toxicity: 'sds-form-unknown-acute-toxicity',
    Additional_Text_Two: 'sds-form-additional-text-two',
    Composition_Ingredient_Information:
        'sds-form-composition-ingredient-information',
    Chemical_Composition: 'sds-form-chemical-composition',
    Substance: 'sds-form-substance',
    Chemical_Composition_CAS_No: 'sds-form-chemical-composition-cas-no',
    Chemical_Composition_Chemical_Name:
        'sds-form-chemical-composition-chemical-name',
    Chemical_Composition_Component: 'sds-form-chemical-composition-component',
    Chemical_Composition_Lower_Operator:
        'sds-form-chemical-composition-lower-operator',
    Chemical_Composition_Lower_Percent:
        'sds-form-chemical-composition-lower-percent',
    Chemical_Composition_Upper_Operator:
        'sds-form-chemical-composition-upper-operator',
    Chemical_Composition_Upper_Percent:
        'sds-form-chemical-composition-upper-percent',
    Chemical_Composition_EC_No: 'sds-form-chemical-composition-ec-no',
    Chemical_Composition_Reach_No: 'sds-form-chemical-composition-reach-no',
    Chemical_Composition_Classification:
        'sds-form-chemical-composition-classification',
    Chemical_Composition_Trade_Secret:
        'sds-form-chemical-composition-trade-secret',
    Chemical_Composition_CAS_Selection_CAS_No:
        'sds-form-chemical-composition-cas-selection-cas-no',
    Chemical_Composition_CAS_Selection_Chemical_Name:
        'sds-form-chemical-composition-cas-selection-chemical-name',
    Chemical_Composition_CAS_Selection_EC_No:
        'sds-form-chemical-composition-cas-selection-ec-no',
    Chemical_Composition_CAS_Selection_Reach_No:
        'sds-form-chemical-composition-cas-selection-reach-no',
    Chemical_Composition_CAS_Selection_Classification:
        'sds-form-chemical-composition-cas-selection-classification',
    Chemical_Composition_CAS_Selection_Molecular_Formula:
        'sds-form-chemical-composition-cas-selection-molecular-formula',
    Chemical_Composition_Component_Selection_Item_ID:
        'sds-form-chemical-composition-component-selection-item-id',
    Chemical_Composition_Component_Selection_Item_Name:
        'sds-form-chemical-composition-component-selection-item-name',
    Chemical_Composition_Component_Selection_Version:
        'sds-form-chemical-composition-component-selection-version',
    Chemical_Composition_Component_Selection_Description_One:
        'sds-form-chemical-composition-component-selection-description-one',
    Chemical_Composition_Component_Selection_Description_Two:
        'sds-form-chemical-composition-component-selection-description-two',
    Chemical_Composition_Component_Selection_Status:
        'sds-form-chemical-composition-component-selection-status',
    Chemical_Composition_Component_Selection_Inventory_Type:
        'sds-form-chemical-composition-component-selection-inventory-type',
    Chemical_Composition_Component_Selection_UPC:
        'sds-form-chemical-composition-component-selection-upc',
    Chemical_Composition_Component_Selection_Cost:
        'sds-form-chemical-composition-component-selection-cost',
    Additional_Text_Three: 'sds-form-additional-text-three',
    First_Aid_Measure: 'sds-form-first-aid-measures',
    Description_Of_First_Aid_Measures:
        'sds-form-description-of-first-aid-measures',
    First_Aid_Measures_General: 'sds-form-first-aid-measures-general',
    After_Inhalation: 'sds-form-after-inhalation',
    After_Skin_Contact: 'sds-form-after-skin-contact',
    After_Eye_Contact: 'sds-form-after-eye-contact',
    After_Ingestion: 'sds-form-after-ingestion',
    Self_Protection_First_Aider: 'sds-form-self-protection-first-aider',
    Most_Important_Symptoms_Text: 'sds-form-most-important-symptoms-text',
    Most_Important_Symptoms: 'sds-form-most-important-symptoms',
    Indication_Immediate_Medical_Attention:
        'sds-form-indication-immediate-medical-attention',
    Immediate_Attention: 'sds-form-immediate-attention',
    Additional_Text_Four: 'sds-form-additional-text-four',
    Firefighting_Measures: 'sds-form-firefighting-measures',
    Extinguishing_Media: 'sds-form-extinguishing-media',
    Suitable_Extinguishing_Media: 'sds-form-suitable-extinguishing-media',
    Unsuitable_Extinguishing_Media: 'sds-form-unsuitable-extinguishing-media',
    Special_Hazard_Arising: 'sds-form-special-hazard-arising',
    Special_Hazards: 'sds-form-special-hazards',
    Advice_For_Firefighters_Text: 'sds-form-advice-for-firefighters-text',
    Advice_For_Firefighters: 'sds-form-advice-for-firefighters',
    Additional_Text_Five: 'sds-form-additional-text-five',
    Accidental_Release: 'sds-form-accidental-release',
    Precautions_Equipment_Procedures_Text:
        'sds-form-precautions-equipment-procedures-text',
    Precautions_Equipment_Procedures:
        'sds-form-precautions-equipment-procedures',
    Environmental_Precautions_Text: 'sds-form-environmental-precautions-text',
    Environmental_Precautions: 'sds-form-environmental-precautions',
    Methods_Material_For_Containment_Text:
        'sds-form-methods-materials-for-containment-text',
    Methods_Material_For_Containment_Cleanup:
        'sds-form-methods-materials-for-containment-cleanup',
    Reference_To_Other_Section_Text: 'sds-form-reference-to-other-section-text',
    Reference_To_Other_Section: 'sds-form-reference-to-other-section',
    Additional_Text_Six: 'sds-form-additional-text-six',
    Handling_Storage: 'sds-form-handling-storage',
    Precaution_For_Safe_Handling: 'sds-form-precaution-for-safe-handling',
    Advice_On_Safe_Handling: 'sds-form-advice-on-safe-handling',
    Conditions_For_Safe_Storage: 'sds-form-conditions-for-safe-storage',
    Safe_Storage: 'sds-form-safe-storage',
    Incompatibilities_Specific_End_Use:
        'sds-form-incompatibilities-specific-end-use',
    Incompatibilities: 'sds-form-incompatibilities',
    Specific_End_Use: 'sds-form-specific-end-use',
    Additional_Text_Seven: 'sds-form-additional-text-seven',
    Exposure_Controls: 'sds-form-exposure-controls',
    Control_Parameters: 'sds-form-control-parameters',
    Control_Parameters_CAS_No: 'sds-form-control-parameters-cas-no',
    Control_Parameters_Chemical_Name:
        'sds-form-control-parameters-chemical-name',
    Control_Parameters_Exposure_Agency_Code:
        'sds-form-control-parameters-exposure-agency-code',
    Control_Parameters_Exposure_Agencies_Description:
        'sds-form-control-parameters-exposure-agencies-description',
    Control_Parameters_Exposure_Value:
        'sds-form-control-parameters-exposure-value',
    Control_Parameters_Exposure_Form:
        'sds-form-control-parameters-exposure-form',
    Control_Parameters_Exposure_Notes:
        'sds-form-control-parameters-exposure-notes',
    Control_Parameters_CAS_Selection_CAS_No:
        'sds-form-control-parameters-cas-selection-cas-no',
    Control_Parameters_CAS_Selection_Chemical_Name:
        'sds-form-control-parameters-cas-selection-chemical-name',
    Control_Parameters_CAS_Selection_EC_No:
        'sds-form-control-parameters-cas-selection-ec-no',
    Control_Parameters_CAS_Selection_Reach_No:
        'sds-form-control-parameters-cas-selection-reach-no',
    Control_Parameters_CAS_Selection_Classification:
        'sds-form-control-parameters-cas-selection-classification',
    Control_Parameters_CAS_Selection_Molecular_Formula:
        'sds-form-control-parameters-cas-selection-molecular-formula',
    Control_Parameters_Exposure_Agency_Selection_Exposure_Agency_Code:
        'sds-form-control-parameters-exposure-agency-selection-exposure-agency-code',
    Control_Parameters_Exposure_Agency_Selection_Description:
        'sds-form-control-parameters-exposure-agency-selection-description',
    Engineering_Controls_Text: 'sds-form-engineering-controls-text',
    Engineering_Controls: 'sds-form-engineering-controls',
    Environmental_Exposure_Controls: 'sds-form-environmental-exposure-controls',
    Protective_Measures: 'sds-form-protective-measures',
    Eye_Face_Protection: 'sds-form-eye-face-protection',
    Hand_Protection: 'sds-form-hand-protection',
    Skin_Protection: 'sds-form-skin-protection',
    Other_Protection: 'sds-form-other-protection',
    Respiratory_Protection: 'sds-form-respiratory-protection',
    General_Hygiene_Consideration: 'sds-form-general-hygiene-consideration',
    Thermal_Hazards: 'sds-form-thermal-hazards',
    Additional_Text_Eight: 'sds-form-additional-text-eight',
    Physical_Chemical: 'sds-form-physical-chemical',
    Information_On_Basic_Physical: 'sds-form-information-on-basic-physical',
    Properties_Property_Code: 'sds-form-properties-property-code',
    Properties_Property_Name: 'sds-form-properties-property-name',
    Properties_Value: 'sds-form-properties-value',
    Properties_UOM: 'sds-form-properties-uom',
    Properties_Selection_Text: 'sds-form-properties-selection-text',
    Properties_Selection_Property_Code:
        'sds-form-properties-selection-property-code',
    Properties_Selection_Property_Name:
        'sds-form-properties-selection-property-name',
    Properties_Selection_Default_Value:
        'sds-form-properties-selection-default-value',
    Properties_UOM_Selection_UOM: 'sds-form-properties-uom-selection-uom',
    Properties_UOM_Selection_Description:
        'sds-form-properties-uom-selection-description',
    Other_Information_Text: 'sds-form-other-information-text',
    Other_Information: 'sds-form-other-information',
    Stability_Reactivity: 'sds-form-stability-reactivity',
    Reactivity_Text: 'sds-form-reactivity-text',
    Reactivity: 'sds-form-reactivity',
    Chemical_Stability_Text: 'sds-form-chemical-stability-text',
    Chemical_Stability: 'sds-form-chemical-stability',
    Possibility_Hazardous_Reactions: 'sds-form-possibility-hazardous-reactions',
    Hazardous_Reactions: 'sds-form-hazardous-reactions',
    Conditions_To_Avoid_Text: 'sds-form-conditions-to-avoid-text',
    Conditions_To_Avoid: 'sds-form-conditions-to-avoid',
    Incompatible_Materials: 'sds-form-incompatible-materials',
    Incompatible_Substances: 'sds-form-incompatible-substances',
    Hazardous_Decomposition_Products:
        'sds-form-hazardous-decomposition-products',
    Decomposition_Hazard: 'sds-form-decomposition-hazard',
    Additional_Text_Ten: 'sds-form-additional-text-ten',
    Toxicological: 'sds-form-toxicological',
    Information_On_Toxicological_Effects:
        'sds-form-information-on-toxicological-effects',
    Skin_Contact: 'sds-form-skin-contact',
    Eye_Contact: 'sds-form-eye-contact',
    Inhalation: 'sds-form-inhalation',
    Ingestion: 'sds-form-ingestion',
    Symptoms_Text: 'sds-form-symptoms-text',
    Symptoms_Related_To_Characteristics:
        'sds-form-symptoms-related-to-characteristics',
    Delayed_And_Chronic_Effects: 'sds-form-delayed-and-chronic-effects',
    Acute_Effects: 'sds-form-acute-effects',
    Chronic_Effects: 'sds-form-chronic-effects',
    Numerical_Measure_Of_Toxicity: 'sds-form-numerical-measure-of-toxicity',
    Measure_Toxicity_CAS_No: 'sds-form-measure-toxicity-cas-no',
    Measure_Toxicity_Chemical_Name: 'sds-form-measure-toxicity-chemical-name',
    Measure_Toxicity_Route: 'sds-form-measure-toxicity-route',
    Measure_Toxicity_Test_Type: 'sds-form-measure-toxicity-test-type',
    Measure_Toxicity_Test_Description:
        'sds-form-measure-toxicity-test-description',
    Measure_Toxicity_Effective_Dose: 'sds-form-measure-toxicity-effective-dose',
    Measure_Toxicity_Exposure_Time: 'sds-form-measure-toxicity-exposure-time',
    Measure_Toxicity_Species: 'sds-form-measure-toxicity-species',
    Measure_Toxicity_Species_Description:
        'sds-form-measure-toxicity-species-description',
    Measure_Toxicity_Class_Of_Species:
        'sds-form-measure-toxicity-class-of-species',
    Measure_Toxicity_Note: 'sds-form-measure-toxicity-note',
    Measure_Toxicity_Source: 'sds-form-measure-toxicity-source',
    Measure_Toxicity_CAS_Selection_CAS_No:
        'sds-form-measure-toxicity-cas-selection-cas-no',
    Measure_Toxicity_CAS_Selection_Chemical_Name:
        'sds-form-measure-toxicity-cas-selection-chemical-name',
    Measure_Toxicity_CAS_Selection_EC_No:
        'sds-form-measure-toxicity-cas-selection-ec-no',
    Measure_Toxicity_CAS_Selection_Reach_No:
        'sds-form-measure-toxicity-cas-selection-reach-no',
    Measure_Toxicity_CAS_Selection_Classification:
        'sds-form-measure-toxicity-cas-selection-classification',
    Measure_Toxicity_CAS_Selection_Molecular_Formula:
        'sds-form-measure-toxicity-cas-selection-molecular-formula',
    Measure_Toxicity_Test_Selection_Toxic_Test_Code:
        'sds-form-measure-toxicity-test-selection-toxic-test-code',
    Measure_Toxicity_Test_Selection_Description:
        'sds-form-measure-toxicity-test-selection-description',
    Measure_Toxicity_Species_Selection_Toxic_Species_Code:
        'sds-form-measure-toxicity-species-selection-toxic-species-code',
    Measure_Toxicity_Species_Selection_Description:
        'sds-form-measure-toxicity-species-selection-description',
    Hazardous_Chemical_Listing_In_NTP:
        'sds-form-hazardous-chemical-listed-in-ntp',
    Carcinogenicity: 'sds-form-carcinogenicity',
    Germ_Cell_Mutagenicity: 'sds-form-germ-cell-mutagenicity',
    Reproductive_Toxicity: 'sds-form-reproductive-toxicity',
    Specific_Target_Organ_Toxicity_Single_Exposure:
        'sds-form-specific-target-organ-toxicity-single-exposure',
    Specific_Target_Organ_Toxicity_Repeated_Exposure:
        'sds-form-specific-target-organ-toxicity-repeated-exposure',
    Aspiration_Hazard: 'sds-form-aspiration-hazard',
    Additional_Text_Eleven: 'sds-form-additional-text-eleven',
    Ecological_Information: 'sds-form-ecological-information',
    Toxicity: 'sds-form-toxicity',
    Ecotoxicity: 'sds-form-ecotoxicity',
    Toxicity_CAS_No: 'sds-form-toxicity-cas-no',
    Toxicity_Chemical_Name: 'sds-form-toxicity-chemical-name',
    Toxicity_Test_Type: 'sds-form-toxicity-test-type',
    Toxicity_Test_Description: 'sds-form-toxicity-test-description',
    Toxicity_Effective_Dose: 'sds-form-toxicity-effective-dose',
    Toxicity_Exposure_Time: 'sds-form-toxicity-exposure-time',
    Toxicity_Species: 'sds-form-toxicity-species',
    Toxicity_Species_Description: 'sds-form-toxicity-species-description',
    Toxicity_Class_Of_Species: 'sds-form-toxicity-class-of-species',
    Toxicity_Note: 'sds-form-toxicity-note',
    Toxicity_Source: 'sds-form-toxicity-source',
    Toxicity_CAS_Selection_CAS_No: 'sds-form-toxicity-cas-selection-cas-no',
    Toxicity_CAS_Selection_Chemical_Name:
        'sds-form-toxicity-cas-selection-chemical-name',
    Toxicity_CAS_Selection_EC_No: 'sds-form-toxicity-cas-selection-ec-no',
    Toxicity_CAS_Selection_Reach_No: 'sds-form-toxicity-cas-selection-reach-no',
    Toxicity_CAS_Selection_Classification:
        'sds-form-toxicity-cas-selection-classification',
    Toxicity_CAS_Selection_Molecular_Formula:
        'sds-form-toxicity-cas-selection-molecular-formula',
    Toxicity_Test_Selection_Toxic_Test_Code:
        'sds-form-toxicity-test-selection-toxic-test-code',
    Toxicity_Test_Selection_Description:
        'sds-form-toxicity-test-selection-description',
    Toxicity_Species_Selection_Toxic_Species_Code:
        'sds-form-toxicity-species-selection-toxic-species-code',
    Toxicity_Species_Selection_Description:
        'sds-form-toxicity-species-selection-description',
    Persistence_Degradability_Text: 'sds-form-persistence-degradability-text',
    Persistence_Degradability: 'sds-form-persistence-degradability',
    BioAccumulative_Potential_Text: 'sds-form-bioaccumulative-potential-text',
    BioAccumulative_Potential: 'sds-form-bioaccumulative-potential',
    Mobility_In_Soil_Text: 'sds-form-mobility-in-soil-text',
    Mobility_In_Soil: 'sds-form-mobility-in-soil',
    Results_Of_PBT_and_PvB_Assessment_Text:
        'sds-form-results-of-pbt-and-pvb-assessment-text',
    Results_Of_PBT_and_PvB_Assessment:
        'sds-form-results-of-pbt-and-pvb-assessment',
    Other_Adverse_Effects_Text: 'sds-form-other-adverse-effects-text',
    Other_Adverse_Effects: 'sds-form-other-adverse-effects',
    Additional_Information: 'sds-form-additional-information',
    Additional_Text_Twelve: 'sds-form-additional-text-twelve',
    Disposal_Considerations: 'sds-form-disposal-considerations',
    Waste_Treatment_Methods: 'sds-form-waste-treatment-methods',
    Handling_For_Disposal: 'sds-form-handling-for-disposal',
    Methods_Of_Disposal: 'sds-form-methods-of-disposal',
    Contaminated_Packaging: 'sds-form-contaminated-packaging',
    Additional_Text_Thirteen: 'sds-form-additional-text-thirteen',
    Transport: 'sds-form-transport',
    Environmental_Hazards: 'sds-form-environmental-hazards',
    Marine_Pollutant: 'sds-form-marine-pollutant',
    Other_Hazards_Two: 'sds-form-other-hazards-two',
    Special_Precautions_For_Users_Text:
        'sds-form-special-precautions-for-users-text',
    Special_Precautions_For_Users: 'sds-form-special-precautions-for-users',
    Transport_In_Bulk_Text: 'sds-form-transport-in-bulk-text',
    Transport_In_Bulk: 'sds-form-transport-in-bulk',
    Additional_Text_Fourteen: 'sds-form-additional-text-fourteen',
    Regulatory_Information: 'sds-form-regulatory-information',
    Safety_Health_Environmental: 'sds-form-safety-health-environmental',
    Regulation_Regulatory_Code: 'sds-form-regulation-regulatory-code',
    Regulation_Title: 'sds-form-regulation-title',
    Regulation_CAS_No: 'sds-form-regulation-cas-no',
    Regulation_Chemical_Name: 'sds-form-regulation-chemical-name',
    Regulation_On_List: 'sds-form-regulation-on-list',
    Regulation_Import_Manufacture_Limit:
        'sds-form-regulation-import-manufacture-limit',
    Regulation_Threshold: 'sds-form-regulation-threshold',
    Regulation_Notes: 'sds-form-regulation-notes',
    Regulation_Regulation_Body: 'sds-form-regulation-regulation-body',
    Regulation_Section: 'sds-form-regulation-section',
    Regulation_State_Province: 'sds-form-regulation-state-province',
    Regulation_Country: 'sds-form-regulation-country',
    Regulation_CAS_Selection_CAS_No:
        'sds-form-regulations-cas-selection-cas-no',
    Regulation_CAS_Selection_Chemical_Name:
        'sds-form-regulations-cas-selection-chemical-name',
    Regulation_CAS_Selection_EC_No: 'sds-form-regulations-cas-selection-ec-no',
    Regulation_CAS_Selection_Reach_No:
        'sds-form-regulations-cas-selection-reach-no',
    Regulation_CAS_Selection_Classification:
        'sds-form-regulations-cas-selection-classification',
    Regulation_CAS_Selection_Molecular_Formula:
        'sds-form-regulations-cas-selection-molecular-formula',
    Regulation_State_Selection_UOM: 'sds-form-regulations-state-selection-uom',
    Regulation_State_Selection_Description:
        'sds-form-regulations-state-selection-description',
    Regulation_Country_Selection_Name:
        'sds-form-regulations-country-selection-name',
    Chemical_Safety_Assessment: 'sds-form-chemical-safety-assessment',
    Chemical_Safety: 'sds-form-chemical-safety',
    Regulatory_Code_Popup_Regulatory_Code:
        'sds-form-regulatory-code-popup-regulatory-code',
    Regulatory_Code_Popup_Regulatory_Title:
        'sds-form-regulatory-code-popup-regulatory-title',
    Additional_Text_Fifteen: 'sds-form-additional-text-fifteen',
};
export const GhsHazardPhrasesGridDefs: GhsHazardPhrasesGrid = {
    Hazard_Phrases: 'ghs-hazard-phrases-grid-hazard-phrases',
    Phrase_Library: 'ghs-hazard-phrases-grid-phrase-library',
    Phrase_ID: 'ghs-hazard-phrases-grid-phrase-id',
    Text: 'ghs-hazard-phrases-grid-text',
};
export const GhsHazardPhrasesFormDefs: GhsHazardPhrasesForm = {
    Create_Phrases: 'ghs-hazard-phrases-form-create-phrases',
    Edit_Phrases: 'ghs-hazard-phrases-form-edit-phrases',
    Phrase_ID: 'ghs-hazard-phrases-form-phrase-id',
    User_Input_Needed: 'ghs-hazard-phrases-form-user-input-needed',
    Text: 'ghs-hazard-phrases-form-text',
    Hazard_Type: 'ghs-hazard-phrases-form-hazard-type',
    Add_Language: 'ghs-hazard-phrases-form-add-language',
    Changes_Not_Saved: 'ghs-hazard-phrases-form-changes-not-saved',
};
export const GhsPrecautionaryPhrasesGridDefs: GhsPrecautionaryPhrasesGrid = {
    Precautionary_Phrases:
        'ghs-precautionary-phrases-grid-precautionary-phrases',
    Phrase_Library: 'ghs-precautionary-phrases-grid-phrase-library',
    Phrase_ID: 'ghs-precautionary-phrases-grid-phrase-id',
    Text: 'ghs-precautionary-phrases-grid-text',
};
export const GhsPrecautionaryPhrasesFormDefs: GhsPrecautionaryPhrasesForm = {
    Create_Phrases: 'ghs-precautionary-phrases-form-create-phrases',
    Edit_Phrases: 'ghs-precautionary-phrases-form-edit-phrases',
    Phrase_ID: 'ghs-precautionary-phrases-form-phrase-id',
    User_Input_Needed: 'ghs-precautionary-phrases-form-user-input-needed',
    Text: 'ghs-precautionary-phrases-form-text',
    Statement_Type: 'ghs-precautionary-phrases-form-statement-type',
    Add_Language: 'ghs-precautionary-phrases-form-add-language',
    Changes_Not_Saved: 'ghs-precautionary-phrases-form-changes-not-saved',
};
export const RegulationsGridDefs: RegulationsGrid = {
    Regulation_Code: 'regulations-grid-regulation-code',
    Regulation_Title: 'regulations-grid-regulation-title',
    Regulation_Body: 'regulations-grid-regulation-body',
    Section: 'regulations-grid-section',
    State_Province: 'regulations-grid-state-province',
};
export const RegulationsFormDefs: RegulationsForm = {
    Regulations: 'regulations-form-regulations',
    Regulation_Code: 'regulations-form-regulation-code',
    Regulation_Title: 'regulations-form-regulation-title',
    Regulation_Body: 'regulations-form-regulation-body',
    Section: 'regulations-form-section',
    State: 'regulations-form-state',
};
export const FormulaPropertiesGridDefs: FormulaPropertiesGrid = {
    Property_Code: 'formula-properties-grid-property-code',
    Description: 'formula-properties-grid-description',
    Sort_Order: 'formula-properties-grid-sort-order',
    Default_Value: 'formula-properties-grid-default-value',
    Property_Type: 'formula-properties-grid-property-type',
    Show_On_SDS: 'formula-properties-grid-show-on-sds',
    SDS_Generation: 'formula-properties-grid-sds-generation',
};
export const FormulaPropertiesFormDefs: FormulaPropertiesForm = {
    Formula_Properties: 'formula-properties-form-formula-properties',
    Property_Code: 'formula-properties-form-property-code',
    Description: 'formula-properties-form-description',
    Sort_Order: 'formula-properties-form-sort-order',
    Default_Value: 'formula-properties-form-default-value',
    Property_Type: 'formula-properties-form-property-type',
    Show_On_SDS: 'formula-properties-form-show-on-sds',
    SDS_Generation: 'formula-properties-form-sds-generation',
};
export const ToxicSpeciesGridDefs: ToxicSpeciesGrid = {
    Species_Code: 'toxic-species-grid-species-code',
    Description: 'toxic-species-grid-description',
};
export const ToxicSpeciesFormDefs: ToxicSpeciesForm = {
    Toxic_Species: 'toxic-species-form-toxic-species',
    Species_Code: 'toxic-species-form-species-code',
    Description: 'toxic-species-form-description',
};
export const ToxicTestGridDefs: ToxicTestGrid = {
    Code: 'toxic-test-grid-code',
    Description: 'toxic-test-grid-description',
};
export const ToxicTestFormDefs: ToxicTestForm = {
    Toxic_Test: 'toxic-test-form-toxic-test',
    Code: 'toxic-test-form-code',
    Description: 'toxic-test-form-description',
};
export const ExposureAgencyGridDefs: ExposureAgenciesGrid = {
    Agency_Code: 'exposure-agencies-grid-agency-code',
    Description: 'exposure-agencies-grid-description',
};
export const ExposureAgenciesFormDefs: ExposureAgenciesForm = {
    Exposure_Agency: 'exposure-agencies-form-exposure-agency',
    Exposure_Agency_Code: 'exposure-agencies-form-exposure-agency-code',
    Description: 'exposure-agencies-form-description',
};
export const SdsSectionHeadersGridDefs: SdsSectionHeadersGrid = {
    SDS_Section_Headers: 'sds-section-headers-grid-sds-section-headers',
    SDS_Section_Header_Library:
        'sds-section-headers-grid-sds-section-header-library',
    Header_ID: 'sds-section-headers-grid-header-id',
    Section_Text: 'sds-section-headers-grid-section-text',
    Locale_Group: 'sds-section-headers-grid-locale-group',
};
export const SdsSectionHeadersFormDefs: SdsSectionHeadersForm = {
    Create_SDS_Section_Header:
        'sds-section-headers-form-create-sds-section-header',
    Edit_SDS_Section_Header: 'sds-section-headers-form-edit-sds-section-header',
    Changes_Not_Saved: 'sds-section-headers-form-changes-not-saved',
    Add_Language: 'sds-section-headers-form-add-language',
    Header_ID: 'sds-section-headers-form-header-id',
    Locale_Group: 'sds-section-headers-form-locale-group',
    Section_Text: 'sds-section-headers-form-section-text',
    Section: 'sds-section-headers-form-section',
    Sub_Section: 'sds-section-headers-form-sub-section',
    Sub_Section_Letter: 'sds-section-headers-form-sub-section-letter',
    Show_Header_On_SDS: 'sds-section-headers-form-show-header-on-sds',
};
export const LocaleGroupsGridDefs: LocaleGroupsGrid = {
    Enabled_Active: 'locale-groups-grid-enable-active',
    Disabled_Active: 'locale-groups-grid-disabled-active',
    Locale_Group_Id: 'locale-groups-grid-locale-group-id',
    Description: 'locale-groups-grid-description',
};
export const LocaleGroupsFormDefs: LocaleGroupsForm = {
    Locale_Groups: 'locale-groups-form-locale-groups',
    Locale_Group_ID: 'locale-groups-form-locale-group-id',
    Active: 'locale-groups-form-active',
    Description: 'locale-groups-form-description',
};
export const LocalesGridDefs: LocalesGrid = {
    Locales: 'locales-grid-locales',
    Locale_ID: 'locales-grid-locale-id',
    Description: 'locales-grid-description',
    Group: 'locales-grid-group',
    Group_Description: 'locales-grid-group-description',
    Locale_Group_ID: 'locales-grid-locale-group-id',
    Locale_Group_Description: 'locales-grid-locale-group-description',
};
export const HazardClassMappingGridDefs: HazardClassMappingGrid = {
    Hazard_Class_Mapping: 'hazard-class-mapping-grid-hazard-class-mapping',
    Hazard_Code: 'hazard-class-mapping-grid-hazard-code',
    Description: 'hazard-class-mapping-grid-description',
};
export const FormulationSharedComponentsDefs: FormulationSharedComponents = {
    Select_Language: 'form-shared-select-language',
    Language: 'form-shared-language',
    Add_Language: 'form-shared-add-language',
    Phrase_Ok: 'form-shared-ok',
};
