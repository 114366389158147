import React, { useContext } from 'react';
import { FormMethods } from '../../../libs/hooksLib';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import BaseFormToolbar, { BaseFormToolbarProps } from './BaseFormToolbar';
import DatacorLogoSpinner from '../../datacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../formContainer/FormContainer';
import _ from 'lodash';
import TranslatableText from '../../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../contexts/settings.context';

export interface BaseFormProps extends BaseFormToolbarProps {
    title: any;
    containerTitle?: string;
    formMethods: FormMethods | FormMethods[];
    children: React.ReactNode;
    isLoading: boolean;
    revisionEntityTypeId?: number;
    readOnly?: boolean;
    termSet?: any;
}

export type ParamTypes = {
    id: string;
    type?: string;
    group?: string;
};

const BaseForm = (props: BaseFormProps) => {
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );
    return (
        <Card data-testid="formModule">
            <CardHeader title={<>{props.title}</>} />

            {props.isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <FormContainer
                                headerTitle={
                                    props.containerTitle || (
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SharedComponentsDefs.Primary_Information
                                            }
                                        />
                                    )
                                }
                                onSubmit={() => {
                                    if (_.isArray(props.formMethods)) {
                                        props.formMethods.forEach(
                                            (formMethod: FormMethods) => {
                                                formMethod.setIsFormSubmitted(
                                                    true
                                                );
                                            }
                                        );
                                    } else {
                                        props.formMethods.setIsFormSubmitted(
                                            true
                                        );
                                    }
                                }}
                                headerComponent={
                                    <BaseFormToolbar
                                        {...props}
                                        termSet={termSet}
                                    />
                                }>
                                {props.readOnly ? (
                                    <fieldset disabled>
                                        {props.children}
                                    </fieldset>
                                ) : (
                                    props.children
                                )}
                            </FormContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            )}
        </Card>
    );
};

export default BaseForm;
