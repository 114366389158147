export const PERMISSIONS = {
    SETTINGS: {
        FEATURE_FLAGS: {
            VIEW: 'Settings.featureFlag.view',
            EDIT: 'Settings.featureFlag.edit',
        },
    },
    USER_PORTAL: {
        VIEW: 'Administration.View.viewUserPortal',
        USERS: {
            VIEW: 'Administration.Users.view',
            CREATE: 'Administration.Users.create',
            EDIT: 'Administration.Users.edit',
            DELETE: 'Administration.Users.delete',
        },
        COMPANIES: {
            VIEW: 'Administration.Business Entities.View',
            CREATE: 'Administration.Business Entities.create',
            EDIT: 'Administration.Business Entities.edit',
            DELETE: 'Administration.Business Entities.delete',
        },
    },
    ADMINISTRATION: {
        VIEW: 'Administration.View.viewUserPortal',
        BUSINESS_ENTITIES: {
            VIEW: 'Administration.Business Entities.View',
            CREATE: 'Administration.Business Entities.create',
            EDIT: 'Administration.Business Entities.edit',
            DELETE: 'Administration.Business Entities.delete',
        },
        USERS: {
            VIEW: 'Administration.Users.view',
            CREATE: 'Administration.Users.create',
            EDIT: 'Administration.Users.edit',
            DELETE: 'Administration.Users.delete',
        },
    },
    AP_AUTOMATION: {
        VIEW: 'AP Automation.view',
        INVOICES: {
            UPDATE: 'AP Automation.updateInvoice',
        },
        APPROVAL_WORKFLOWS: {
            VIEW: 'AP Automation.approvalWorkflows.view',
            CREATE: 'AP Automation.approvalWorkflows.create',
            DELETE: 'AP Automation.approvalWorkflows.delete',
            UPDATE: 'AP Automation.approvalWorkflows.update',
        },
        DEFAULT_TOLERANCE: {
            VIEW: 'AP Automation.defaultTolerance.view',
            EDIT: 'AP Automation.defaultTolerance.edit',
        },
        MANUAL_MATCH: 'AP Automation.manualPoMatching',
        VIEW_ASSIGNED_ONLY: 'AP Automation.viewOnlyAssignedDocuments',
    },
    FIXED_ASSETS: {
        FIXED_ASSET_REGISTER: {
            CREATE: 'Fixed Assets.Fixed Asset Register.create',
            EDIT: 'Fixed Assets.Fixed Asset Register.edit',
            VIEW: 'Fixed Assets.Fixed Asset Register.view',
            IMPORT: 'Fixed Assets.Fixed Asset Register.import',
        },
        ASSET_ENTRY: {
            CREATE: 'Fixed Assets.Asset Entry.create',
            DELETE: 'Fixed Assets.Asset Entry.delete',
            LOCK: 'Fixed Assets.Asset Entry.lock',
            VIEW: 'Fixed Assets.Asset Entry.view',
            DOWNLOAD: 'Fixed Assets.Asset Entry.download',
        },
        ASSET_COST_CODES: {
            CREATE: 'Fixed Assets.Setup.Asset Cost Codes.create',
            EDIT: 'Fixed Assets.Setup.Asset Cost Codes.edit',
            DELETE: 'Fixed Assets.Setup.Asset Cost Codes.delete',
            VIEW: 'Fixed Assets.Setup.Asset Cost Codes.view',
        },
        DEPRECIATION_ENTRIES: {
            CREATE: 'Fixed Assets.Depreciation Entries.create',
            DOWNLOAD: 'Fixed Assets.Depreciation Entries.download',
            LOCK: 'Fixed Assets.Depreciation Entries.lock',
            VIEW: 'Fixed Assets.Depreciation Entries.view',
            EDIT: 'Fixed Assets.Depreciation Entries.edit',
            DELETE: 'Fixed Assets.Depreciation Entries.delete',
        },
        DEPRECIATION_METHODS: {
            CREATE: 'Fixed Assets.Setup.Depreciation Methods.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Methods.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Methods.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Methods.view',
        },
        DEPRECIATION_METHOD_STATUSES: {
            CREATE: 'Fixed Assets.Setup.Depreciation Method Statuses.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Method Statuses.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Method Statuses.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Method Statuses.view',
        },
        DEPRECIATION_METHOD_TYPES: {
            CREATE: 'Fixed Assets.Setup.Depreciation Method Types.create',
            EDIT: 'Fixed Assets.Setup.Depreciation Method Types.edit',
            DELETE: 'Fixed Assets.Setup.Depreciation Method Types.delete',
            VIEW: 'Fixed Assets.Setup.Depreciation Method Types.view',
        },
        DEPRECIATION_SCHEDULES: {
            CREATE: 'Fixed Assets.Depreciation Schedules.create',
            EDIT: 'Fixed Assets.Depreciation Schedules.edit',
            DELETE: 'Fixed Assets.Depreciation Schedules.delete',
            VIEW: 'Fixed Assets.Depreciation Schedules.view',
            LOCK: 'Fixed Assets.Depreciation Schedules.lock',
        },
        DISPOSAL_ENTRY: {
            CREATE: 'Fixed Assets.Disposal Entry.create',
            DELETE: 'Fixed Assets.Disposal Entry.delete',
            LOCK: 'Fixed Assets.Disposal Entry.lock',
            DOWNLOAD: 'Fixed Assets.Disposal Entry.download',
            VIEW: 'Fixed Assets.Disposal Entry.view',
        },
        ASSET_STATUSES: {
            CREATE: 'Fixed Assets.Setup.Asset Statuses.create',
            EDIT: 'Fixed Assets.Setup.Asset Statuses.edit',
            DELETE: 'Fixed Assets.Setup.Asset Statuses.delete',
            VIEW: 'Fixed Assets.Setup.Asset Statuses.view',
        },
        ASSET_TYPES: {
            CREATE: 'Fixed Assets.Setup.Asset Types.create',
            EDIT: 'Fixed Assets.Setup.Asset Types.edit',
            DELETE: 'Fixed Assets.Setup.Asset Types.delete',
            VIEW: 'Fixed Assets.Setup.Asset Types.view',
        },
        CONTRACTS: {
            CREATE: 'Fixed Assets.Contracts.create',
            EDIT: 'Fixed Assets.Contracts.edit',
            DELETE: 'Fixed Assets.Contracts.delete',
            VIEW: 'Fixed Assets.Contracts.view',
            VIEW_CONTRACT_COVERAGE:
                'Fixed Assets.Contracts.viewContractCoverage',
        },
        CONTRACT_TYPES: {
            CREATE: 'Fixed Assets.Setup.Contract Types.create',
            EDIT: 'Fixed Assets.Setup.Contract Types.edit',
            DELETE: 'Fixed Assets.Setup.Contract Types.delete',
            VIEW: 'Fixed Assets.Setup.Contract Types.view',
        },
        CHART_OF_ACCOUNTS: {
            CREATE: 'Fixed Assets.Setup.Chart Of Accounts.create',
            EDIT: 'Fixed Assets.Setup.Chart Of Accounts.edit',
            DELETE: 'Fixed Assets.Setup.Chart Of Accounts.delete',
            VIEW: 'Fixed Assets.Setup.Chart Of Accounts.view',
        },
        FUNCTION_CODES: {
            EDIT: 'Fixed Assets.Setup.Function Codes.edit',
            VIEW: 'Fixed Assets.Setup.Function Codes.view',
        },
        REPORTS: {
            ACQUISITIONS: {
                VIEW: 'Fixed Assets.Reports.viewAcquisitions',
            },
            DEPRECIATION_SUMMARY: {
                VIEW: 'Fixed Assets.Reports.viewDepreciationSummary',
            },
            DISPOSALS: {
                VIEW: 'Fixed Assets.Reports.viewDisposals',
            },
            DEPRECIATION_DETAIL: {
                VIEW: 'Fixed Assets.Reports.viewDepreciationDetail',
            },
        },
    },
    ACCOUNTING: {
        FUNCTION_CODES: {
            EDIT: 'Accounting.Setup.Function Codes.edit',
            VIEW: 'Accounting.Setup.Function Codes.view',
        },
    },
    FORMULATION: {
        REGULATIONS: {
            CREATE: 'Formulation.Setup.Regulatory.Regulations.create',
            EDIT: 'Formulation.Setup.Regulatory.Regulations.edit',
            DELETE: 'Formulation.Setup.Regulatory.Regulations.delete',
            VIEW: 'Formulation.Setup.Regulatory.Regulations.view',
        },
        FORMULA_PROPERTIES: {
            CREATE: 'Formulation.Setup.Regulatory.Formula Properties.create',
            EDIT: 'Formulation.Setup.Regulatory.Formula Properties.edit',
            DELETE: 'Formulation.Setup.Regulatory.Formula Properties.delete',
            VIEW: 'Formulation.Setup.Regulatory.Formula Properties.view',
        },
        EXPOSURE_AGENCIES: {
            CREATE: 'Formulation.Setup.Regulatory.Exposure Agencies.create',
            EDIT: 'Formulation.Setup.Regulatory.Exposure Agencies.edit',
            DELETE: 'Formulation.Setup.Regulatory.Exposure Agencies.delete',
            VIEW: 'Formulation.Setup.Regulatory.Exposure Agencies.view',
        },
        GHS_HAZARD_PHRASE: {
            CREATE: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.create',
            EDIT: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.edit',
            DELETE: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.delete',
            VIEW: 'Formulation.Setup.Regulatory.GHS Hazard Phrases.view',
        },
        GHS_PRECAUTIONARY_PHRASE: {
            CREATE: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.create',
            EDIT: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.edit',
            DELETE: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.delete',
            VIEW: 'Formulation.Setup.Regulatory.GHS Precautionary Phrases.view',
        },
        CAS_MASTER: {
            CREATE: 'Formulation.CAS Master.create',
            EDIT: 'Formulation.CAS Master.edit',
            DELETE: 'Formulation.CAS Master.delete',
            VIEW: 'Formulation.CAS Master.view',
        },
        ITEM_MASTER: {
            CREATE: 'Formulation.Item Master.create',
            EDIT: 'Formulation.Item Master.edit',
            DELETE: 'Formulation.Item Master.delete',
            VIEW: 'Formulation.Item Master.view',
        },
        LOCALE_GROUPS: {
            CREATE: 'Formulation.Setup.Regulatory.Locale Groups.create',
            EDIT: 'Formulation.Setup.Regulatory.Locale Groups.edit',
            DELETE: 'Formulation.Setup.Regulatory.Locale Groups.delete',
            VIEW: 'Formulation.Setup.Regulatory.Locale Groups.view',
        },
        TOXIC_SPECIES: {
            CREATE: 'Formulation.Setup.Regulatory.Toxic Species.create',
            EDIT: 'Formulation.Setup.Regulatory.Toxic Species.edit',
            DELETE: 'Formulation.Setup.Regulatory.Toxic Species.delete',
            VIEW: 'Formulation.Setup.Regulatory.Toxic Species.view',
        },
        TOXIC_TEST: {
            CREATE: 'Formulation.Setup.Regulatory.Toxic Test.create',
            EDIT: 'Formulation.Setup.Regulatory.Toxic Test.edit',
            DELETE: 'Formulation.Setup.Regulatory.Toxic Test.delete',
            VIEW: 'Formulation.Setup.Regulatory.Toxic Test.view',
        },
        SAFETY_DATA_SHEET_SECTION_HEADER: {
            CREATE: 'Formulation.Setup.Regulatory.SDS Section Header.create',
            EDIT: 'Formulation.Setup.Regulatory.SDS Section Header.edit',
            DELETE: 'Formulation.Setup.Regulatory.SDS Section Header.delete',
            VIEW: 'Formulation.Setup.Regulatory.SDS Section Header.view',
        },
        LOCALE: {
            EDIT: 'Formulation.Setup.Regulatory.Locales.edit',
            VIEW: 'Formulation.Setup.Regulatory.Locales.view',
        },
        SDS_HEADER: {
            CREATE: 'Formulation.Safety Data Sheet.create',
            EDIT: 'Formulation.Safety Data Sheet.edit',
            DELETE: 'Formulation.Safety Data Sheet.delete',
            VIEW: 'Formulation.Safety Data Sheet.view',
        },
        RD_FORMULA: {
            CREATE: 'Formulation.RD Formula.create',
            EDIT: 'Formulation.RD Formula.edit',
            DELETE: 'Formulation.RD Formula.delete',
            VIEW: 'Formulation.RD Formula.view',
        },
    },
    SETUP: {
        UNITS_OF_MEASURE: {
            CREATE: 'Setup.General.Units of Measure.create',
            EDIT: 'Setup.General.Units of Measure.edit',
            DELETE: 'Setup.General.Units of Measure.delete',
            VIEW: 'Setup.General.Units of Measure.view',
        },
        UNIT_OF_MEASURE_CONVERSION: {
            CREATE: 'Setup.General.Unit Of Measure Conversion.create',
            EDIT: 'Setup.General.Unit Of Measure Conversion.edit',
            DELETE: 'Setup.General.Unit Of Measure Conversion.delete',
            VIEW: 'Setup.General.Unit Of Measure Conversion.view',
        },
        PROPER_SHIPPING_NAME: {
            CREATE: 'Setup.General.Proper Shipping Name.create',
            EDIT: 'Setup.General.Proper Shipping Name.edit',
            DELETE: 'Setup.General.Proper Shipping Name.delete',
            VIEW: 'Setup.General.Proper Shipping Name.view',
        },
        BOILER_PLATE_NOTE: {
            CREATE: 'Setup.General.Boiler Plate Notes.create',
            EDIT: 'Setup.General.Boiler Plate Notes.edit',
            DELETE: 'Setup.General.Boiler Plate Notes.delete',
            VIEW: 'Setup.General.Boiler Plate Notes.view',
        },
        ATTRIBUTES: {
            MANAGE: 'Setup.General.Attributes.manage',
            VIEW: 'Setup.General.Attributes.view',
        },
    },
};
