import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import {
    AccountStatus,
    AccountType,
    BalanceType,
    Building,
    Contract,
    ContractType,
    GL_Account,
    GL_AccountFunction,
    GL_AccountFunctionCode,
    RecoveryUnit,
} from '../../types/Accounting.types';
import { datacorAppApi } from '../datacorAppApi';

export const accountingAPI = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        //region General Ledger Accounts
        createGeneralLedgerAccount: build.mutation<
            any,
            BaseCreateEntityArgs<GL_Account>
        >({
            query: (contractType) => ({
                url: 'accounting/account',
                method: 'POST',
                body: contractType.postBody,
            }),
            invalidatesTags: [
                'AllGeneralLedgerAccounts',
                'AllFixedAssetAccountFunctions',
            ],
        }),

        updateGeneralLedgerAccount: build.mutation<
            any,
            BaseUpdateEntityArgs<GL_Account>
        >({
            query: (args) => ({
                url: `accounting/account/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllGeneralLedgerAccounts',
                'ActiveGeneralLedgerAccount',
                'AllFixedAssetAccountFunctions',
                'FunctionCodesByGroup',
            ],
        }),

        getGeneralLedgerAccount: build.query<any, string>({
            query: (id) => `accounting/account/${id}?full=true`,
            providesTags: ['ActiveGeneralLedgerAccount'],
        }),

        getAllGeneralLedgerAccounts: build.query<any, void>({
            query: () => `accounting/account/getAll`,
            providesTags: ['AllGeneralLedgerAccounts'],
        }),

        deleteGeneralLedgerAccount: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/account/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllGeneralLedgerAccounts'],
        }),
        //endregion

        //region Account Functions
        getAllGeneralLedgerAccountFunctionsForDropdowns: build.query<any, void>(
            {
                query: () => `accounting/accountFunction/getAll`,
                transformResponse: (response: GL_AccountFunction[]) => {
                    return {
                        depreciationExpenseAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 1
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        accumulateDepreciationAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 2
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        assetAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 3
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        assetControlAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 4
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        disposalGainAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 5
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        disposalLossAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 6
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                        disposalSettlementAccounts: response
                            .filter(
                                (af: GL_AccountFunction) =>
                                    af.account &&
                                    Number(af.functionCodeId) === 7
                            )
                            .map((af: GL_AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: `${af.account.number} - ${af.account.name}`,
                                };
                            }),
                    };
                },
                providesTags: ['AllFixedAssetAccountFunctions'],
            }
        ),
        //endregion

        //region Contracts
        createContract: build.mutation<any, BaseCreateEntityArgs<Contract>>({
            query: (args) => ({
                url: 'accounting/contract',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContracts'],
        }),

        updateContract: build.mutation<any, BaseUpdateEntityArgs<Contract>>({
            query: (args) => ({
                url: `accounting/contract/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContracts', 'ActiveContract'],
        }),

        getContract: build.query<any, string>({
            query: (id) => `accounting/contract/${id}`,
            providesTags: ['ActiveContract'],
        }),

        getAllContracts: build.query<any, void>({
            query: () => `accounting/contract/getAll`,
            providesTags: ['AllContracts'],
        }),

        deleteContract: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/contract/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllContracts'],
        }),
        //endregion

        //region Contract Types
        createContractType: build.mutation<
            any,
            BaseCreateEntityArgs<ContractType>
        >({
            query: (args) => ({
                url: 'accounting/contractType',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContractTypes'],
        }),

        updateContractType: build.mutation<
            any,
            BaseUpdateEntityArgs<ContractType>
        >({
            query: (args) => ({
                url: `accounting/contractType/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContractTypes', 'ActiveContractType'],
        }),

        getContractType: build.query<any, string>({
            query: (id) => `accounting/contractType/${id}`,
            providesTags: ['ActiveContractType'],
        }),

        getAllContractTypes: build.query<any, void>({
            query: () => `accounting/contractType/getAll`,
            providesTags: ['AllContractTypes'],
        }),

        getContractTypesForDropdown: build.query<any, void>({
            query: () => `accounting/contractType/getAll`,
            transformResponse: (response: ContractType[]) => {
                return response.map((cType: ContractType) => {
                    return { value: cType.id, label: cType.code };
                });
            },
            providesTags: ['AllContractTypes'],
        }),

        deleteContractType: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/contractType/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllContractTypes'],
        }),

        updateListContractTypes: build.mutation<boolean, ContractType[]>({
            query: (args) => ({
                url: `accounting/contractType/updateList`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['AllContractTypes', 'ActiveContractType'],
        }),
        //endregion

        //region Function Codes
        getFunctionCode: build.query<any, { id: string; group: string }>({
            query: (args) =>
                `accounting/functionCode/${args.id}?includeAvailableAccounts=true?functionGroup=${args.group}`,
            providesTags: ['ActiveFunctionCode'],
        }),

        updateFunctionCode: build.mutation<
            any,
            {
                data: BaseUpdateEntityArgs<GL_AccountFunctionCode>;
                group: string;
            }
        >({
            query: (args) => ({
                url: `accounting/functionCode/${args.data.id}?functionGroup=${args.group}`,
                method: 'PATCH',
                body: args.data.postBody,
            }),
            invalidatesTags: [
                'FunctionCodes',
                'FunctionCodesByGroup',
                'ActiveFunctionCode',
                'ActiveGeneralLedgerAccount',
                'AllFixedAssetAccountFunctions',
            ],
        }),

        listFunctionCode: build.query<any, void>({
            query: () => `accounting/functionCode`,
            providesTags: ['FunctionCodes'],
        }),

        listFunctionCodeByGroup: build.query<any, string>({
            query: (functionGroup) =>
                `accounting/functionCode?functionGroup=${functionGroup}`,
            providesTags: ['FunctionCodesByGroup'],
        }),
        //endregion

        //region Account Statuses
        getAccountStatusesForDropdown: build.query<any, void>({
            query: () => `accounting/accountStatus`,
            transformResponse: (response: AccountStatus[]) => {
                return response.map((accountStatus: AccountStatus) => {
                    return {
                        value: accountStatus.id,
                        label: accountStatus.status,
                    };
                });
            },
            providesTags: ['AllAccountStatuses'],
        }),
        //endregion

        //region Account Types
        getAccountTypesForDropdown: build.query<any, void>({
            query: () => `accounting/accountType`,
            transformResponse: (response: AccountType[]) => {
                return response.map((accountType: AccountType) => {
                    return { value: accountType.id, label: accountType.type };
                });
            },
            providesTags: ['AllAccountTypes'],
        }),
        //endregion

        //region Balance Types
        getBalanceTypesForDropdown: build.query<any, void>({
            query: () => `accounting/balanceType/getAll`,
            transformResponse: (response: BalanceType[]) => {
                return response.map((bType: BalanceType) => {
                    return { value: bType.id, label: bType.type };
                });
            },
            providesTags: ['AllBalanceTypes'],
        }),
        //endregion

        //region Recovery Units
        getRecoveryUnitsForDropdown: build.query<any, void>({
            query: () => `accounting/recoveryUnit/getAll`,
            transformResponse: (response: RecoveryUnit[]) => {
                return response.map((rUnit: RecoveryUnit) => {
                    return { value: rUnit.id, label: rUnit.unit };
                });
            },
            providesTags: ['AllRecoveryUnits'],
        }),
        //endregion

        //region Buildings
        getBuildingsForDropdown: build.query<any, void>({
            query: () => `accounting/building`,
            transformResponse: (response: Building[]) => {
                return response.map((building: Building) => {
                    return { value: building.id, label: building.code };
                });
            },
            providesTags: ['AllBuildings'],
        }),
        //endregion
    }),
});

export const {
    useGetGeneralLedgerAccountQuery,
    useGetAllGeneralLedgerAccountsQuery,
    useCreateGeneralLedgerAccountMutation,
    useUpdateGeneralLedgerAccountMutation,
    useDeleteGeneralLedgerAccountMutation,
    useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery,
    useGetContractQuery,
    useGetAllContractsQuery,
    useCreateContractMutation,
    useUpdateContractMutation,
    useDeleteContractMutation,
    useGetContractTypeQuery,
    useGetAllContractTypesQuery,
    useGetContractTypesForDropdownQuery,
    useCreateContractTypeMutation,
    useUpdateContractTypeMutation,
    useDeleteContractTypeMutation,
    useUpdateListContractTypesMutation,
    useGetFunctionCodeQuery,
    useListFunctionCodeQuery,
    useUpdateFunctionCodeMutation,
    useListFunctionCodeByGroupQuery,
    useGetBalanceTypesForDropdownQuery,
    useGetRecoveryUnitsForDropdownQuery,
    useGetBuildingsForDropdownQuery,
    useGetAccountStatusesForDropdownQuery,
    useGetAccountTypesForDropdownQuery,
} = accountingAPI;
