import { SdsHeader } from '../../../../../../types/formulation';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import TextInputPopup from '../../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';
import Autocomplete from '@mui/material/Autocomplete';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import DateInput from '../../../../../../components/form/formInputs/DateInput/DateInput';
import { FormMethods } from '../../../../../../libs/hooksLib';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

export interface SdsHeaderFormProps {
    sdsId: string;
    currentSdsRecord: SdsHeader;
    formMethods: FormMethods;
    handleFieldChange: (event: any) => void;
    canUpdateSdsHeader: boolean;
    canViewItemMaster: boolean;
    canViewLocaleGroup: boolean;
    isLoadingSdsRecord: boolean;
    isLoadingSdsHeaderStatusOptions: boolean;
    isLoadingLanguagesOptions: boolean;
    setDropdownOptions: any;
    getDefaultLanguage: any;
    dropdownOptions: any;
    localeGroupOptions: any;
    languagesOptions: any;
    itemMasterOptions: any;
    localeGroupTextPopupColDefs: any;
    itemCodeTextPopupColDefs: any;
    sdsHeaderStatusOptions: any;
    termSet?: any;
}

const SdsHeaderForm = (sdsHeaderFormProps: SdsHeaderFormProps) => {
    const {
        sdsId,
        currentSdsRecord,
        formMethods,
        handleFieldChange,
        canUpdateSdsHeader,
        canViewItemMaster,
        canViewLocaleGroup,
        isLoadingSdsRecord,
        isLoadingSdsHeaderStatusOptions,
        isLoadingLanguagesOptions,
        setDropdownOptions,
        getDefaultLanguage,
        dropdownOptions,
        localeGroupOptions,
        languagesOptions,
        itemMasterOptions,
        localeGroupTextPopupColDefs,
        itemCodeTextPopupColDefs,
        sdsHeaderStatusOptions,
        termSet,
    } = sdsHeaderFormProps;

    return (
        <>
            {isLoadingSdsRecord ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextInput
                                    fullWidth
                                    id="sdsCode"
                                    value={currentSdsRecord.sdsCode}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SDSFormDefs.SDS_Code}
                                        />
                                    }
                                    maxLength={50}
                                    formMethods={formMethods}
                                    onChange={handleFieldChange}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    fullWidth
                                    id="sdsDescription"
                                    value={currentSdsRecord.sdsDescription}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SDSFormDefs.SDS_Description
                                            }
                                        />
                                    }
                                    maxLength={30}
                                    formMethods={formMethods}
                                    required={true}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInputPopup
                                    fullWidth
                                    id="itemCodeId"
                                    onChange={handleFieldChange}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SDSFormDefs.Item_Code}
                                        />
                                    }
                                    formMethods={formMethods}
                                    popupOptions={
                                        canViewItemMaster
                                            ? itemMasterOptions || []
                                            : []
                                    }
                                    colDefs={itemCodeTextPopupColDefs}
                                    value={currentSdsRecord.itemCodeId}
                                    valueFormatter={{
                                        optionLabel: 'label',
                                        value: 'value',
                                    }}
                                    disabled={!isNilOrEmpty(sdsId)}
                                    optionLabel={
                                        dropdownOptions?.selectedItemCode?.label
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SDSFormDefs.Template}
                                        />
                                    }
                                    control={
                                        <Checkbox
                                            id="template"
                                            checked={currentSdsRecord.template}
                                            onChange={handleFieldChange}
                                            required={false}
                                            disabled={!isNilOrEmpty(sdsId)}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    className="basic-select"
                                    id="status"
                                    options={sdsHeaderStatusOptions || []}
                                    getOptionLabel={(option: any) =>
                                        `${option.label}`
                                    }
                                    onChange={(event: any, value: any) => {
                                        handleFieldChange({
                                            target: {
                                                id: 'status',
                                                value: value?.value || null,
                                            },
                                        });
                                    }}
                                    onInputChange={(event, newInput) => {
                                        setDropdownOptions(
                                            (previousValues: any) => ({
                                                ...previousValues,
                                                sdsHeaderStatusValue: newInput,
                                            })
                                        );
                                    }}
                                    inputValue={
                                        dropdownOptions?.sdsHeaderStatusValue
                                    }
                                    loading={isLoadingSdsHeaderStatusOptions}
                                    value={
                                        dropdownOptions?.selectedSdsHeaderStatus ||
                                        null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={SDSFormDefs.Status}
                                                />
                                            }
                                            required={true}
                                            error={
                                                formMethods?.isFormSubmitted &&
                                                isNilOrEmpty(
                                                    params.inputProps.value
                                                )
                                            }
                                            helperText={
                                                formMethods?.isFormSubmitted &&
                                                isNilOrEmpty(
                                                    params.inputProps.value
                                                )
                                                    ? 'Status is required'
                                                    : null
                                            }
                                        />
                                    )}
                                    disabled={sdsId && !canUpdateSdsHeader}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <NumericInput
                                    fullWidth
                                    id="revisionNumber"
                                    value={currentSdsRecord.revisionNumber}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={
                                                SDSFormDefs.Revision_Number
                                            }
                                        />
                                    }
                                    formMethods={formMethods}
                                    required={true}
                                    greaterThan={1}
                                    maxValue={999}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DateInput
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SDSFormDefs.Revision_Date}
                                        />
                                    }
                                    id="revisionDate"
                                    value={currentSdsRecord.revisionDate}
                                    readOnly={false}
                                    onChange={handleFieldChange}
                                    formMethods={formMethods}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    className="basic-select"
                                    id="langId"
                                    options={languagesOptions || []}
                                    getOptionLabel={(option: any) =>
                                        `${option.label}`
                                    }
                                    onChange={(event: any, value: any) => {
                                        handleFieldChange({
                                            target: {
                                                id: 'langId',
                                                value:
                                                    value?.value ||
                                                    getDefaultLanguage().value,
                                            },
                                        });
                                    }}
                                    onInputChange={(event, newInput) => {
                                        setDropdownOptions(
                                            (previousValues: any) => ({
                                                ...previousValues,
                                                languageValue: newInput,
                                            })
                                        );
                                    }}
                                    inputValue={dropdownOptions?.languageValue}
                                    loading={isLoadingLanguagesOptions}
                                    value={
                                        dropdownOptions?.selectedLanguage ||
                                        getDefaultLanguage()
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        SDSFormDefs.Language
                                                    }
                                                />
                                            }
                                            required={true}
                                            error={
                                                formMethods?.isFormSubmitted &&
                                                isNilOrEmpty(
                                                    params.inputProps.value
                                                )
                                            }
                                            helperText={
                                                formMethods?.isFormSubmitted &&
                                                isNilOrEmpty(
                                                    params.inputProps.value
                                                )
                                                    ? 'Language is required'
                                                    : null
                                            }
                                        />
                                    )}
                                    disabled={!isNilOrEmpty(sdsId)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInputPopup
                                    fullWidth
                                    id="localeGroupId"
                                    onChange={handleFieldChange}
                                    label={
                                        <TranslatableText
                                            termSet={termSet}
                                            termKey={SDSFormDefs.Locale_Group}
                                        />
                                    }
                                    formMethods={formMethods}
                                    popupOptions={
                                        canViewLocaleGroup
                                            ? localeGroupOptions || []
                                            : []
                                    }
                                    colDefs={localeGroupTextPopupColDefs}
                                    value={currentSdsRecord.localeGroupId}
                                    valueFormatter={{
                                        optionLabel: 'label',
                                        value: 'value',
                                    }}
                                    disabled={!isNilOrEmpty(sdsId)}
                                    required={true}
                                    optionLabel={
                                        dropdownOptions?.selectedLocaleGroup
                                            ?.label
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsHeaderForm;
