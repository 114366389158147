import React from 'react';
import useRDFormulaGrid from './hooks/useRDFormulaGrid';
import BaseFormGrid from '../../../../components/grids/BaseFormGrid';

const RDFormulaGrid = () => {
    const {
        isLoadingFormulaList,
        columnDefs,
        redirectToForm,
        onGridReady,
        defaultColumnDefinitionOptions,
        formulaList,
        isLoadingItemMasterOptions,
        isLoadingUserOptions,
        canCreateFormula,
        canViewFormula,
    } = useRDFormulaGrid();

    return (
        <BaseFormGrid
            autoSize={true}
            displayGrid={canViewFormula}
            isLoading={
                isLoadingFormulaList ||
                isLoadingItemMasterOptions ||
                isLoadingUserOptions
            }
            rowData={formulaList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateFormula}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onGridReady={onGridReady}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default RDFormulaGrid;
