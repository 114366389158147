import React from 'react';
import { UseFixedAssetsStatusGrid } from './hooks/useFixedAssetsStatusGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { cloneDeep } from 'lodash';

const FixedAssetStatusGrid = () => {
    const {
        statusList,
        isLoading,
        canCreateAssetStatus,
        canViewAssetStatus,
        redirectToForm,
        handleGridEdits,
    } = UseFixedAssetsStatusGrid();

    const statusColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'isInService',
            headerName: 'In Service',
            ...DefaultColumnTypes.Boolean,
        },
        {
            field: 'isDisposal',
            headerName: 'Disposal',
            ...DefaultColumnTypes.Boolean,
        },
    ];

    return (
        <BaseFormGrid
            title="Asset Statuses"
            displayGrid={canViewAssetStatus}
            isLoading={isLoading}
            rowData={cloneDeep(statusList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={statusColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateAssetStatus}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            displayExportDataButton={true}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetStatusGrid;
