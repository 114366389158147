import React from 'react';
import useFixedAssetsCostCodeGrid from './hooks/useFixedAssetsCostCodeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { cloneDeep } from 'lodash';

const FixedAssetCostCodeGrid = () => {
    const {
        costCodeList,
        isLoadingCostCodes,
        canCreateAssetCostCode,
        canViewAssetCostCode,
        redirectToForm,
        handleGridEdits,
    } = useFixedAssetsCostCodeGrid();

    const costCodeColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'isAdd',
            headerName: 'Is Add',
            ...DefaultColumnTypes.Boolean,
        },
    ];

    return (
        <BaseFormGrid
            title="Asset Cost Codes"
            displayGrid={canViewAssetCostCode}
            isLoading={isLoadingCostCodes}
            rowData={cloneDeep(costCodeList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={costCodeColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateAssetCostCode}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetCostCodeGrid;
