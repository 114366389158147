import { ColDef, ValueGetterParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { GridOptions } from '../../../components/grids/Grid.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import React from 'react';
import useSdsGrid from './hooks/useSdsGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { SafetyDataSheetGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const SdsGrid = () => {
    const {
        sdsHeaderList,
        isLoadingSdsHeaderList,
        canCreateSafetyDataSheet,
        canViewSafetyDataSheet,
        redirectToForm,
        getLanguageDescriptionById,
        getStatusDescriptionById,
        getItemCodeById,
        termSet,
    } = useSdsGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'sdsCode',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.SDS_Code}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'sdsDescription',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.SDS_Description}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'itemCodeId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Item_Code}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) =>
                getItemCodeById(params.data.itemCodeId),
            filterParams: {
                valueGetter: (params: ValueGetterParams) =>
                    getItemCodeById(params.data.itemCodeId),
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'status',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Status}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) =>
                getStatusDescriptionById(params.data.status),
            filterParams: {
                valueGetter: (params: ValueGetterParams) =>
                    getStatusDescriptionById(params.data.status),
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'revisionNumber',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Revision_Number}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'revisionDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Revision_Date}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'langId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Language}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) =>
                getLanguageDescriptionById(params.data.langId),
            filterParams: {
                valueGetter: (params: ValueGetterParams) =>
                    getLanguageDescriptionById(params.data.langId),
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'localeGroup.groupId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={SafetyDataSheetGridDefs.Locale_Group}
                    />
                );
            },
            filter: 'agTextColumnFilter',
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            autoSize={true}
            displayGrid={canViewSafetyDataSheet}
            isLoading={isLoadingSdsHeaderList}
            rowData={sdsHeaderList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            dataKey={'id'}
            displayToolbar={true}
            displayCreateNewButton={canCreateSafetyDataSheet}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={colDefOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};
export default SdsGrid;
