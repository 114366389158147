import {
    useCreateSdsBlobMutation,
    useGetSdsBlobQuery,
    useUpdateSdsBlobMutation,
} from '../../../../../../../../services/formulation/sds/blob/sdsBlob.service';
import { EntityBase } from '../../../../../../../../types/Shared.types';
import useBaseForm from '../../../../../../../form/hooks/useBaseForm';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import { useEffect } from 'react';
import { SdsBlobSubSection } from '../../../../../../../../types/formulation';
import { SdsBlobSubSectionFormProps } from './SdsBlobSectionFormProps';

/**
 Contains the basic functionality to handle the create/update processes for the SDS Blob Sections.
 **/
const useSdsBlobSectionForm = <
    TParent extends EntityBase,
    TChild extends SdsBlobSubSection
>(
    props: SdsBlobSubSectionFormProps<TParent, TChild>
) => {
    const { currentParentRecord, isParentLoading } = props.parentData;

    const { sectionNumber, subSectionNumber, blankBlobEntity } = props.blobData;

    const { registerBlob } = props.blobMethods;

    const [createSdsBlob] = useCreateSdsBlobMutation();

    const [updateSdsBlob] = useUpdateSdsBlobMutation();

    const { data: blobWithDefinition, isLoading: isBlobLoading } =
        useGetSdsBlobQuery({
            sdsId: currentParentRecord.id,
            sectionNumber: sectionNumber,
            subSectionNumber: subSectionNumber,
        });

    const {
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        setValues,
        hasEntityChanges,
        verifyChanges,
    } = useBaseForm({
        closePath: '/formulation/sds',
        reroute: false,
        onlyUpdateEntityWithChanges: true,
        blankEntity: blankBlobEntity as TChild,
        activeEntity: blobWithDefinition?.sdsBlob?.data as TChild,
        isChildForm: true,
        getDescription: () => {
            return `Safety Data Sheet Blob ${fields.sdsId}`;
        },
        createEntity: (sdsId: bigint) => {
            return createSdsBlob({
                postBody: getPostBody(sdsId),
            });
        },
        updateEntity: () => {
            if (isNilOrEmpty(blobWithDefinition?.sdsBlob)) {
                return createSdsBlob({
                    postBody: getPostBody(),
                });
            }

            return updateSdsBlob({
                id: Number(blobWithDefinition?.sdsBlob.id),
                postBody: getPostBody(),
            });
        },
        deleteEntity: () => {
            return null;
        },
    });

    const getPostBody = (sdsId: bigint = null) => {
        return {
            sdsId: sdsId ? sdsId : currentParentRecord.id,
            data: fields,
            definitionId: blobWithDefinition?.id,
        };
    };

    const { childForm } = verifyChanges;

    const { clearChangesHistory } = childForm;

    useEffect(() => {
        if (
            !isBlobLoading &&
            !isNilOrEmpty(blobWithDefinition?.sdsBlob?.data)
        ) {
            setValues(blobWithDefinition?.sdsBlob?.data);
        }
    }, [blobWithDefinition, isBlobLoading]);

    useEffect(() => {
        if (!isParentLoading && !isBlobLoading) {
            registerBlob(blobWithDefinition?.subSectionName, {
                formMethods,
                hasEntityChanges,
                onCreate,
                onUpdate,
                clearChangesHistory,
            });
        }
    }, [fields, isParentLoading, isBlobLoading]);

    return {
        fields: fields as TChild,
        isBlobLoading,
        formMethods,
        handleFieldChange,
    };
};

export default useSdsBlobSectionForm;
