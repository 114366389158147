import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useSubscribersGrid from './hooks/useSubscribersGrid';
import { SubscriberGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';

const SubscribersGrid = () => {
    const {
        user,
        onGridReady,
        subscribers,
        isLoading,
        isLoadingUser,
        redirectToForm,
        viewEntitiesColumn,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    } = useSubscribersGrid();

    return (
        <>
            {!isLoading && !isLoadingUser && termSet ? (
                <AgGrid
                    testId="company-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={subscribers}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={user.isDatacorAdmin}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onGridReady={onGridReady}
                    frameworkComponents={{
                        viewEntities: viewEntitiesColumn,
                    }}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        floatingFilter: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        field="action"
                        filter={false}
                        sortable={false}
                        resizable={false}
                        headerName=""
                        width={70}
                        cellRenderer="viewEntities"
                    />

                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        field="name"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={SubscriberGridDefs.Subscriber_Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="description"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        SubscriberGridDefs.Subscriber_Description
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="createdAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        SubscriberGridDefs.Subscriber_Date_Created
                                    }
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        SubscriberGridDefs.Subscriber_Last_Update_Date
                                    }
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default SubscribersGrid;
