import React from 'react';
import SequenceBase from '../../../../components/sequences/SequenceBase';
import {
    useGetSdsHeaderSequenceParametersQuery,
    useGetSdsHeaderSequenceQuery,
    useListSdsHeaderSequenceParentsQuery,
    useUpdateSdsHeaderSequenceMutation,
} from '../../../../services/formulation/sds/header/sdsHeader.service';

const SdsSequences = () => {
    return (
        <SequenceBase
            sequenceQuery={useGetSdsHeaderSequenceQuery}
            sequenceMutation={useUpdateSdsHeaderSequenceMutation}
            parameterQuery={useGetSdsHeaderSequenceParametersQuery}
            useParent={true}
            parentQuery={useListSdsHeaderSequenceParentsQuery}
            title={'SDS'}
            closePath={`/formulation/sds`}
        />
    );
};

export default SdsSequences;
