import { useNavigate } from 'react-router-dom';
import {
    useGetAllStatusQuery,
    useUpdateListStatusesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetStatus } from '../../../types/FixedAsset.types';
import { useEffect } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

export const UseFixedAssetsStatusGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: statusList, isLoading } = useGetAllStatusQuery();
    const [updateAssetStatuses] = useUpdateListStatusesMutation();

    const canCreateAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.CREATE
    );
    const canViewAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW
    );

    const handleGridEdits = (editedRows: FA_AssetStatus[]) => {
        updateAssetStatuses(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/status/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/status/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (statusList && statusList.length > 0) {
            const data = _.maxBy(
                statusList,
                (assetStatus: FA_AssetStatus) => assetStatus.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, statusList);

    return {
        statusList,
        isLoading,
        canCreateAssetStatus,
        canViewAssetStatus,
        redirectToForm,
        handleGridEdits,
    };
};
