import { RouteProps } from './routesConfig';
import UnitOfMeasure from '../containers/setup/UnitOfMeasure';
import UnitOfMeasureForm from '../containers/setup/pages/UnitOfMeasureForm';
import UnitOfMeasureConversion from '../containers/setup/UnitOfMeasureConversion';
import UnitOfMeasureConversionForm from '../containers/setup/pages/UnitOfMeasureConversionForm';
import ProperShippingName from '../containers/setup/ProperShippingName';
import ProperShippingNameForm from '../containers/setup/pages/ProperShippingNameForm';
import BoilerPlateNote from '../containers/setup/BoilerPlateNote';
import BoilerPlateNoteForm from '../containers/setup/pages/BoilerPlateNoteForm';
import AttributesView from '../containers/setup/pages/AttributesView';
import AttributeFormHandler from '../containers/setup/AttributeFormHandler';

const SetupRoutes: RouteProps[] = [
    {
        path: '/setup/unitOfMeasure',
        component: UnitOfMeasure,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasure/new',
        component: UnitOfMeasureForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasure/:id',
        component: UnitOfMeasureForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion',
        component: UnitOfMeasureConversion,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion/new',
        component: UnitOfMeasureConversionForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/unitOfMeasureConversion/:id',
        component: UnitOfMeasureConversionForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName',
        component: ProperShippingName,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName/new',
        component: ProperShippingNameForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/properShippingName/:id',
        component: ProperShippingNameForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/boilerPlateNote',
        component: BoilerPlateNote,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/boilerPlateNote/new',
        component: BoilerPlateNoteForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/boilerPlateNote/:id',
        component: BoilerPlateNoteForm,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    //TODO: add all routes here
    {
        path: '/setup/attributes',
        component: AttributesView,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab',
        component: AttributesView,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab/new',
        component: AttributeFormHandler,
        featureFlag: 'dashboard.nav.apps.setup',
    },
    {
        path: '/setup/attributes/:tab/:id',
        component: AttributeFormHandler,
        featureFlag: 'dashboard.nav.apps.setup',
    },
];

export default SetupRoutes;
