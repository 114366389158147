import { useNavigate } from 'react-router-dom';
import {
    useGetAllCostCodesQuery,
    useUpdateListCostCodesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetCostCode } from '../../../types/FixedAsset.types';
import { useEffect } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

const UseFixedAssetsCostCodeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();
    const [updateCostCodes] = useUpdateListCostCodesMutation();

    const canCreateAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.CREATE
    );
    const canViewAssetCostCode = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW
    );

    const handleGridEdits = (editedRows: FA_AssetCostCode[]) => {
        updateCostCodes(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/costCode/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/costCode/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (costCodeList && costCodeList.length > 0) {
            const data = _.maxBy(
                costCodeList,
                (costCode: FA_AssetCostCode) => costCode.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, costCodeList);

    return {
        costCodeList,
        isLoadingCostCodes,
        canCreateAssetCostCode,
        canViewAssetCostCode,
        redirectToForm,
        handleGridEdits,
    };
};

export default UseFixedAssetsCostCodeGrid;
