import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FA_Asset } from '../../../../types/FixedAsset.types';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';

const useDisposalsGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const canViewDisposalsReport = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.REPORTS.DISPOSALS.VIEW
    );

    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [assetList, setAssetList] = useState<FA_Asset[]>([]);
    const [disposalRows, setDisposalRows] = useState<any[]>([]);

    const _createRowsFromList = (list: FA_Asset[]): any[] => {
        const rows: any[] = [];

        list.map((asset: FA_Asset) => {
            let totalDepreciation = 0;
            let assetValue = 0;
            let settlement = 0;
            let disposalGainLoss = 0;

            asset.disposalEntries.map((entry) => {
                if (
                    entry.accountId ===
                    asset.ledgerAssetType.accumulatedDeprecationAccountId
                ) {
                    totalDepreciation = entry.debit;
                } else if (
                    entry.accountId === asset.ledgerAssetType.assetAccountId
                ) {
                    assetValue = entry.credit;
                } else if (
                    entry.accountId ===
                    asset.ledgerAssetType.disposalSettlementAccountId
                ) {
                    settlement = entry.debit;
                } else if (
                    entry.accountId ===
                    asset.ledgerAssetType.disposalLossAccountId
                ) {
                    disposalGainLoss = -entry.debit;
                } else if (
                    entry.accountId ===
                    asset.ledgerAssetType.disposalGainAccountId
                ) {
                    disposalGainLoss = entry.credit;
                }
            });

            rows.push({
                ...asset,
                totalDepreciation: totalDepreciation,
                remainingValue: assetValue - totalDepreciation,
                settlement: settlement,
                disposalGainLoss,
            });
        });

        return rows;
    };

    const disposalsSummaryRow = useMemo(() => {
        let row: any = {
            acquireDate: null,
            inServiceDate: null,
            disposalDate: null,
        };

        if (disposalRows?.length > 0) {
            let sumTotalCost = 0;
            let sumTotalDepreciation = 0;
            let sumRemainingValue = 0;
            let sumSettlement = 0;
            let sumDisposalGainLoss = 0;
            disposalRows.map((disposalRow) => {
                sumTotalCost += Number(disposalRow.totalCost);
                sumTotalDepreciation += Number(disposalRow.totalDepreciation);
                sumRemainingValue += Number(disposalRow.remainingValue);
                sumSettlement += Number(disposalRow.settlement);
                sumDisposalGainLoss += Number(disposalRow.disposalGainLoss);
            });
            return [
                {
                    ...row,
                    totalCost: sumTotalCost,
                    totalDepreciation: sumTotalDepreciation,
                    remainingValue: sumRemainingValue,
                    settlement: sumSettlement,
                    disposalGainLoss: sumDisposalGainLoss,
                },
            ];
        }
        return [row];
    }, [disposalRows]);

    useEffect(() => {
        if (assetList) {
            setDisposalRows(_createRowsFromList(assetList));
        }
    }, [assetList]);

    return {
        disposalRows,
        setAssetList,
        setShowOptionsModal,
        showOptionsModal,
        disposalsSummaryRow,
        canViewDisposalsReport,
    };
};

export default useDisposalsGrid;
