import React from 'react';
import useAccountFunctionCodeGrid from './hooks/useAccountFunctionCodeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { useParams } from 'react-router-dom';
import { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { FunctionCodesGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import TranslatableText from '../../components/i18n/TranslatableText';

const AccountFunctionCodeGrid = () => {
    const { group } = useParams<ParamTypes>();
    const {
        functionCodeList,
        isLoading,
        AccountNumberColumn,
        AccountNameColumn,
        getAccountNumberValue,
        getAccountNameValue,
        redirectToForm,
        canViewFunctionCode,
        termSet,
    } = useAccountFunctionCodeGrid(group);
    console.log(termSet);
    const functionCodeColumnDefs: ColDef[] = [
        {
            field: 'code',

            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FunctionCodesGridDefs.Function_Code}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FunctionCodesGridDefs.Number}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
            ...DefaultColumnTypes.AutoHeight,
            cellRenderer: 'accountNumberColumn',
            valueGetter: (params) => {
                return getAccountNumberValue(params.data.functions);
            },
        },
        {
            headerComponentFramework: (props: any) => {
                return (
                    <TranslatableText
                        termSet={termSet}
                        termKey={FunctionCodesGridDefs.Name}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
            cellRenderer: 'accountNameColumn',
            valueGetter: (params) => {
                return getAccountNameValue(params.data.functions);
            },
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewFunctionCode}
            isLoading={isLoading}
            rowData={functionCodeList}
            columnDefs={functionCodeColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={false}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onRowClicked={(row) => redirectToForm(row.data.id)}
            defaultColDef={{
                resizable: true,
                sortable: false,
                filter: true,
                wrapText: true,
                floatingFilter: true,
            }}
            frameworkComponents={{
                accountNumberColumn: AccountNumberColumn,
                accountNameColumn: AccountNameColumn,
            }}
        />
    );
};

export default AccountFunctionCodeGrid;
