import { useNavigate } from 'react-router-dom';
import {
    useGetAllDepreciationMethodStatusesQuery,
    useUpdateListDepreciationMethodStatusesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_DepreciationMethodStatus } from '../../../types/FixedAsset.types';
import { useEffect } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

const UseFixedAssetDepreciationMethodStatusGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data: statusList, isLoading: isLoadingStatuses } =
        useGetAllDepreciationMethodStatusesQuery();

    const [updateDepreciationMethodStatuses] =
        useUpdateListDepreciationMethodStatusesMutation();

    const canCreateDepreciationMethodStatus =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.CREATE
        );
    const canViewDepreciationMethodStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW
    );

    const handleGridEdits = (editedRows: FA_DepreciationMethodStatus[]) => {
        updateDepreciationMethodStatuses(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/depreciationMethodStatus/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/depreciationMethodStatus/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (statusList && statusList.length > 0) {
            const data = _.maxBy(
                statusList,
                (depreciationStatus: FA_DepreciationMethodStatus) =>
                    depreciationStatus.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, statusList);

    return {
        statusList,
        redirectToForm,
        isLoadingStatuses,
        canCreateDepreciationMethodStatus,
        canViewDepreciationMethodStatus,
        handleGridEdits,
    };
};

export default UseFixedAssetDepreciationMethodStatusGrid;
