import React from 'react';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { UseGeneralLedgerAccountGrid } from './hooks/useGeneralLedgerAccountGrid';

const GeneralLedgerAccountGrid = () => {
    const {
        generalLedgerAccountList,
        isLoading,
        redirectToForm,
        canCreateChartOfAccounts,
        canViewChartOfAccounts,
    } = UseGeneralLedgerAccountGrid();

    const accountsColumnDefs: ColDef[] = [
        {
            field: 'number',
            headerName: 'Account Number',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'name',
            headerName: 'Account Name',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'accountStatus.status',
            headerName: 'Account Status',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'balanceType.type',
            headerName: 'Balance Type',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'accountType.type',
            headerName: 'Account Type',
            ...DefaultColumnTypes.ShortText,
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewChartOfAccounts}
            isLoading={isLoading}
            rowData={generalLedgerAccountList}
            columnDefs={accountsColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateChartOfAccounts}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
        />
    );
};

export default GeneralLedgerAccountGrid;
