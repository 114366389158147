import { useEffect, useState } from 'react';
import { SdsHeader } from '../../../../../../../types/formulation';
import {
    useListProperShippingNameDropdownQuery,
    useListProperShippingNameQuery,
} from '../../../../../../../services/organizations/organizations.service';
import {
    HandleFieldChangedEvent,
    useForm,
} from '../../../../../../../libs/hooksLib';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';
import { SdsTransportation } from '../../../../../../../types/formulation/sds/sections/sdsTransportation/SdsTransportation.type';
import { skipToken } from '@reduxjs/toolkit/query';
import { ProperShippingName } from '../../../../../../../types/Setup.types';
import _ from 'lodash';
import { openModalConfirmBasicWithMessage } from '../../../../../../../store/uiElements';
import { useDispatch } from 'react-redux';
import { SelectionOption } from '../../../../../../../types/Shared.types';

const useSdsTransportForm = (props: ChildFormProps<SdsHeader>) => {
    const blankSdsTransportation: SdsTransportation = {
        sdsId: null,
        landProperShippingNameId: null,
        landProperShippingNameDescription: null,
        airProperShippingNameId: null,
        airProperShippingNameDescription: null,
        seaProperShippingNameId: null,
        seaProperShippingNameDescription: null,
    };

    const initialStateCopyDropdowns: any = {
        selectedCopySource: null,
        selectedCopyDestination: null,
        copySourceValue: '',
        copyDestinationValue: '',
    };

    const initialState: any = {
        selectedProperShippingNameLand: null,
        selectedProperShippingNameAir: null,
        selectedProperShippingNameSea: null,
        properShippingNameInputValueLand: '',
        properShippingNameInputValueSea: '',
        properShippingNameInputValueAir: '',
    };

    const initialStateDisplayFields: any = {
        landClass: null,
        landPackaging: null,
        airClass: null,
        airPackaging: null,
        seaClass: null,
        seaPackaging: null,
    };

    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState<string>('0');
    const { currentParentRecord, isParentLoading, user, dataFromQuery } =
        props.parentData;
    const [isFormSet, setIsFormSet] = useState<boolean>(false);
    const transportationOptions = { land: 'Land', air: 'Air', sea: 'Sea' };
    const landOption = { value: 1, label: 'Land' };
    const airOption = { value: 2, label: 'Air' };
    const seaOption = { value: 3, label: 'Sea' };
    const initialCopyFunctionalityOptions = [landOption, airOption, seaOption];
    const [copySourceOptions, setCopySourceOptions] = useState<
        SelectionOption[]
    >(initialCopyFunctionalityOptions);
    const [copyDestinationOptions, setCopyDestinationOptions] = useState<
        SelectionOption[]
    >(initialCopyFunctionalityOptions);
    const { handleChildRecord, registerChildForm, updateCompleteChildRecord } =
        props.helpers;
    const [isLandEnabled, setIsLandEnabled] = useState(false);
    const [isAirEnabled, setIsAirEnabled] = useState(false);
    const [isSeaEnabled, setIsSeaEnabled] = useState(false);
    const [isCopyButtonEnabled, setIsCopyButtonEnabled] = useState(false);
    const [isDestinationFieldEnabled, setIsDestinationFieldEnabled] =
        useState(false);
    const [dropdownOptions, setDropdownOptions] = useState(initialState);
    const [copyDropdownOptions, setCopyDropdownOptions] = useState(
        initialStateCopyDropdowns
    );
    const [displayFields, setDisplayFieldValues] = useState<any>(
        initialStateDisplayFields
    );
    const { fields, handleFieldChange, setValues, verifyChanges, formMethods } =
        useForm(blankSdsTransportation, true);
    const { childForm } = verifyChanges;
    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const {
        data: properShippingNameOptions,
        isLoading: isLoadingProperShippingNameOptions,
    } = useListProperShippingNameDropdownQuery();

    const {
        data: properShippingNameList,
        isLoading: isLoadingProperShippingName,
    } = useListProperShippingNameQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );

    const {
        fields: copyFields,
        setValues: setCopyValues,
        formMethods: copyFormMethods,
    } = useForm({ copySourceId: null, copyDestinationId: null }, true);

    // haven't done anything with this yet. coming soon.
    const headerPermissions = {
        canViewProperShippingName: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.PROPER_SHIPPING_NAME.VIEW
        ),
    };

    const findProperShippingNameRecord = (
        properShippingNameId: bigint
    ): ProperShippingName => {
        return _.find(properShippingNameList, { id: properShippingNameId });
    };

    /**
     * happens once on page load, sets checkbox state
     */
    const setInitialCheckboxValues = (sdsTransportation: SdsTransportation) => {
        if (sdsTransportation?.landProperShippingNameId) {
            setIsLandEnabled(true);
        }
        if (sdsTransportation?.airProperShippingNameId) {
            setIsAirEnabled(true);
        }
        if (sdsTransportation?.seaProperShippingNameId) {
            setIsSeaEnabled(true);
        }
    };

    /**
     * triggers on copy dropdown changes
     */
    const onCopyFieldChange = async (event: HandleFieldChangedEvent) => {
        if (event.target.id === 'copySourceId') {
            setCopyValues({ copySourceId: event.target.value });
        }
        if (event.target.id === 'copyDestinationId') {
            setCopyValues({ copyDestinationId: event.target.value });
        }
    };

    /**
     * updates both properShippingNameId and description when proper shipping name dropdown changes
     */
    const setFieldsOnProperShippingNameChange = async (
        event: HandleFieldChangedEvent
    ) => {
        const properShippingName = findProperShippingNameRecord(
            event.target.value
        );
        if (event.target.id.includes('land')) {
            setValues({
                landProperShippingNameId: event.target.value,
                landProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
            updateCompleteChildRecord('sdsTransportation', {
                ...fields,
                landProperShippingNameId: event.target.value,
                landProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
        }
        if (event.target.id.includes('air')) {
            setValues({
                airProperShippingNameId: event.target.value,
                airProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
            updateCompleteChildRecord('sdsTransportation', {
                ...fields,
                airProperShippingNameId: event.target.value,
                airProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
        }
        if (event.target.id.includes('sea')) {
            setValues({
                seaProperShippingNameId: event.target.value,
                seaProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
            updateCompleteChildRecord('sdsTransportation', {
                ...fields,
                seaProperShippingNameId: event.target.value,
                seaProperShippingNameDescription:
                    properShippingName?.properShippingName || null,
            });
        }
    };

    /**
     * keeps parent form up to date as form values change
     */
    const handlePropertyChanged = async (event: HandleFieldChangedEvent) => {
        handleFieldChange(event);
        handleChildRecord('sdsTransportation', event);
    };

    /**
     * triggers on Copy button click, display modal, copies values from one tab to another, clear copy dropdowns
     */
    const performCopyAction = () => {
        const copySourceSelectedOption = _.find(copySourceOptions, {
            value: copyFields.copySourceId,
        });
        const copyDestinationSelectedOption = _.find(copyDestinationOptions, {
            value: copyFields.copyDestinationId,
        });
        const message = `Are you sure you would like to copy values from ${copySourceSelectedOption.label} to ${copyDestinationSelectedOption.label} This will overwrite any existing Data.`;
        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: '',
                onConfirm: async () => {
                    let copySourceProperShippingNameId: BigInt;
                    let copySourceProperShippingNameDescription: string;
                    let destinationFieldUpdates;

                    if (copySourceSelectedOption.label === 'Land') {
                        copySourceProperShippingNameId =
                            fields.landProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.landProperShippingNameDescription;
                    }
                    if (copySourceSelectedOption.label === 'Air') {
                        copySourceProperShippingNameId =
                            fields.airProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.airProperShippingNameDescription;
                    }
                    if (copySourceSelectedOption.label === 'Sea') {
                        copySourceProperShippingNameId =
                            fields.seaProperShippingNameId;
                        copySourceProperShippingNameDescription =
                            fields.seaProperShippingNameDescription;
                    }

                    if (copyDestinationSelectedOption.label === 'Land') {
                        destinationFieldUpdates = {
                            ...fields,
                            landProperShippingNameId:
                                copySourceProperShippingNameId,
                            landProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    if (copyDestinationSelectedOption.label === 'Air') {
                        destinationFieldUpdates = {
                            ...fields,
                            airProperShippingNameId:
                                copySourceProperShippingNameId,
                            airProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    if (copyDestinationSelectedOption.label === 'Sea') {
                        destinationFieldUpdates = {
                            ...fields,
                            seaProperShippingNameId:
                                copySourceProperShippingNameId,
                            seaProperShippingNameDescription:
                                copySourceProperShippingNameDescription,
                        };
                    }
                    setValues(destinationFieldUpdates);
                    setCopyValues({
                        copySourceId: null,
                        copyDestinationId: null,
                    });
                    updateCompleteChildRecord(
                        'sdsTransportation',
                        destinationFieldUpdates
                    );
                },
            })
        );
    };

    /**
     * on checkbox toggle, display confirm modal and clear data
     */
    const onCheckboxToggle = async (
        currentCheckboxState: boolean,
        currentInput: any
    ) => {
        const message = `All data from fields on ${currentInput} will be deleted are you sure?`;
        if (currentInput === transportationOptions.land) {
            if (currentCheckboxState === false) {
                setIsLandEnabled(true);
            } else {
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: async () => {
                            setValues({
                                landProperShippingNameId: null,
                                landProperShippingNameDescription: null,
                            });
                            updateCompleteChildRecord('sdsTransportation', {
                                ...fields,
                                landProperShippingNameId: null,
                                landProperShippingNameDescription: null,
                            });
                            setIsLandEnabled(false);
                        },
                    })
                );
            }
        }

        if (currentInput === transportationOptions.sea) {
            if (currentCheckboxState === false) {
                setIsSeaEnabled(true);
            } else {
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: () => {
                            setValues({
                                seaProperShippingNameId: null,
                                seaProperShippingNameDescription: null,
                            });
                            updateCompleteChildRecord('sdsTransportation', {
                                ...fields,
                                seaProperShippingNameId: null,
                                seaProperShippingNameDescription: null,
                            });
                            setIsSeaEnabled(false);
                        },
                    })
                );
            }
        }

        if (currentInput === transportationOptions.air) {
            if (currentCheckboxState === false) {
                setIsAirEnabled(true);
            } else {
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: () => {
                            setValues({
                                airProperShippingNameId: null,
                                airProperShippingNameDescription: null,
                            });
                            updateCompleteChildRecord('sdsTransportation', {
                                ...fields,
                                airProperShippingNameId: null,
                                airProperShippingNameDescription: null,
                            });
                            setIsAirEnabled(false);
                        },
                    })
                );
            }
        }
    };

    /**
     * disable/enable Copy button based on if copy dropdowns are populated
     */
    useEffect(() => {
        setIsCopyButtonEnabled(
            copyFields.copySourceId && copyFields.copyDestinationId
        );
    }, [copyFields.copySourceId, copyFields.copyDestinationId]);

    /**
     * sets the copy source field dropdown values based on current checkbox values
     */
    useEffect(() => {
        const copyDropdowns = [];
        if (isLandEnabled) {
            copyDropdowns.push(landOption);
        }
        if (isAirEnabled) {
            copyDropdowns.push(airOption);
        }
        if (isSeaEnabled) {
            copyDropdowns.push(seaOption);
        }

        setCopySourceOptions(copyDropdowns);

        // clears value in Copy Source if option no longer exists
        const copySourceSelectedOption = _.find(copyDropdowns, {
            value: copyFields.copySourceId,
        });
        if (!copySourceSelectedOption) {
            setCopyValues({ copySourceId: null });
        }
    }, [isAirEnabled, isSeaEnabled, isLandEnabled]);

    /**
     * sets display fields (Hazard and Class) for all tabs
     */
    useEffect(() => {
        const destinationDropdowns = [];
        const copySourceSelectedOption = _.find(copySourceOptions, {
            value: copyFields.copySourceId,
        });
        if (
            isLandEnabled &&
            copySourceSelectedOption?.value !== landOption.value
        ) {
            destinationDropdowns.push(landOption);
        }
        if (
            isAirEnabled &&
            copySourceSelectedOption?.value !== airOption.value
        ) {
            destinationDropdowns.push(airOption);
        }
        if (
            isSeaEnabled &&
            copySourceSelectedOption?.value !== seaOption.value
        ) {
            destinationDropdowns.push(seaOption);
        }

        const selectedCopyDestinationOption = _.find(destinationDropdowns, {
            value: copyFields.copyDestinationId,
        });
        if (!selectedCopyDestinationOption || !copySourceSelectedOption) {
            setCopyValues({ copyDestinationId: null });
        }
        setCopyDestinationOptions(destinationDropdowns);
        setIsDestinationFieldEnabled(!!copyFields.copySourceId);
    }, [copyFields.copySourceId, isAirEnabled, isSeaEnabled, isLandEnabled]);

    /**
     * only happens once, sets form up on page load
     */
    useEffect(() => {
        if (!isParentLoading && isFormSet === false && properShippingNameList) {
            registerChildForm('sdsTransportation', {
                formMethods,
                clearChangesHistory,
                hasEntityChanges: hasCurrentRecordChanged,
            });

            setOriginalEntity(
                dataFromQuery?.sdsTransportation || blankSdsTransportation
            );

            setValues(
                dataFromQuery?.sdsTransportation || blankSdsTransportation
            );

            setIsFormSet(true);

            setInitialCheckboxValues(dataFromQuery?.sdsTransportation);
        }
    }, [
        currentParentRecord?.sdsTransportation,
        isParentLoading,
        dataFromQuery,
        properShippingNameList,
    ]);

    /**
     * sets display fields (Hazard and Class) for all tabs
     */
    useEffect(() => {
        if (isFormSet) {
            const valuesToSet: any = {};
            const properShippingNameAir = findProperShippingNameRecord(
                fields.airProperShippingNameId
            );
            const properShippingNameLand = findProperShippingNameRecord(
                fields.landProperShippingNameId
            );
            const properShippingNameSea = findProperShippingNameRecord(
                fields.seaProperShippingNameId
            );
            valuesToSet.airClass = properShippingNameAir?.hazardClass || null;
            valuesToSet.airPackaging =
                properShippingNameAir?.packagingGroup || null;
            valuesToSet.landClass = properShippingNameLand?.hazardClass || null;
            valuesToSet.landPackaging =
                properShippingNameLand?.packagingGroup || null;
            valuesToSet.seaClass = properShippingNameSea?.hazardClass || null;
            valuesToSet.seaPackaging =
                properShippingNameSea?.packagingGroup || null;
            setDisplayFieldValues(valuesToSet);
        }
    }, [
        dropdownOptions?.selectedProperShippingNameLand,
        dropdownOptions?.selectedProperShippingNameSea,
        dropdownOptions?.selectedProperShippingNameAir,
        isFormSet,
    ]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (
            !isParentLoading &&
            !isLoadingProperShippingNameOptions &&
            properShippingNameOptions &&
            !isLoadingProperShippingName &&
            properShippingNameList
        ) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedProperShippingNameLand: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.landProperShippingNameId
                ),
                selectedProperShippingNameSea: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.seaProperShippingNameId
                ),
                selectedProperShippingNameAir: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
                selectedCopySource: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
                selectedCopyDestination: properShippingNameOptions?.find(
                    (current: any) =>
                        current.value === fields.airProperShippingNameId
                ),
            }));
            setCopyDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedCopySource: copySourceOptions?.find(
                    (current: any) => current.value === copyFields.copySourceId
                ),
                selectedCopyDestination: copySourceOptions?.find(
                    (current: any) =>
                        current.value === copyFields.copyDestinationId
                ),
            }));
        }
    }, [
        isParentLoading,
        fields,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        properShippingNameList,
        isLoadingProperShippingName,
        copyFields,
    ]);

    return {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        currentTab,
        setCurrentTab,
        setDropdownOptions,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        setFieldsOnProperShippingNameChange,
        onCopyFieldChange,
        isAirEnabled,
        isLandEnabled,
        isSeaEnabled,
        isCopyButtonEnabled,
        isDestinationFieldEnabled,
        onCheckboxToggle,
        transportationOptions,
        displayFields,
        copySourceOptions,
        copyDestinationOptions,
        setCopyDropdownOptions,
        copyDropdownOptions,
        copyFormMethods,
        performCopyAction,
    };
};
export default useSdsTransportForm;
