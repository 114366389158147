export const ACCOUNTING_DEFS = {
    FIXED_ASSET_REGISTER_GRID: 'fixed-asset-register-grid',
    FIXED_ASSET_SCHEDULE_GRID: 'fixed-asset-schedule-grid',
    DEPRECIATION_ENTRIES_GRID: 'depreciation-entries-grid',
    FIXED_ASSET_REGISTER_FORM: 'fixed-asset-register-form',
    DISPOSAL_ENTRIES_GRID: 'disposal-entries-grid',
    CONTRACTS_FORM: 'contracts-form',
    ASSET_ENTRIES_GRID: 'asset-entries-grid',
    CONTRACTS_GRID: 'contracts-grid',
    CONTRACT_COVERAGE_GRID: 'contract-coverage-grid',
    FUNCTION_CODES_GRID: 'function-codes-grid',
};
