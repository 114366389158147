import { FA_Asset } from '../../../types/FixedAsset.types';
import useDepreciationSummaryModal from './hooks/useDepreciationSummaryModal';
import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectInput from '../../../components/form/formInputs/MultiSelectInput/MutliSelectInput';
import DateInput from '../../../components/form/formInputs/DateInput/DateInput';
import { LoadingButton } from '@mui/lab';
import { validateDateRange } from '../../../utils/comparisonUtils';

export interface DepreciationSummaryProps {
    open: boolean;
    onDepreciationSummaryLoaded: (entries: FA_Asset[]) => void;
    onClose: () => void;
}

const DepreciationSummaryModal = (props: DepreciationSummaryProps) => {
    const {
        fields,
        handleFieldChange,
        assetTypeOptions,
        isLoadingAssetTypes,
        handleGetReportEntries,
        isLoadingReportEntries,
    } = useDepreciationSummaryModal(props);

    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth={'sm'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Depreciation Summary Options</Box>
                    <Box>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiSelectInput
                            label="Asset Type(s)"
                            id="assetTypes"
                            value={fields.assetTypes}
                            onChange={handleFieldChange}
                            disabled={isLoadingAssetTypes}
                            options={assetTypeOptions}
                            disableCloseOnSelect={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label="Depreciation Date Begin"
                            id="startDate"
                            value={fields.startDate}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            label="Depreciation Date End"
                            id="endDate"
                            value={fields.endDate}
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={
                                !validateDateRange(
                                    fields.startDate,
                                    fields.endDate
                                )
                            }
                            loading={isLoadingReportEntries}
                            onClick={handleGetReportEntries}>
                            Get Depreciation Summary
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DepreciationSummaryModal;
