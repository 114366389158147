import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
} from '@mui/material';
import TextInput from '../form/formInputs/TextInput/TextInput';
import LoaderButton from '../LoaderButton';
import SelectInput from '../form/formInputs/SelectInput/SelectInput';
import BaseGrid from '../grids/BaseGrid';
import useSequenceBase from './hooks/useSequenceBase';
import { isNilOrEmpty } from '../../utils/objectUtils';
import BaseFormToolbar from '../form/baseForm/BaseFormToolbar';
import ExpandableDropdown from '../form/expandableDropdown/ExpandableDropdown';
import FormPartial from '../form/formContainer/FormPartial';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import NumericInput from '../form/formInputs/NumericInput/NumericInput';

const SequenceBase = ({
    sequenceQuery,
    sequenceMutation,
    parameterQuery,
    useParent,
    parentQuery,
    title,
    closePath,
}: any) => {
    const {
        fields,
        handlePrefixGridEdits,
        handleSuffixGridEdits,
        prefixColDefs,
        handleFieldChange,
        sequencePreview,
        handlePreviewNext,
        onUpdate,
        closeForm,
        parsedPrefixes,
        parsedSuffixes,
        handleRowDrag,
        businessEntities,
        setValues,
        parents,
        sharedTermSet,
    } = useSequenceBase({
        sequenceQuery,
        sequenceMutation,
        parameterQuery,
        useParent,
        parentQuery,
        closePath,
    });

    return (
        <Card>
            <CardHeader title={`${title} Sequences`} />
            <CardContent>
                <BaseFormToolbar
                    isNew={false}
                    onCancel={closeForm}
                    displayCreateButton={true}
                    displayUpdateButton={true}
                    onUpdate={onUpdate}
                    termSet={sharedTermSet}
                />
                <FormPartial title={'Use Parent'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                key={Math.random()}
                                id={'useParent'}
                                checked={fields?.useParent}
                                onChange={handleFieldChange}
                            />
                        }
                        label={'Use Parent?'}
                    />
                    {fields?.useParent && (
                        <ExpandableDropdown
                            valueSelected={(value) =>
                                setValues({
                                    parentId: parents.find(
                                        (parent: any) =>
                                            parent?.businessEntityId === value
                                    )?.id,
                                })
                            }
                            items={businessEntities.filter((be: any) => {
                                return parents?.some(
                                    (parent: any) =>
                                        parent.businessEntityId === be.id
                                );
                            })}
                            defaultValue={
                                parents?.find((parent: any) => {
                                    return parent.id === fields?.parentId;
                                })?.businessEntityId
                            }
                            idKey="id"
                            labelKey="name"
                            label="Parent Business Entity"
                            id="parentId"
                        />
                    )}
                </FormPartial>
                {!fields?.useParent && (
                    <>
                        <FormPartial title={'Prefixes'}>
                            {!isNilOrEmpty(fields?.prefixSegments) &&
                            !isNilOrEmpty(prefixColDefs) ? (
                                <BaseGrid
                                    displayGrid={true}
                                    onRowDragEnd={(params: any) =>
                                        handleRowDrag(params, 'prefixSegments')
                                    }
                                    rowDragManaged={true}
                                    animateRows={true}
                                    displayCreateNewButton={true}
                                    rowData={parsedPrefixes}
                                    gridClass="full-size-item"
                                    displayToolbar={true}
                                    sizeColumnsToFit={true}
                                    onHandleGridEdits={handlePrefixGridEdits}
                                    isEditable={true}
                                    pagination={false}
                                    columnDefs={prefixColDefs}
                                    newRowPlaceholderValues={{
                                        type: 'Select a type...',
                                        value: 'Enter Value...',
                                    }}></BaseGrid>
                            ) : (
                                <DatacorLogoSpinner />
                            )}
                        </FormPartial>
                        <FormPartial title={'Sequence Number'}>
                            <div style={{ width: '50%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <NumericInput
                                            id="nextNumber"
                                            label="Next Number"
                                            value={fields.nextNumber}
                                            onChange={handleFieldChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <NumericInput
                                            id="paddingLength"
                                            label={'Padding Length'}
                                            value={fields.paddingLength}
                                            onChange={handleFieldChange}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextInput
                                            id="paddingChar"
                                            label="Padding Character"
                                            value={fields.paddingChar}
                                            onChange={handleFieldChange}
                                            inputProps={{ maxLength: 1 }}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <NumericInput
                                            id="step"
                                            label="Step (Increment By)"
                                            value={fields.step}
                                            onChange={handleFieldChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectInput
                                            id={'resetPeriod'}
                                            label={'Auto Reset'}
                                            options={[
                                                {
                                                    label: 'None',
                                                    value: 'None',
                                                },
                                                {
                                                    label: 'Month',
                                                    value: 'Month',
                                                },
                                                {
                                                    label: 'Year',
                                                    value: 'Year',
                                                },
                                            ]}
                                            value={fields.resetPeriod}
                                            onChange={handleFieldChange}
                                        />
                                    </Grid>
                                </Grid>

                                <FormPartial title={'Preview'}>
                                    <h2>{sequencePreview}</h2>
                                    <LoaderButton
                                        variant={'contained'}
                                        onClick={handlePreviewNext}>
                                        Preview Next
                                    </LoaderButton>
                                </FormPartial>
                            </div>
                        </FormPartial>
                        <FormPartial title={'Suffixes'}>
                            {!isNilOrEmpty(fields?.suffixSegments) &&
                            !isNilOrEmpty(prefixColDefs) ? (
                                <BaseGrid
                                    displayGrid={true}
                                    displayCreateNewButton={true}
                                    onRowDragEnd={(params: any) =>
                                        handleRowDrag(params, 'suffixSegments')
                                    }
                                    rowDragManaged={true}
                                    animateRows={true}
                                    rowData={parsedSuffixes}
                                    gridClass="full-size-item"
                                    displayToolbar={true}
                                    sizeColumnsToFit={true}
                                    onHandleGridEdits={handleSuffixGridEdits}
                                    isEditable={true}
                                    pagination={false}
                                    columnDefs={prefixColDefs}
                                    newRowPlaceholderValues={{
                                        type: 'Select a type...',
                                        value: 'Enter Value...',
                                    }}></BaseGrid>
                            ) : (
                                <DatacorLogoSpinner />
                            )}
                        </FormPartial>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default SequenceBase;
