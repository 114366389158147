import React, { ChangeEvent, useEffect, useState } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { isNil } from '../../../../utils/objectUtils';
import { GreaterThanValidator } from '../../../../validators/greaterThan.validator';
import { MaxValueValidator } from '../../../../validators/maxValue.validator';
import _ from 'lodash';

export type NumericInputProps = {
    numDecimals?: number;
    greaterThan?: number;
    maxValue?: number;
} & InputBaseProps;

const NumericInput = ({
    numDecimals = 0,
    greaterThan = undefined,
    maxValue = undefined,
    ...props
}: NumericInputProps) => {
    const [previousInput, setPreviousInput] = useState('');

    useEffect(() => {
        if (props.formMethods) {
            //for now, assume this is greater than OR equal to
            if (!isNil(greaterThan)) {
                props.formMethods.addValidator(
                    props.id,
                    _.isObject(props.label) ? (
                        <>{props.label}</>
                    ) : (
                        props.label.toString()
                    ),
                    GreaterThanValidator(greaterThan)
                );
            }
            if (!isNil(maxValue)) {
                props.formMethods.addValidator(
                    props.id,
                    _.isObject(props.label) ? (
                        <>{props.label}</>
                    ) : (
                        props.label.toString()
                    ),
                    MaxValueValidator(maxValue.toFixed(numDecimals))
                );
            }
        }
    }, []);

    const onValueChanged = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const currentValue: any = event.target.value;
        let isValidNumber: boolean;

        if (isNil(currentValue) || currentValue === '-') {
            isValidNumber = true;
        } else if (numDecimals > 0) {
            isValidNumber = RegExp(
                `^$|^-?\\d+(?:[\\.\\,]\\d{0,${numDecimals}})?$`,
                'g'
            ).test(currentValue);
        } else {
            //not specifying the number of decimals will be treated as zero decimals
            isValidNumber = new RegExp(`^-?\\d+$`, 'g').test(currentValue);
        }
        if (isValidNumber) {
            setPreviousInput(currentValue);
        } else {
            if (previousInput !== '') {
                event.target.value = previousInput;
            } else {
                event.target.value = event.target.defaultValue || previousInput;
                setPreviousInput(event.target.value);
            }
        }

        props.onChange && props.onChange(event);
    };

    return <InputBase {...props} onChange={(event) => onValueChanged(event)} />;
};

export default NumericInput;
