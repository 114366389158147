import React, { createContext } from 'react';

export interface Settings {
    userSettings: any;
    businessEntitySettings: any;
    formulationSettings: any;
    accountingSettings: any;
}

const SettingsContext = createContext({
    settings: {
        userSettings: {},
        businessEntitySettings: {},
        accountingSettings: {},
        formulationSettings: {},
    } as Settings,
    setSettings: (values: any) => {},
});

export default SettingsContext;
