export const USER_FIELDS = {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    mobilePhone: 'Mobile Phone',
    messagingPrefId: 'Messaging Preference',
    externalUser: 'External User',
    activeWebBusinessEntityId: 'Selected Business Entity',
    subscriberId: 'Subscriber ID',
    businessEntityId: 'Business Entity',
    active: 'Active',
    isDatacorAdmin: 'Datacor Admin',
    isAppStreamUser: 'App Stream',
    email: 'Email',
    groups: 'Group(s)',
    groupsChange: 'Groups(s)',
    roles: 'Role(s)',
    rolesChange: 'Roles(s)',
    supervisorId: 'Supervisor ID',
    supervisor: 'Supervisor',
    departmentCodeId: 'Department Code ID',
    name: 'Department Code',
    code: 'Code',
    subscribers: 'Subscribers',
    businessEntities: 'Business Entities',
};

export const BUSINESS_ENTITY_FIELDS = {
    name: 'Name',
    description: 'Description',
    type: 'Type',
    parentId: 'Parent Business Entity',
    zone: 'Zone',
    businessEntityType: 'Business Entity Type',
};

export const SUBSCRIBER_FIELDS = {
    name: 'Name',
    description: 'Description',
};

export const PERMISSION_FIELDS = {
    permissions: 'Permissions',
};

export const ASSET_COST_CODE_FIELDS = {
    code: 'Code',
    sortOrder: 'Sort Order',
    isAdd: 'Is Add',
};

export const ASSET_STATUS_FIELDS = {
    code: 'Code',
    sortOrder: 'Sort Order',
    description: 'Description',
    isInService: 'In Service',
    isDisposal: 'Disposal',
};

export const ASSET_TYPE_FIELDS = {
    sortOrder: 'Sort Order',
    code: 'Code',
    description: 'Description',
    recoveryTerm: 'Recovery Term',
    recoveryUnit: 'Recovery Unit',
    depreciationMethod: 'Depreciation Method',
    depreciationExpenseAccount: 'Depreciation Expense Account',
    accumulatedDeprecationAccount: 'Accumulated Deprecation Account',
    assetAccount: 'Asset Account',
    assetControlAccount: 'Asset Control Account',
    disposalGainAccount: 'Disposal Gain Account',
    disposalLossAccount: 'Disposal Loss Account',
    disposalSettlementAccount: 'Disposal Settlement Account',
};

export const ACCOUNT_FUNCTION_CODE_FIELDS = {
    accounts: 'Accounts',
};

export const DEPRECIATION_METHOD_STATUS_FIELDS = {
    code: 'Code',
    description: 'Description',
    sortOrder: 'Sort Order',
    isActive: 'Active',
};

export const CONTRACT_FIELDS = {
    number: 'Contract No',
    name: 'Contract Name',
    description: 'Description',
    supplier: 'Provider',
    startDate: 'Date Start',
    endDate: 'Date End',
    amount: 'Contract Amount',
    contractType: 'Contract Type',
};

export const CONTRACT_TYPE_FIELDS = {
    code: 'Code',
    sortOrder: 'Sort Order',
    description: 'Description',
};

export const DEPRECIATION_METHOD_TYPE_FIELDS = {
    code: 'Code',
    sortOrder: 'Sort Order',
    description: 'Description',
    postsJournalEntries: 'Journal Posting',
    predefinedSchedule: 'Predefined Schedule',
};

export const DEPRECIATION_METHOD_FIELDS = {
    code: 'Code',
    name: 'Name',
    sortOrder: 'Sort Order',
    description: 'Description',
    depreciationMethodStatus: 'Depreciation Method Status',
    depreciationMethodType: 'Depreciation Method Type',
    depreciationFormula: 'Formula',
    costs: 'Cost Codes',
    recoveryIntervals: 'Recovery Intervals',
};

export const GENERAL_LEDGER_ACCOUNT_FIELDS = {
    number: 'Number',
    name: 'Name',
    description: 'Description',
    accountStatus: 'Status',
    accountType: 'Account Type',
    balanceType: 'Balance Type',
};

export const FIXED_ASSET_FIELDS = {
    code: 'Code',
    name: 'Name',
    description: 'Description',
    status: 'Status',
    acquireDate: 'Acquire Date',
    inServiceDate: 'In Service Date',
    disposalDate: 'Disposal Date',
    ledgerType: 'Asset Type (Book)',
    taxType: 'Asset Type (Tax)',
    building: 'Building',
    manufacturerSerialNumber: 'Manufacturer Serial #',
    purchaseOrderNumber: 'Purchase Order #',
    supplierInvoiceNumber: 'Supplier Invoice #',
    capitalProjectNumber: 'Capital Project #',
    costs: 'Costs',
    contracts: 'Contracts',
};

export const ATTRIBUTE_DEFINITION_FIELDS = {
    code: 'Code',
    name: 'Name',
    attrValueType: 'Value Type',
    defaultValue: 'Default Value',
    locked: 'Locked',
    options: 'Options',
};

export const ATTRIBUTE_TEMPLATE_FIELDS = {
    code: 'Template Code',
    name: 'Template Name',
    attrEntity: 'Data Entity',
    attrDefinitions: 'Attribute Definitions',
    locked: 'Locked',
};

export const MODULE_MAPPINGS: any = {
    user: USER_FIELDS,
    'business-entity': BUSINESS_ENTITY_FIELDS,
    subscriber: SUBSCRIBER_FIELDS,
    permission: PERMISSION_FIELDS,
    'attribute-definition': ATTRIBUTE_DEFINITION_FIELDS,
    'attribute-template': ATTRIBUTE_TEMPLATE_FIELDS,
    'fixed-asset-function-code': ACCOUNT_FUNCTION_CODE_FIELDS,
    'accounting-function-code': ACCOUNT_FUNCTION_CODE_FIELDS,
    'asset-cost-code': ASSET_COST_CODE_FIELDS,
    'asset-status': ASSET_STATUS_FIELDS,
    'asset-type': ASSET_TYPE_FIELDS,
    contract: CONTRACT_FIELDS,
    'contract-type': CONTRACT_TYPE_FIELDS,
    'depreciation-method': DEPRECIATION_METHOD_FIELDS,
    'depreciation-method-type': DEPRECIATION_METHOD_TYPE_FIELDS,
    'depreciation-method-status': DEPRECIATION_METHOD_STATUS_FIELDS,
    'fixed-asset': FIXED_ASSET_FIELDS,
    'general-ledger-account': GENERAL_LEDGER_ACCOUNT_FIELDS,
    'formulation-sds': null,
    'formulation-cas': null,
};

export const REVISION_ENTITY_TYPES = {
    USER: 1,
    BUSINESS_ENTITY: 2,
    SUBSCRIBERS: 3,
    PERMISSIONS: 4,
    ATTRIBUTE_DEFINITIONS: 5,
    ATTRIBUTE_TEMPLATES: 6,
};

export const CAS_FIELDS = {
    weightThreshold: 'If weight is greater than',
    identifier: 'CAS No.',
    chemicalName: 'Chemical Name',
    allowChemicalUpdate: 'Chemical Update',
    index: 'Index No.',
    ecIdentifier: 'EC No.',
    reach: 'Reach No.',
    classification: 'Classification',
    molecularFormula: 'Molecular Formula',
    displayOnSds: 'Show on SDS',
    tradeSecret: 'Trade Secret',
};

export const ITEM_FIELDS = {
    itemId: 'Item ID',
    itemName: 'Item Name',
    version: 'Version',
    description1: 'Description 1',
    description2: 'Description 2',
    upc: 'UPC',
    cost: 'Cost',
    inventoryTypeId: 'Inventory Type',
    itemStatusId: 'Status',
};
