import React from 'react';
import useSdsExposureGrid from './hooks/useSdsExposureGrid';
import { isNilOrEmpty } from '../../../../../../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../../../components/grids/BaseGrid';
import FormPartial from '../../../../../../../../components/form/formContainer/FormPartial';
import { ChildEditableGridProps } from '../../../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { SdsHeader } from '../../../../../../../../types/formulation';
import TranslatableText from '../../../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsExposureGrid = (props: ChildEditableGridProps<SdsHeader>) => {
    const {
        isLoadingExposureAgencies,
        sdsExposuresColumnDefs,
        sdsExposures,
        handleGridEdits,
        handleRowValidations,
        colDefOptions,
        termSet,
    } = useSdsExposureGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, isGridEditable } = props.displayGridButtons;

    return isLoadingExposureAgencies || isNilOrEmpty(sdsExposuresColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <FormPartial
            title={
                (
                    <TranslatableText
                        termSet={termSet}
                        termKey={SDSFormDefs.Control_Parameters}
                    />
                ) as unknown as string
            }>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                useRowDrag={isGridEditable}
                displaySortButton={isGridEditable}
                sortKey={'sortOrder'}
                rowData={sdsExposures || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                columnDefs={sdsExposuresColumnDefs}
                newRowPlaceholderValues={{
                    casNumber: 'Enter a CAS Number...',
                    exposureAgencyCode: 'Enter an Agency Code...',
                    exposureValue: 'Enter an Exposure Value...',
                    exposureForm: 'Enter an Exposure Form...',
                    notes: 'Enter Exposure Notes...',
                }}></BaseGrid>
        </FormPartial>
    );
};

export default SdsExposureGrid;
