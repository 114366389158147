import { AgGridColumn } from 'ag-grid-react';
import LoaderButton from '../../../components/LoaderButton';
import React from 'react';
import ApprovalsModal from '../ApprovalsModal';
import CommentsView from '../comments/CommentsView';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import ApproverWorkflowsButtonGroup from '../approvalWorkflows/ApproverWorkflowsButtonGroup';
import { isInvoiceLocked } from '../Utils';
import Grid from '../../../components/grids/Grid';
import useApprovalsTab from '../hooks/Tabs/useApprovalsTab';
import RejectOptionsDialog from '../../../components/apAutomation/RejectOptionsDialog';
import { UserList } from '../../../utils/users/userList';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';

interface ApprovalsTabProps {
    documentId: string;
}

const ApprovalsTab = ({ documentId }: ApprovalsTabProps) => {
    const {
        user,
        getGridData,
        userList,
        isLoading,
        canUserUpdateApprovers,
        isUserApprover,
        isUserApproved,
        approveCurrentDocument,
        unapproveCurrentDocument,
        isLoadingSaveApprove,
        isLoadingSaveUnapprove,
        activeDocument,
        showChangeDefaultModal,
        setShowChangeDefaultModal,
        open,
        handleClickOpen,
        handleClose,
        REJECTION_REASONS,
    } = useApprovalsTab(documentId);

    return (
        <>
            <RejectOptionsDialog
                open={open}
                onClose={handleClose}
                documentId={documentId}
            />
            {!isLoading && (
                <Grid
                    gridStyle={{ width: '90%' }}
                    testId="approvers-list-grid"
                    autofitColumns={true}
                    rowData={getGridData()}
                    immutableData={true}
                    dataKey={'email'}>
                    <AgGridColumn
                        field="email"
                        headerName="Approver"
                        valueFormatter={(val) =>
                            new UserList(userList)?.getUsersFullNameFromEmail(
                                val.value,
                                true
                            )
                        }
                        filterParams={() => {
                            return {
                                valueFormatter: (params: any) => {
                                    return new UserList(
                                        userList
                                    )?.getUsersFullNameFromEmail(
                                        params.value,
                                        true
                                    );
                                },
                            };
                        }}
                    />
                    <AgGridColumn
                        field="approvalStatus.value"
                        valueFormatter={(val: any) => {
                            if (val.data?.approvalStatus?.key === 'REJECTED') {
                                return `${val.data.approvalStatus.value} - ${
                                    REJECTION_REASONS[
                                        val.data.approvalStatus.reason
                                    ]
                                }`;
                            }
                            return val.data?.approvalStatus?.value
                                ? val.data?.approvalStatus?.value
                                : 'Auto Approved';
                        }}
                        headerName="Approval Status"
                    />
                </Grid>
            )}
            <br />
            {canUserUpdateApprovers &&
            isUserApprover &&
            PermissionsUtil.isPermissionEnabled(
                user.permissions,
                PERMISSIONS.AP_AUTOMATION.INVOICES.UPDATE
            ) ? (
                isUserApproved ? (
                    <>
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            onClick={unapproveCurrentDocument}
                            variant="contained"
                            isLoading={isLoadingSaveUnapprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Unapprove
                        </LoaderButton>{' '}
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            variant="contained"
                            onClick={handleClickOpen}
                            color="error"
                            disabled={isInvoiceLocked(activeDocument)}>
                            Reject
                        </LoaderButton>
                    </>
                ) : (
                    <>
                        <LoaderButton
                            data-testid="approveInvoiceBtn"
                            onClick={approveCurrentDocument}
                            variant="contained"
                            color="success"
                            isLoading={isLoadingSaveApprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Approve
                        </LoaderButton>{' '}
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            onClick={handleClickOpen}
                            variant="contained"
                            color="error"
                            disabled={isInvoiceLocked(activeDocument)}>
                            Reject
                        </LoaderButton>
                    </>
                )
            ) : null}{' '}
            {canUserUpdateApprovers &&
            PermissionsUtil.isPermissionEnabled(
                user.permissions,
                PERMISSIONS.AP_AUTOMATION.INVOICES.UPDATE
            ) ? (
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="changeInvoiceApproversBtn"
                    onClick={() => setShowChangeDefaultModal(true)}
                    disabled={isInvoiceLocked(activeDocument)}>
                    Change Invoice Approvers
                </LoaderButton>
            ) : null}
            <br />
            <br />
            Assign Workflow to Invoice:
            <br />
            <br />
            <ApproverWorkflowsButtonGroup documentId={documentId} />
            <br />
            <br />
            <CommentsView documentId={documentId} />
            <ApprovalsModal
                show={showChangeDefaultModal}
                handleClose={() => setShowChangeDefaultModal(false)}
                documentId={documentId}
            />
        </>
    );
};

export default ApprovalsTab;
