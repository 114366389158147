import React from 'react';
import {
    useCreateDepreciationMethodStatusMutation,
    useDeleteDepreciationMethodStatusMutation,
    useGetDepreciationMethodStatusQuery,
    useUpdateDepreciationMethodStatusMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FA_DepreciationMethodStatus } from '../../../types/FixedAsset.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';

const UseFixedAssetDepreciationMethodStatusForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const depreciationStatus = useSelector(
        (state: RootState) => state.fixedAssets
    );
    const { data: activeStatus, isLoading: isLoadingStatus } =
        useGetDepreciationMethodStatusQuery(
            id === RoutingValues.newId ? skipToken : id
        );
    const [createStatus] = useCreateDepreciationMethodStatusMutation();
    const [updateStatus] = useUpdateDepreciationMethodStatusMutation();
    const [deleteStatus] = useDeleteDepreciationMethodStatusMutation();

    const canCreateDepreciationMethodStatus =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.CREATE
        );
    const canUpdateDepreciationMethodStatus =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.EDIT
        );
    const canDeleteDepreciationMethodStatus =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.DELETE
        );

    const blankStatus: FA_DepreciationMethodStatus = {
        code: '',
        description: '',
        sortOrder: depreciationStatus.sortOrderSet.maxValue,
        isActive: false,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethodStatus',
        blankEntity: blankStatus,
        activeEntity: activeStatus,
        getDescription: () => {
            return `Depreciation Method Status ${fields.code}`;
        },
        createEntity: () => {
            return createStatus({ postBody: fields });
        },
        updateEntity: () => {
            return updateStatus({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteStatus(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingStatus,
        canCreateDepreciationMethodStatus,
        canUpdateDepreciationMethodStatus,
        canDeleteDepreciationMethodStatus,
    };
};

export default UseFixedAssetDepreciationMethodStatusForm;
