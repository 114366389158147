import { datacorAppApi } from '../datacorAppApi';

export const i18nApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        getTermSet: build.query<any, any>({
            query: (args) =>
                `i18n/translation/getTermSet/${args.languageId}/${args.code}`,
            providesTags: ['TermSet'],
        }),
    }),
});

export const { useGetTermSetQuery } = i18nApi;
