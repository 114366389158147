import { useNavigate } from 'react-router-dom';
import {
    useGetAllContractTypesQuery,
    useUpdateListContractTypesMutation,
} from '../../../services/accounting/accounting.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { ContractType } from '../../../types/Accounting.types';
import { useEffect } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

const UseContractTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: contractTypeList, isLoading: isLoadingContractTypes } =
        useGetAllContractTypesQuery();
    const [updateContractTypes] = useUpdateListContractTypesMutation();

    const canCreateContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.CREATE
    );
    const canViewContractType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW
    );

    const handleGridEdits = (editedRows: ContractType[]) => {
        updateContractTypes(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/contractType/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/contractType/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (contractTypeList) {
            const data = _.maxBy(
                contractTypeList,
                (contractType: ContractType) => contractType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, contractTypeList);

    return {
        contractTypeList,
        isLoadingContractTypes,
        canCreateContractType,
        canViewContractType,
        redirectToForm,
        handleGridEdits,
    };
};
export default UseContractTypeGrid;
