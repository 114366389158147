import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import useLocaleGrid from './hooks/useLocaleGrid';
import EditableBaseFormGrid from '../../../components/grids/EditableBaseFormGrid';
import { Locale } from '../../../types/formulation';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import DatacorLogoSpinner from '../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { LocalesGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../components/i18n/TranslatableText';

const LocaleGrid = () => {
    const {
        isLoadingLocales,
        isLoadingLocaleGrp,
        localesList,
        columnDefs,
        handleUpdate,
        canViewLocales,
        termSet,
    } = useLocaleGrid();

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <>
            {isNilOrEmpty(columnDefs) ? (
                <DatacorLogoSpinner />
            ) : (
                <EditableBaseFormGrid<Locale>
                    autoSize={true}
                    displayGrid={canViewLocales}
                    dataKey={'id'}
                    isLoading={
                        isLoadingLocales &&
                        isLoadingLocaleGrp &&
                        columnDefs == null
                    }
                    rowData={localesList}
                    columnDefs={columnDefs}
                    gridClass="full-size-item"
                    title={
                        (
                            <TranslatableText
                                termSet={termSet}
                                termKey={LocalesGridDefs.Locales}
                            />
                        ) as unknown as string
                    }
                    displayToolbar={false}
                    displayCreateNewButton={false}
                    displayExportDataButton={false}
                    displayResetStateButton={false}
                    defaultColDef={defaultColumnDefinitionOptions}
                    sizeColumnsToFit={true}
                    isEditable={true}
                    onSave={(locales) => handleUpdate(locales)}
                />
            )}
        </>
    );
};

export default LocaleGrid;
