import { Button } from '@mui/material';
import useAcquisitionGrid from './hooks/useAcquisitionsGrid';
import React from 'react';
import ListIcon from '@mui/icons-material/List';
import AcquisitionOptionsModal from './AcquisitionOptionsModal';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';

const AcquisitionsGrid = () => {
    const {
        acquisitionRows,
        setAssetList,
        columnDefs,
        setShowOptionsModal,
        showOptionsModal,
        loadingCostCodeList,
        acquisitionsSummaryRow,
        canViewAcquisitionReport,
    } = useAcquisitionGrid();

    return (
        <React.Fragment>
            <BaseFormGrid
                title="Acquisition Report"
                displayGrid={canViewAcquisitionReport}
                isLoading={loadingCostCodeList}
                rowData={acquisitionRows}
                columnDefs={columnDefs}
                gridClass="full-size-item"
                displaySummaryRow={true}
                pinnedBottomRowData={acquisitionsSummaryRow}
                displayToolbar={true}
                displayExportDataButton={true}
                toolbarChildren={
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => setShowOptionsModal(true)}
                        startIcon={<ListIcon />}>
                        Options
                    </Button>
                }></BaseFormGrid>
            <AcquisitionOptionsModal
                open={showOptionsModal}
                onAcquisitionsLoaded={(assets) => setAssetList(assets)}
                onClose={() => setShowOptionsModal(false)}
            />
        </React.Fragment>
    );
};
export default AcquisitionsGrid;
