import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useRolesGrid from './hooks/useRolesGrid';
import { RolesGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';
const RolesGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    } = useRolesGrid();

    return (
        <>
            {!isLoading ? (
                <AgGrid
                    testId="roles-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={rowData}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        floatingFilter: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={RolesGridDefs.Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="description"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={RolesGridDefs.Description}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="businessEntity.name"
                        filter={'agTextColumnFilter'}
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={RolesGridDefs.Business_Entity}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="createdAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={RolesGridDefs.Date_Created}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={RolesGridDefs.Last_Update_Date}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default RolesGrid;
