import React from 'react';
import useContractTypeGrid from './hooks/useContractTypeGrid';
import { ColDef } from 'ag-grid-community';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { cloneDeep } from 'lodash';

const ContractTypeGrid = () => {
    const {
        contractTypeList,
        redirectToForm,
        isLoadingContractTypes,
        canCreateContractType,
        canViewContractType,
        handleGridEdits,
    } = useContractTypeGrid();

    const contractTypeColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
    ];

    return (
        <BaseFormGrid
            title={'Contract Types'}
            displayGrid={canViewContractType}
            isLoading={isLoadingContractTypes}
            rowData={cloneDeep(contractTypeList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={contractTypeColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateContractType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default ContractTypeGrid;
