import { Grid } from '@mui/material';
import React from 'react';
import useSdsIdentificationForm from './hooks/useSdsIdentificationForm';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import { SdsHeader } from '../../../../../../types/formulation';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import TextInputPopup from '../../../../../../components/form/formInputs/TextInputPopup/TextInputPopup';
import NumericInput from '../../../../../../components/form/formInputs/NumericInput/NumericInput';
import DateInput from '../../../../../../components/form/formInputs/DateInput/DateInput';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection1_3Form from './subSections/subSection1_3/SubSection1_3Form';
import PhoneNumberInput from '../../../../../../components/form/formInputs/PhoneNumberInput/PhoneNumberInput';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsIdentificationForm = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { parentData } = props;

    const { isParentLoading } = parentData;

    const { disabledField } = props.permissions;

    const {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        userList,
        casList,
        formulaList,
        userTextPopupColDefs,
        casTextPopupColDefs,
        formulaTextPopupColDefs,
        headerPermissions,
        termSet,
    } = useSdsIdentificationForm(props);

    const { canViewCasMaster, canViewFormula, canViewUsers } =
        headerPermissions;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SDSFormDefs.Identification}
                                />
                            ) as unknown as string
                        }>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={SDSFormDefs.Product_Identifier}
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="productName"
                                        value={fields.productName}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Product_Name
                                                }
                                            />
                                        }
                                        maxLength={50}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInputPopup
                                        fullWidth
                                        id="casId"
                                        onChange={handlePropertyChanged}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={SDSFormDefs.CAS_No}
                                            />
                                        }
                                        formMethods={formMethods}
                                        popupOptions={
                                            canViewCasMaster
                                                ? casList || []
                                                : []
                                        }
                                        colDefs={casTextPopupColDefs}
                                        value={fields.casId}
                                        valueFormatter={{
                                            optionLabel: 'label',
                                            value: 'value',
                                        }}
                                        optionLabel={
                                            dropdownOptions?.selectedCas?.label
                                        }
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="registrationNumber"
                                        value={fields.registrationNumber}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Registration_Number
                                                }
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="additionalCode"
                                        value={fields.additionalCode}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Additional_Code
                                                }
                                            />
                                        }
                                        maxLength={20}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={SDSFormDefs.Synonyms}
                                            />
                                        }
                                        id="synonyms"
                                        disabled={disabledField}
                                        formMethods={formMethods}
                                        value={fields.synonyms}
                                        onChange={handlePropertyChanged}
                                        maxLength={255}
                                        multiline={true}
                                        maxRows={15}
                                        minRows={5}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            SDSFormDefs.Relevant_Identified_Uses
                                        }
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="productUseDescription"
                                        value={fields.productUseDescription}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Product_Use_Description
                                                }
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CheckBoxInput
                                        id="certified"
                                        label={
                                            (
                                                <TranslatableText
                                                    termSet={termSet}
                                                    termKey={
                                                        SDSFormDefs.Certified
                                                    }
                                                />
                                            ) as unknown as string
                                        }
                                        checked={fields.certified}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Certification_Date
                                                }
                                            />
                                        }
                                        id="certificationDate"
                                        value={fields.certificationDate}
                                        readOnly={false}
                                        onChange={handlePropertyChanged}
                                        formMethods={formMethods}
                                        required={false}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInputPopup
                                        fullWidth
                                        id="certificationUserId"
                                        onChange={handlePropertyChanged}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Certification_User
                                                }
                                            />
                                        }
                                        formMethods={formMethods}
                                        popupOptions={
                                            canViewUsers ? userList || [] : []
                                        }
                                        colDefs={userTextPopupColDefs}
                                        value={fields.certificationUserId}
                                        valueFormatter={{
                                            optionLabel: 'label',
                                            value: 'value',
                                        }}
                                        optionLabel={
                                            dropdownOptions
                                                ?.selectedCertifiedUser?.label
                                        }
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInputPopup
                                        fullWidth
                                        id="formulaId"
                                        onChange={handlePropertyChanged}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Formula_Code
                                                }
                                            />
                                        }
                                        formMethods={formMethods}
                                        popupOptions={
                                            canViewFormula
                                                ? formulaList || []
                                                : []
                                        }
                                        colDefs={formulaTextPopupColDefs}
                                        value={fields.casId}
                                        valueFormatter={{
                                            optionLabel: 'label',
                                            value: 'value',
                                        }}
                                        optionLabel={
                                            dropdownOptions?.selectedFormula
                                                ?.label
                                        }
                                        disabled={disabledField}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            SDSFormDefs.Details_Of_The_Supplier
                                        }
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        id="regulatoryAddress"
                                        value={fields.regulatoryAddress}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Supplier_Name
                                                }
                                            />
                                        }
                                        maxLength={50}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <NumericInput
                                        fullWidth
                                        id="regulatoryPhone"
                                        value={fields.regulatoryPhone}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Phone_Number
                                                }
                                            />
                                        }
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="regulatoryEmail"
                                        value={fields.regulatoryEmail}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={SDSFormDefs.Email}
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        id="website"
                                        value={fields.website}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={SDSFormDefs.Website}
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid>
                                <SubSection1_3Form
                                    {...props}
                                    termSet={termSet}
                                />
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title={
                                (
                                    <TranslatableText
                                        termSet={termSet}
                                        termKey={
                                            SDSFormDefs.Emergency_Telephone_Information
                                        }
                                    />
                                ) as unknown as string
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        id="contactName"
                                        value={fields.contactName}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Emergency_Contact_Name
                                                }
                                            />
                                        }
                                        maxLength={50}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        fullWidth
                                        id="contactPhone1"
                                        value={fields.contactPhone1}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Phone_Number_One
                                                }
                                            />
                                        }
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                        minPhoneDigits={11}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="contactPhoneDescription1"
                                        value={fields.contactPhoneDescription1}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Phone_Number_One_Description
                                                }
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        fullWidth
                                        id="contactPhone2"
                                        value={fields.contactPhone2}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Phone_Number_Two
                                                }
                                            />
                                        }
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                        minPhoneDigits={11}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="contactPhoneDescription2"
                                        value={fields.contactPhoneDescription2}
                                        label={
                                            <TranslatableText
                                                termSet={termSet}
                                                termKey={
                                                    SDSFormDefs.Phone_Number_Two_Description
                                                }
                                            />
                                        }
                                        maxLength={30}
                                        formMethods={formMethods}
                                        required={false}
                                        onChange={handlePropertyChanged}
                                        disabled={disabledField}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormPartial>
                </>
            )}
        </>
    );
};
export default SdsIdentificationForm;
