import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    useCreateFixedAssetMutation,
    useGetAllTypesQuery,
    useGetFixedAssetQuery,
    useGetStatusesForDropdownQuery,
    useUpdateFixedAssetMutation,
} from '../../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FA_Asset, FA_AssetType } from '../../../../types/FixedAsset.types';
import { RoutingValues, SelectionOption } from '../../../../types/Shared.types';
import useBaseForm from '../../../form/hooks/useBaseForm';
import { useGetBuildingsForDropdownQuery } from '../../../../services/accounting/accounting.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { ACCOUNTING_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/accounting';

const UseFixedAssetForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: ACCOUNTING_DEFS.FIXED_ASSET_REGISTER_FORM,
              }
            : skipToken
    );
    const refCostGrid = useRef(null);
    const refContractGrid = useRef(null);
    const refScheduleDepreciationGrid = useRef(null);

    const [currentTab, setCurrentTab] = useState<string>('0');

    const [hasLedgerDepreciationSchedule, setHasLedgerDepreciationSchedule] =
        useState<boolean>(false);

    const [hasTaxDepreciationSchedule, setHasTaxDepreciationSchedule] =
        useState<boolean>(false);

    const [bookAssetTypes, setBookAssetTypes] = useState<SelectionOption[]>([]);
    const [taxAssetTypes, setTaxAssetTypes] = useState<SelectionOption[]>([]);

    const bookHeader = useRef('');
    const taxHeader = useRef('');

    const { data: activeAsset, isLoading: isLoadingAsset } =
        useGetFixedAssetQuery(id === RoutingValues.newId ? skipToken : id);
    const [createAsset] = useCreateFixedAssetMutation();
    const [updateAsset] = useUpdateFixedAssetMutation();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetStatusesForDropdownQuery();

    const { data: typeOptions, isLoading: isLoadingTypes } =
        useGetAllTypesQuery();

    const { data: buildingOptions, isLoading: isLoadingBuildings } =
        useGetBuildingsForDropdownQuery();

    const canCreateAssetRecords = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.CREATE
    );
    const canUpdateAssetRecords = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.EDIT
    );

    const blankAsset: FA_Asset = {
        number: null,
        code: '',
        name: '',
        description: '',
        statusId: null,
        acquireDate: null,
        inServiceDate: null,
        disposalDate: null,
        ledgerTypeId: null,
        taxTypeId: null,
        buildingId: null,
        manufacturerSerialNumber: '',
        purchaseOrderNumber: '',
        supplierInvoiceNumber: '',
        capitalProjectNumber: '',
        contractList: [],
    };

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        formType,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/fixedAsset',
        copyPath: `/accounting/fixedAssets/fixedAsset/${RoutingValues.newId}/${RoutingValues.copy}`,
        blankEntity: blankAsset,
        activeEntity: activeAsset,
        getDescription: () => {
            return `Fixed Asset ${fields.code}`;
        },
        createEntity: () => {
            return createAsset({
                postBody: buildPostBody(),
            });
        },
        updateEntity: () => {
            return updateAsset({
                id: id as unknown as number,
                postBody: buildPostBody(),
            });
        },
    });

    const buildPostBody = () => {
        return {
            ...fields,
            costs: refCostGrid?.current?.getAllRows(),
            contracts: refContractGrid?.current?.getContracts(),
        };
    };

    useEffect(() => {
        const bookTypes: FA_AssetType[] =
            typeOptions?.filter(
                (assetType: FA_AssetType) =>
                    assetType?.depreciationMethod?.depreciationMethodType
                        ?.postsJournalEntries
            ) || [];
        const bookTypesAsSelections: SelectionOption[] = bookTypes.map(
            (type) => {
                return {
                    value: type.id,
                    label: type.code,
                };
            }
        );
        setBookAssetTypes(bookTypesAsSelections);

        const taxTypes: FA_AssetType[] =
            typeOptions?.filter(
                (assetType: FA_AssetType) =>
                    !assetType?.depreciationMethod?.depreciationMethodType
                        ?.postsJournalEntries
            ) || [];
        const taxTypesAsSelections: SelectionOption[] = taxTypes.map((type) => {
            return {
                value: type.id,
                label: type.code,
            };
        });
        setTaxAssetTypes(taxTypesAsSelections);
    }, [typeOptions]);

    useEffect(() => {
        let currentTax = taxAssetTypes.find(
            (type) => type.value === fields.taxTypeId
        );
        taxHeader.current = currentTax ? currentTax.label : '';

        let currentBook = bookAssetTypes.find(
            (type) => type.value === fields.ledgerTypeId
        );
        bookHeader.current = currentBook ? currentBook.label : '';
    }, [fields.ledgerTypeId, fields.taxTypeId]);

    useEffect(() => {
        setHasLedgerDepreciationSchedule(
            activeAsset?.ledgerDepreciationSchedule?.length > 0
        );
        setHasTaxDepreciationSchedule(
            activeAsset?.taxDepreciationSchedule?.length > 0
        );
    }, [activeAsset]);

    useEffect(() => {
        if (formType === RoutingValues.copy) {
            setValues({
                id: null,
                uuid: undefined,
                code: `${fields.code}-copy`,
                inServiceDate: null,
                disposalDate: null,
                manufacturerSerialNumber: null,
            });
            refCostGrid?.current?.setCostValues();
        }
    }, [formType]);

    const onAssetTypeChanged = (event: any) => {
        handleFieldChange(event);
        refScheduleDepreciationGrid?.current?.disableCreateSchedules(true);
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        bookHeader,
        taxHeader,
        isLoadingAsset,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingBuildings,
        statusOptions,
        buildingOptions,
        bookAssetTypes,
        taxAssetTypes,
        currentTab,
        setCurrentTab,
        onAssetTypeChanged,
        canCreateAssetRecords,
        canUpdateAssetRecords,
        hasLedgerDepreciationSchedule,
        hasTaxDepreciationSchedule,
        activeAsset,
        refCostGrid,
        refContractGrid,
        refScheduleDepreciationGrid,
        termSet,
    };
};

export default UseFixedAssetForm;
