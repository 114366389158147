import FixedAssets from '../containers/fixedAssets/assetRegister/FixedAssets';
import FixedAssetGrid from '../containers/fixedAssets/assetRegister/FixedAssetGrid';
import FixedAssetForm from '../containers/fixedAssets/assetRegister/FixedAssetForm';
import FixedAssetStatusGrid from '../containers/fixedAssets/FixedAssetStatusGrid';
import FixedAssetsStatusForm from '../containers/fixedAssets/FixedAssetsStatusForm';
import AccountFunctionCodeGrid from '../containers/accounting/AccountFunctionCodeGrid';
import AccountFunctionCodeForm from '../containers/accounting/AccountFunctionCodeForm';
import FixedAssetCostCodeGrid from '../containers/fixedAssets/FixedAssetsCostCodeGrid';
import FixedAssetsCostCodeForm from '../containers/fixedAssets/FixedAssetsCostCodeForm';
import FixedAssetTypeGrid from '../containers/fixedAssets/FixedAssetTypeGrid';
import FixedAssetTypeForm from '../containers/fixedAssets/FixedAssetTypeForm';
import FixedAssetDepreciationMethodStatusGrid from '../containers/fixedAssets/FixedAssetDepreciationMethodStatusGrid';
import FixedAssetDepreciationMethodStatusForm from '../containers/fixedAssets/FixedAssetDepreciationMethodStatusForm';
import FixedAssetDepreciationMethodTypeGrid from '../containers/fixedAssets/FixedAssetDepreciationMethodTypeGrid';
import FixedAssetDepreciationMethodTypeForm from '../containers/fixedAssets/FixedAssetDepreciationMethodTypeForm';
import FixedAssetDepreciationMethodGrid from '../containers/fixedAssets/FixedAssetDepreciationMethodGrid';
import FixedAssetDepreciationMethodForm from '../containers/fixedAssets/FixedAssetDepreciationMethodForm';
import ContractGrid from '../containers/accounting/ContractGrid';
import ContractForm from '../containers/accounting/ContractForm';
import ContractTypeGrid from '../containers/accounting/ContractTypeGrid';
import ContractTypeForm from '../containers/accounting/ContractTypeForm';
import GeneralLedgerAccountGrid from '../containers/accounting/GeneralLedgerAccountGrid';
import GeneralLedgerAccountForm from '../containers/accounting/GeneralLedgerAccountForm';
import AssetEntriesGrid from '../containers/fixedAssets/assetEntries/AssetEntriesGrid';
import FixedAssetSchedulesGrid from '../containers/fixedAssets/FixedAssetSchedulesGrid';
import FixedAssetDepreciationEntriesGrid from '../containers/fixedAssets/FixedAssetDepreciationEntriesGrid';
import ContractCoverageGrid from '../containers/fixedAssets/fixedAssetContract/ContractCoverageGrid';
import FixedAssetDisposalEntriesGrid from '../containers/fixedAssets/fixedAssetDisposalEntries/FixedAssetDisposalEntriesGrid';
import AcquisitionsGrid from '../containers/fixedAssets/reports/AcquisitionsGrid';
import DisposalsGrid from '../containers/fixedAssets/reports/DisposalsGrid';
import DepreciationSummaryGrid from '../containers/fixedAssets/reports/DepreciationSummaryGrid';
import DepreciationDetailGrid from '../containers/fixedAssets/reports/DepreciationDetailGrid';

import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';

const FixedAssetsRoutes: RouteProps[] = [
    {
        path: '/accounting/fixedAssets',
        component: FixedAssets,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/fixedAsset',
        component: FixedAssetGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW,
    },
    {
        path: '/accounting/fixedAssets/fixedAsset/:id/:type',
        component: FixedAssetForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FIXED_ASSET_REGISTER.VIEW,
    },
    {
        path: '/accounting/fixedAssets/assetSchedules',
        component: FixedAssetSchedulesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_SCHEDULES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationEntries',
        component: FixedAssetDepreciationEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_ENTRIES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/status',
        component: FixedAssetStatusGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/status/:id/:type',
        component: FixedAssetsStatusForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/functionCode/:group',
        component: AccountFunctionCodeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/functionCode/:group/:id',
        component: AccountFunctionCodeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.fixedAssets',
    },
    {
        path: '/accounting/fixedAssets/costCode',
        component: FixedAssetCostCodeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/costCode/:id/:type',
        component: FixedAssetsCostCodeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_COST_CODES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/type',
        component: FixedAssetTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/type/:id/:type',
        component: FixedAssetTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodStatus',
        component: FixedAssetDepreciationMethodStatusGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodStatus/:id/:type',
        component: FixedAssetDepreciationMethodStatusForm,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_STATUSES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodType',
        component: FixedAssetDepreciationMethodTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethodType/:id/:type',
        component: FixedAssetDepreciationMethodTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHOD_TYPES.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethod',
        component: FixedAssetDepreciationMethodGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/depreciationMethod/:id/:type',
        component: FixedAssetDepreciationMethodForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DEPRECIATION_METHODS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/acquisitions',
        component: AcquisitionsGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.ACQUISITIONS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/disposals',
        component: DisposalsGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.REPORTS.DISPOSALS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/depreciationSummary',
        component: DepreciationSummaryGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_SUMMARY.VIEW,
    },
    {
        path: '/accounting/fixedAssets/reports/depreciationDetail',
        component: DepreciationDetailGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_DETAIL.VIEW,
    },
    {
        path: '/accounting/contract',
        component: ContractGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW,
    },
    {
        path: '/accounting/contract/:id/:type',
        component: ContractForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW,
    },
    {
        path: '/accounting/contractCoverage',
        component: ContractCoverageGrid,
        permissionKey:
            PERMISSIONS.FIXED_ASSETS.CONTRACTS.VIEW_CONTRACT_COVERAGE,
    },
    {
        path: '/accounting/contractType',
        component: ContractTypeGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW,
    },
    {
        path: '/accounting/contractType/:id/:type',
        component: ContractTypeForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CONTRACT_TYPES.VIEW,
    },
    {
        path: '/accounting/generalLedgerAccount',
        component: GeneralLedgerAccountGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW,
    },
    {
        path: '/accounting/generalLedgerAccount/:id/:type',
        component: GeneralLedgerAccountForm,
        permissionKey: PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW,
    },
    {
        path: '/accounting/fixedAssets/disposalEntries',
        component: FixedAssetDisposalEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.DISPOSAL_ENTRY.VIEW,
    },
    {
        path: '/accounting/fixedAssets/assetEntries',
        component: AssetEntriesGrid,
        permissionKey: PERMISSIONS.FIXED_ASSETS.ASSET_ENTRY.VIEW,
    },
];

export default FixedAssetsRoutes;
