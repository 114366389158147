import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import useFixedAssetDepreciationMethodForm from './hooks/useFixedAssetDepreciationMethodForm';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import { AgGridColumn } from 'ag-grid-react';
import BaseGrid from '../../components/grids/BaseGrid';
import { ValueFormatterParams } from 'ag-grid-community';
import { IAggFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import _ from 'lodash';
import { DepreciationBasis } from '../../types/FixedAsset.types';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { percentFormatter } from '../../utils/formattingUtils';
import { DEPRECIATION_METHOD_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';

const FixedAssetDepreciationMethodForm = () => {
    const { id, type } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingMethod,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostCodes,
        statusOptions,
        typeOptions,
        formulaOptions,
        costCodeList,
        onGridReady,
        onRowSelected,
        depreciationScheduleWorksheet,
        depreciationBasis,
        totalAmount,
        numberOfRecoveryIntervals,
        handleRecoveryIntervalChange,
        canCreateDepreciationMethod,
        canUpdateDepreciationMethod,
        canDeleteDepreciationMethod,
    } = useFixedAssetDepreciationMethodForm(id);

    return (
        <BaseForm
            title={'Fixed Asset Depreciation Method'}
            formMethods={formMethods}
            isLoading={isLoadingMethod}
            isNew={type === RoutingValues.create}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={DEPRECIATION_METHOD_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateDepreciationMethod}
            displayUpdateButton={canUpdateDepreciationMethod}
            displayDeleteButton={canDeleteDepreciationMethod}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label="Depreciation Method Code"
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Depreciation Method Name"
                            id="name"
                            value={fields.name}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={90}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumericInput
                            fullWidth
                            label="Sort Order"
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Depreciation Method Status"
                            id="depreciationMethodStatusId"
                            value={fields.depreciationMethodStatusId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={statusOptions}
                            disabled={isLoadingStatuses}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Depreciation Method Type"
                            id="depreciationMethodTypeId"
                            value={fields.depreciationMethodTypeId}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={typeOptions}
                            disabled={isLoadingTypes}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial title="Cost Codes" isVisible={!isLoadingCostCodes}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BaseGrid
                            rowData={costCodeList || []}
                            displayGrid={true}
                            onGridReady={onGridReady}
                            onRowSelected={onRowSelected}
                            rowMultiSelectWithClick={true}
                            rowStyle={{ cursor: 'pointer' }}
                            rowSelection={'multiple'}
                            defaultColDef={{
                                resizable: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                headerName="Selected"
                                {...DefaultColumnTypes.CheckboxSelection}
                            />
                            <AgGridColumn
                                field="code"
                                headerName="Cost Code"
                                {...DefaultColumnTypes.ShortText}
                            />
                        </BaseGrid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Total Amount"
                            id="totalAmount"
                            value={totalAmount}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial
                title="Depreciation Basis"
                isVisible={depreciationBasis !== DepreciationBasis.None}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={2}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Formula
                        }>
                        <SelectInput
                            fullWidth
                            label="Depreciation Option"
                            id="depreciationFormula"
                            value={fields.depreciationFormula}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={formulaOptions}
                            required={fields.depreciationBasis === 'Formula'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Formula
                        }>
                        <TextInput
                            fullWidth
                            id="depreciationFormula"
                            value={fields.depreciationFormula}
                            disabled={true}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Schedule
                        }>
                        <NumericInput
                            fullWidth
                            label="Number Of Recovery Intervals"
                            id="numberOfRecoveryIntervals"
                            value={numberOfRecoveryIntervals}
                            onChange={handleRecoveryIntervalChange}
                            formMethods={formMethods}
                            required={
                                depreciationBasis === DepreciationBasis.Schedule
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        hidden={
                            depreciationBasis !== DepreciationBasis.Schedule
                        }>
                        <BaseGrid
                            domLayout={'normal'}
                            pagination={false}
                            gridStyle={{
                                height: '400px',
                                width: '100%',
                                overflow: 'scroll',
                            }}
                            rowData={depreciationScheduleWorksheet}
                            displayGrid={true}
                            groupIncludeTotalFooter={true}
                            defaultColDef={{
                                resizable: true,
                                wrapText: true,
                            }}>
                            <AgGridColumn
                                field="interval"
                                headerName="Recovery Interval"
                                {...DefaultColumnTypes.NumberOnly}
                            />
                            <AgGridColumn
                                field="percentage"
                                headerName="Percentage"
                                {...DefaultColumnTypes.Percent}
                                valueFormatter={(
                                    params: ValueFormatterParams
                                ) => {
                                    return percentFormatter(params.value, 3);
                                }}
                                editable={true}
                                aggFunc={(params: IAggFuncParams) => {
                                    return _.sumBy(
                                        params.values,
                                        (value: any) =>
                                            isNaN(value) ? 0 : Number(value)
                                    );
                                }}
                            />
                        </BaseGrid>
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetDepreciationMethodForm;
