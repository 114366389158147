import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFixedAssetsCostCodeForm from './hooks/useFixedAssetsCostCodeForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { ASSET_COST_CODE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';

const FixedAssetsCostCodeForm = () => {
    const { id, type } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingCostCode,
        canCreateAssetCostCode,
        canUpdateAssetCostCode,
        canDeleteAssetCostCode,
    } = useFixedAssetsCostCodeForm(id);

    return (
        <BaseForm
            title={'Fixed Asset Cost Code'}
            formMethods={formMethods}
            isLoading={isLoadingCostCode}
            isNew={type === RoutingValues.create}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            showOnRevision={true}
            fieldMappings={ASSET_COST_CODE_FIELDS}
            entityUuid={fields?.uuid}
            displayCreateButton={canCreateAssetCostCode}
            displayUpdateButton={canUpdateAssetCostCode}
            displayDeleteButton={canDeleteAssetCostCode}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Cost Code"
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label="Sort Order"
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            label="Is Add"
                            control={
                                <Checkbox
                                    id="isAdd"
                                    checked={fields.isAdd}
                                    onChange={handleFieldChange}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetsCostCodeForm;
