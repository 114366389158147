import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface FormContainerProps {
    headerTitle?: any;
    children?: React.ReactNode;
    headerComponent?: React.ReactNode;
    onSubmit?: (event: any) => void;
}

const FormContainer = (props: FormContainerProps) => {
    const submit = (event: any) => {
        event.preventDefault();
        if (props.onSubmit) {
            props.onSubmit(event);
        }
    };

    return (
        <Box
            component="div"
            sx={{ backgroundColor: '#EDEFF0', width: '100%', height: '100%' }}>
            <form onSubmit={(event) => submit(event)}>
                <Box component="div">{props.headerComponent}</Box>
                {props.headerTitle && (
                    <Box
                        component="div"
                        sx={{
                            height: '40px',
                            backgroundColor: '#DCDFE2',
                            width: '100%',
                        }}>
                        <Typography
                            sx={{
                                font: 'normal normal 600 16px/24px Noto Sans',
                                paddingLeft: '15px',
                                paddingTop: '10px',
                            }}>
                            {props.headerTitle}
                        </Typography>
                    </Box>
                )}
            </form>

            <Box component="div" sx={{ padding: '25px', paddingTop: '15px' }}>
                {props.children}
            </Box>
        </Box>
    );
};

export default FormContainer;
