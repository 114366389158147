import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import { dateFormatter } from '../../utils/formattingUtils';
import UpdateIcon from '@mui/icons-material/Update';
import { useGetRevisionsQuery } from '../../services/organizations/organizations.service';
import RevisionItemModal from './RevisionItemModal';
import { useParams } from 'react-router-dom';

const drawerWidth = 400;

interface themeTypes {
    theme: any;
}

const DrawerHeader = styled('div')(({ theme }: themeTypes) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(10, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

interface RevisionHistoryDrawerProps {
    open: boolean;
    setOpen: (_: boolean) => void;
    /** Any mappings to fields to show in the delta */
    fieldMappings: any;
    /** The uuid of the entity you're getting revision data for */
    entityUuid: string;
}

export default function RevisionHistoryDrawer({
    open,
    setOpen,
    fieldMappings,
    entityUuid,
}: RevisionHistoryDrawerProps) {
    const { id } = useParams<any>();
    const [openAuditItem, setOpenAuditItem] = useState(false);
    const [selectedRevision, setSelectedRevision] = useState(null);
    const { data: revisions, isLoading: isLoadingRevisions } =
        useGetRevisionsQuery({
            entityUuid: entityUuid,
            isSummary: true,
        });
    const theme = useTheme();

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpen = (item: any) => {
        setSelectedRevision(item);
        setOpenAuditItem(true);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <RevisionItemModal
                item={selectedRevision}
                open={openAuditItem}
                onClose={() => setOpenAuditItem(false)}
                fieldMappings={fieldMappings}
            />
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}>
                <DrawerHeader>
                    <Typography variant="h6">Revision History</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Timeline position="left">
                    {!isLoadingRevisions && revisions?.length > 0 ? (
                        revisions.map((revision: any, index: number) => (
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="text"
                                size="small"
                                key={revision.id}
                                onClick={(e) => handleOpen(revision)}>
                                <TimelineItem sx={{ width: '100%' }}>
                                    <TimelineOppositeContent
                                        sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {dateFormatter(revision.createdAt)}
                                            <br />
                                            {`${revision?.user?.firstName} ${revision?.user?.lastName}`}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot>
                                            <UpdateIcon />
                                        </TimelineDot>
                                        {index !== revisions.length - 1 && (
                                            <TimelineConnector />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '18px', px: 2 }}>
                                        <Typography>
                                            {revision?.revisionAction?.name}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Button>
                        ))
                    ) : (
                        <>No Revision History Available...</>
                    )}
                </Timeline>
            </Drawer>
        </Box>
    );
}
