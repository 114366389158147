import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    ItemChemicalComposition,
    ItemMaster,
    ItemProperty,
} from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { useListUnitOfMeasureDropdownQuery } from '../../../../../services/organizations/organizations.service';
import { useGetActiveAttributeTemplatesQuery } from '../../../../../services/attributes/attributes.service';
import { isNilOrEmpty } from '../../../../../utils/objectUtils';
import { useGetItemStatusesForDropdownQuery } from '../../../../../services/formulation/itemMaster/itemStatus/itemStatus.service';
import { useGetInventoryTypesForDropdownQuery } from '../../../../../services/formulation/itemMaster/inventoryType/inventoryType.services';
import {
    useApplyTemplateMutation,
    useCreateItemMasterMutation,
    useDeleteItemMasterMutation,
    useGetItemMasterQuery,
    useUpdateItemAttributeMutation,
    useUpdateItemMasterMutation,
} from '../../../../../services/formulation/itemMaster/item/itemMaster.service';
import { RoutingValues } from '../../../../../types/Shared.types';
import { ChildEditableGridProps } from '../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ChildFormProps } from '../../../../form/helpers/ChildFormProps';
import { ExtendedFormProps } from '../../../../form/helpers/ExtendedFormProps';
import useCopyItemMasterForm from './useCopyItemMasterForm';
import useChildFormHelper from '../../../../form/hooks/useChildFormHelper';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const useItemMasterForm = (itemMasterId: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetItemStatusesForDropdownQuery();
    const { data: inventoryTypesOptions, isLoading: isLoadingInventoryTypes } =
        useGetInventoryTypesForDropdownQuery();
    const { data: itemMasterRecord, isLoading: isLoadingItemMaster } =
        useGetItemMasterQuery(
            itemMasterId === RoutingValues.newId ? skipToken : itemMasterId
        );

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const [isAttributeValid, setIsAttributeValid] = useState(null);

    const { data: activeTemplates, isLoading: isLoadingTemplates } =
        useGetActiveAttributeTemplatesQuery('1');

    const [itemAttrValues, setItemAttrValues] = useState({});
    const [attrFieldsDisabled, setAttrFieldsDisabled] = useState(true);
    const [formItemDefs, setFormItemDefs] = useState(null);

    const initialState: any = {
        attrTemplateId: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const [createItemMaster] = useCreateItemMasterMutation();
    const [updateItemMaster] = useUpdateItemMasterMutation();
    const [deleteItemMaster] = useDeleteItemMasterMutation();

    const [applyTemplate] = useApplyTemplateMutation();
    const [updateItemAttribute] = useUpdateItemAttributeMutation();

    const [updatedItemRecord, setUpdatedItemRecord] = useState(null);

    const canCreateItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.CREATE
    );

    const canUpdateItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.EDIT
    );

    const canDeleteItemMaster = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.DELETE
    );

    const disableDropdown = (viewPermissionValue: boolean) => {
        if (viewPermissionValue) {
            return itemMasterId && !canUpdateItemMaster;
        }
        return true;
    };

    const blankItemMaster: ItemMaster = {
        itemId: '',
        itemName: '',
        version: null,
        description1: '',
        description2: '',
        itemStatusId: null,
        inventoryTypeId: null,
        upc: '',
        cost: null,
        businessEntityId: user?.businessEntity?.id,
        itemProperties: [] as ItemProperty[],
        chemicalComposition: [] as ItemChemicalComposition[],
        itemUnitOfMeasure: {
            stockUnitId: null as bigint,
            weightUnitId: null as bigint,
            volumeUnitId: null as bigint,
            specificGravity: null as number,
            density: null as number,
            stockWeight: null as number,
            stockVolume: null as number,
        },
        itemProperShippingName: {
            properShippingNameId: null as bigint,
            rqRequirement: null as number,
            rqUom: null as bigint,
            marinePollutant: false,
            freightClass: '',
            nmfc: '',
        },
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.ITEM_MASTER_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        if (!isNilOrEmpty(itemMasterRecord)) {
            //if the item record already has an item profile
            //then a template has already been applied
            //we can enable the fields and disable the template selection
            if (!isNilOrEmpty(itemMasterRecord?.itemProfile)) {
                setAttrFieldsDisabled(false);
            }
            setUpdatedItemRecord({
                ...itemMasterRecord,
                attrTemplateId:
                    itemMasterRecord?.itemProfile[0]?.attrTemplateId,
            });
            //if we already have a template applied
            if (!isNilOrEmpty(itemMasterRecord?.itemProfile)) {
                const formDefs =
                    itemMasterRecord?.itemProfile[0]?.attrTemplate
                        ?.attrDefinitions;
                setFormItemDefs(formDefs);
            }
        }
    }, [itemMasterRecord]);

    const { childrenFormHooks, allChildrenFormMethods, registerChildForm } =
        useChildFormHelper();

    const {
        fields,
        formMethods,
        hasEntityChanges,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        copyForm,
        formType,
        setValues,
        handleChildrenRecords,
        handleChildRecord,
        childForms,
    } = useBaseForm({
        closePath: '/formulation/itemMaster',
        copyPath: `/formulation/itemMaster/${RoutingValues.newId}/${RoutingValues.copy}`,
        blankEntity: blankItemMaster,
        activeEntity: updatedItemRecord,
        childForms: childrenFormHooks,
        onlyUpdateEntityWithChanges: true,
        getDescription: () => {
            return `Item Master ${fields.itemId}`;
        },
        createEntity: () => {
            return createItemMaster({ postBody: fields });
        },
        afterCreate: async (response: any) => {
            if (fields?.itemAttributes) {
                for (const attr of fields?.itemAttributes) {
                    //@ts-ignore
                    attr.data = itemAttrValues[attr.attrDefinitionId];
                }
            }

            if (fields?.attrTemplateId && !attrFieldsDisabled) {
                const args = {
                    id: fields?.attrTemplateId,
                    postBody: {
                        itemProfile: {
                            itemId: response?.id,
                            attrTemplateId: fields?.attrTemplateId,
                        },
                        itemAttributes:
                            fields?.itemAttributes || itemAttrValues,
                        inCopy: formType === RoutingValues.copy,
                    },
                };
                //@ts-ignore
                const { itemProfile, attrDefinitions } = await applyTemplate(
                    args
                );
            }
        },
        updateEntity: () => {
            const args = {
                id: itemMasterId,
                postBody: {
                    itemProfileId: itemMasterRecord?.itemProfile[0]?.id,
                    itemAttributes: itemAttrValues,
                },
            };
            updateItemAttribute(args);
            return updateItemMaster({
                id: itemMasterId as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteItemMaster(itemMasterId);
        },
    });

    const handleApplyTemplate = async () => {
        setAttrFieldsDisabled(false);
        //if we're in an update
        if (!isNilOrEmpty(itemMasterId)) {
            //we have a template selected
            if (fields?.attrTemplateId) {
                const args = {
                    id: fields?.attrTemplateId,
                    postBody: {
                        itemProfile: {
                            itemId: itemMasterId,
                            attrTemplateId: fields?.attrTemplateId,
                        },
                        itemAttributes: itemAttrValues,
                    },
                };
                //@ts-ignore
                const { itemProfile, attrDefinitions } = await applyTemplate(
                    args
                );
                setFormItemDefs(attrDefinitions);
            }
        }
    };

    const parentData = {
        currentParentRecord: fields,
        isParentLoading: isLoadingItemMaster,
        user: user,
    };

    const editableGridProps: ChildEditableGridProps<ItemMaster> = {
        parentData,
        displayGridButtons: {
            showAddButton:
                (canCreateItemMaster && canUpdateItemMaster) ||
                (canCreateItemMaster && !itemMasterId),
            showDeleteButton: canDeleteItemMaster,
            showSortButton:
                (canCreateItemMaster && !itemMasterId) ||
                (canUpdateItemMaster && !!itemMasterId),
            isGridEditable:
                (canCreateItemMaster && !itemMasterId) ||
                (canUpdateItemMaster && !!itemMasterId),
        },
        helpers: {
            handleChildrenRecords,
        },
    };

    const childFormProps: ChildFormProps<ItemMaster> = {
        parentData: {
            ...parentData,
            dataFromQuery: itemMasterRecord,
        },
        permissions: {
            canCreate: canCreateItemMaster,
            canUpdate: canUpdateItemMaster,
        },
        helpers: {
            handleChildRecord,
            registerChildForm,
            disableDropdown,
        },
    };

    const extendedFormProps: ExtendedFormProps<ItemMaster> = {
        parentData,
        helpers: {
            formMethods,
            handleFieldChange,
        },
        permissions: {
            readOnly: !!itemMasterId,
        },
    };

    const { copyMethods } = useCopyItemMasterForm({
        fields,
        dataFromQuery: itemMasterRecord,
        setValues,
        formType,
    });

    return {
        fields,
        formMethods,
        hasEntityChanges,
        allFormMethods: [formMethods, ...allChildrenFormMethods],
        childForms,
        statusOptions,
        inventoryTypesOptions,
        handleFieldChange,
        closeForm,
        onCreate,
        onUpdate,
        onDelete,
        isLoadingItemMaster,
        isLoadingStatuses,
        isLoadingInventoryTypes,
        canCreateItemMaster,
        canUpdateItemMaster,
        canDeleteItemMaster,
        setDropdownOptions,
        dropdownOptions,
        disableDropdown,
        handleChildrenRecords,
        activeTemplates,
        isLoadingTemplates,
        itemAttrValues,
        setItemAttrValues,
        handleApplyTemplate,
        attrFieldsDisabled,
        formItemDefs,
        setIsAttributeValid,
        termSet,
        showAddButton:
            (canCreateItemMaster && canUpdateItemMaster) ||
            (canCreateItemMaster && !itemMasterId),
        showDeleteButton: canDeleteItemMaster,
        isGridEditable:
            (canCreateItemMaster && !itemMasterId) ||
            (canUpdateItemMaster && !!itemMasterId),
        /** All display/permissions **/
        display: {
            canCreateItemMaster,
            canUpdateItemMaster,
            canDeleteItemMaster,
            readOnly: itemMasterId && !canUpdateItemMaster,
        },
        /** editable grids, child form and extended form methods **/
        children: {
            editableGridProps,
            childFormProps,
            extendedFormProps,
        },
        /** Copy functionality**/
        copyForm,
        copyMethods,
        unitOfMeasureOptions,
        isLoadingUnitOfMeasure,
    };
};

export default useItemMasterForm;
