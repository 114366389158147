import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection6_1Form from './subSections/subSection6_1/SubSection6_1Form';
import SubSection6_2Form from './subSections/subSection6_2/SubSection6_2Form';
import SubSection6_3Form from './subSections/subSection6_3/SubSection6_3Form';
import SubSection6_4Form from './subSections/subSection6_4/SubSection6_4Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

export interface sdsAccidentalReleaseFormProps {
    sdsId: string;
    isLoadingSdsRecord: boolean;
    addSectionToParent: Function;
    isFieldDisabled: boolean;
}

const SdsAccidentalReleaseForm = (
    props: SdsBlobSectionFormProps<SdsHeader>
) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Accidental_Release}
                                />
                            ) as unknown as string
                        }>
                        <SubSection6_1Form {...props} termSet={props.termSet} />
                        <SubSection6_2Form {...props} termSet={props.termSet} />
                        <SubSection6_3Form {...props} termSet={props.termSet} />
                        <SubSection6_4Form {...props} termSet={props.termSet} />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsAccidentalReleaseForm;
