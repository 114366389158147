import React from 'react';
import SequenceBase from '../../../../components/sequences/SequenceBase';
import {
    useGetRDFormulaHeaderSequenceParametersQuery,
    useGetRDFormulaHeaderSequenceQuery,
    useListRDFormulaHeaderSequenceParentsQuery,
    useUpdateRDFormulaHeaderSequenceMutation,
} from '../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';

const RDFormulaSequences = () => {
    return (
        <SequenceBase
            sequenceQuery={useGetRDFormulaHeaderSequenceQuery}
            sequenceMutation={useUpdateRDFormulaHeaderSequenceMutation}
            parameterQuery={useGetRDFormulaHeaderSequenceParametersQuery}
            useParent={true}
            parentQuery={useListRDFormulaHeaderSequenceParentsQuery}
            title={'RDFormula'}
            closePath={`/formulation/rndFormula`}
        />
    );
};

export default RDFormulaSequences;
