import React from 'react';
import UseFixedAssetDepreciationMethodTypeGrid from './hooks/useFixedAssetDepreciationMethodTypeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { cloneDeep } from 'lodash';

const FixedAssetDepreciationMethodTypeGrid = () => {
    const {
        typeList,
        redirectToForm,
        isLoadingTypes,
        canCreateDepreciationMethodType,
        canViewDepreciationMethodType,
        handleGridEdits,
    } = UseFixedAssetDepreciationMethodTypeGrid();

    const typeColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'postsJournalEntries',
            headerName: 'Journal Posting',
            ...DefaultColumnTypes.Boolean,
        },
        {
            field: 'predefinedSchedule',
            headerName: 'Predefined Schedule',
            ...DefaultColumnTypes.Boolean,
        },
    ];

    return (
        <BaseFormGrid
            title="Depreciation Method Types"
            displayGrid={canViewDepreciationMethodType}
            isLoading={isLoadingTypes}
            rowData={cloneDeep(typeList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={typeColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateDepreciationMethodType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetDepreciationMethodTypeGrid;
