import React from 'react';
import NumericInput, { NumericInputProps } from '../NumericInput/NumericInput';

export type CurrencyInputProps = {
    currency?: string;
} & NumericInputProps;

const CurrencyInput = ({ numDecimals, ...props }: CurrencyInputProps) => {
    return <NumericInput {...props} numDecimals={numDecimals || 2} />;
};

export default CurrencyInput;
