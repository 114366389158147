import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import NumericInput from '../../../../components/form/formInputs/NumericInput/NumericInput';
import useSafetyDataSheetSectionHeaderForm, {
    SafetyDataSheetSectionHeaderFormProps,
} from './hooks/useSafetyDataSheetSectionHeaderForm';
import { SdsSectionHeadersFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

const SafetyDataSheetSectionHeaderForm = ({
    id,
    headerId,
    localeGroupId,
    currentLangId,
    defaultLangId,
    section,
    subSection,
    subSectionLetter,
    showOnSds,
    localeGroupOptions,
    allowDelete,
    handleSetPreviousTab,
    safetyDataSheetSectionHeaderKeyList,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: SafetyDataSheetSectionHeaderFormProps) => {
    const {
        fields,
        formMethods,
        isLoadingSafetyDataSheetSectionHeader,
        handleFieldChange,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        handleHeaderKeyOnBlur,
        canCreateSdsHeader,
        canEditSdsHeader,
        canDeleteSdsHeader,
        termSet,
    } = useSafetyDataSheetSectionHeaderForm({
        id,
        headerId,
        localeGroupId,
        currentLangId,
        defaultLangId,
        section,
        subSection,
        subSectionLetter,
        showOnSds,
        localeGroupOptions,
        allowDelete,
        handleSetPreviousTab,
        safetyDataSheetSectionHeaderKeyList,
        handleSetIsFormDirty,
        cancelNewLanguage,
        setDisableLangButton,
    });

    return (
        <BaseForm
            title={''}
            formMethods={formMethods}
            isLoading={isLoadingSafetyDataSheetSectionHeader}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={handleCancelForm}
            displayCreateButton={canCreateSdsHeader}
            displayUpdateButton={canEditSdsHeader}
            displayDeleteButton={allowDelete && canDeleteSdsHeader}
            readOnly={!id ? !canCreateSdsHeader : !canEditSdsHeader}>
            <FormPartial>
                <Grid container flexDirection="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Header_ID
                                    }
                                />
                            }
                            id="headerId"
                            value={fields.headerId}
                            disabled={!!id || !!headerId}
                            required={true}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                            maxLength={10}
                            onBlur={handleHeaderKeyOnBlur}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Locale_Group
                                    }
                                />
                            }
                            id="localeGroupId"
                            required={true}
                            value={
                                fields.localeGroupId == null
                                    ? ''
                                    : fields.localeGroupId
                            }
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            options={localeGroupOptions}
                            disabled={!!id || !!headerId}
                            onBlur={handleHeaderKeyOnBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            id="sectionText"
                            value={fields.sectionText}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Section_Text
                                    }
                                />
                            }
                            maxLength={255}
                            multiline={true}
                            rows={3}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={SdsSectionHeadersFormDefs.Section}
                                />
                            }
                            id="section"
                            maxValue={999}
                            greaterThan={0}
                            formMethods={formMethods}
                            value={
                                fields.section === 0
                                    ? fields.section.toString()
                                    : fields.section
                            }
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <NumericInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Sub_Section
                                    }
                                />
                            }
                            id="subSection"
                            maxValue={999}
                            greaterThan={0}
                            formMethods={formMethods}
                            value={
                                fields.subSection === 0
                                    ? fields.subSection.toString()
                                    : fields.subSection
                            }
                            onChange={handleFieldChange}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput
                            fullWidth
                            id="subSectionLetter"
                            value={fields.subSectionLetter}
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Sub_Section_Letter
                                    }
                                />
                            }
                            maxLength={4}
                            formMethods={formMethods}
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControlLabel
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        SdsSectionHeadersFormDefs.Show_Header_On_SDS
                                    }
                                />
                            }
                            control={
                                <Checkbox
                                    id="showOnSds"
                                    checked={fields.showOnSds}
                                    onChange={handleFieldChange}
                                    required={true}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default SafetyDataSheetSectionHeaderForm;
