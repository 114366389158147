import { RouteProps } from './routesConfig';
import CasMaster from '../containers/formulation/cas/CasMaster';
import CasForm from '../containers/formulation/cas/form/CasForm';
import ItemMaster from '../containers/formulation/itemMaster/ItemMaster';
import ItemMasterForm from '../containers/formulation/itemMaster/form/ItemMasterForm';
import FormulaProperty from '../containers/formulation/formulaProperty/FormulaProperty';
import FormulaPropertyForm from '../containers/formulation/formulaProperty/form/FormulaPropertyForm';
import GhsHazardPhrases from '../containers/formulation/ghsHazardPhrases/GhsHazardPhrases';
import GhsHazardPhraseShowForm from '../containers/formulation/ghsHazardPhrases/GhsHazardPhraseShowForm';
import GhsPrecautionaryPhrase from '../containers/formulation/ghsPrecautionaryPhrases/GhsPrecautionaryPhrase';
import GhsPrecautionaryPhraseShowForm from '../containers/formulation/ghsPrecautionaryPhrases/GhsPrecautionaryPhraseShowForm';
import Regulation from '../containers/formulation/regulation/Regulation';
import RegulationForm from '../containers/formulation/regulation/form/RegulationForm';
import ToxicTest from '../containers/formulation/toxicTest/ToxicTest';
import ToxicTestForm from '../containers/formulation/toxicTest/form/ToxicTestForm';
import ToxicSpecies from '../containers/formulation/toxicSpecies/ToxicSpecies';
import ToxicSpeciesForm from '../containers/formulation/toxicSpecies/form/ToxicSpeciesForm';
import ExposureAgency from '../containers/formulation/exposureAgency/ExposureAgency';
import ExposureAgencyForm from '../containers/formulation/exposureAgency/form/ExposureAgencyForm';
import SafetyDataSheetSectionHeader from '../containers/formulation/safetyDataSheetSectionHeader/SafetyDataSheetSectionHeader';
import SafetyDataSheetSectionHeaderShowForm from '../containers/formulation/safetyDataSheetSectionHeader/SafetyDataSheetSectionHeaderShowForm';
import LocaleGroup from '../containers/formulation/localeGroup/LocaleGroup';
import LocaleGroupForm from '../containers/formulation/localeGroup/form/LocaleGroupForm';
import RDFormula from '../containers/formulation/rdFormula/RDFormula';
import Locale from '../containers/formulation/locale/Locale';
import SafetyDataSheet from '../containers/formulation/sds/SafetyDataSheet';
import SdsForm from '../containers/formulation/sds/form/SdsForm';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';
import RDFormulaForm from '../containers/formulation/rdFormula/form/RDFormulaForm';
import Sequences from '../containers/formulation/sequences/Sequences';
import HazardClassificationMapping from '../containers/formulation/hazardClasificationMapping/HazardClassificationMapping';

const FormulationRoutes: RouteProps[] = [
    {
        path: '/formulation/RDFormula',
        component: RDFormula,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/casMaster',
        component: CasMaster,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/casMaster/new',
        component: CasForm,
    },
    {
        path: '/formulation/casMaster/:id',
        component: CasForm,
    },
    {
        path: '/formulation/itemMaster',
        component: ItemMaster,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/itemMaster/new',
        component: ItemMasterForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/itemMaster/:id',
        component: ItemMasterForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases',
        component: GhsHazardPhrases,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases/new',
        component: GhsHazardPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsHazardPhrases/:phraseId',
        component: GhsHazardPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaProperties',
        component: FormulaProperty,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/formulaProperties/new',
        component: FormulaPropertyForm,
    },
    {
        path: '/formulation/formulaProperties/:id',
        component: FormulaPropertyForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases',
        component: GhsPrecautionaryPhrase,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases/new',
        component: GhsPrecautionaryPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/ghsPrecautionaryPhrases/:phraseId',
        component: GhsPrecautionaryPhraseShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/regulations',
        component: Regulation,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/regulations/new',
        component: RegulationForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/regulations/:id',
        component: RegulationForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicSpecies',
        component: ToxicSpecies,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicSpecies/new',
        component: ToxicSpeciesForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicSpecies/:id',
        component: ToxicSpeciesForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicTest',
        component: ToxicTest,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicTest/new',
        component: ToxicTestForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/toxicTest/:id',
        component: ToxicTestForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/exposureAgency',
        component: ExposureAgency,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/exposureAgency/new',
        component: ExposureAgencyForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/exposureAgency/:id',
        component: ExposureAgencyForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader',
        component: SafetyDataSheetSectionHeader,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader/new',
        component: SafetyDataSheetSectionHeaderShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sdsSectionHeader/:headerId/:localeGroupId',
        component: SafetyDataSheetSectionHeaderShowForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup',
        component: LocaleGroup,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup/new',
        component: LocaleGroupForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/localeGroup/:id',
        component: LocaleGroupForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula',
        component: RDFormula,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula/new',
        component: RDFormulaForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/rndFormula/:id',
        component: RDFormulaForm,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/locale',
        component: Locale,
        featureFlag: 'dashboard.nav.apps.formulation',
    },
    {
        path: '/formulation/sds',
        component: SafetyDataSheet,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
    },
    {
        path: '/formulation/sds/new',
        component: SdsForm,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
    },
    {
        path: '/formulation/sds/:id',
        component: SdsForm,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
    },
    {
        path: '/formulation/sequences',
        component: Sequences,
        featureFlag: 'dashboard.nav.apps.formulation',
        permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
    },
    {
        path: '/formulation/hazardClassMapping',
        component: HazardClassificationMapping,
        featureFlag: 'dashboard.nav.apps.formulation',
        //permissionKey: PERMISSIONS.FORMULATION.SDS_HEADER.VIEW,
    },
];

export default FormulationRoutes;
