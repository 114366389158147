import AccountFunctionCodeGrid from '../containers/accounting/AccountFunctionCodeGrid';
import AccountFunctionCodeForm from '../containers/accounting/AccountFunctionCodeForm';

import { RouteProps } from './routesConfig';
import { PERMISSIONS } from '../constants/permissions/Permissions.constants';

const AccountingRoutes: RouteProps[] = [
    {
        path: '/accounting/functionCode/:group',
        component: AccountFunctionCodeGrid,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
    {
        path: '/accounting/functionCode/:group/:id',
        component: AccountFunctionCodeForm,
        permissionKey: PERMISSIONS.ACCOUNTING.FUNCTION_CODES.VIEW,
        featureFlag: 'dashboard.nav.apps.accounting',
    },
];

export default AccountingRoutes;
