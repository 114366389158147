import React from 'react';
import useRDFormulaComponentsGrid from './hooks/useRDFormulaComponentsGrid';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { RDFormula } from '../../../../../../types/formulation';
import { isNilOrEmpty } from '../../../../../../utils/objectUtils';

const RDFormulaComponentsGrid = (props: ChildEditableGridProps<RDFormula>) => {
    const {
        isLoadingUnitOfMeasure,
        isLoadingComponentTypes,
        isLoadingBoilerPlateNotes,
        isLoadingItems,
        formulaComponentColumnDefs,
        formulaComponents,
        handleGridEdits,
        handleColumnChanged,
        handleRowValidations,
        colDefOptions,
    } = useRDFormulaComponentsGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, isGridEditable } = props.displayGridButtons;

    return isLoadingUnitOfMeasure ||
        isLoadingComponentTypes ||
        isLoadingBoilerPlateNotes ||
        isLoadingItems ||
        isNilOrEmpty(formulaComponentColumnDefs) ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                displayCreateNewButton={showAddButton}
                rowData={formulaComponents || []}
                dataKey={'rowId'}
                defaultColDef={colDefOptions}
                gridClass="full-size-item"
                displayToolbar={true}
                sizeColumnsToFit={true}
                onHandleGridEdits={handleGridEdits}
                isEditable={isGridEditable}
                onHandleRowValidations={handleRowValidations}
                onHandleColumnChanged={handleColumnChanged}
                columnDefs={formulaComponentColumnDefs}
                newRowPlaceholderValues={{
                    stepNo: 'Enter Step No...',
                    componentCode: 'Enter Component...',
                    componentTypeId: 'Enter Type...',
                    formulaQty: 'Enter Qty...',
                    unitCode: 'Enter Component Unit...',
                    uomCost: 'Enter Component Unit Cost...',
                    lockQty: 'Lock Qty?',
                    boilerPlateNoteCode: 'Enter Boiler Plate Note...',
                    componentUomCode: 'Enter UOM...',
                    stockUomCode: 'Enter Stock UOM...',
                    stockCost: 'Enter Stock Cost...',
                    scrapPercent: 'Enter Scrap %...',
                }}></BaseGrid>
        </>
    );
};

export default RDFormulaComponentsGrid;
