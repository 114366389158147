import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/grids/Grid';
import DatacorLogoSpinner from '../../components/datacorLogoSpinner/DatacorLogoSpinner';
import useGroupsGrid from './hooks/useGroupsGrid';
import { GroupsGridDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import CustomHeader from '../../components/grids/CustomHeader';
import { dateValueComparator } from '../../utils/comparisonUtils';
import { dateFormatter } from '../../utils/formattingUtils';

const GroupsGrid = () => {
    const {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        onSortChanged,
        onFirstDataRendered,
        termSet,
    } = useGroupsGrid();

    return (
        <>
            {!isLoading ? (
                <AgGrid
                    testId="groups-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={rowData}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onFirstDataRendered={onFirstDataRendered}
                    onSortChanged={onSortChanged}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        floatingFilter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        filter={'agTextColumnFilter'}
                        field="name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={GroupsGridDefs.Group_Name}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="description"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={
                                        GroupsGridDefs.Groups_Grid_Description
                                    }
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agTextColumnFilter'}
                        field="businessEntity.name"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={GroupsGridDefs.Business_Entity}
                                />
                            );
                        }}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="createdAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={GroupsGridDefs.Date_Created}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                    <AgGridColumn
                        minWidth={200}
                        filter={'agDateColumnFilter'}
                        filterParams={dateValueComparator}
                        field="updatedAt"
                        headerComponentFramework={(props: any) => {
                            return (
                                <CustomHeader
                                    {...props}
                                    termSet={termSet}
                                    termKey={GroupsGridDefs.Last_Update_Date}
                                />
                            );
                        }}
                        valueFormatter={dateFormatter}
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default GroupsGrid;
