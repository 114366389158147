import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useListExposureAgencyQuery } from '../../../../services/formulation/exposureAgency/exposureAgency.service';
import SettingsContext from '../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';

const useExposureAgencyGrid = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { isLoading, data: exposureAgencyList } =
        useListExposureAgencyQuery();

    const canCreateExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.CREATE
    );
    const canViewExposureAgency = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.EXPOSURE_AGENCIES.VIEW
    );

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.EXPOSURE_AGENCIES_GRID,
              }
            : skipToken
    );

    const redirectToForm = (row: any) => {
        if (row && row.id) {
            navigate(`/formulation/exposureAgency/${row.id}`);
        } else {
            navigate('/formulation/exposureAgency/new');
        }
    };

    return {
        isLoading,
        exposureAgencyList,
        redirectToForm,
        canCreateExposureAgency,
        canViewExposureAgency,
        termSet,
    };
};

export default useExposureAgencyGrid;
