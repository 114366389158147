import React, { useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FormMethods } from '../../../../libs/hooksLib';
import { RequiredValidator } from '../../../../validators/required.validator';
import { isNil } from '../../../../utils/objectUtils';
import _ from 'lodash';

export type InputBaseProps = {
    formMethods?: FormMethods;
} & TextFieldProps;

const InputBase = ({ formMethods, ...props }: InputBaseProps) => {
    useEffect(() => {
        if (props.required) {
            formMethods?.addValidator(
                props.id,
                _.isObject(props.label) ? (
                    <>{props.label}</>
                ) : (
                    props.label.toString()
                ),
                RequiredValidator
            );
        } else {
            formMethods?.removeValidator(props.id, RequiredValidator);
        }
    }, [props.required]);

    const getHelperTextElements = (validationErrors: any[]) => {
        if (!validationErrors) {
            return;
        }

        return validationErrors.reduce((result, item) => (
            <>
                {result}
                <br></br>
                {item}
            </>
        ));
    };

    return (
        <TextField
            {...props}
            value={_.isNil(props?.value) ? '' : props?.value}
            error={
                formMethods?.isFormSubmitted &&
                !isNil(formMethods?.fieldValidationErrors?.get(props.id))
            }
            helperText={
                formMethods?.isFormSubmitted ? (
                    <>
                        {getHelperTextElements(
                            formMethods?.fieldValidationErrors?.get(props.id)
                        )}
                    </>
                ) : null
            }
        />
    );
};

export default InputBase;
