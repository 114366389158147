import { useNavigate } from 'react-router-dom';
import { useGetAllGeneralLedgerAccountsQuery } from '../../../services/accounting/accounting.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';

export const UseGeneralLedgerAccountGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: generalLedgerAccountList, isLoading } =
        useGetAllGeneralLedgerAccountsQuery();

    const canCreateChartOfAccounts = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.CREATE
    );

    const canViewChartOfAccounts = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.CHART_OF_ACCOUNTS.VIEW
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/generalLedgerAccount/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/generalLedgerAccount/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    return {
        generalLedgerAccountList,
        isLoading,
        redirectToForm,
        canCreateChartOfAccounts,
        canViewChartOfAccounts,
    };
};
