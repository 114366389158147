import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { clearModalConfirmBasic } from '../../store/uiElements';
import { SharedComponentsDefs } from '../../constants/i18n/translations/termDefinitions/platform';
import TranslatableText from '../i18n/TranslatableText';
import SettingsContext from '../../contexts/settings.context';
import { useGetTermSetQuery } from '../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../constants/i18n/translations/termSetDefinitions/platform';
import { skipToken } from '@reduxjs/toolkit/query';

const ModalConfirm = () => {
    const dispatch = useDispatch();
    const { modalConfirmBasic } = useSelector(
        (state: RootState) => state.uiElements
    );

    function handleClose() {
        if (modalConfirmBasic.onCancel) {
            modalConfirmBasic.onCancel();
        }
        dispatch(clearModalConfirmBasic());
    }
    function handleConfirm() {
        dispatch(clearModalConfirmBasic());
        modalConfirmBasic.onConfirm();
    }

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );

    return (
        <>
            <Dialog
                open={modalConfirmBasic.isOpen}
                onClose={handleClose}
                maxWidth={'lg'}>
                <DialogTitle id="alert-dialog-title">
                    {modalConfirmBasic.title ?? ''}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalConfirmBasic.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <TranslatableText
                            termSet={termSet}
                            termKey={SharedComponentsDefs.Modal_Cancel}
                        />
                    </Button>
                    <Button onClick={handleConfirm} autoFocus>
                        <TranslatableText
                            termSet={termSet}
                            termKey={SharedComponentsDefs.Modal_Ok}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalConfirm;
