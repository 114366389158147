import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import SelectInput from '../../../../components/form/formInputs/SelectInput/SelectInput';
import useRegulationForm from './hooks/useRegulationForm';
import { RegulationsFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = { id: string };

const RegulationForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        fields,
        formMethods,
        isLoadingRegulation,
        isLoadingRegulationBodyOptions,
        isLoadingRegulationSectionOptions,
        isLoadingStateOptions,
        regulationSectionOptions,
        regulationBodyOptions,
        stateOptions,
        closeForm,
        handleFieldChange,
        onUpdate,
        onDelete,
        onCreate,
        canCreateRegulation,
        canUpdateRegulation,
        canDeleteRegulation,
        termSet,
    } = useRegulationForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={RegulationsFormDefs.Regulations}
                />
            }
            formMethods={formMethods}
            isLoading={isLoadingRegulation}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateRegulation}
            displayUpdateButton={canUpdateRegulation}
            displayDeleteButton={canDeleteRegulation}
            readOnly={id && !canUpdateRegulation}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RegulationsFormDefs.Regulation_Code
                                    }
                                />
                            }
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={25}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RegulationsFormDefs.Regulation_Title
                                    }
                                />
                            }
                            id="title"
                            formMethods={formMethods}
                            value={fields.title}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        RegulationsFormDefs.Regulation_Body
                                    }
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateRegulation
                                    : true,
                                readOnly: id && !canUpdateRegulation,
                            }}
                            id="regulationBodyId"
                            formMethods={formMethods}
                            value={fields.regulationBodyId}
                            onChange={handleFieldChange}
                            options={regulationBodyOptions}
                            disabled={isLoadingRegulationBodyOptions}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RegulationsFormDefs.Section}
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateRegulation
                                    : true,
                                readOnly: id && !canUpdateRegulation,
                            }}
                            id="regulationSectionId"
                            formMethods={formMethods}
                            value={fields.regulationSectionId}
                            onChange={handleFieldChange}
                            options={regulationSectionOptions}
                            disabled={isLoadingRegulationSectionOptions}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={RegulationsFormDefs.State}
                                />
                            }
                            SelectProps={{
                                showClearButton: id
                                    ? canUpdateRegulation
                                    : true,
                                readOnly: id && !canUpdateRegulation,
                            }}
                            id="stateCodeId"
                            formMethods={formMethods}
                            value={fields.stateCodeId}
                            onChange={handleFieldChange}
                            options={stateOptions}
                            disabled={isLoadingStateOptions}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default RegulationForm;
