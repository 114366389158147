import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import BaseGrid from './BaseGrid';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BaseGridSortModal = (props: any) => {
    const { open, setOpenSortGrid, parentProps, gridRef } = props;

    const [sortRowData, setSortRowData] = useState([]);

    const handleClose = () => {
        setOpenSortGrid(false);
    };

    useEffect(() => {
        setSortRowData(
            parentProps.rowData.filter(
                (row: any) => row.rowStatus !== 'deleted'
            )
        );
    }, [JSON.stringify(parentProps.rowData)]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth>
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Sort Grid</Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <BaseGrid
                    {...parentProps}
                    rowData={sortRowData || []}
                    columnDefs={[
                        {
                            field: 'dragRow',
                            headerName: '',
                            rowDrag: true,
                            filter: false,
                            sortable: false,
                            minWidth: 50,
                            maxWidth: 50,
                        },
                        ...parentProps?.columnDefs
                            ?.map((col: any) => {
                                return {
                                    ...col,
                                    filter: false,
                                    editable: false,
                                };
                            })
                            /** removing the delete column from the modal grid  **/
                            .filter((col: any) => col.field !== 'deleteColumn'),
                    ]}
                    filter={false}
                    displayCreateNewButton={false}
                    useRowDrag={true}
                    displaySortButton={false}
                    animateRows={true}
                    pagination={false}
                />
            </DialogContent>
        </Dialog>
    );
};

export default BaseGridSortModal;
