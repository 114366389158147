import {
    SdsRegulation,
    Regulation,
    SdsHeader,
    CasMaster,
} from '../../../../../../../../../types/formulation';
import React, { useContext, useEffect, useState } from 'react';
import { PermissionsUtil } from '../../../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../../../constants/permissions/Permissions.constants';
import { GridOptions } from '../../../../../../../../../components/grids/Grid.constants';
import { isNilOrEmpty } from '../../../../../../../../../utils/objectUtils';
import { RowStatus } from '../../../../../../../../../components/grids/hooks/useBaseGridEditable';
import _, { cloneDeep } from 'lodash';
import {
    CountryCode,
    useGetCountriesQuery,
    useGetStatesQuery,
} from '../../../../../../../../../services/organizations/organizations.service';
import DefaultColumnTypes from '../../../../../../../../../components/grids/columns/Column.constants';
import { editableGridCellSelect } from '../../../../../../../../../components/grids/columns/editableGridCellSelect';
import { ColumnMaxValueValidator } from '../../../../../../../../grid/validators/columnMaxValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../../../grid/validators/columnNumericValue.validator';
import applyEditableGridValidations from '../../../../../../../../grid/utils/applyEditableGridValidations';
import { ColumnRequiredValueValidator } from '../../../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnMinValueValidator } from '../../../../../../../../grid/validators/columnMinValue.validator';
import { useListRegulationsQuery } from '../../../../../../../../../services/formulation/regulations/regulations.service';
import { useGetRegulationSectionsForDropDownQuery } from '../../../../../../../../../services/formulation/regulations/section/regulationSection.service';
import { useGetRegulationBodiesForDropDownQuery } from '../../../../../../../../../services/formulation/regulations/body/regulationBody.service';
import {
    SelectedDropdownOption,
    SelectionOption,
} from '../../../../../../../../../types/Shared.types';
import { useListCasQuery } from '../../../../../../../../../services/formulation/casMaster/cas/casMaster.service';
import { ValueGetterParams } from 'ag-grid-community';
import { ChildEditableGridProps } from '../../../../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import {
    getStateCodeById,
    StateCodeDisplay,
} from '../../../../../../../shared/utilities/formulation.utils';
import SettingsContext from '../../../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import CustomHeader from '../../../../../../../../../components/grids/CustomHeader';
import { SDSFormDefs } from '../../../../../../../../../constants/i18n/translations/termDefinitions/formulation';

const useSdsRegulationsGrid = (props: ChildEditableGridProps<SdsHeader>) => {
    const { currentParentRecord, user } = props.parentData;

    const { showDeleteButton, isGridEditable } = props.displayGridButtons;

    const { handleChildrenRecords } = props.helpers;

    const [maxRowId, setMaxRowId] = useState(0);
    const onListYes = { label: 'Yes', value: 100 as unknown as bigint };
    const onListNo = { label: 'No', value: 200 as unknown as bigint };
    const onListOptionsList = [onListYes, onListNo];
    const [sdsRegulations, setSdsRegulations] = useState([] as SdsRegulation[]);

    const {
        data: regulationOptionsList,
        isLoading: isLoadingRegulationOptionsList,
    } = useListRegulationsQuery();

    const [showMultiSelectGridModal, setShowMultiSelectGridModal] =
        useState(false);

    const [regulationList, setRegulationList] = useState([] as Regulation[]);

    const [sdsRegulationsColumnDefs, setSdsRegulationsColumnDefs] =
        useState(null);

    const [statesColDefs, setStatesColDefs] = useState(null);
    const [countryColDefs, setCountryColDefs] = useState(null);

    const canViewRegulations = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.REGULATIONS.VIEW
    );

    const canViewCas = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.CAS_MASTER.VIEW
    );

    const [casColumnDefs, setCasColumnDefs] = useState(null);

    const colDefOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    const { data: casOptions, isLoading: isLoadingCas } = useListCasQuery(
        user?.businessEntity?.id.toString()
    );

    const { isLoading: isLoadingStates, data: statesList } =
        useGetStatesQuery();

    const { isLoading: isLoadingCountries, data: countryList } =
        useGetCountriesQuery();

    const {
        data: regulationSectionOptions,
        isLoading: isLoadingRegulationSectionOptions,
    } = useGetRegulationSectionsForDropDownQuery();

    const {
        data: regulationBodyOptions,
        isLoading: isLoadingRegulationBodiesOptions,
    } = useGetRegulationBodiesForDropDownQuery();

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.SDS_FORM,
              }
            : skipToken
    );

    /**
     * sorts records in sdsRegulations grid according to sdsSortOrder, then code
     * @param sdsRegulations
     */
    const sortAndSetSdsRegulationRecords = (
        sdsRegulations: SdsRegulation[]
    ) => {
        const newList = cloneDeep(
            sdsRegulations.filter(
                (current) => current.rowStatus !== RowStatus.DELETED
            )
        );
        let currentRowId = maxRowId;
        newList.forEach((current) => {
            current.rowId = ++currentRowId;
        });
        setMaxRowId(currentRowId);
        setSdsRegulations(_.orderBy(newList, ['sortBy', 'code'], ['asc']));
    };

    /**
     * finds matching StateCode according to given param
     * @param stateCodeId
     */
    const getStateById = (stateCodeId: bigint): StateCodeDisplay => {
        return getStateCodeById(stateCodeId, statesList);
    };

    /**
     * used to manage multiselect modal state
     * filters hazard phrase list here to guarantee the list in the modal is accurate
     * @param showModal
     */
    const handleSetShowMultiSelectGridModal = (showModal: boolean) => {
        if (showModal) {
            setFilteredRegulationsList();
        }
        setShowMultiSelectGridModal(showModal);
    };

    /**
     * finds matching CountryCode according to given param
     * if no match is found, returns empty object
     * @param countryCodeId
     */
    const getCountryById = (countryCodeId: bigint): SelectedDropdownOption => {
        let countryCode: SelectedDropdownOption = {
            id: null,
            name: '',
            display: '',
        };
        if (!isLoadingCountries && countryList) {
            (countryList as CountryCode[]).find((country) => {
                if (country.id === countryCodeId) {
                    countryCode = {
                        id: country.id,
                        name: country.name,
                        //display field mandatory for use with select modal
                        display: country.name,
                    };
                }
            });
        }
        return countryCode;
    };

    /**
     * filters out records found in sdsRegulations grid for multiselect modal
     * then sets them for the multiselect modal to use
     */
    const setFilteredRegulationsList = () => {
        if (canViewRegulations) {
            const filteredRegulationOptionsList =
                removeSdsRegulationsFromRegulationsList();
            setRegulationList(filteredRegulationOptionsList);
        } else {
            setRegulationList([]);
        }
    };

    /**
     * removes select options from multiselect modal data
     * if phrase is already present in sdsRegulations grid
     */
    const removeSdsRegulationsFromRegulationsList = (): Regulation[] => {
        let regulationsListWithoutCas: Regulation[] = regulationOptionsList;
        // identifies regulation options that are in sdsRegulations grid
        // finds their matching record in list used for multiselect
        _.forEach(sdsRegulations, function (sdsRegulation: SdsRegulation) {
            const matchingSdsRegulations = _.find(
                regulationOptionsList,
                (currentRegulation: Regulation) => {
                    // rows that are marked deleted we don't filter out
                    // so that they can be added again via multiselect
                    return (
                        currentRegulation.code === sdsRegulation.code &&
                        sdsRegulation.rowStatus !== RowStatus.DELETED
                    );
                }
            );

            // if there are matching records, we remove them from filtered list
            if (matchingSdsRegulations) {
                regulationsListWithoutCas = _.without(
                    regulationsListWithoutCas,
                    matchingSdsRegulations
                );
            }
        });
        // returns regulationOptionsList sorted and filtered
        return _.sortBy(regulationsListWithoutCas, ['code']);
    };

    /**
     * converts regulations selected from multiselect to valid sdsRegulations
     * @param selectedRecords
     */
    const handleSelectedRecords = (selectedRecords: Regulation[]) => {
        const newRows: SdsRegulation[] = [];
        let index = maxRowId;
        selectedRecords?.forEach((record: Regulation) => {
            index = index + 1;
            setMaxRowId((maxRowId) => maxRowId + 1);
            const updatedRecord: SdsRegulation = { ...record };
            updatedRecord.rowId = index;
            updatedRecord.rowStatus = RowStatus.ADDED;
            updatedRecord.stateCodeId = record.stateCodeId;
            updatedRecord.stateCode = getStateById(record.stateCodeId);
            updatedRecord.stateCodeName = getStateById(record.stateCodeId).name;
            updatedRecord.countryCode = { id: null, name: '' };
            updatedRecord.countryCodeId = null;
            updatedRecord.onList = false;
            updatedRecord.onListDisplay = onListNo.value;
            updatedRecord.importManufactureLimit = null;
            updatedRecord.threshold = null;
            updatedRecord.notes = null;
            updatedRecord.sortBy = null;
            delete updatedRecord.id;
            newRows.push(updatedRecord);
        });

        updateSdsRegulationsRecords(newRows);
        handleSaveGridEdits(newRows);
    };

    /**
     * Concatenates any non-deleted rows with the new added SdsRegulations list
     * @param newSdsRegulations
     */
    const updateSdsRegulationsRecords = (
        newSdsRegulations: SdsRegulation[]
    ) => {
        const filteredList = sdsRegulations.filter(
            (current: SdsRegulation) => current.rowStatus !== RowStatus.DELETED
        );
        setSdsRegulations(
            filteredList
                ? filteredList.concat(newSdsRegulations)
                : newSdsRegulations
        );
    };

    /**
     * sets validations on each of row fields in sdsRegulations grid
     * @param editedRows
     */
    const handleRowValidations = (editedRows: SdsRegulation[]) => {
        editedRows?.forEach((row: SdsRegulation) => {
            row.validationRules = [
                ColumnRequiredValueValidator('Code', row.code),
                ColumnMaxValueValidator('Code', 25, row.code),
                ColumnRequiredValueValidator('Title', row.title),
                ColumnMaxValueValidator('Title', 50, row.title),
                ColumnMaxValueValidator('Threshold', 250, row.threshold),
                ColumnMaxValueValidator('Notes', 250, row.notes),
                ColumnNumericValueValidator(
                    'Import/Manufacture Limit',
                    0,
                    row.importManufactureLimit
                ),
                ColumnMinValueValidator(
                    'Import/Manufacture Limit',
                    0,
                    Number(row.importManufactureLimit)
                ),
                ColumnNumericValueValidator('SDS Sort Order', 0, row.sortBy),
                ColumnMinValueValidator(
                    'SDS Sort Order',
                    0,
                    Number(row.sortBy)
                ),
            ];
            applyEditableGridValidations(row, `Regulation ${row.code}`);
        });
        return editedRows;
    };

    /**
     * takes onListValue and converts to boolean for db usage
     * @param onListValue
     */
    const getOnListBoolean = (onListValue: bigint) => {
        return onListValue === onListYes.value;
    };

    const getCasDetails = (id: bigint, options: CasMaster[]) => {
        let detailRecord: CasMaster;
        options?.find((current: CasMaster) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    const getCasNumber = (casId: any): SelectedDropdownOption => {
        let casNumber: SelectedDropdownOption = { id: null, display: '' };
        if (!isNilOrEmpty(casId)) {
            casOptions?.find((current: CasMaster) => {
                if (current.id === casId) {
                    casNumber = {
                        id: current.id,
                        display: current.identifier,
                    };
                }
            });
        }
        return casNumber;
    };

    /**
     * takes onList boolean and converts to onList object for use in select
     * @param onList
     */
    const setOnListDisplay = (onList: boolean): SelectionOption => {
        return onList ? onListYes : onListNo;
    };

    /**
     * when save edits button is clicked
     * converts data from selects and multiselect grid options in rows
     * into a format that can be saved to the parent cas record
     * @param editedRows
     */
    const handleSaveGridEdits = (editedRows: SdsRegulation[]) => {
        const updatedFields: SdsRegulation[] = [...sdsRegulations];
        editedRows?.forEach((sdsRegulation: SdsRegulation) => {
            const index = sdsRegulations?.findIndex(
                (row: any) =>
                    row.code === sdsRegulation.code &&
                    row.rowId === sdsRegulation.rowId
            );
            if (
                !isNilOrEmpty(sdsRegulation.casNumber) &&
                !isNilOrEmpty(sdsRegulation.casNumber?.id)
            ) {
                sdsRegulation.casId = sdsRegulation.casNumber?.id;
            }

            if (
                !isNilOrEmpty(sdsRegulation.stateCode) &&
                !isNilOrEmpty(sdsRegulation.stateCode.id)
            ) {
                sdsRegulation.stateCodeId = sdsRegulation.stateCode.id;
            }

            if (
                !isNilOrEmpty(sdsRegulation.countryCode) &&
                !isNilOrEmpty(sdsRegulation.countryCode.id)
            ) {
                sdsRegulation.countryCodeId = sdsRegulation.countryCode.id;
            }

            if (!isNilOrEmpty(sdsRegulation.onListDisplay)) {
                sdsRegulation.onList = getOnListBoolean(
                    sdsRegulation.onListDisplay
                );
            }
            if (index > -1) {
                updatedFields[index] = sdsRegulation;
            } else {
                updatedFields.push(sdsRegulation);
            }
        });
        sortAndSetSdsRegulationRecords(updatedFields);
        handleChildrenRecords('sdsRegulations', updatedFields);
    };

    /**
     * when data is ready, sets each sdsRegulation record with data that fits
     * the proper formatting for display in the sdsRegulations grid
     * then sorts and sets the data to be consumned by the grid
     */
    useEffect(() => {
        const includeEditedRows = currentParentRecord?.sdsRegulations
            ? !isNilOrEmpty(
                  currentParentRecord.sdsRegulations.find(
                      (current) =>
                          current.rowStatus &&
                          (current?.autoPopulated
                              ? !current?.autoPopulated
                              : true)
                  )
              )
            : false;
        if (
            currentParentRecord?.sdsRegulations &&
            countryList &&
            statesList &&
            !isLoadingCas &&
            !isNilOrEmpty(casOptions) &&
            !includeEditedRows
        ) {
            const regulations: SdsRegulation[] = [];
            let index = maxRowId;

            currentParentRecord?.sdsRegulations.forEach(
                (sdsRegulation: SdsRegulation) => {
                    index = index + 1;
                    setMaxRowId((maxRowId) => maxRowId + 1);
                    if (sdsRegulation.rowStatus !== RowStatus.DELETED) {
                        const newSdsRegulation: SdsRegulation = {
                            ...sdsRegulation,
                            casNumber: getCasNumber(sdsRegulation.casId),
                            stateCode: getStateById(sdsRegulation.stateCodeId),
                            countryCode: getCountryById(
                                sdsRegulation.countryCodeId
                            ),
                            onListDisplay: setOnListDisplay(
                                sdsRegulation.onList
                            ).value,
                            rowId: sdsRegulation.rowId
                                ? sdsRegulation.rowId
                                : index,
                        };
                        regulations.push(newSdsRegulation);
                    }
                }
            );
            sortAndSetSdsRegulationRecords(regulations);
        }
    }, [
        currentParentRecord?.sdsRegulations,
        isLoadingCountries,
        isLoadingStates,
        statesList,
        countryList,
        isLoadingCas,
        casOptions,
    ]);

    useEffect(() => {
        if (!isLoadingCas && !isNilOrEmpty(casOptions)) {
            setCasColumnDefs([
                {
                    field: 'identifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_CAS_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'chemicalName',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_Chemical_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'ecIdentifier',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_EC_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'reach',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_Reach_No
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'classification',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_Classification
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'molecularFormula',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_CAS_Selection_Molecular_Formula
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                    filter: 'agTextColumnFilter',
                },
            ]);
        }
    }, [isLoadingCas, casOptions]);

    /**
     * builds column definitions for state select pop out modal
     */
    useEffect(() => {
        if (!isLoadingStates && !isNilOrEmpty(statesList)) {
            setStatesColDefs([
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_State_Selection_UOM
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
                {
                    field: 'name',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_State_Selection_Description
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingStates, statesList]);

    /**
     * builds column definitions for country select pop out modal
     */
    useEffect(() => {
        if (!isLoadingCountries && !isNilOrEmpty(countryList)) {
            setCountryColDefs([
                {
                    field: 'name',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_Country_Selection_Name
                                }
                            />
                        );
                    },
                    cellClass: 'ag-left-aligned-cell',
                },
            ]);
        }
    }, [isLoadingCountries, countryList]);

    /**
     * builds column definitions for cas Regulations grid
     */
    useEffect(() => {
        if (
            !isLoadingRegulationOptionsList &&
            !isNilOrEmpty(regulationOptionsList) &&
            !isNilOrEmpty(countryColDefs) &&
            !isNilOrEmpty(statesColDefs) &&
            !isLoadingRegulationBodiesOptions &&
            !isNilOrEmpty(regulationBodyOptions) &&
            !isLoadingRegulationSectionOptions &&
            !isNilOrEmpty(regulationSectionOptions) &&
            !isNilOrEmpty(casColumnDefs) &&
            !isLoadingCas &&
            !isNilOrEmpty(casOptions)
        ) {
            setSdsRegulationsColumnDefs([
                {
                    field: 'deleteColumn',
                    minWidth: 50,
                    hide: !showDeleteButton || !isGridEditable,
                    filter: false,
                },
                {
                    field: 'code',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Regulatory_Code}
                            />
                        );
                    },
                    editable: false,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'title',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Title}
                            />
                        );
                    },
                    editable: isGridEditable,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'casNumber',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_CAS_No}
                            />
                        );
                    },
                    minWidth: 200,
                    editable: canViewCas && isGridEditable,
                    cellRenderer: (params: any) => params?.value?.display,
                    useGridPopup: true,
                    filter: 'agTextColumnFilter',
                    gridPopupParameters: {
                        isLoading: isLoadingCas,
                        displayGrid: true,
                        rowData: canViewCas ? casOptions : [],
                        defaultColDef: colDefOptions,
                        columnDefs: casColumnDefs,
                        displayField: 'identifier',
                    },
                },
                {
                    field: 'casDescription',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Chemical_Name}
                            />
                        );
                    },
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    editable: false,
                    valueGetter: (params: ValueGetterParams) => {
                        const details = getCasDetails(
                            params.data.casNumber?.id,
                            casOptions
                        );
                        return details?.chemicalName;
                    },
                },
                {
                    field: 'onListDisplay',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_On_List}
                            />
                        );
                    },
                    minWidth: 115,
                    filter: 'agTextColumnFilter',
                    ...editableGridCellSelect(onListOptionsList),
                    editable: isGridEditable,
                },
                {
                    field: 'importManufactureLimit',
                    minWidth: 225,
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={
                                    SDSFormDefs.Regulation_Import_Manufacture_Limit
                                }
                            />
                        );
                    },
                    filter: 'agNumberColumnFilter',
                    editable: isGridEditable,
                },
                {
                    field: 'threshold',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Threshold}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 125,
                },
                {
                    field: 'notes',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Notes}
                            />
                        );
                    },
                    filter: 'agTextColumnFilter',
                    editable: isGridEditable,
                    ...DefaultColumnTypes.LargeTextEditor,
                    minWidth: 100,
                },
                {
                    field: 'regulationBodyId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Regulation_Body}
                            />
                        );
                    },
                    ...editableGridCellSelect(regulationBodyOptions),
                    editable: isGridEditable,
                    minWidth: 200,
                },
                {
                    field: 'regulationSectionId',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Section}
                            />
                        );
                    },
                    minWidth: 115,
                    ...editableGridCellSelect(regulationSectionOptions),
                    editable: isGridEditable,
                },
                {
                    field: 'stateCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_State_Province}
                            />
                        );
                    },
                    ...DefaultColumnTypes.MediumText,
                    cellRenderer: (params: any) => params.value.display,
                    editable: canViewRegulations && isGridEditable,
                    useGridPopup: true,
                    minWidth: 200,
                    gridPopupParameters: {
                        isLoading: isLoadingStates,
                        displayGrid: true,
                        rowData: statesList,
                        defaultColDef: colDefOptions,
                        columnDefs: statesColDefs,
                        displayField: 'name',
                    },
                },
                {
                    field: 'countryCode',
                    headerComponentFramework: (props: any) => {
                        return (
                            <CustomHeader
                                {...props}
                                termSet={termSet}
                                termKey={SDSFormDefs.Regulation_Country}
                            />
                        );
                    },
                    cellRenderer: (params: any) => params.value.display,
                    editable: isGridEditable,
                    useGridPopup: true,
                    minWidth: 115,
                    gridPopupParameters: {
                        isLoading: isLoadingCountries,
                        displayGrid: true,
                        rowData: countryList,
                        defaultColDef: colDefOptions,
                        columnDefs: countryColDefs,
                        displayField: 'name',
                    },
                },
            ]);
        }
    }, [
        statesColDefs,
        countryColDefs,
        isLoadingRegulationOptionsList,
        regulationOptionsList,
        regulationBodyOptions,
        regulationSectionOptions,
        isLoadingRegulationBodiesOptions,
        isLoadingRegulationSectionOptions,
        isLoadingCas,
        casOptions,
        casColumnDefs,
    ]);

    /**
     * makes sure that when a new item is added/deleted from sdsRegulations
     * that deleted rows will be available again in multiselect
     */
    useEffect(() => {
        setFilteredRegulationsList();
    }, [sdsRegulations]);

    /**
     * filters and sets regulationOptions for multiselect for the first page load
     * also sort and set sdsRegulations
     */
    useEffect(() => {
        if (!isLoadingRegulationOptionsList && isNilOrEmpty(regulationList)) {
            setFilteredRegulationsList();
        }
    }, [isLoadingRegulationOptionsList]);

    return {
        handleSaveGridEdits,
        sdsRegulations,
        regulationList,
        showMultiSelectGridModal,
        handleSetShowMultiSelectGridModal,
        handleSelectedRecords,
        getStateById,
        sdsRegulationsColumnDefs,
        handleRowValidations,
        colDefOptions,
        termSet,
    };
};

export default useSdsRegulationsGrid;
