import React from 'react';
import UseFixedAssetDepreciationMethodGrid from './hooks/useFixedAssetDepreciationMethodGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { cloneDeep } from 'lodash';

const FixedAssetDepreciationMethodGrid = () => {
    const {
        methodList,
        isLoadingMethods,
        redirectToForm,
        CostCodeColumn,
        getCostCodeValue,
        canCreateDepreciationMethod,
        canViewDepreciationMethod,
        handleGridEdits,
    } = UseFixedAssetDepreciationMethodGrid();

    const methodsColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'name',
            headerName: 'Name',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'depreciationMethodStatus.code',
            headerName: 'Status',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'depreciationMethodType.code',
            headerName: 'Type',
            ...DefaultColumnTypes.ShortText,
        },
        {
            headerName: 'Cost Code(s)',
            ...DefaultColumnTypes.LongText,
            cellRenderer: 'costCodeColumn',
            valueGetter: (params) => {
                return getCostCodeValue(params.data.costs);
            },
        },
    ];

    return (
        <BaseFormGrid
            title="Depreciation Methods"
            displayGrid={canViewDepreciationMethod}
            isLoading={isLoadingMethods}
            rowData={cloneDeep(methodList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={methodsColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateDepreciationMethod}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
            frameworkComponents={{
                costCodeColumn: CostCodeColumn,
            }}
        />
    );
};

export default FixedAssetDepreciationMethodGrid;
