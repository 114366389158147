import React, { useContext, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { GhsHazardPhrase } from '../../../../../types/formulation';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { useListHazardTypesQuery } from '../../../../../services/formulation/ghsHazardPhrases/hazardTypes/hazardTypes.service';
import {
    useCreateGhsHazardPhraseMutation,
    useDeleteGhsHazardPhraseMutation,
    useGetGhsHazardPhraseQuery,
    useUpdateGhsHazardPhraseMutation,
} from '../../../../../services/formulation/ghsHazardPhrases/ghsHazardPhrases.service';
import { openModalConfirmBasicWithMessage } from '../../../../../store/uiElements';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

export interface HazardPhraseFormProps {
    id: bigint;
    currentLangId: bigint;
    phraseIdList: Array<string>;
    allowDelete: boolean;
    currentPhraseId?: string;
    currentHazardType?: bigint;
    currentUserInputNeeded?: boolean;
    defaultLangId: bigint;
    handleSetPreviousTab(): void;
    handleSetIsFormDirty(formDirty: boolean): void;
    cancelNewLanguage(closeForm: boolean): void;
    setDisableLangButton(disableButton: boolean): void;
}

const useGhsHazardPhraseForm = ({
    id,
    defaultLangId,
    currentLangId,
    phraseIdList,
    currentPhraseId,
    currentHazardType,
    currentUserInputNeeded,
    handleSetPreviousTab,
    handleSetIsFormDirty,
    cancelNewLanguage,
    setDisableLangButton,
}: HazardPhraseFormProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const navigate = useNavigate();
    const { data: hazardTypeOptionsList, isLoading: isLoadingHazardTypes } =
        useListHazardTypesQuery();

    const { data: currentHazardPhrase, isFetching: isLoadingPhraseRecord } =
        useGetGhsHazardPhraseQuery(id ? id.toString() : skipToken);

    const [createHazardPhrase] = useCreateGhsHazardPhraseMutation();
    const [updateHazardPhrase] = useUpdateGhsHazardPhraseMutation();
    const [deleteHazardPhrase] = useDeleteGhsHazardPhraseMutation();

    const canCreateHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.CREATE
    );
    const canUpdateHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.EDIT
    );
    const canDeleteHazardPhrase = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.GHS_HAZARD_PHRASE.DELETE
    );

    const blankHazardPhrase: GhsHazardPhrase = {
        phraseId: !currentPhraseId ? '' : currentPhraseId,
        langId: currentLangId,
        phraseText: '',
        hazardTypeId: !currentHazardType ? null : currentHazardType,
        companySpecific:
            currentUserInputNeeded == null ? false : currentUserInputNeeded,
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.GHS_HAZARD_PHRASES_FORM,
              }
            : skipToken
    );

    useEffect(() => {
        setDisableLangButton(id ? false : true);
        if (
            !isLoadingHazardTypes &&
            hazardTypeOptionsList &&
            !id &&
            !currentHazardType
        ) {
            blankHazardPhrase.hazardTypeId = hazardTypeOptionsList[0].value;
            setValues(blankHazardPhrase);
        }
    }, [id, currentHazardType, hazardTypeOptionsList, isLoadingHazardTypes]);

    const afterCreateRecord = () => {
        handleSetIsFormDirty(false);
        navigate(`/formulation/ghsHazardPhrases/${fields.phraseId}`);
    };

    const afterUpdateRecord = () => {
        handleSetIsFormDirty(false);
    };

    const afterDeleteRecord = () => {
        handleSetIsFormDirty(false);
        if (defaultLangId === currentLangId) {
            navigate(`/formulation/ghsHazardPhrases`);
        } else {
            handleSetPreviousTab();
        }
    };

    const {
        fields,
        formMethods,
        setValues,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
    } = useBaseForm({
        blankEntity: blankHazardPhrase,
        activeEntity: !id ? undefined : currentHazardPhrase,
        getDescription: () => {
            return `GHS Hazard Phrase ${fields.phraseId}`;
        },
        createEntity: () => {
            const postBody: GhsHazardPhrase = {
                phraseId: fields.phraseId,
                langId: fields.langId,
                businessEntityId: fields.businessEntityId,
                phraseText: fields.phraseText,
                hazardTypeId: fields.hazardTypeId,
                companySpecific: fields.companySpecific,
            };
            return createHazardPhrase({ postBody });
        },
        updateEntity: () => {
            return updateHazardPhrase({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteHazardPhrase(id.toString());
        },
        afterCreate: afterCreateRecord,
        afterDelete: afterDeleteRecord,
        afterUpdate: afterUpdateRecord,
    });

    useEffect(() => {
        if (id && currentHazardPhrase && id === currentHazardPhrase.id) {
            if (getFieldsChanged(currentHazardPhrase)) {
                handleSetIsFormDirty(true);
            } else {
                handleSetIsFormDirty(false);
            }
        } else if (!id) {
            handleSetIsFormDirty(true);
        }
    }, [fields]);

    const getFieldsChanged = (currentEntity: GhsHazardPhrase): boolean => {
        if (fields && currentEntity && currentEntity.id === fields.id) {
            return (
                fields.phraseId !== currentEntity.phraseId ||
                fields.phraseText !== currentEntity.phraseText ||
                fields.companySpecific !== currentEntity.companySpecific ||
                fields.hazardTypeId !== currentEntity.hazardTypeId
            );
        }
    };

    const handleCancelForm = () => {
        if (!id) {
            cancelNewLanguage(currentLangId === defaultLangId);
        } else {
            setValues(currentHazardPhrase);
        }
    };

    const showExistingPhrase = (currentPhraseId: string) => {
        handleSetIsFormDirty(false);
        navigate(`/formulation/ghsHazardPhrases/${currentPhraseId}`);
    };

    const handlePhraseIdOnBlur = () => {
        if (!id && currentLangId && phraseIdList) {
            const currentPhraseId = phraseIdList.find(
                (current) => current === fields.phraseId
            );
            if (currentPhraseId) {
                const message = `GHS Hazard Phrase with Phrase ID ${currentPhraseId} already exists.\nDo you want to show it?`;
                dispatch(
                    openModalConfirmBasicWithMessage({
                        message: message,
                        title: '',
                        onConfirm: () => {
                            showExistingPhrase(currentPhraseId);
                        },
                    })
                );
            }
        }
    };

    return {
        fields,
        formMethods,
        hazardTypeOptionsList,
        isLoadingPhraseRecord,
        isLoadingHazardTypes,
        handleFieldChange,
        handlePhraseIdOnBlur,
        handleCancelForm,
        onCreate,
        onUpdate,
        onDelete,
        canCreateHazardPhrase,
        canUpdateHazardPhrase,
        canDeleteHazardPhrase,
        termSet,
    };
};

export default useGhsHazardPhraseForm;
