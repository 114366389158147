import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection14_5Form from './subSections/subSection14_5/SubSection14_5Form';
import SubSection14_6Form from './subSections/subSection14_6/SubSection14_6Form';
import SubSection14_7Form from './subSections/subSection14_7/SubSection14_7Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import { Button, DialogTitle, Grid, Tab } from '@mui/material';
import useSdsTransportForm from './hooks/useSdsTransportForm';
import TextInput from '../../../../../../components/form/formInputs/TextInput/TextInput';
import AutoCompleteInput from '../../../../../../components/form/formInputs/AutoCompleteInput/AutoCompleteInput';
import CheckBoxInput from '../../../../../../components/form/formInputs/CheckBoxInput/CheckBoxInput';

export interface sdsTransportFormProps {
    sdsId: string;
    isLoadingSdsRecord: boolean;
    addSectionToParent: Function;
    isFieldDisabled: boolean;
}

const SdsTransportForm = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { parentData } = props;
    const { isParentLoading } = parentData;

    const {
        fields,
        handlePropertyChanged,
        dropdownOptions,
        formMethods,
        currentTab,
        setCurrentTab,
        setDropdownOptions,
        properShippingNameOptions,
        isLoadingProperShippingNameOptions,
        setFieldsOnProperShippingNameChange,
        onCopyFieldChange,
        isAirEnabled,
        isLandEnabled,
        isSeaEnabled,
        isCopyButtonEnabled,
        isDestinationFieldEnabled,
        onCheckboxToggle,
        transportationOptions,
        displayFields,
        copySourceOptions,
        copyDestinationOptions,
        setCopyDropdownOptions,
        copyDropdownOptions,
        copyFormMethods,
        performCopyAction,
    } = useSdsTransportForm(props);

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Transport}
                                />
                            ) as unknown as string
                        }>
                        <FormPartial title="14.1 - 14.4" isVisible={true}>
                            <Grid container spacing={2}>
                                <CheckBoxInput
                                    id="land"
                                    label={'Land'}
                                    checked={isLandEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isLandEnabled,
                                            transportationOptions.land
                                        );
                                    }}
                                    disabled={false}
                                />
                                <CheckBoxInput
                                    id="air"
                                    label={'Air'}
                                    checked={isAirEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isAirEnabled,
                                            transportationOptions.air
                                        );
                                    }}
                                    disabled={false}
                                />
                                <CheckBoxInput
                                    id="sea"
                                    label={'Sea'}
                                    checked={isSeaEnabled}
                                    onChange={() => {
                                        onCheckboxToggle(
                                            isSeaEnabled,
                                            transportationOptions.sea
                                        );
                                    }}
                                    disabled={false}
                                />
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        id="copySourceId"
                                        inputFieldName="copySourceValue"
                                        label={'Copy Source'}
                                        onHandlePropertyChanged={
                                            onCopyFieldChange
                                        }
                                        options={copySourceOptions}
                                        setDropdownOptions={
                                            setCopyDropdownOptions
                                        }
                                        value={
                                            copyDropdownOptions?.selectedCopySource
                                        }
                                        inputValue={
                                            copyDropdownOptions?.copySourceValue
                                        }
                                        isLoading={false}
                                        formMethods={copyFormMethods}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <DialogTitle>to</DialogTitle>
                                </Grid>
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        id="copyDestinationId"
                                        inputFieldName="copyDestinationValue"
                                        label={'Copy Destination'}
                                        onHandlePropertyChanged={
                                            onCopyFieldChange
                                        }
                                        options={copyDestinationOptions}
                                        setDropdownOptions={
                                            setCopyDropdownOptions
                                        }
                                        value={
                                            copyDropdownOptions?.selectedCopyDestination
                                        }
                                        inputValue={
                                            copyDropdownOptions?.copyDestinationValue
                                        }
                                        isLoading={false}
                                        formMethods={copyFormMethods}
                                        disabled={!isDestinationFieldEnabled}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        onClick={performCopyAction}
                                        disabled={!isCopyButtonEnabled}>
                                        Copy
                                    </Button>
                                </Grid>
                            </Grid>
                            <TabContext value={currentTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <TabList
                                        onChange={(event, value: string) =>
                                            setCurrentTab(value)
                                        }>
                                        <Tab label="Land" value="0" />
                                        <Tab label="Air" value="1" />
                                        <Tab label="Sea" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <AutoCompleteInput
                                                id="landProperShippingNameId"
                                                inputFieldName="properShippingNameInputValueLand"
                                                label={
                                                    'Proper Shipping Name Code'
                                                }
                                                onHandlePropertyChanged={
                                                    setFieldsOnProperShippingNameChange
                                                }
                                                options={
                                                    properShippingNameOptions
                                                }
                                                setDropdownOptions={
                                                    setDropdownOptions
                                                }
                                                value={
                                                    dropdownOptions?.selectedProperShippingNameLand
                                                }
                                                inputValue={
                                                    dropdownOptions?.properShippingNameInputValueLand
                                                }
                                                isLoading={
                                                    isLoadingProperShippingNameOptions
                                                }
                                                formMethods={formMethods}
                                                disabled={!isLandEnabled}
                                                required={isLandEnabled}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="landClass"
                                                value={displayFields.landClass}
                                                label="Class"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="landPackaging"
                                                value={
                                                    displayFields.landPackaging
                                                }
                                                label="Packaging Group"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="landProperShippingNameDescription"
                                                value={
                                                    fields.landProperShippingNameDescription
                                                }
                                                label="DOT Proper Shipping Name"
                                                maxLength={50}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={!isLandEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="1">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <AutoCompleteInput
                                                id="airProperShippingNameId"
                                                inputFieldName="properShippingNameInputValueAir"
                                                label={
                                                    'Proper Shipping Name Code'
                                                }
                                                onHandlePropertyChanged={
                                                    setFieldsOnProperShippingNameChange
                                                }
                                                options={
                                                    properShippingNameOptions
                                                }
                                                setDropdownOptions={
                                                    setDropdownOptions
                                                }
                                                value={
                                                    dropdownOptions?.selectedProperShippingNameAir
                                                }
                                                inputValue={
                                                    dropdownOptions?.properShippingNameInputValueAir
                                                }
                                                isLoading={
                                                    isLoadingProperShippingNameOptions
                                                }
                                                formMethods={formMethods}
                                                disabled={!isAirEnabled}
                                                required={isAirEnabled}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="airClass"
                                                value={displayFields.airClass}
                                                label="Class"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="airPackaging"
                                                value={
                                                    displayFields.airPackaging
                                                }
                                                label="Packaging Group"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="airProperShippingNameDescription"
                                                value={
                                                    fields.airProperShippingNameDescription
                                                }
                                                label="DOT Proper Shipping Name"
                                                maxLength={50}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={!isAirEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <AutoCompleteInput
                                                id="seaProperShippingNameId"
                                                inputFieldName="properShippingNameInputValueSea"
                                                label={
                                                    'Proper Shipping Name Code'
                                                }
                                                onHandlePropertyChanged={
                                                    setFieldsOnProperShippingNameChange
                                                }
                                                options={
                                                    properShippingNameOptions
                                                }
                                                setDropdownOptions={
                                                    setDropdownOptions
                                                }
                                                value={
                                                    dropdownOptions?.selectedProperShippingNameSea
                                                }
                                                inputValue={
                                                    dropdownOptions?.properShippingNameInputValueSea
                                                }
                                                isLoading={
                                                    isLoadingProperShippingNameOptions
                                                }
                                                formMethods={formMethods}
                                                disabled={!isSeaEnabled}
                                                required={isSeaEnabled}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="seaClass"
                                                value={displayFields.seaClass}
                                                label="Class"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                fullWidth
                                                id="seaPackaging"
                                                value={
                                                    displayFields.seaPackaging
                                                }
                                                label="Packaging Group"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                fullWidth
                                                id="seaProperShippingNameDescription"
                                                value={
                                                    fields.seaProperShippingNameDescription
                                                }
                                                label="DOT Proper Shipping Name"
                                                maxLength={50}
                                                formMethods={formMethods}
                                                required={false}
                                                onChange={handlePropertyChanged}
                                                disabled={!isSeaEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </FormPartial>
                        <SubSection14_5Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection14_6Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection14_7Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsTransportForm;
