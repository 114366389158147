import BatchScheduler from '../containers/batchTicketScheduler/BatchScheduler';
import UserPortal from '../containers/userPortal/UserPortal';
import ApiDocumentation from '../containers/apiDocumentation/ApiDocumentation';
import AppStreaming from '../containers/appStreaming/AppStreaming';
import MyApps from '../containers/myApps/MyApps';
import { RouteProps } from './routesConfig';
import DocumentManagement from '../containers/documentManagement/DocumentManagement';
import BusinessIntelligence from '../containers/businessIntelligence/BusinessIntelligence';
import DevArea from '../containers/devArea/DevArea';

const GeneralRoutes: RouteProps[] = [
    {
        path: '/scheduler',
        component: BatchScheduler,
        featureFlag: 'dashboard.nav.apps.scheduler',
    },
    {
        path: '/userPortal',
        component: UserPortal,
        featureFlag: 'dashboard.nav.apps.administration',
    },
    {
        path: '/apiDocumentation',
        component: ApiDocumentation,
        featureFlag: 'dashboard.nav.apps.apiDocumentation',
    },
    {
        path: '/erpAppStreaming',
        component: AppStreaming,
        featureFlag: 'dashboard.nav.apps.erpAppStreaming',
    },
    {
        path: '/myApps',
        component: MyApps,
    },
    {
        path: '/documentManagement',
        component: DocumentManagement,
        featureFlag: 'dashboard.nav.apps.documentManagement',
    },
    {
        path: '/businessIntelligence',
        component: BusinessIntelligence,
        featureFlag: 'dashboard.nav.apps.businessIntelligence',
    },
    {
        path: '/dev-area',
        component: DevArea,
        featureFlag: 'dashboard.nav.apps.administration',
        permissionKey: 'Datacor.developer',
        datacorAdminOnly: true,
    },
];

export default GeneralRoutes;
