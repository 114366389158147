import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from '../../../libs/hooksLib';
import { MutationError, RoutingValues } from '../../../types/Shared.types';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { useDispatch } from 'react-redux';
import {
    openModalConfirmBasicWithMessage,
    openSnackbarBasicWithMessage,
} from '../../../store/uiElements';
import { AlertColor } from '@mui/material';
import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';
import { organizationsApi } from '../../../services/organizations/organizations.service';
import _ from 'lodash';
import { handleInvalidateTags } from '../../../utils/services/invalidateTags';
export interface UseBaseFormProps {
    closePath?: string;
    copyPath?: string;
    blankEntity: object;
    activeEntity: object;
    getDescription: () => string;
    createEntity: (_?: any) => MutationActionCreatorResult<any>;
    updateEntity: () => MutationActionCreatorResult<any>;
    deleteEntity?: () => MutationActionCreatorResult<any>;
    lockEntity?: () => MutationActionCreatorResult<any>;
    afterCreate?: (_?: any) => void;
    afterDelete?: () => void;
    afterUpdate?: () => void;
    reroute?: boolean;
    onlyUpdateEntityWithChanges?: boolean;
    isChildForm?: boolean;
    childForms?: any[];
    onBeforeDeleteConfirmation?: () => string;
}

const useBaseForm = (props: UseBaseFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formType, setFormType] = useState<string>(RoutingValues.newId);

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        isFormValid,
        handleChildrenRecords,
        handleChildRecord,
        verifyChanges,
        updateCompleteChildRecord,
    } = useForm(props.blankEntity, props.onlyUpdateEntityWithChanges);

    const { mainForm } = verifyChanges;

    useEffect(() => {
        if (props.onlyUpdateEntityWithChanges) {
            mainForm.setOriginalEntity(props.activeEntity || props.blankEntity);
        }
        setValues(props.activeEntity || props.blankEntity);
    }, [props.activeEntity]);

    const closeForm = () => {
        if (props.closePath) {
            navigate(props.closePath);
        }
    };

    const copyForm = () => {
        if (props.copyPath) {
            setFormType(RoutingValues.copy);
            navigate(props.copyPath);
        }
    };

    /// Verify if the entity fields changed.
    // If return true, the system will execute the update process.
    const hasEntityChanges = (): boolean => {
        let hasChanged: boolean = mainForm.hasCurrentRecordChanged();
        if (!hasChanged && props?.childForms) {
            _.forEach(props?.childForms, async (childForm) => {
                if (
                    childForm.hasEntityChanges &&
                    childForm.hasEntityChanges()
                ) {
                    hasChanged = true;
                    return;
                }
            });
        }
        return hasChanged;
    };

    const checkValidity = (): boolean => {
        let areFormsValid = true;
        if (!isFormValid) {
            areFormsValid = false;
        }
        if (props?.childForms) {
            _.forEach(props?.childForms, function (childForm) {
                if (!childForm.formMethods.isFormValid) {
                    areFormsValid = false;
                    return;
                }
            });
        }
        return areFormsValid;
    };

    //region Create
    const onCreate = (parentFormId: any = null) => {
        if (!checkValidity()) {
            return;
        }

        props
            .createEntity(parentFormId)
            .unwrap()
            .then((response) => _onSuccessfulCreate(response))
            .catch((error: MutationError) => handleError(error));
    };

    const _onSuccessfulCreate = async (response: any) => {
        if (isNilOrEmpty(props?.isChildForm) || !props?.isChildForm) {
            if (props.childForms) {
                _.forEach(props?.childForms, async (childForm) => {
                    if (
                        childForm.hasEntityChanges &&
                        childForm.hasEntityChanges() &&
                        childForm.onCreate
                    ) {
                        await childForm.onCreate(response?.id);
                    }

                    if (
                        props.onlyUpdateEntityWithChanges &&
                        childForm?.clearChangesHistory
                    ) {
                        childForm?.clearChangesHistory();
                    }
                });
            }

            showSuccessNotification(
                `${props.getDescription()} was successfully added`
            );

            if (
                props.onlyUpdateEntityWithChanges &&
                mainForm?.clearChangesHistory
            ) {
                mainForm.clearChangesHistory();
            }

            await handleInvalidateTags(
                dispatch,
                organizationsApi,
                ['Revisions'],
                false
            );
            if (props.afterCreate) {
                props.afterCreate(response);
            }

            if (isNil(props.reroute) || props.reroute) {
                closeForm();
            }
        }
    };
    //endregion

    //region Update
    const onUpdate = () => {
        if (props.onlyUpdateEntityWithChanges) {
            if (!hasEntityChanges()) {
                return;
            }
            mainForm.setOriginalEntity(fields);
        }

        if (!checkValidity()) {
            return;
        }

        props
            .updateEntity()
            .unwrap()
            .then(() => _onSuccessfulUpdate())
            .catch((error: MutationError) => handleError(error));
    };

    const _onSuccessfulUpdate = async () => {
        if (isNilOrEmpty(props?.isChildForm) || !props?.isChildForm) {
            if (props.childForms) {
                _.forEach(props?.childForms, async (childForm) => {
                    if (
                        childForm.hasEntityChanges &&
                        childForm.hasEntityChanges() &&
                        childForm.onUpdate
                    ) {
                        await childForm.onUpdate();
                    }
                    if (
                        props.onlyUpdateEntityWithChanges &&
                        childForm?.clearChangesHistory
                    ) {
                        childForm?.clearChangesHistory();
                    }
                });
            }

            showSuccessNotification(
                `${props.getDescription()} was successfully updated`
            );

            if (
                props.onlyUpdateEntityWithChanges &&
                mainForm?.clearChangesHistory
            ) {
                mainForm.clearChangesHistory();
            }

            await handleInvalidateTags(
                dispatch,
                organizationsApi,
                ['Revisions'],
                false
            );
            if (props.afterUpdate) {
                props.afterUpdate();
            }
        }
    };
    //endregion

    //region Delete
    const onDelete = () => {
        const deleteMessage = `Please confirm the desired action is to delete ${props.getDescription()}`;
        _showConfirmModal(deleteMessage, _beforeConfirmDelete);
    };

    const _beforeConfirmDelete = () => {
        if (props.onBeforeDeleteConfirmation) {
            const deleteMessage = props.onBeforeDeleteConfirmation();
            if (!isNilOrEmpty(deleteMessage)) {
                _showConfirmModal(deleteMessage, _confirmDelete);
            } else {
                _confirmDelete();
            }
        } else {
            _confirmDelete();
        }
    };

    const _confirmDelete = () => {
        props
            .deleteEntity()
            .unwrap()
            .then(() => _onSuccessfulDelete())
            .catch((error: MutationError) => handleError(error));
    };

    const _onSuccessfulDelete = () => {
        showSuccessNotification(
            `${props.getDescription()} was successfully deleted`
        );
        if (props.afterDelete) {
            props.afterDelete();
        }
        closeForm();
    };
    //endregion

    //region lock

    const onLock = () => {
        const lockMessage = `Please confirm the desired action is to lock ${props.getDescription()}. This action cannot be undone.`;
        _showConfirmModal(lockMessage, _confirmLock);
    };

    const _confirmLock = () => {
        props
            .lockEntity()
            .unwrap()
            .then(() => _onSuccessfulLock())
            .catch((error: MutationError) => handleError(error));
    };

    const _onSuccessfulLock = () => {
        showSuccessNotification(
            `${props.getDescription()} was successfully locked`
        );
        if (props.afterDelete) {
            props.afterDelete();
        }
        closeForm();
    };
    //region Notifications
    const showSuccessNotification = (message: string) => {
        _showNotification(message, 'success');
    };

    const showErrorNotification = (message: string) => {
        _showNotification(message, 'error');
    };

    const _showNotification = (message: string, severity: AlertColor) => {
        dispatch(
            openSnackbarBasicWithMessage({
                message: message,
                severity: severity,
            })
        );
    };
    const _showConfirmModal = (
        message: string,
        onConfirm: any,
        title: string = ''
    ) => {
        dispatch(
            openModalConfirmBasicWithMessage({
                message: message,
                title: title,
                onConfirm: onConfirm,
            })
        );
    };
    //endregion

    //region Error Handling
    const handleError = (error: MutationError) => {
        if (error.status === 400) {
            const errorMessage = error.data
                .map((error: any) => {
                    return error.message;
                })
                .join('\r\n');
            showErrorNotification(errorMessage);
        } else {
            showErrorNotification(
                `An unexpected error occurred: ${error.data?.toString()}`
            );
        }
    };
    //endregion

    return {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        onLock,
        closeForm,
        copyForm,
        formType,
        isFormValid,
        handleChildrenRecords,
        handleChildRecord,
        hasEntityChanges,
        verifyChanges,
        childForms: props.childForms,
        updateCompleteChildRecord,
    };
};

export default useBaseForm;
