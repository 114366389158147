import { createSlice } from '@reduxjs/toolkit';
import { SliceState } from '../types/store/GridsStore.types';

const initialState: SliceState = {
    pricingAnalysisTool: {
        pricingConcernDetails: {
            column: null,
            filter: null,
        },
        pricingProposalReport: {
            column: null,
            filter: null,
        },
        pricingCustomerProductDetails: {
            column: null,
            filter: null,
        },
    },
    apAutomation: {
        documentList: {
            column: null,
            filter: null,
        },
    },
    administration: {
        buildingsList: {
            column: null,
            filter: null,
        },
        businessEntityList: {
            column: null,
            filter: null,
        },
        departmentCodesList: {
            column: null,
            filter: null,
        },
        groupsList: {
            column: null,
            filter: null,
        },
        languagesList: {
            column: null,
            filter: null,
        },
        permissionRolesList: {
            column: null,
            filter: null,
        },
        revisionSearchList: {
            column: null,
            filter: null,
        },
        rolesList: {
            column: null,
            filter: null,
        },
        subscribersList: {
            column: null,
            filter: null,
        },
        usersList: {
            column: null,
            filter: null,
        },
        featureFlagsList: {
            column: null,
            filter: null,
        },
    },
    formulation: {
        ghsHazardPhrases: {
            column: null,
            filter: null,
        },
        ghsPrecautionaryPhrases: {
            column: null,
            filter: null,
        },
        sdsSectionHeader: {
            column: null,
            filter: null,
        },
        localeGroupList: {
            column: null,
            filter: null,
        },
    },
};

const slice = createSlice({
    name: 'grids',
    initialState: initialState,
    reducers: {
        updateGridModels: (state: any, action) => {
            state[action.payload.gridLocation][action.payload.gridName][
                action.payload.type
            ] = action.payload.model;
        },
    },
});

export default slice.reducer;

// Actions
export const { updateGridModels } = slice.actions;
