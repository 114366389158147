import {
    BuildingsForm,
    BuildingsGrid,
    BusinessEntityForm,
    BusinessEntityGrid,
    DepartmentCodesForm,
    DepartmentCodesGrid,
    FeatureFlagsGrid,
    GroupsForm,
    GroupsGrid,
    LanguagesGrid,
    PermissionsGrid,
    RevisionHistoryForm,
    RolesForm,
    RolesGrid,
    SharedComponents,
    SubscriberForm,
    SubscriberGrid,
    UserForm,
    UserGrid,
    UserSettings,
} from './termDefs.types';

export const UserFormDefs: UserForm = {
    Create_New_User: 'user-create-new-user-text',
    Primary_Information: 'user-primary-information-text',
    Cancel: 'user-cancel-button',
    Add: 'user-add-button',
    Update: 'user-update-button',
    Delete: 'user-delete-button',
    History: 'user-history-button',
    Status: 'user-status',
    First_Name: 'user-first-name',
    Middle_Name: 'user-middle-name',
    Last_Name: 'user-last-name',
    Email: 'user-email',
    Phone: 'user-phone',
    Messaging_Preference: 'user-messaging-preference',
    External_Username: 'user-external-username',
    Datacor_Admin: 'user-datacor-admin',
    App_Stream: 'user-app-stream',
    AP_Automation: 'user-ap-automation-text',
    Invoice_Target: 'user-invoice-target',
    Groups_and_Roles: 'user-groups-and-roles-text',
    Groups: 'user-groups',
    Roles: 'user-roles',
    User_Trial: 'user-user-trial-text',
    Set_Up_User_With_Trial_Period: 'user-set-up-user-with-trial-period',
    Start_Date: 'user-start-mm/dd/yyyy',
    End_Date: 'user-end-mm/dd/yyyy',
    Subscribers_Text: 'user-subscribers-text',
    Subscribers: 'user-subscribers',
    Business_Entity: 'user-business-entity-text',
    Department_Code_ID: 'user-department-code-id',
    Supervisor_ID: 'user-supervisor-id',
};

export const UserGridDefs: UserGrid = {
    First_Name: 'user-grid-first-name',
    Last_Name: 'user-grid-last-name',
};

export const UserSettingsDefs: UserSettings = {
    SETTINGS_HEADER: 'user-settings-header',
    DARK_MODE: 'user-settings-dark-mode-switch',
    DEFAULT_PAGE: 'user-settings-default-page',
    LANGUAGE: 'user-settings-lang',
    LOGIN_HEADER: 'user-settings-login-settings-header',
    CHANGE_PASSWORD: 'user-settings-change-password-button',
    CHANGE_EMAIL: 'user-settings-change-password-button',
};
export const SubscriberFormDefs: SubscriberForm = {
    Create_New_Subscriber: 'subscriber-create-new-subscriber-text',
    Name: 'subscriber-name',
    Primary_Information: 'subscriber-primary-information-text',
    Cancel_Button: 'subscriber-cancel-button',
    Update_Button: 'subscriber-update-button',
    History_Button: 'subscriber-history-button',
    Add_Button: 'subscriber-add-button',
    Is_Datacor: 'subscriber-is-datacor',
    Department_Codes: 'subscriber-department-codes-text',
    Business_Entity: 'subscriber-business-entity-text',
    Department_Name: 'subscriber-form-department-name',
    Department_Code: 'subscriber-form-department-code',
    View_Business_Entity: 'subscriber-view-business-entity',
};

export const SubscriberGridDefs: SubscriberGrid = {
    Subscriber_Name: 'subscriber-subscriber-name',
    Subscriber_Description: 'subscriber-description',
    Subscriber_Date_Created: 'subscriber-date-created',
    Subscriber_Last_Update_Date: 'subscriber-last-update-date',
    Subscriber_Add_New: 'subscriber-add-new',
    View_Business_Entities: 'subscriber-view-business-entities',
};
export const BusinessEntityGridDefs: BusinessEntityGrid = {
    View_Business_Entities: 'business-entity-grid-view-business-entities',
    Name: 'business-entity-grid-name',
    Description: 'business-entity-grid-description',
    Subscriber: 'business-entity-grid-subscriber',
    Date_Created: 'business-entity-grid-date-created',
    Last_Update_Date: 'business-entity-grid-last-update-date',
};

export const BusinessEntityFormDefs: BusinessEntityForm = {
    Create_New_Business_Entity: 'business-entity-create-new-business-entity',
    Name: 'business-entity-name',
    Description: 'business-entity-description',
    Type: 'business-entity-type',
    Parent_Business_Entity: 'business-entity-parent-business-entity',
    Enable_External_API_Access: 'business-entity-enable-external-api-access',
    Zone_Information: 'business-entity-zone-information',
    Platform: 'business-entity-platform',
    URL: 'business-entity-url',
    Port: 'business-entity-port',
};
export const BuildingsFormDefs: BuildingsForm = {
    Create_New_Building: 'buildings-create-new-building',
    Building_Code: 'buildings-form-building-code',
    Building_Name: 'buildings-form-building-name',
    Supplier_Code: 'buildings-form-supplier-code',
    Customer_Code: 'buildings-form-customer-code',
    Note: 'buildings-form-note',
    Address_Information: 'buildings-address-information',
    Country: 'buildings-country',
    Zip: 'buildings-zip',
    State: 'buildings-form-state',
    Street: 'buildings-form-street',
    County: 'buildings-form-county',
    City: 'buildings-form-city',
};

export const BuildingsGridDefs: BuildingsGrid = {
    Building_Code: 'buildings-building-code',
    Building_Name: 'buildings-building-name',
    Supplier_Code: 'buildings-supplier-code',
    Customer_Code: 'buildings-customer-code',
    Street: 'buildings-street',
    City: 'buildings-city',
    County: 'buildings-county',
    State: 'buildings-state',
    Postal_Code: 'buildings-postal-code',
    Country_Code: 'buildings-country-code',
    Note: 'buildings-note',
};
export const RolesGridDefs: RolesGrid = {
    Name: 'roles-grid-name',
    Description: 'roles-grid-description',
    Business_Entity: 'roles-grid-business-entity',
    Date_Created: 'roles-grid-date-created',
    Last_Update_Date: 'roles-grid-last-update-date',
};

export const GroupsGridDefs: GroupsGrid = {
    Group_Name: 'groups-group-name',
    Groups_Grid_Description: 'groups-grid-description',
    Business_Entity: 'groups-business-entity',
    Date_Created: 'groups-date-created',
    Last_Update_Date: 'groups-last-update-date',
};
export const RolesFormDefs: RolesForm = {
    Name: 'roles-form-name',
    Description: 'roles-form-description',
    Users: 'roles-form-users',
    Permissions: 'roles-form-permissions',
    Create_New_Role: 'roles-form-create-new-role',
};

export const GroupsFormDefs: GroupsForm = {
    Create_New_Group: 'groups-create-new-group',
    Name: 'groups-name',
    Description: 'groups-form-description',
    Users: 'groups-users',
};
export const PermissionsGridDefs: PermissionsGrid = {
    Name: 'permissions-name',
    Description: 'permissions-description',
    Business_Entity: 'permissions-business-entity',
    Date_Created: 'permissions-date-created',
    Last_Update_Date: 'permissions-last-update-date',
};

export const RevisionHistoryFormDefs: RevisionHistoryForm = {
    Users: 'revision-history-form-users',
    Start_Date: 'revision-history-form-start',
    End_Date: 'revision-history-form-end',
    Actions: 'revision-history-form-actions',
    Sources: 'revision-history-form-sources',
    Modules: 'revision-history-form-modules',
    Date_Created: 'revision-history-form-date-created',
    User: 'revision-history-form-user',
    Revision_Action: 'revision-history-form-revision-action',
    Entity: 'revision-history-form-entity',
    Module: 'revision-history-form-module',
    Revision_Source: 'revision-history-form-revision-source',
    View_Items: 'revision-history-form-review-items',
    Search: 'revision-history-form-search',
};

export const LanguagesGridDefs: LanguagesGrid = {
    Code: 'languages-code',
    Name: 'languages-name',
    ISO3: 'languages-iso3',
    ISO2: 'languages-iso2',
};

export const DepartmentCodesFormDefs: DepartmentCodesForm = {
    Create_New_Department_Code: 'department-codes-create-new-department-code',
    Name: 'department-codes-form-name',
    Code: 'department-codes-form-code',
};

export const DepartmentCodesGridDefs: DepartmentCodesGrid = {
    Name: 'department-codes-grid-name',
    Code: 'department-codes-grid-code',
    Date_Created: 'department-codes-grid-date-created',
    Last_Update_Date: 'department-codes-grid-last-update-date',
};
export const FeatureFlagsGridDefs: FeatureFlagsGrid = {
    Global: 'feature-flags-global',
    Business_Entity: 'feature-flags-business-entity',
    Enabled: 'feature-flags-enabled',
    Name: 'feature-flags-name',
    Description: 'feature-flags-description',
    Locked: 'feature-flags-locked',
};
export const SharedComponentsDefs: SharedComponents = {
    Add_New: 'shared-base-grid-toolbar-add-new',
    Bulk_Download: 'shared-base-grid-toolbar-bulk-download',
    Settings: 'shared-base-grid-toolbar-settings',
    Bulk_Upload: 'shared-base-grid-toolbar-bulk-upload',
    Reset_View: 'shared-base-grid-toolbar-reset-view',
    Enable_Disable_Selected: 'shared-base-grid-toolbar-enable-disable-selected',
    Dock_Title: 'shared-base-grid-toolbar-dock-title',
    Sort: 'shared-base-grid-toolbar-sort',
    Cancel: 'shared-base-form-toolbar-cancel',
    Add: 'shared-base-form-toolbar-add',
    Update: 'shared-base-form-toolbar-update',
    Delete: 'shared-base-form-toolbar-delete',
    Copy: 'shared-base-form-toolbar-copy',
    History: 'shared-base-form-toolbar-history',
    Primary_Information: 'shared-base-form-primary-information',
    Business_Entity_Select: 'shared-business-entity-select',
    Selected: 'shared-multi-select-grid-selected',
    Select: 'shared-multi-select-grid-select',
    Modal_Cancel: 'shared-confirm-modal-cancel',
    Modal_Ok: 'shared-confirm-modal-ok',
};
