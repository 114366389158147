import { useNavigate } from 'react-router-dom';
import {
    useGetAllTypesQuery,
    useUpdateListTypesMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';
import { FA_AssetType } from '../../../types/FixedAsset.types';
import { useEffect } from 'react';
import _ from 'lodash';
import { getMaxSortOrderValue } from '../../../store/fixedAssets';

const UseFixedAssetTypeGrid = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { data: typeList, isLoading: isLoadingTypes } = useGetAllTypesQuery();
    const [updateAssetTypes] = useUpdateListTypesMutation();

    const canCreateAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.CREATE
    );
    const canViewAssetType = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_TYPES.VIEW
    );

    const handleGridEdits = (editedRows: FA_AssetType[]) => {
        updateAssetTypes(editedRows);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/accounting/fixedAssets/type/${row.id}/${RoutingValues.update}`
            );
        } else {
            navigate(
                `/accounting/fixedAssets/type/${RoutingValues.newId}/${RoutingValues.create}`
            );
        }
    };

    useEffect(() => {
        let maxValue = 1;
        if (typeList && typeList.length > 0) {
            const data = _.maxBy(
                typeList,
                (assetType: FA_AssetType) => assetType.sortOrder
            );
            maxValue = data.sortOrder + 1;
        }
        dispatch(
            getMaxSortOrderValue({ isLoading: false, maxValue: maxValue })
        );
    }, typeList);

    return {
        typeList,
        isLoadingTypes,
        canCreateAssetType,
        canViewAssetType,
        redirectToForm,
        handleGridEdits,
    };
};

export default UseFixedAssetTypeGrid;
