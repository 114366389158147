import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import LoaderButton from '../../LoaderButton';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import LockIcon from '@mui/icons-material/Lock';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RevisionHistoryDrawer from '../../administration/RevisionHistoryDrawer';
import TranslatableText from '../../i18n/TranslatableText';
import { SharedComponentsDefs } from '../../../constants/i18n/translations/termDefinitions/platform';

enum ButtonClickOption {
    None = 0,
    Add = 1,
    Update = 2,
    Delete = 3,
    Revision = 4,
    Lock = 5,
    Copy = 6,
}

export interface BaseFormToolbarProps {
    isNew: boolean;
    onCreate?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
    onCancel: () => void;
    onLock?: () => void;
    onRevision?: () => void;
    onCopy?: () => void;
    showOnRevision?: boolean;
    fieldMappings?: any;
    entityUuid?: any;
    displayCreateButton?: boolean;
    displayUpdateButton?: boolean;
    displayDeleteButton?: boolean;
    displayLockButton?: boolean;
    displayCopyButton?: boolean;
    isLocked?: boolean;
    customToolbarButtons?: React.ReactNode;
    hasEntityChanges?: () => boolean;
    childForms?: any[];
    termSet?: any;
}

const BaseFormToolbar = (props: BaseFormToolbarProps) => {
    const [buttonClickOption, setButtonClickOption] =
        useState<ButtonClickOption>(ButtonClickOption.None);
    const [open, setOpen] = useState(false);

    const handleCreateButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Add);
        props.onCreate();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleUpdateButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Update);
        props.onUpdate();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleDeleteButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Delete);
        props.onDelete();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleRevisionButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Revision);
        setOpen((prev) => !prev);
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleLockButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Lock);
        props.onLock();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleCopyButtonClick = () => {
        setButtonClickOption(ButtonClickOption.Copy);
        props.onCopy();
        setButtonClickOption(ButtonClickOption.None);
    };

    const handleDisableUpdateButtonAction = (): boolean => {
        let disable =
            buttonClickOption !== ButtonClickOption.None &&
            buttonClickOption !== ButtonClickOption.Update;

        if (!disable && props.hasEntityChanges) {
            disable = !props.hasEntityChanges();
        }

        return disable;
    };

    const handleDisableCopyButtonAction = (): boolean => {
        let disable =
            buttonClickOption !== ButtonClickOption.None &&
            buttonClickOption !== ButtonClickOption.Copy;

        if (!disable && props.hasEntityChanges) {
            disable = props.hasEntityChanges();
        }

        return disable;
    };

    return (
        <Grid
            container
            flexDirection={'row'}
            spacing={2}
            className="form-header-body">
            <Button
                className="form-header-button"
                variant="text"
                data-testid="cancelButton"
                onClick={props.onCancel}
                disabled={buttonClickOption !== ButtonClickOption.None}>
                <CancelIcon />
                <div className="form-header-button-text">
                    <TranslatableText
                        termSet={props.termSet}
                        termKey={SharedComponentsDefs.Cancel}
                    />
                </div>
            </Button>
            {props.isNew && props.displayCreateButton ? (
                <LoaderButton
                    className="form-header-button"
                    type="submit"
                    variant="text"
                    data-testid="saveCreateButton"
                    onClick={handleCreateButtonClick}
                    isLoading={buttonClickOption === ButtonClickOption.Add}
                    disabled={
                        buttonClickOption !== ButtonClickOption.None &&
                        buttonClickOption !== ButtonClickOption.Add
                    }>
                    <SaveIcon style={{ color: '#00A84E' }} />
                    <div className="form-header-button-text">
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={SharedComponentsDefs.Add}
                        />
                    </div>
                </LoaderButton>
            ) : null}
            {!props.isNew && props.displayUpdateButton ? (
                <LoaderButton
                    className="form-header-button"
                    type="submit"
                    variant="text"
                    data-testid="saveCreateButton"
                    onClick={handleUpdateButtonClick}
                    isLoading={buttonClickOption === ButtonClickOption.Update}
                    disabled={handleDisableUpdateButtonAction()}>
                    <SaveIcon style={{ color: '#00A84E' }} />
                    <div className="form-header-button-text">
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={SharedComponentsDefs.Update}
                        />
                    </div>
                </LoaderButton>
            ) : null}
            {!props.isNew && props.displayDeleteButton ? (
                <LoaderButton
                    className="form-header-button"
                    variant="text"
                    data-testid="deleteButton"
                    onClick={handleDeleteButtonClick}
                    isLoading={buttonClickOption === ButtonClickOption.Delete}
                    disabled={
                        buttonClickOption !== ButtonClickOption.None &&
                        buttonClickOption !== ButtonClickOption.Delete
                    }>
                    <DeleteIcon style={{ color: '#FF0000' }} />
                    <div className="form-header-button-text">
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={SharedComponentsDefs.Delete}
                        />
                    </div>
                </LoaderButton>
            ) : null}
            {!props.isNew && props.displayLockButton && (
                <LoaderButton
                    className="form-header-button"
                    variant="text"
                    data-testid="lockButton"
                    onClick={handleLockButtonClick}
                    isLoading={buttonClickOption === ButtonClickOption.Lock}
                    disabled={
                        (buttonClickOption !== ButtonClickOption.None &&
                            buttonClickOption !== ButtonClickOption.Lock) ||
                        props.isLocked
                    }>
                    <LockIcon />
                    <div className="form-header-button-text">
                        {props.isLocked ? 'Locked' : 'Lock'}
                    </div>
                </LoaderButton>
            )}
            {props.customToolbarButtons}
            {!props.isNew && props.displayCopyButton ? (
                <LoaderButton
                    className="form-header-button"
                    type="submit"
                    variant="text"
                    data-testid="copyButton"
                    onClick={handleCopyButtonClick}
                    isLoading={buttonClickOption === ButtonClickOption.Copy}
                    disabled={handleDisableCopyButtonAction()}>
                    <CopyAllIcon style={{ color: '#00A84E' }} />
                    <div className="form-header-button-text">
                        <TranslatableText
                            termSet={props.termSet}
                            termKey={SharedComponentsDefs.Copy}
                        />
                    </div>
                </LoaderButton>
            ) : null}
            {!props.isNew && props.showOnRevision && (
                <>
                    <LoaderButton
                        className="form-header-button"
                        variant="text"
                        data-testid="historyButton"
                        onClick={handleRevisionButtonClick}
                        isLoading={
                            buttonClickOption === ButtonClickOption.Revision
                        }
                        disabled={
                            buttonClickOption !== ButtonClickOption.None &&
                            buttonClickOption !== ButtonClickOption.Revision
                        }>
                        <HistoryIcon />
                        <div className="form-header-button-text">
                            <TranslatableText
                                termSet={props.termSet}
                                termKey={SharedComponentsDefs.History}
                            />
                        </div>
                    </LoaderButton>
                    <RevisionHistoryDrawer
                        open={open}
                        setOpen={setOpen}
                        fieldMappings={props.fieldMappings}
                        entityUuid={props.entityUuid}
                    />
                </>
            )}
        </Grid>
    );
};

export default BaseFormToolbar;
