import { useNavigate } from 'react-router-dom';
import { RoutingValues } from '../../../types/Shared.types';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useGetAttributeDefinitionsQuery } from '../../../services/attributes/attributes.service';
import { skipToken } from '@reduxjs/toolkit/query';
import LockedCellRenderer from '../../../components/grids/cellRenderers/lockedCell.renderer';

const useAttributeDefinitionsGrid = (attrEntityId: string) => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const { data: attributeDefinitions, isLoading: isLoadingDefinitions } =
        useGetAttributeDefinitionsQuery(
            attrEntityId ? attrEntityId : skipToken
        );

    const canManageAttribute = PermissionsUtil.isPermissionEnabled(
        user?.permissions,
        PERMISSIONS.SETUP.ATTRIBUTES.MANAGE
    );

    const redirectToForm = (row: any) => {
        if (row.id) {
            navigate(
                `/setup/attributes/definitions/${row.id}/${RoutingValues.update}`,
                { state: { attrEntityId: attrEntityId } }
            );
        } else {
            navigate(
                `/setup/attributes/definitions/${RoutingValues.newId}/${RoutingValues.create}`,
                { state: { attrEntityId: attrEntityId } }
            );
        }
    };

    const attributeTypesColDef: ColDef[] = [
        {
            field: 'code',
            headerName: 'Attribute Code',
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'attrValueType.name',
            headerName: 'Attribute Type',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'locked',
            headerName: 'Locked',
            ...DefaultColumnTypes.Locked,
        },
    ];

    const frameworkComponents = {
        lockedCellRenderer: LockedCellRenderer,
    };

    return {
        redirectToForm,
        attributeTypesColDef,
        canManageAttribute,
        attributeDefinitions,
        isLoadingDefinitions,
        frameworkComponents,
    };
};

export default useAttributeDefinitionsGrid;
