import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useExposureAgencyGrid from './hooks/useExposureAgencyGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { ExposureAgencyGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const ExposureAgencyGrid = () => {
    const {
        isLoading,
        exposureAgencyList,
        redirectToForm,
        canCreateExposureAgency,
        canViewExposureAgency,
        termSet,
    } = useExposureAgencyGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ExposureAgencyGridDefs.Agency_Code}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ExposureAgencyGridDefs.Description}
                    />
                );
            },
            minWidth: DefaultColumnTypes.LongText.minWidth,
            filter: DefaultColumnTypes.LongText.filter,
            ...DefaultColumnTypes.AutoHeight,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            displayGrid={canViewExposureAgency}
            isLoading={isLoading}
            rowData={exposureAgencyList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateExposureAgency}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default ExposureAgencyGrid;
