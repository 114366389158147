import { RootStateOrAny, useSelector } from 'react-redux';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    useGetFormulaQuery,
    useCreateFormulaMutation,
    useUpdateFormulaMutation,
    useDeleteFormulaMutation,
} from '../../../../../services/formulation/rdFormula/rdFormulaHeader/rdFormula.service';
import {
    RDFormula,
    RDFormulaCasComposition,
    RDFormulaComponent,
    RDFormulaTotal,
} from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import { ChildEditableGridProps } from '../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { ExtendedFormProps } from '../../../../form/helpers/ExtendedFormProps';
import { useContext, useState } from 'react';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { ChildFormProps } from '../../../../form/helpers/ChildFormProps';
import useChildFormHelper from '../../../../form/hooks/useChildFormHelper';

const useRDFormulaForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: currentFormula, isLoading: isLoadingFormulas } =
        useGetFormulaQuery(id ? id : skipToken);

    const displayCreateNewButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.RD_FORMULA.CREATE
    );

    const displayUpdateButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.RD_FORMULA.EDIT
    );

    const displayDeleteButton = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.RD_FORMULA.DELETE
    );

    const [currentTab, setCurrentTab] = useState<string>('0');

    const [createFormula] = useCreateFormulaMutation();
    const [updateFormula] = useUpdateFormulaMutation();
    const [deleteFormula] = useDeleteFormulaMutation();

    const blankFormula: RDFormula = {
        formulaCode: '',
        formulaDescription: '',
        itemId: null,
        formulaStatusId: null as bigint,
        template: null,
        productDate: null,
        propertiesDate: null,
        maxPilotQty: null,
        unitId: null,
        tradeName: '',
        approvedByUserId: null,
        approvedDate: null,
        approvalTypeId: null,
        supersedesItemId: null,
        supersededByItemId: null,
        isDeleted: null,
        additionalText: '',
        businessEntityId: user?.businessEntity?.id,
        casCompositions: [] as RDFormulaCasComposition[],
        components: [] as RDFormulaComponent[],
        formulaTotals: {
            formulaUomId: null,
            formulaTotal: null as number,
            manualYieldPct: null as number,
            calculatedYieldPct: null as number,
            yieldCalcOverride: false,
            calcSpecificGravity: null as number,
            totalUnitCost: null as number,
            totalNetCost: null as number,
            totalExtendedCost: null as number,
        } as RDFormulaTotal,
    };

    const { registerChildForm, allChildrenFormMethods, childrenFormHooks } =
        useChildFormHelper();

    const {
        fields,
        hasEntityChanges,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleChildrenRecords,
        handleChildRecord,
    } = useBaseForm({
        closePath: '/formulation/rndFormula',
        blankEntity: blankFormula,
        activeEntity: currentFormula,
        onlyUpdateEntityWithChanges: true,
        childForms: childrenFormHooks,
        getDescription: () => {
            return 'R&D Formula';
        },
        createEntity: () => {
            return createFormula({
                postBody: fields,
            });
        },
        updateEntity: () => {
            return updateFormula({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteFormula(id);
        },
    });

    const parentData = {
        currentParentRecord: fields,
        isParentLoading: isLoadingFormulas,
        user: user,
    };

    const editableGridProps: ChildEditableGridProps<RDFormula> = {
        parentData,
        displayGridButtons: {
            showAddButton:
                (displayCreateNewButton && displayUpdateButton) ||
                (displayCreateNewButton && !id),
            showDeleteButton: displayDeleteButton,
            showSortButton:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
            isGridEditable:
                (displayCreateNewButton && !id) ||
                (displayUpdateButton && !!id),
        },
        helpers: {
            handleChildrenRecords,
        },
    };

    const extendedFormProps: ExtendedFormProps<RDFormula> = {
        parentData,
        helpers: {
            formMethods,
            handleFieldChange,
        },
        permissions: {
            canCreate: displayCreateNewButton,
            canUpdate: displayUpdateButton,
            canDelete: displayDeleteButton,
            canView: PermissionsUtil.isPermissionEnabled(
                user.permissions,
                PERMISSIONS.FORMULATION.RD_FORMULA.VIEW
            ),
        },
    };

    const childFormProps: ChildFormProps<RDFormula> = {
        parentData: {
            ...parentData,
            dataFromQuery: currentFormula,
        },
        permissions: {
            canCreate: displayCreateNewButton,
            canUpdate: displayUpdateButton,
            disabledField:
                parentData.currentParentRecord.id && !displayUpdateButton,
        },
        helpers: {
            handleChildRecord,
            registerChildForm,
        },
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    return {
        fields,
        hasEntityChanges,
        allFormMethods: [formMethods, ...allChildrenFormMethods],
        isLoadingFormulas,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        termSet,
        display: {
            displayCreateNewButton,
            displayUpdateButton,
            displayDeleteButton,
            readOnly: id && !displayUpdateButton,
        },
        children: {
            editableGridProps,
            extendedFormProps,
            childFormProps,
        },
        currentTab,
        setCurrentTab,
    };
};
export default useRDFormulaForm;
