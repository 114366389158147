import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import DatacorLogoSpinner from '../datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from './BaseGrid';
import { BaseGridProps } from './Grids.propTypes';

export type BaseFormGridProps = {
    title?: string;
    isLoading: boolean;
    belowGridChildren?: React.ReactNode;
} & BaseGridProps;

const BaseFormGrid = (props: BaseFormGridProps) => {
    props = { domLayout: 'normal', paginationPageSize: 30, ...props };
    props.defaultColDef = props.defaultColDef || {
        resizable: true,
        sortable: true,
        unSortIcon: true,
        filter: true,
        wrapText: true,
        floatingFilter: true,
        flex: 1,
    };

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
                title={props.title}
                sx={{ display: props.title ? 'block' : 'none' }}
            />

            {props.isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent className={'full-size-item'}>
                    <BaseGrid {...props} />
                    {props.belowGridChildren}
                </CardContent>
            )}
        </Card>
    );
};

export default BaseFormGrid;
