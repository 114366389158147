import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'accounting',
    initialState: initialState,
    reducers: {},
});

export default slice.reducer;

//TODO: specify actions for export
export const actions = slice.actions;
