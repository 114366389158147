import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BaseForm from '../../../../components/form/baseForm/BaseForm';
import FormPartial from '../../../../components/form/formContainer/FormPartial';
import TextInput from '../../../../components/form/formInputs/TextInput/TextInput';
import useExposureAgencyForm from './hooks/useExposureAgencyForm';
import { ExposureAgenciesFormDefs } from '../../../../constants/i18n/translations/termDefinitions/formulation';
import TranslatableText from '../../../../components/i18n/TranslatableText';

type ParamTypes = {
    id: string;
};

const ExposureAgencyForm = () => {
    const { id } = useParams<ParamTypes>();

    const {
        isLoading,
        fields,
        formMethods,
        handleFieldChange,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        canCreateExposureAgency,
        canUpdateExposureAgency,
        canDeleteExposureAgency,
        termSet,
    } = useExposureAgencyForm(id);

    return (
        <BaseForm
            title={
                <TranslatableText
                    termSet={termSet}
                    termKey={ExposureAgenciesFormDefs.Exposure_Agency}
                />
            }
            formMethods={formMethods}
            isLoading={isLoading}
            isNew={!id}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateExposureAgency}
            displayUpdateButton={canUpdateExposureAgency}
            displayDeleteButton={canDeleteExposureAgency}
            readOnly={id && !canUpdateExposureAgency}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ExposureAgenciesFormDefs.Exposure_Agency_Code
                                    }
                                />
                            }
                            id="code"
                            formMethods={formMethods}
                            value={fields.code}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={75}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            fullWidth
                            label={
                                <TranslatableText
                                    termSet={termSet}
                                    termKey={
                                        ExposureAgenciesFormDefs.Description
                                    }
                                />
                            }
                            id="description"
                            formMethods={formMethods}
                            value={fields.description}
                            onChange={handleFieldChange}
                            required={true}
                            maxLength={255}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default ExposureAgencyForm;
