import { LocaleGroup } from '../../../types/formulation';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../serviceInterfaces';
import { SelectionOption } from '../../../types/Shared.types';
import { datacorAppApi } from '../../datacorAppApi';

const serviceUrl: string = 'formulation/localeGroup';

export const localeGroupApi = datacorAppApi.injectEndpoints({
    endpoints: (build) => ({
        createLocaleGroup: build.mutation<
            LocaleGroup,
            BaseCreateEntityArgs<LocaleGroup>
        >({
            query: (args) => ({
                url: serviceUrl,
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['LocaleGroup'],
        }),
        updateLocaleGroup: build.mutation<
            LocaleGroup,
            BaseUpdateEntityArgs<LocaleGroup>
        >({
            query: (args) => ({
                url: `${serviceUrl}/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['LocaleGroup', 'ActiveLocaleGroup'],
        }),
        updateAllLocaleGroup: build.mutation<LocaleGroup, any>({
            query: (args) => ({
                url: serviceUrl,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['LocaleGroup'],
        }),
        deleteLocaleGroup: build.mutation<LocaleGroup, string>({
            query: (id) => ({
                url: `${serviceUrl}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['LocaleGroup'],
        }),
        getLocaleGroup: build.query<LocaleGroup, string>({
            query: (id) => {
                return {
                    url: `${serviceUrl}/${id}`,
                };
            },
            providesTags: ['ActiveLocaleGroup'],
        }),
        listLocaleGroup: build.query<LocaleGroup[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            providesTags: ['LocaleGroup'],
        }),
        listLocaleGroupDropDown: build.query<SelectionOption[], void>({
            query: () => {
                return {
                    url: serviceUrl,
                };
            },
            transformResponse: (response: LocaleGroup[]) => {
                return response.map((current: LocaleGroup) => {
                    return {
                        label: current.groupId,
                        value: current.id,
                        object: current,
                        description: current.description,
                    };
                });
            },
            providesTags: ['LocaleGroup'],
        }),
        listLocaleGroupByActive: build.query<LocaleGroup[], boolean>({
            query: (active) => {
                return {
                    url: serviceUrl,
                    params: {
                        active,
                    },
                };
            },
            providesTags: ['LocaleGroup'],
        }),
    }),
});

export const {
    useCreateLocaleGroupMutation,
    useUpdateLocaleGroupMutation,
    useUpdateAllLocaleGroupMutation,
    useDeleteLocaleGroupMutation,
    useGetLocaleGroupQuery,
    useListLocaleGroupQuery,
    useListLocaleGroupDropDownQuery,
    useListLocaleGroupByActiveQuery,
} = localeGroupApi;
