import { FA_Asset } from '../../../../types/FixedAsset.types';
import { useEffect, useState } from 'react';
import { PERMISSIONS } from '../../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { PermissionsUtil } from '../../../../utils/permissions/permissionsUtil';

export class MainEntryRow {
    public readonly id: string;
    public readonly asset: FA_Asset;
    public readonly assetEntries: FA_Asset[] = [];

    constructor(fixedAssetEntry: FA_Asset) {
        this.id = `${fixedAssetEntry.id}|${fixedAssetEntry.inServiceDate}`;
        this.asset = fixedAssetEntry;
        this.addEntry(fixedAssetEntry);
    }

    public addEntry = (entry: FA_Asset) => {
        this.assetEntries.push(entry);
    };
}

const useDepreciationSummaryGrid = () => {
    const user = useSelector((state: RootState) => state.user);

    const canViewDepreciationSummaryReport =
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.FIXED_ASSETS.REPORTS.DEPRECIATION_SUMMARY.VIEW
        );

    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(true);
    const [assetEntryList, setAssetEntryList] = useState<FA_Asset[]>([]);
    const [mainEntryRows, setMainEntryRows] = useState<MainEntryRow[]>([]);

    const _createRowsFromList = (list: FA_Asset[]): MainEntryRow[] => {
        const rowMap: Map<string, MainEntryRow> = new Map<
            string,
            MainEntryRow
        >();

        list?.forEach((entry: FA_Asset) => {
            //establish the row key for uniqueness
            const rowKey: string = `${entry.id}|${entry.ledgerAssetType}|${entry.inServiceDate}`;

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new MainEntryRow(entry));
            } else {
                rowMap.get(rowKey).addEntry(entry);
            }
        });

        return Array.from(rowMap.values());
    };

    useEffect(() => {
        setMainEntryRows(_createRowsFromList(assetEntryList));
    }, [assetEntryList]);

    return {
        showOptionsModal,
        setShowOptionsModal,
        setAssetEntryList,
        mainEntryRows,
        canViewDepreciationSummaryReport,
    };
};

export default useDepreciationSummaryGrid;
