import React, { useContext } from 'react';
import FormPartial from '../../../components/form/formContainer/FormPartial';
import { Grid } from '@mui/material';
import SettingsContext from '../../../contexts/settings.context';
import TextInput from '../../../components/form/formInputs/TextInput/TextInput';

export interface SettingSectionProps {
    onChange: (event: any, settingKey: string) => void;
}
const AccountingSettings = ({ onChange }: SettingSectionProps) => {
    const { settings } = useContext(SettingsContext);

    return (
        <FormPartial title={'Accounting Settings'}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        id={'negativeNumberFormat'}
                        onChange={(event: any) =>
                            onChange(event, 'accountingSettings')
                        }
                        fullWidth
                        value={
                            settings.accountingSettings?.negativeNumberFormat
                        }
                        label="Negative Number Format"
                    />
                </Grid>
            </Grid>
        </FormPartial>
    );
};

export default AccountingSettings;
