import React from 'react';
import {
    useCreateStatusMutation,
    useDeleteStatusMutation,
    useGetStatusQuery,
    useUpdateStatusMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FA_AssetStatus } from '../../../types/FixedAsset.types';
import useBaseForm from '../../form/hooks/useBaseForm';
import { PermissionsUtil } from '../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/Permissions.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoutingValues } from '../../../types/Shared.types';

const UseFixedAssetStatusForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const assetStatus = useSelector((state: RootState) => state.fixedAssets);
    const { data: activeStatus, isLoading: isLoadingStatus } =
        useGetStatusQuery(id === RoutingValues.newId ? skipToken : id);

    const canCreateAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.CREATE
    );
    const canUpdateAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.EDIT
    );
    const canDeleteAssetStatus = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FIXED_ASSETS.ASSET_STATUSES.DELETE
    );

    const [createStatus] = useCreateStatusMutation();
    const [updateStatus] = useUpdateStatusMutation();
    const [deleteStatus] = useDeleteStatusMutation();

    const blankStatus: FA_AssetStatus = {
        sortOrder: assetStatus.sortOrderSet.maxValue,
        code: '',
        description: '',
        isInService: false,
        isDisposal: false,
    };

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/status',
        blankEntity: blankStatus,
        activeEntity: activeStatus,
        getDescription: () => {
            return `Fixed Asset Status ${fields.code}`;
        },
        createEntity: () => {
            return createStatus({ postBody: fields });
        },
        updateEntity: () => {
            return updateStatus({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteStatus(id);
        },
    });

    const handleInServiceChange = (event: any) => {
        if (event.target.checked) {
            setValues({
                isInService: true,
                isDisposal: false,
            });
            return;
        }
        setValues({ isInService: false });
    };

    const handleDisposalChange = (event: any) => {
        if (event.target.checked) {
            setValues({
                isDisposal: true,
                isInService: false,
            });
            return;
        }
        setValues({ isDisposal: false });
    };

    return {
        fields,
        handleFieldChange,
        handleInServiceChange,
        handleDisposalChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingStatus,
        canCreateAssetStatus,
        canUpdateAssetStatus,
        canDeleteAssetStatus,
    };
};

export default UseFixedAssetStatusForm;
