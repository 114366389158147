import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import React from 'react';
import FormPartial from '../../../../../../components/form/formContainer/FormPartial';
import SubSection10_1Form from './subSections/subSection10_1/SubSection10_1Form';
import SubSection10_2Form from './subSections/subSection10_2/SubSection10_2Form';
import SubSection10_3Form from './subSections/subSection10_3/SubSection10_3Form';
import SubSection10_4Form from './subSections/subSection10_4/SubSection10_4Form';
import SubSection10_6Form from './subSections/subSection10_6/SubSection10_6Form';
import SubSection10_5Form from './subSections/subSection10_5/SubSection10_5Form';
import { SdsBlobSectionFormProps } from '../shared/blobs/hooks/SdsBlobSectionFormProps';
import { SdsHeader } from '../../../../../../types/formulation';
import TranslatableText from '../../../../../../components/i18n/TranslatableText';
import { SDSFormDefs } from '../../../../../../constants/i18n/translations/termDefinitions/formulation';

const SdsStabilityReactivity = (props: SdsBlobSectionFormProps<SdsHeader>) => {
    const { isParentLoading } = props.parentData;

    return (
        <>
            {isParentLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <>
                    <FormPartial
                        title={
                            (
                                <TranslatableText
                                    termSet={props.termSet}
                                    termKey={SDSFormDefs.Stability_Reactivity}
                                />
                            ) as unknown as string
                        }>
                        <SubSection10_1Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection10_2Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection10_3Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection10_4Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection10_5Form
                            {...props}
                            termSet={props.termSet}
                        />
                        <SubSection10_6Form
                            {...props}
                            termSet={props.termSet}
                        />
                    </FormPartial>
                </>
            )}
        </>
    );
};

export default SdsStabilityReactivity;
