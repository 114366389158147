import {
    GridOptions,
    GridPopups,
} from '../../../../../../components/grids/Grid.constants';
import DatacorLogoSpinner from '../../../../../../components/datacorLogoSpinner/DatacorLogoSpinner';
import BaseGrid from '../../../../../../components/grids/BaseGrid';
import React from 'react';
import useSdsCasCompositionGrid from './hooks/useSdsCasCompositionGrid';
import { ChildEditableGridProps } from '../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { SdsHeader } from '../../../../../../types/formulation';

const SdsCasCompositionGrid = (props: ChildEditableGridProps<SdsHeader>) => {
    const {
        casCompositionList,
        casCompositionColDefs,
        handleGridEdits,
        handleRowValidations,
    } = useSdsCasCompositionGrid(props);

    const { isParentLoading } = props.parentData;

    const { showAddButton, showSortButton, isGridEditable } =
        props.displayGridButtons;

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return !casCompositionColDefs || isParentLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <>
            <BaseGrid
                autoSize={true}
                displayGrid={!isParentLoading}
                dataKey={'casId'}
                useRowDrag={showSortButton}
                displaySortButton={showSortButton}
                sortKey={'sortOrder'}
                rowData={casCompositionList}
                columnDefs={casCompositionColDefs}
                gridClass="full-size-item"
                displayToolbar={true}
                displayCreateNewButton={showAddButton && isGridEditable}
                displayExportDataButton={false}
                displayResetStateButton={false}
                defaultColDef={defaultColumnDefinitionOptions}
                sizeColumnsToFit={true}
                isEditable={isGridEditable}
                onHandleGridEdits={handleGridEdits}
                onHandleRowValidations={handleRowValidations}
                context={GridPopups['SDS_CAS_COMPOSITION']}
                singleSelectApplyFilter={false}
                newRowPlaceholderValues={{
                    casValue: 'Enter a CAS Number...',
                    itemValue: 'Enter a Component...',
                    lowerOperatorId: 'Enter a Lower Operator...',
                    lowerPercentage: 'Enter a Lower %...',
                    upperOperatorId: 'Enter an Upper Operator...',
                    upperPercentage: 'Enter a Upper %...',
                    tradeSecretValue: 'Enter a Trade Secret...',
                }}
                createRow={casCompositionList?.length === 0}></BaseGrid>
        </>
    );
};

export default SdsCasCompositionGrid;
