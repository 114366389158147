import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFixedAssetTypeForm from './hooks/useFixedAssetTypeForm';
import FormPartial from '../../components/form/formContainer/FormPartial';
import TextInput from '../../components/form/formInputs/TextInput/TextInput';
import NumericInput from '../../components/form/formInputs/NumericInput/NumericInput';
import SelectInput from '../../components/form/formInputs/SelectInput/SelectInput';
import BaseForm, { ParamTypes } from '../../components/form/baseForm/BaseForm';
import { NumberMaxValues, RoutingValues } from '../../types/Shared.types';
import { ASSET_TYPE_FIELDS } from '../../constants/revisionHistory/revisionHistory.constants';

const FixedAssetTypeForm = () => {
    const { id, type } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        isLoadingDepreciationMethods,
        isLoadingAccountDropdowns,
        depreciationMethodOptions,
        recoveryUnitOptions,
        accountDropdowns,
        onDepreciationMethodChanged,
        accountsRequired,
        showAccountsPanel,
        canCreateAssetType,
        canUpdateAssetType,
        canDeleteAssetType,
    } = useFixedAssetTypeForm(id);

    return (
        <BaseForm
            title={'Fixed Asset Type'}
            formMethods={formMethods}
            isLoading={isLoadingType}
            isNew={type === RoutingValues.create}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCancel={closeForm}
            displayCreateButton={canCreateAssetType}
            displayUpdateButton={canUpdateAssetType}
            displayDeleteButton={canDeleteAssetType}
            showOnRevision={true}
            fieldMappings={ASSET_TYPE_FIELDS}
            entityUuid={fields?.uuid}>
            <FormPartial>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            fullWidth
                            label="Code"
                            id="code"
                            value={fields.code}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxLength={30}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumericInput
                            fullWidth
                            label="Sort Order"
                            id="sortOrder"
                            value={fields.sortOrder}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            maxValue={NumberMaxValues.smallint}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label="Description"
                            id="description"
                            value={fields.description}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumericInput
                            fullWidth
                            label="Recovery Term"
                            id="recoveryTerm"
                            value={fields.recoveryTerm}
                            onChange={handleFieldChange}
                            formMethods={formMethods}
                            required={true}
                            greaterThan={1}
                            maxValue={NumberMaxValues.integer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Recovery Unit"
                            id="recoveryUnitId"
                            value={fields.recoveryUnitId}
                            onChange={handleFieldChange}
                            options={recoveryUnitOptions}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Depreciation Method"
                            id="depreciationMethodId"
                            value={fields.depreciationMethodId}
                            onChange={onDepreciationMethodChanged}
                            options={depreciationMethodOptions}
                            disabled={isLoadingDepreciationMethods}
                            formMethods={formMethods}
                            required={true}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
            <FormPartial title="Accounts" isVisible={showAccountsPanel}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Depreciation Expense Account"
                            id="depreciationExpenseAccountId"
                            formMethods={formMethods}
                            value={fields.depreciationExpenseAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.depreciationExpenseAccounts ||
                                []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Accumulated Depreciation Account"
                            id="accumulatedDeprecationAccountId"
                            formMethods={formMethods}
                            value={fields.accumulatedDeprecationAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.accumulateDepreciationAccounts ||
                                []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Asset Account"
                            id="assetAccountId"
                            formMethods={formMethods}
                            value={fields.assetAccountId}
                            onChange={handleFieldChange}
                            options={accountDropdowns?.assetAccounts || []}
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Asset Control Account"
                            id="assetControlAccountId"
                            formMethods={formMethods}
                            value={fields.assetControlAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.assetControlAccounts || []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Disposal Gain Account"
                            id="disposalGainAccountId"
                            formMethods={formMethods}
                            value={fields.disposalGainAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.disposalGainAccounts || []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Disposal Loss Account"
                            id="disposalLossAccountId"
                            formMethods={formMethods}
                            value={fields.disposalLossAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.disposalLossAccounts || []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            fullWidth
                            label="Disposal Settlement Account"
                            id="disposalSettlementAccountId"
                            formMethods={formMethods}
                            value={fields.disposalSettlementAccountId}
                            onChange={handleFieldChange}
                            options={
                                accountDropdowns?.disposalSettlementAccounts ||
                                []
                            }
                            disabled={isLoadingAccountDropdowns}
                            required={accountsRequired}
                        />
                    </Grid>
                </Grid>
            </FormPartial>
        </BaseForm>
    );
};

export default FixedAssetTypeForm;
