import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import useBaseForm from '../../../../form/hooks/useBaseForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ToxicTest } from '../../../../../types/formulation';
import { PermissionsUtil } from '../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../constants/permissions/Permissions.constants';
import {
    useCreateToxicTestMutation,
    useDeleteToxicTestMutation,
    useGetToxicTestQuery,
    useUpdateToxicTestMutation,
} from '../../../../../services/formulation/toxicTest/toxicTest.service';
import SettingsContext from '../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../constants/i18n/translations/termSetDefinitions/formulation';

const UseToxicTestForm = (id: string) => {
    const user = useSelector((state: RootStateOrAny) => state.user);

    const { data: activeToxicTest, isLoading: isLoadingToxicTest } =
        useGetToxicTestQuery(id ? id : skipToken);

    const canCreateToxicTest = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_TEST.CREATE
    );
    const canUpdateToxicTest = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_TEST.EDIT
    );
    const canDeleteToxicTest = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.TOXIC_TEST.DELETE
    );

    const [createToxicTest] = useCreateToxicTestMutation();
    const [updateToxicTest] = useUpdateToxicTestMutation();
    const [deleteToxicTest] = useDeleteToxicTestMutation();

    const blankToxicTest: ToxicTest = {
        code: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.TOXIC_TEST_FORM,
              }
            : skipToken
    );

    const {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/formulation/toxicTest',
        reroute: true,
        blankEntity: blankToxicTest,
        activeEntity: activeToxicTest,
        getDescription: () => {
            return `Toxic Test ${fields.code}`;
        },
        createEntity: () => {
            return createToxicTest({ postBody: fields });
        },
        updateEntity: () => {
            return updateToxicTest({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteToxicTest(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingToxicTest,
        canCreateToxicTest,
        canDeleteToxicTest,
        canUpdateToxicTest,
        termSet,
    };
};

export default UseToxicTestForm;
