const organizationTags = [
    'Subscribers',
    'BusinessEntities',
    'BusinessEntityTypes',
    'BusinessEntityUsers',
    'BusinessEntityRoles',
    'BusinessEntityGroups',
    'BusinessEntityPermissions',
    'MessagingPreferences',
    'DepartmentCodes',
    'Buildings',
    'Languages',
    'UnitOfMeasure',
    'UnitOfMeasureGlobal',
    'ActiveUnitOfMeasure',
    'UnitOfMeasureConversion',
    'ActiveUnitOfMeasureConversion',
    'ActiveProperShippingName',
    'ProperShippingName',
    'UnitType',
    'Revisions',
    'BoilerPlateNote',
    'ActiveBoilerPlateNote',
    'EntitySetting',
];

const refreshOrg = [
    'BusinessEntityTypes',
    'BusinessEntityUsers',
    'BusinessEntityRoles',
    'BusinessEntityGroups',
    'BusinessEntityPermissions',
    'MessagingPreferences',
    'DepartmentCodes',
    'Buildings',
    'Languages',
    'UnitOfMeasure',
    'ActiveUnitOfMeasure',
    'UnitOfMeasureConversion',
    'ActiveUnitOfMeasureConversion',
    'ActiveProperShippingName',
    'ProperShippingName',
    'UnitType',
    'Revisions',
    'BoilerPlateNote',
    'ActiveBoilerPlateNote',
    'EntitySetting',
];

const permissionsTagTypes = ['Role', 'Permissions'];

const accessTagTypes = ['FeatureFlags', 'GlobalFeatureFlags'];

const accountingTagTypes = [
    'AllGeneralLedgerAccounts',
    'ActiveGeneralLedgerAccount',
    'AllContracts',
    'ActiveContract',
    'AllContractTypes',
    'ActiveContractType',
    'FunctionCodes',
    'ActiveFunctionCode',
    'AllFixedAssetAccountFunctions',
    'FunctionCodesByGroup',
    'AllAccountStatuses',
    'AllAccountTypes',
    'AllBalanceTypes',
    'AllRecoveryUnits',
    'AllBuildings',
];

const apaTagTypes = [
    'Invoice',
    'Approvers',
    'Users',
    'Addresses',
    'Tolerance',
    'Comment',
    'Workflows',
];

const appStreamingTagTypes = ['stream'];

const attributeTagTypes = [
    'AttributeDefinitions',
    'AttributeTemplates',
    'AttributeEntities',
];

const featureFlagTagTypes = ['FeatureFlags'];

const fixedAssetTagTypes = [
    'AllFixedAssets',
    'ActiveFixedAsset',
    'AllStatuses',
    'ActiveStatus',
    'AllCostCodes',
    'ActiveCostCode',
    'AllAccountGroups',
    'ActiveAccountGroup',
    'AllTypes',
    'ActiveType',
    'AllDepreciationMethodStatuses',
    'ActiveDepreciationMethodStatus',
    'AllDepreciationMethodTypes',
    'ActiveDepreciationMethodType',
    'AllDepreciationMethods',
    'ActiveDepreciationMethod',
    'FA_DepreciationSchedules',
    'FA_DepreciationEntries',
    'FA_AssetContract',
    'FA_DisposalEntries',
    'AssetEntries',
    'AcquisitionReport',
    'DisposalReport',
    'DepreciationDetailReport',
];

const casMasterTagTypes = ['Cas', 'ActiveCas'];

const casPicSignalWordTagTypes = ['CasPictogramSignalWord'];

const casSpeciesTagTypes = ['CasSpeciesClass'];

const casToxicRoutesTagType = ['CasToxicRoutes'];

const exposureAgencyTagTypes = ['ExposureAgency', 'ActiveExposureAgency'];

const propertyTagTypes = ['PropertyType'];

const formulaPropertyTagTypes = ['FormulaProperty', 'ActiveFormulaProperty'];

const hazardTagTypes = ['HazardType'];

const ghsHazardTagTypes = ['GhsHazardPhrase'];

const hazardClassTagTypes = ['HazardClassification'];

const inventoryTypeTagTypes = ['InventoryType'];

const itemMasterTagTypes = ['ItemMaster', 'ActiveItemMaster'];

const itemStatusTagTypes = ['ItemStatus'];

const localeTagTypes = ['Locale'];

const localeGroupTagTypes = ['LocaleGroup', 'ActiveLocaleGroup'];

const statementTypesTagTypes = ['StatementTypes'];

const precautionaryPhrasesTagTypes = [
    'PrecautionaryPhrases',
    'ActivePrecautionaryPhrase',
];

const rdFormulaTagTypes = [
    'ApprovalType',
    'Formula',
    'ActiveFormula',
    'FormulaStatus',
    'RDFormulaSequence',
];

const regulationTagTypes = [
    'RegulationBody',
    'RegulationSection',
    'Regulation',
    'ActiveRegulation',
    'RegulationBody',
    'RegulationSection',
];

const sdsTagTypes = [
    'SafetyDataSheetSectionHeader',
    'ActiveSafetyDataSheetSectionHeader',
    'SdsHeader',
    'ActiveSdsHeader',
    'SdsHeaderStatus',
    'ActiveSdsBlob',
    'SdsBlob',
    'SdsHeaderSequence',
];

const toxicSpeciesTagTypes = ['ToxicSpecies', 'ActiveToxicSpecies'];

const toxicTestTagTypes = ['ToxicTest', 'ActiveToxicTest'];

const hazardClassMappingsTagTypes = ['hazardClassMappings'];

const userTagTypes = ['User', 'Zones', 'Company', 'Audit'];

const casCompositionOperatorTypes = ['CasCompositionOperators'];

const i18nTagTypes = ['TermSet'];

const sequelizeTagTypes = ['Migrations'];

export const tagTypes = [
    ...organizationTags,
    ...permissionsTagTypes,
    ...accessTagTypes,
    ...accountingTagTypes,
    ...apaTagTypes,
    ...appStreamingTagTypes,
    ...attributeTagTypes,
    ...featureFlagTagTypes,
    ...fixedAssetTagTypes,
    ...casMasterTagTypes,
    ...casPicSignalWordTagTypes,
    ...casSpeciesTagTypes,
    ...casToxicRoutesTagType,
    ...exposureAgencyTagTypes,
    ...propertyTagTypes,
    ...formulaPropertyTagTypes,
    ...hazardTagTypes,
    ...hazardClassTagTypes,
    ...ghsHazardTagTypes,
    ...inventoryTypeTagTypes,
    ...itemMasterTagTypes,
    ...itemStatusTagTypes,
    ...localeGroupTagTypes,
    ...localeTagTypes,
    ...statementTypesTagTypes,
    ...precautionaryPhrasesTagTypes,
    ...rdFormulaTagTypes,
    ...regulationTagTypes,
    ...sdsTagTypes,
    ...toxicSpeciesTagTypes,
    ...toxicTestTagTypes,
    ...userTagTypes,
    ...casCompositionOperatorTypes,
    ...i18nTagTypes,
    ...sequelizeTagTypes,
    ...hazardClassMappingsTagTypes,
];

export const refreshTags = [...refreshOrg, ...tagTypes];
