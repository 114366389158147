import { useCallback, useEffect } from 'react';
import {
    GridReadyEvent,
    GridSizeChangedEvent,
    KeyCreatorParams,
} from 'ag-grid-community';
import { editableGridCellPopup } from '../columns/editableGridCellPopup';
import { editableGridDeleteRow } from '../columns/editableGridDeleteRow';
import { numericGridCell } from '../columns/numericGridCell';
import { isNil } from '../../../utils/objectUtils';
import { isEmpty } from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { RowStatus } from './useBaseGridEditable';

const useBaseGridHelpers = ({
    gridRef,
    handleNewValue,
    props,
    createNewEditableRow,
    handleDeleteRow,
    handleErrorTooltipGetter,
    shouldAutoResize,
    deletedRows,
}: any) => {
    const isCellNonEditable = (params: any) => {
        if (
            params.colDef.editable &&
            typeof params.colDef.editable === 'function'
        ) {
            return (
                params.colDef.field !== 'deleteColumn' &&
                !params.colDef.editable(params)
            );
        }

        return (
            params.colDef.field !== 'deleteColumn' &&
            (isNil(params.colDef.editable) ||
                (!isNil(params.colDef.editable) && !params.colDef.editable))
        );
    };

    const getAllRows = () => {
        let rowData: any[] = [];
        gridRef?.current?.api?.forEachNode((node: any) =>
            rowData.push(node.data)
        );

        return rowData;
    };

    const cellClassRules = {
        'cell-new': (params: any) => params.data.rowStatus === RowStatus.NEW,
        'cell-error': (params: any) => params.data.hasError,
        'cell-edited': (params: any) =>
            params.data.rowStatus === RowStatus.ADDED ||
            params.data.rowStatus === RowStatus.EDITED,
        'even-cell-non-editable': (params: any) =>
            params.rowIndex % 2 === 0 && isCellNonEditable(params),
        'odd-cell-non-editable': (params: any) =>
            params.rowIndex % 2 !== 0 && isCellNonEditable(params),
    };

    const onCellKeyDown = (event: any) => {
        const { key } = event.event;

        const isBackspace = key === 'Backspace';
        const notTextField =
            event.colDef.filter !== 'agTextColumnFilter' &&
            event.colDef.filter !== 'agNumberColumnFilter';
        const isEditable = event.colDef.editable;

        const shouldClear = isBackspace && notTextField && isEditable;

        if (shouldClear) {
            const { api } = gridRef.current;
            const selectedCell = api.getFocusedCell();

            if (selectedCell) {
                const { column } = selectedCell;
                const colId = column.getColId();
                const rowNode = api.getRowNode(event.node.id);

                // Clear the cell value
                rowNode.setDataValue(colId, '');
            }
        }
    };

    useEffect(() => {
        handleSetColDefs();
    }, [props, JSON.stringify(props.rowData), getAllRows(), props.rowData]);

    const onGridReady = async (params: GridReadyEvent) => {
        if (props.sizeColumnsToFit) {
            params.api.sizeColumnsToFit();
        } else {
            params.columnApi.autoSizeAllColumns();
        }
        if (props.displaySummaryRow) {
            params.api.setPinnedBottomRowData(props.pinnedBottomRowData);
        }
        if (props.isEditable) {
            setupEditableColDefs();
        }
        if (props.onGridReady) {
            props.onGridReady(params);
        }
    };

    const moveInArray = (arr: any[], fromIndex: number, toIndex: number) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    };

    /**
     * In order to keep the filter/sort functionality in ag grid we need
     * to use un-managed dragging (we have to do it manually)
     * this move the rowdata to it's correct spot and updates the sort order
     * via the sortKey prop
     * **/
    const handleRowDrag = async (event: any) => {
        let immutableStore = getAllRows();

        const movingNode = event.node;
        const overNode = event.overNode;
        const rowNeedsToMove = movingNode !== overNode;
        if (rowNeedsToMove) {
            // the list of rows we have is data, not row nodes, so extract the data
            const movingData = movingNode?.data;
            /** Adding the rowStatus to moved items (per Marvin's Request) **/
            movingData['rowStatus'] = RowStatus.ADDED;
            const overData = overNode?.data;
            const fromIndex = immutableStore.indexOf(movingData);
            const toIndex = immutableStore.indexOf(overData);
            const newStore = immutableStore.slice();
            moveInArray(newStore, fromIndex, toIndex);

            newStore.forEach((item: any, index: number) => {
                item[props.sortKey] = index + 1;
            });

            /**
             * when determining if we should show inline or modal sort
             * we need to use the non-deleted rows
             * **/
            const removeDeleted = newStore.filter(
                (row: any) => row.rowStatus !== 'deleted'
            );

            gridRef.current!.api.setRowData(removeDeleted);
            gridRef.current!.api.clearFocusedCell();
        }
    };

    /**
     * when we're finished dragging our rows - we need to update the field data
     * with the new rowData
     * **/

    const onRowDragEnd = () => {
        const rows = getAllRows();
        const gridEditRows =
            deletedRows && deletedRows.length > 0
                ? rows.concat(...deletedRows)
                : rows;
        props.onHandleGridEdits(gridEditRows);
    };

    const setupEditableColDefs = () => {
        handleSetColDefs();
        const shouldCreateRows =
            props.displayCreateNewButton && props.createRow;
        const hasNoRows =
            props.displayCreateNewButton &&
            isNil(props.createRow) &&
            props.rowData &&
            isEmpty(props.rowData);

        if (shouldCreateRows || hasNoRows) {
            createNewEditableRow();
        }
    };

    const handleSetColDefs = () => {
        if (props.columnDefs) {
            let columnDefs: any[] = props.columnDefs;
            if (props.isEditable) {
                columnDefs = props.columnDefs.map((colDef: any) => {
                    if (colDef.useGridPopup) {
                        return gridPopupSetup(colDef);
                    } else if (colDef.field === 'deleteColumn') {
                        return deleteColumnSetup(colDef);
                    } else if (colDef.editable) {
                        return editableSetup(colDef);
                    } else {
                        return defaultSetup(colDef);
                    }
                });
            }

            /**
             * when determining if we should show inline or modal sort
             * we need to use the non-deleted rows
             * **/
            const removeDeleted = getAllRows()?.filter(
                (row: any) => row.rowStatus !== 'deleted'
            );

            const removeDeletedProps = props.rowData?.filter(
                (row: any) => row.rowStatus !== 'deleted'
            );
            gridRef.current!.api.setColumnDefs(columnDefs);

            if (props.useRowDrag) {
                const updatedColDefs =
                    !(props.paginationPageSize >= removeDeleted?.length) ||
                    !(props.paginationPageSize >= removeDeletedProps?.length)
                        ? columnDefs
                        : [
                              {
                                  field: 'dragRow',
                                  headerName: '',
                                  rowDrag: true,
                                  filter: false,
                                  sortable: false,
                                  minWidth: 50,
                                  maxWidth: 50,
                              },
                              ...columnDefs,
                          ];
                gridRef.current!.api.setColumnDefs(updatedColDefs);
            }
        }
    };
    const defaultSetup = (colDef: any) => {
        if (colDef.isNumeric) {
            colDef = { ...numericGridCell(), ...colDef };
        }
        return {
            ...colDef,
            cellClassRules: cellClassRules,
            tooltipValueGetter: (params: any) =>
                handleErrorTooltipGetter(params),
        };
    };
    const editableSetup = (colDef: any) => {
        if (colDef.isNumeric) {
            colDef = { ...numericGridCell(), ...colDef };
        }
        return {
            ...colDef,
            valueSetter: handleNewValue,
            cellClassRules: cellClassRules,
            tooltipValueGetter: (params: any) =>
                handleErrorTooltipGetter(params),
        };
    };
    const deleteColumnSetup = (colDef: any) => {
        return {
            ...colDef,
            ...editableGridDeleteRow(gridRef.current),
            onDelete: (data: any) => handleDeleteRow(data),
            cellClassRules: cellClassRules,
        };
    };

    const gridPopupSetup = (colDef: any) => {
        return {
            editable: true,
            keyCreator: (params: KeyCreatorParams) => {
                return params.value?.display;
            },
            comparator: sortGridPopupColumnBy,
            ...colDef,
            ...editableGridCellPopup(
                gridRef.current,
                colDef?.context ? colDef.context : 'default'
            ),
            valueSetter: handleNewValue,
            cellClassRules: cellClassRules,
            tooltipValueGetter: (params: any) =>
                handleErrorTooltipGetter(params),
        };
    };
    const onFirstDataRendered = async (params: any) => {
        if (props.onFirstDataRendered) {
            props.onFirstDataRendered(params);
        }

        if (props.setInitialRowSelected) {
            gridRef.current!.api.forEachNode((node: any) =>
                node.setSelected(!!node.data && node.data.active)
            );
        }
    };

    const onGridSizeChanged = (params: GridSizeChangedEvent) => {
        if (!shouldAutoResize) {
            return;
        }
        gridRef.current!.api.sizeColumnsToFit();
    };

    const sortGridPopupColumnBy = (currentValue: any, nextValue: any) => {
        return currentValue?.display === nextValue?.display
            ? 0
            : currentValue?.display > nextValue?.display
            ? 1
            : -1;
    };

    const resetState = useCallback(() => {
        gridRef.current!.columnApi.resetColumnState();
        gridRef.current!.api.setFilterModel(null);
        if (props.sizeColumnsToFit) {
            gridRef.current.api.sizeColumnsToFit();
        }
    }, []);

    const exportDataAsExcel = useCallback(async () => {
        const opts = {
            types: [
                {
                    description: 'Excel Workbook',
                    accept: { 'text/plain': ['.xlsx'] },
                },
            ],
        };
        const newHandle = await window.showSaveFilePicker(opts);
        let excelExportParams = {
            fileName: newHandle.name,
            exportMode: 'xlsx',
            sheetName: 'export',
        };
        let excelContentBlob =
            gridRef.current!.api.getDataAsExcel(excelExportParams);

        const writableStream = await newHandle.createWritable();
        await writableStream.write(excelContentBlob);
        await writableStream.close();

        //only used for apa invoice archiving grid
        if (props.archiveGrid) {
            const rows = getAllRows();
            const zip = new JSZip();
            //loop through all invoice items and add it to the zip folder
            for (const item of rows) {
                let file = await prepareUrlForDownload(item.pdfUrl);
                zip.file(`${item.invoiceNumber}.pdf`, file);
            }

            const dateForZip = new Date().toJSON().slice(0, 10);
            const content = await zip.generateAsync({ type: 'blob' });
            //save pdfs in a zip
            saveAs(content, `archive-download-${dateForZip}.zip`);
        }
    }, []);

    const prepareUrlForDownload = async (url: string) => {
        const response = await fetch(url);
        return response.blob();
    };

    return {
        resetState,
        exportDataAsExcel,
        onGridSizeChanged,
        onCellKeyDown,
        onGridReady,
        onFirstDataRendered,
        handleRowDrag,
        onRowDragEnd,
    };
};

export default useBaseGridHelpers;
