import DefaultColumnTypes from '../../../../../../../components/grids/columns/Column.constants';
import React, { useContext, useEffect, useState } from 'react';
import { useListUnitOfMeasureDropdownQuery } from '../../../../../../../services/organizations/organizations.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import {
    RDFormula,
    RDFormulaTotal,
} from '../../../../../../../types/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import CustomHeader from '../../../../../../../components/grids/CustomHeader';
import { RDFormulasFormDefs } from '../../../../../../../constants/i18n/translations/termDefinitions/formulation';
import {
    HandleFieldChangedEvent,
    useForm,
} from '../../../../../../../libs/hooksLib';
import { ChildFormProps } from '../../../../../../form/helpers/ChildFormProps';

const useRDFormulaTotalsForm = (props: ChildFormProps<RDFormula>) => {
    const { currentParentRecord, user, isParentLoading, dataFromQuery } =
        props.parentData;

    const { handleChildRecord, registerChildForm } = props.helpers;

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const initialState: any = {
        selectedFormulaTotalUnit: null,
    };

    const [dropdownOptions, setDropdownOptions] = useState(initialState);

    const headerPermissions = {
        canViewUnitOfMeasure: PermissionsUtil.isPermissionEnabled(
            user.permissions,
            PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
        ),
    };

    const { settings } = useContext(SettingsContext);
    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    const blankFormulaTotalsForm: RDFormulaTotal = {
        formulaUomId: null,
        formulaTotal: 0,
        manualYieldPct: 0,
        calculatedYieldPct: 0,
        yieldCalcOverride: false,
        calcSpecificGravity: 0,
        totalUnitCost: 0,
        totalNetCost: 0,
        totalExtendedCost: 0,
    };

    const { fields, formMethods, handleFieldChange, setValues, verifyChanges } =
        useForm(blankFormulaTotalsForm, true);

    const { childForm } = verifyChanges;

    const { setOriginalEntity, clearChangesHistory, hasCurrentRecordChanged } =
        childForm;

    const handlePropertyChanged = (event: HandleFieldChangedEvent) => {
        handleFieldChange(event);
        handleChildRecord('formulaTotals', event);
    };

    useEffect(() => {
        if (!isParentLoading) {
            registerChildForm('formulaTotals', {
                formMethods,
                hasEntityChanges: hasCurrentRecordChanged,
                clearChangesHistory,
            });
        }
    }, [fields, isParentLoading, formMethods.isFormValid]);

    useEffect(() => {
        if (!isParentLoading) {
            setOriginalEntity(
                dataFromQuery?.formulaTotals || blankFormulaTotalsForm
            );
            setValues(
                currentParentRecord?.formulaTotals || blankFormulaTotalsForm
            );
        }
    }, [currentParentRecord?.formulaTotals, isParentLoading, dataFromQuery]);

    /**
     * Set the labels on all the dropdowns
     */
    useEffect(() => {
        if (!isLoadingUnitOfMeasure && unitOfMeasureOptions) {
            setDropdownOptions((previousValues: any) => ({
                ...previousValues,
                selectedFormulaTotalUnit: unitOfMeasureOptions?.find(
                    (current: any) =>
                        current.value ===
                        currentParentRecord.formulaTotals?.formulaUomId
                ),
            }));
        }
    }, [unitOfMeasureOptions, isLoadingUnitOfMeasure, currentParentRecord]);

    const uomTextPopupColDefs = [
        {
            field: 'label',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.UOM_Selection_Unit_Code}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RDFormulasFormDefs.UOM_Selection_Description}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
    ];

    return {
        isLoadingFields: {
            isLoadingUnitOfMeasure,
        },
        headerPermissions,
        unitOfMeasureOptions,
        setDropdownOptions,
        dropdownOptions,
        uomTextPopupColDefs,
        termSet,
        fields,
        formMethods,
        handlePropertyChanged,
    };
};
export default useRDFormulaTotalsForm;
