import React from 'react';
import { GridOptions } from '../../../components/grids/Grid.constants';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../../components/grids/BaseFormGrid';
import useRegulationGrid from './hooks/useRegulationGrid';
import CustomHeader from '../../../components/grids/CustomHeader';
import { RegulationsGridDefs } from '../../../constants/i18n/translations/termDefinitions/formulation';

const RegulationGrid = () => {
    const {
        regulationList,
        isLoadingRegulationList,
        isLoadingStates,
        redirectToForm,
        getStateById,
        canViewRegulation,
        canCreateRegulation,
        termSet,
    } = useRegulationGrid();

    const columnDefs: ColDef[] = [
        {
            field: 'code',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RegulationsGridDefs.Regulation_Code}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'title',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RegulationsGridDefs.Regulation_Title}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'regulationBody.description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RegulationsGridDefs.Regulation_Body}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'regulationSection.section',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RegulationsGridDefs.Section}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'stateCodeId',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={RegulationsGridDefs.State_Province}
                    />
                );
            },
            valueFormatter: (val) => getStateById(val.value),
            filterParams: {
                valueGetter: (params: any) =>
                    getStateById(params.data['stateCodeId']),
            },
            ...DefaultColumnTypes.MediumText,
        },
    ];

    const defaultColumnDefinitionOptions = {
        ...GridOptions.sortFilterAndWrapColumns,
        floatingFilter: true,
    };

    return (
        <BaseFormGrid
            displayGrid={canViewRegulation}
            isLoading={isLoadingStates || isLoadingRegulationList}
            rowData={regulationList}
            columnDefs={columnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateRegulation}
            displayExportDataButton={false}
            displayResetStateButton={false}
            onCreateNew={() => redirectToForm(null)}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={defaultColumnDefinitionOptions}
            sizeColumnsToFit={true}></BaseFormGrid>
    );
};

export default RegulationGrid;
