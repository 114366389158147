import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SettingsContext from '../../../contexts/settings.context';
import { useNavigate } from 'react-router-dom';
import {
    useGetUserQuery,
    useUpdateEntitySettingMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { showNotificationError } from '../../../libs/errorLib';
import { useGetTermSetQuery } from '../../../services/i18n/i18n.service';
import { PLATFORM_DEFS } from '../../../constants/i18n/translations/termSetDefinitions/platform';

const useSettings = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const { settings, setSettings } = useContext(SettingsContext);
    const navigate = useNavigate();

    const [updateEntitySetting] = useUpdateEntitySettingMutation();

    const { data: userData, isLoading: isLoadingUserData } = useGetUserQuery(
        user?.userId ? { userId: user?.userId?.toString() } : skipToken
    );

    const { data: sharedTermSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: PLATFORM_DEFS.SHARED_COMPONENTS,
              }
            : skipToken
    );

    const SETTING_KEYS: any = {
        formulationSettings: 'FORMULATION_SETTING',
        accountingSettings: 'ACCOUNTING_SETTING',
        userSettings: 'USER_SETTING',
        businessEntitySettings: 'BUSINESS_ENTITY_SETTING',
    };

    const handleSaveSettings = () => {
        for (const setting of Object.keys(settings)) {
            let postBody = {
                settingKey: SETTING_KEYS[setting],
                uuid:
                    setting === 'userSettings'
                        ? userData?.uuid
                        : user?.businessEntity?.uuid,
                owner: userData?.uuid,
                public: false,
                value: JSON.stringify((settings as any)[setting]),
            };

            const args = {
                id: (settings as any)[setting]?.id,
                postBody: postBody,
            };
            updateEntitySetting(args);
            showNotificationError(
                'Settings have been saved.',
                'success',
                dispatch
            );
        }
    };

    const handleChange = (event: any, settingKey: string) => {
        const value =
            event.target.type?.toUpperCase() === 'CHECKBOX'
                ? event.target.checked
                : event.target.value;

        setSettings((prev: any) => ({
            ...prev,
            [settingKey]: {
                ...prev[settingKey],
                [event.target.id]: value,
            },
        }));
    };

    return { handleChange, handleSaveSettings, navigate, user, sharedTermSet };
};

export default useSettings;
