import { ValueGetterParams } from 'ag-grid-community';
import {
    RDFormula,
    RDFormulaComponent,
} from '../../../../../../../types/formulation';
import { ChildEditableGridProps } from '../../../../../../grid/utils/editableGrid/ChildEditableGridProps';
import { isNilOrEmpty } from '../../../../../../../utils/objectUtils';
import { GenericEditableRow } from '../../../../../../../components/grids/hooks/useBaseGridEditable';
import { SelectedDropdownOption } from '../../../../../../../types/Shared.types';
import { useContext, useState } from 'react';
import SettingsContext from '../../../../../../../contexts/settings.context';
import { useGetTermSetQuery } from '../../../../../../../services/i18n/i18n.service';
import { FORMULATION_DEFS } from '../../../../../../../constants/i18n/translations/termSetDefinitions/formulation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetComponentTypesForDropdownQuery } from '../../../../../../../services/formulation/rdFormula/componentType/componentType.service';
import { PermissionsUtil } from '../../../../../../../utils/permissions/permissionsUtil';
import { PERMISSIONS } from '../../../../../../../constants/permissions/Permissions.constants';
import { useGetAllUsableItemsMasterQuery } from '../../../../../../../services/formulation/itemMaster/item/itemMaster.service';
import {
    useListBoilerPlateNoteQuery,
    useListUnitOfMeasureDropdownQuery,
} from '../../../../../../../services/organizations/organizations.service';
import _ from 'lodash';
import { UniqueColumnValueValidator } from '../../../../../../grid/validators/uniqueColumnValue.validator';
import { ColumnRequiredValueValidator } from '../../../../../../grid/validators/columnRequiredValue.validator';
import { ColumnNumericValueValidator } from '../../../../../../grid/validators/columnNumericValue.validator';
import { ColumnMinValueValidator } from '../../../../../../grid/validators/columnMinValue.validator';
import { ColumnMaxValueValidator } from '../../../../../../grid/validators/columnMaxValue.validator';
import applyEditableGridValidations from '../../../../../../grid/utils/applyEditableGridValidations';

const useRDFormulaComponentsFunctions = (
    props: ChildEditableGridProps<RDFormula>
) => {
    const { user } = props.parentData;
    const { handleChildrenRecords } = props.helpers;
    const { settings } = useContext(SettingsContext);

    const { data: termSet } = useGetTermSetQuery(
        settings?.userSettings
            ? {
                  languageId: settings?.userSettings?.languageId,
                  code: FORMULATION_DEFS.RD_FORMULAS_FORM,
              }
            : skipToken
    );

    const { data: componentTypeOptions, isLoading: isLoadingComponentTypes } =
        useGetComponentTypesForDropdownQuery();

    const canViewItem = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.FORMULATION.ITEM_MASTER.VIEW
    );

    const { data: itemList, isLoading: isLoadingItems } =
        useGetAllUsableItemsMasterQuery();

    const canViewUOM = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.UNITS_OF_MEASURE.VIEW
    );

    const { data: unitOfMeasureOptions, isLoading: isLoadingUnitOfMeasure } =
        useListUnitOfMeasureDropdownQuery();

    const canViewBoilerPlateNotes = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.SETUP.BOILER_PLATE_NOTE.VIEW
    );

    const {
        data: boilerPlateNoteOptions,
        isLoading: isLoadingBoilerPlateNotes,
    } = useListBoilerPlateNoteQuery();

    const [formulaComponentColumnDefs, setFormulaComponentColumnDefs] =
        useState(null);
    const [formulaComponents, setFormulaComponents] = useState(
        [] as RDFormulaComponent[]
    );
    const [unitOfMeasureColumnDefs, setUnitOfMeasureColumnDefs] =
        useState(null);
    const [itemColumnDefs, setItemColumnDefs] = useState(null);
    const [boilerPlateColumnDefs, setBoilerPlateColumnDefs] = useState(null);

    /**
     * Generate the next step number value
     */
    const getNextStepNumber = (params: ValueGetterParams) => {
        let rowData: RDFormulaComponent[] = [];
        let maxStep = 0;
        let defaultSettingValue = Number(
            settings?.formulationSettings?.componentStepNo
        );
        let defaultStep =
            isNaN(defaultSettingValue) || defaultSettingValue <= 0
                ? 10
                : defaultSettingValue;

        params.api.forEachNode((node: { data: RDFormulaComponent }) =>
            rowData.push(node.data)
        );

        if (!isNilOrEmpty(rowData)) {
            maxStep = Math.max(
                ...rowData.map((component) =>
                    isNaN(Number(component.stepNo))
                        ? 0
                        : Number(component.stepNo)
                ),
                0
            );
            if (maxStep > 0) {
                maxStep = maxStep - (maxStep % defaultStep);
                return maxStep + defaultStep;
            }
        }

        return defaultStep;
    };

    /**
     * Clears and refreshes row with updated values
     */
    const clearRowValues = (currentEditingRow: any) => {
        currentEditingRow.componentCode = {
            id: null,
            display: '',
        };
        currentEditingRow.componentDescription = '';
        currentEditingRow.stockUomCode = {
            id: null,
            display: '',
        };
        currentEditingRow.componentUomCode = {
            id: null,
            display: '',
        };
        currentEditingRow.boilerPlateNoteCode = {
            id: null,
            display: '',
        };
        currentEditingRow.unitCost = 0;
        currentEditingRow.componentId = null;
        currentEditingRow.density = 0;
        currentEditingRow.errors = [];
        currentEditingRow.uomId = null;
        currentEditingRow.stockUomId = null;
        currentEditingRow.formulaQty = 0;
        currentEditingRow.hasError = false;
        currentEditingRow.lockQty = 2;
        currentEditingRow.scrapPercent = 0;
        currentEditingRow.specificGravity = 0;
        currentEditingRow.stockCost = 0;
        currentEditingRow.unitCost = 0;
        currentEditingRow.uomCost = 0;
        delete currentEditingRow.componentType;
    };

    /**
     * Get the default value for Component Type field
     */
    const getDefaultComponentType = (): any => {
        return componentTypeOptions?.find((componentType: any) =>
            (componentType.label as string).includes('Normal')
        ).value;
    };

    /**
     * Get the component value in the display format
     * @param componentId desired item ID
     */
    const getComponentCode = (componentId: bigint): SelectedDropdownOption => {
        let componentCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(componentId)) {
            itemList?.find((current: any) => {
                if (current.id === componentId) {
                    componentCode = {
                        id: current.id,
                        display: current.itemId,
                    };
                }
            });
        }
        return componentCode;
    };

    /**
     * Get all the values from item master for the selected component
     * @param id desired item ID
     * @param options List of items to look from
     */
    const getComponentDetails = (id: bigint, options: any) => {
        let detailRecord: any;
        options?.find((current: any) => {
            if (current.id === id) {
                detailRecord = current;
            }
        });
        return detailRecord;
    };

    /**
     * Get the Boiler Plate Note value in the display format
     * @param boilerPlateNoteId desired boilerplate ID
     */
    const getBoilerPlateNoteCode = (
        boilerPlateNoteId: bigint
    ): SelectedDropdownOption => {
        let boilerPlateCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(boilerPlateNoteId)) {
            boilerPlateNoteOptions?.find((current: any) => {
                if (current.id === boilerPlateNoteId) {
                    boilerPlateCode = {
                        id: current.id,
                        display: current.code,
                    };
                }
            });
        }
        return boilerPlateCode;
    };

    /**
     * Get the UOM value in the display format
     * @param unitId desired UOM ID
     */
    const getUOMCode = (unitId: bigint): SelectedDropdownOption => {
        let uomCode: SelectedDropdownOption = {
            id: null,
            display: '',
        };
        if (!isNilOrEmpty(unitId)) {
            unitOfMeasureOptions?.find((current: any) => {
                if (current.id === unitId) {
                    uomCode = {
                        id: current.id,
                        display: current.unitCode,
                    };
                }
            });
        }
        return uomCode;
    };

    /**
     * Updates the current row with the values from itemmaster using component id
     */
    const populateFromItem = (currentEditingRow: GenericEditableRow) => {
        const details = getComponentDetails(
            currentEditingRow.componentCode?.id,
            itemList
        );

        if (details) {
            currentEditingRow.unitCost = details?.cost || 0;
            currentEditingRow.stockCost = details?.cost || 0;
            currentEditingRow.density =
                details?.itemUnitOfMeasure?.density || 0;
            currentEditingRow.specificGravity =
                details?.itemUnitOfMeasure?.specificGravity || 0;
            currentEditingRow.stockUomCode = getUOMCode(
                details?.itemUnitOfMeasure?.stockUnitId
            );
            currentEditingRow.componentUomCode = getUOMCode(
                details?.itemUnitOfMeasure?.stockUnitId
            );
        }
    };

    /**
     * Enables or disables cells on a row depending on component type
     */
    const enableDisableCellFunction = (params: any) => {
        const componentType =
            params.data.componentType?.type ||
            componentTypeOptions?.find(
                (componentType: any) =>
                    (componentType.value as bigint) ===
                    params.data.componentTypeId
            ).label;

        const manualTypeFieldArray = [
            'boilerPlateNoteCode',
            'formulaQty',
            'scrapPercent',
            'componentUomCode',
            'uomCost',
            'lockQty',
            'stockCost',
        ];
        const textStageTypeFieldArray = ['boilerPlateNoteCode'];
        const normalTypeFieldArray = [...manualTypeFieldArray, 'componentCode'];
        const costingTypeFieldArray = ['stockCost', 'uomCost'];

        const isNormalType =
            componentType === 'Normal' &&
            normalTypeFieldArray.includes(params.colDef.field);
        const isManualType =
            componentType === 'Manual' &&
            manualTypeFieldArray.includes(params.colDef.field);
        const isTextType =
            componentType === 'Text' &&
            textStageTypeFieldArray.includes(params.colDef.field);
        const isStagingType =
            componentType === 'Stage' &&
            textStageTypeFieldArray.includes(params.colDef.field);
        const isCostingType =
            componentType === 'Costing' &&
            costingTypeFieldArray.includes(params.colDef.field);

        if (
            isNormalType ||
            isManualType ||
            isTextType ||
            isStagingType ||
            isCostingType
        ) {
            return true;
        }

        return false;
    };

    /**
     * Returns all rows on the components grid
     */
    const getAllGridRows = (editedRows: RDFormulaComponent[]) => {
        const updatedFields: RDFormulaComponent[] = [...formulaComponents];

        editedRows?.forEach((row: any) => {
            const index = formulaComponents.findIndex(
                (formulaComponent: RDFormulaComponent) =>
                    formulaComponent.rowId === row.rowId
            );

            if (
                !isNilOrEmpty(row.componentCode) &&
                !isNilOrEmpty(row.componentCode?.id)
            ) {
                row.componentId = row.componentCode?.id;
            }

            if (
                !isNilOrEmpty(row.boilerPlateNoteCode) &&
                !isNilOrEmpty(row.boilerPlateNoteCode?.id)
            ) {
                row.boilerPlateNoteId = row.boilerPlateNoteCode?.id;
            }

            if (
                !isNilOrEmpty(row.stockUomCode) &&
                !isNilOrEmpty(row.stockUomCode?.id)
            ) {
                row.stockUomId = row.stockUomCode?.id;
            }

            if (
                !isNilOrEmpty(row.componentUomCode) &&
                !isNilOrEmpty(row.componentUomCode?.id)
            ) {
                row.uomId = row.componentUomCode?.id;
            }

            if (index > -1) {
                updatedFields[index] = row;
            } else {
                updatedFields.push(row);
            }
        });
        return updatedFields;
    };

    /**
     * Sort the components based on sequence numbers
     */
    const sortFormulaComponents = (componentsList: RDFormulaComponent[]) => {
        setFormulaComponents(_.orderBy(componentsList, ['stepNo'], ['asc']));
    };

    /**
     * Handle edited records and push them to the parent record.
     * @param editedRows current edited rows
     */
    const handleGridEdits = (editedRows: RDFormulaComponent[]) => {
        const updatedFields = getAllGridRows(editedRows);
        sortFormulaComponents(updatedFields);
        handleChildrenRecords('components', updatedFields);
    };

    /**
     * Validations for the Component Row
     * @param editedRows edited Component records
     */
    const handleRowValidations = (editedRows: RDFormulaComponent[]) => {
        const updatedFields = getAllGridRows(editedRows);

        editedRows?.forEach((current) => {
            current.validationRules = [
                UniqueColumnValueValidator(
                    updatedFields,
                    current,
                    ['stepNo', 'componentTypeId', 'componentId'],
                    `Duplicate step No ${current.stepNo}.`
                ),
                ColumnRequiredValueValidator('Step No.', current.stepNo),
                ColumnRequiredValueValidator('Type', current.componentTypeId),
                ColumnNumericValueValidator(
                    'Step No.',
                    0,
                    Number(current.stepNo)
                ),
                ColumnMinValueValidator('Step No.', 0, Number(current.stepNo)),
                ColumnNumericValueValidator(
                    'Formula Qty',
                    8,
                    current.formulaQty
                ),
                ColumnMaxValueValidator(
                    'Formula Qty',
                    999999999,
                    Number(current.formulaQty)
                ),
                ColumnMinValueValidator(
                    'Formula Qty',
                    0,
                    Number(current.formulaQty)
                ),
                ColumnNumericValueValidator(
                    'UOM Cost',
                    4,
                    Number(current.uomCost)
                ),
                ColumnMaxValueValidator(
                    'UOM Cost',
                    999999999,
                    Number(current.uomCost)
                ),
                ColumnMinValueValidator('UOM Cost', 0, Number(current.uomCost)),
                ColumnNumericValueValidator(
                    'Scrap Percentage',
                    0,
                    Number(current.scrapPercent)
                ),
                ColumnMaxValueValidator(
                    'Scrap Percentage',
                    100,
                    Number(current.scrapPercent)
                ),
                ColumnMinValueValidator(
                    'Scrap Percentage',
                    0,
                    Number(current.scrapPercent)
                ),
                ColumnNumericValueValidator(
                    'Stock Cost',
                    4,
                    Number(current.stockCost)
                ),
                ColumnMaxValueValidator(
                    'Stock Cost',
                    999999999,
                    Number(current.stockCost)
                ),
                ColumnMinValueValidator(
                    'Stock Cost',
                    0,
                    Number(current.stockCost)
                ),
            ];
            const message = current.stepNo
                ? `Step No. ${current.stepNo}`
                : null;
            applyEditableGridValidations(current, message);
        });
        return editedRows;
    };

    /**
     * Actions on cell value changed
     */
    const handleColumnChanged = (
        columnName: string,
        currentEditingRow: GenericEditableRow
    ): void => {
        if (columnName === 'componentCode') {
            populateFromItem(currentEditingRow);
        }
        if (columnName === 'componentTypeId') {
            clearRowValues(currentEditingRow);
        }
    };

    return {
        getNextStepNumber,
        getComponentCode,
        getComponentDetails,
        getBoilerPlateNoteCode,
        getUOMCode,
        getDefaultComponentType,
        enableDisableCellFunction,
        termSet,
        canViewItem,
        canViewBoilerPlateNotes,
        canViewUOM,
        isLoadingItems,
        isLoadingBoilerPlateNotes,
        isLoadingUnitOfMeasure,
        isLoadingComponentTypes,
        componentTypeOptions,
        itemList,
        unitOfMeasureOptions,
        boilerPlateNoteOptions,
        formulaComponentColumnDefs,
        setFormulaComponentColumnDefs,
        formulaComponents,
        setFormulaComponents,
        unitOfMeasureColumnDefs,
        setUnitOfMeasureColumnDefs,
        itemColumnDefs,
        setItemColumnDefs,
        boilerPlateColumnDefs,
        setBoilerPlateColumnDefs,
        handleGridEdits,
        handleRowValidations,
        handleColumnChanged,
    };
};

export default useRDFormulaComponentsFunctions;
