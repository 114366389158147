import React from 'react';
import useContractGrid from './hooks/useContractGrid';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ContractsGridDefs } from '../../constants/i18n/translations/termDefinitions/accounting';
import CustomHeader from '../../components/grids/CustomHeader';

const ContractGrid = () => {
    const {
        contractList,
        redirectToForm,
        isLoadingContracts,
        canCreateContract,
        canViewContract,
        termSet,
    } = useContractGrid();

    const contractColumnDefs: ColDef[] = [
        {
            field: 'number',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Number}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'name',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Name}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'description',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Description}
                    />
                );
            },
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'supplier',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Provider}
                    />
                );
            },
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'contractType.code',
            minWidth: 150,
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Type}
                    />
                );
            },
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'startDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Start_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'endDate',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.End_Date}
                    />
                );
            },
            ...DefaultColumnTypes.DateOnly,
        },
        {
            field: 'amount',
            headerComponentFramework: (props: any) => {
                return (
                    <CustomHeader
                        {...props}
                        termSet={termSet}
                        termKey={ContractsGridDefs.Amount}
                    />
                );
            },
            valueGetter: (params: ValueGetterParams) => {
                return Number(params.data.amount);
            },
            ...DefaultColumnTypes.Currency,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            hide: true,
            ...DefaultColumnTypes.DateTime,
        },
        {
            field: 'createdByUser.email',
            headerName: 'Created By',
            hide: true,
            ...DefaultColumnTypes.MediumText,
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            hide: true,
            ...DefaultColumnTypes.DateTime,
        },
        {
            field: 'updatedByUser.email',
            headerName: 'Created By',
            hide: true,
            ...DefaultColumnTypes.MediumText,
        },
    ];

    return (
        <BaseFormGrid
            displayGrid={canViewContract}
            isLoading={isLoadingContracts}
            rowData={contractList}
            columnDefs={contractColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateContract}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
        />
    );
};

export default ContractGrid;
