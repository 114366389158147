import React from 'react';
import useFixedAssetTypeGrid from './hooks/useFixedAssetTypeGrid';
import BaseFormGrid from '../../components/grids/BaseFormGrid';
import { ColDef } from 'ag-grid-community';
import DefaultColumnTypes from '../../components/grids/columns/Column.constants';
import { cloneDeep } from 'lodash';

const FixedAssetTypeGrid = () => {
    const {
        typeList,
        isLoadingTypes,
        canCreateAssetType,
        canViewAssetType,
        redirectToForm,
        handleGridEdits,
    } = useFixedAssetTypeGrid();

    const typeColumnDefs: ColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'description',
            headerName: 'Description',
            ...DefaultColumnTypes.LongText,
        },
        {
            field: 'recoveryTerm',
            headerName: 'Recovery Term',
            ...DefaultColumnTypes.NumberOnly,
        },
        {
            field: 'recoveryUnit.unit',
            headerName: 'Recovery Unit',
            ...DefaultColumnTypes.ShortText,
        },
        {
            field: 'depreciationMethod.code',
            headerName: 'Depreciation Method',
            ...DefaultColumnTypes.ShortText,
        },
    ];

    return (
        <BaseFormGrid
            title="Asset Types"
            displayGrid={canViewAssetType}
            isLoading={isLoadingTypes}
            rowData={cloneDeep(typeList)}
            useRowDrag={true}
            sortKey={'sortOrder'}
            columnDefs={typeColumnDefs}
            gridClass="full-size-item"
            displayToolbar={true}
            displayCreateNewButton={canCreateAssetType}
            displayExportDataButton={true}
            displayResetStateButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            onHandleGridEdits={handleGridEdits}
        />
    );
};

export default FixedAssetTypeGrid;
