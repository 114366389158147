import React, { useEffect, useState } from 'react';
import './app.css';
import Landing from './containers/landing/Landing';
import './styles/app.scss';
import Login from './containers/login/Login';
import { useDispatch, useSelector } from 'react-redux';
import {
    handleUpdateUserFeatureFlags,
    refreshToken,
    setUserDetails,
} from './store/user';
import { Auth } from 'aws-amplify';
import { onError } from './libs/errorLib';
import DatacorLogoSpinner from './components/datacorLogoSpinner/DatacorLogoSpinner';
import {
    useGetUserDetailsQuery,
    useListEntitySettingsQuery,
} from './services/organizations/organizations.service';
import { RootState } from './store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetEnabledBusinessEntityFeatureFlagsQuery } from './services/access/access.service';
import SingleSelectPopupsContext from './contexts/singleSelectPopups.context';
import { GridPopups } from './components/grids/Grid.constants';
import SettingsContext, { Settings } from './contexts/settings.context';
import { themeConfig } from './styles/theme/themeConfig';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { isNilOrEmpty } from './utils/objectUtils';

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const initializeGridPopups = Object.values(GridPopups).map(
        (gridContext) => {
            return {
                gridName: gridContext.gridName,
                fieldName: gridContext.fieldName,
                value: [],
            };
        }
    );
    const [gridPopups, setGridPopups] = useState([
        ...initializeGridPopups,
        { gridName: 'default', fieldName: 'default', value: [] },
    ]);
    const [settings, setSettings] = useState({} as Settings);
    const settingsValue = { settings, setSettings };
    const [initialAuthChecked, setInitialAuthChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { data: userData, isLoading: isUserLoading } = useGetUserDetailsQuery(
        !user?.isSignedIn && skipToken
    );

    const { data: entitySettings, isLoading: isLoadingEntitySetings } =
        useListEntitySettingsQuery(!userData && skipToken);

    const { data: featureFlags, isLoading: isFeatureFlagsLoading } =
        useGetEnabledBusinessEntityFeatureFlagsQuery(
            userData?.activeWebBusinessEntity?.id ||
                userData?.businessEntity?.id ||
                skipToken
        );

    const value = { gridPopups, setGridPopups };

    useEffect(() => {
        if (!isFeatureFlagsLoading && featureFlags) {
            updateFF();
        }
    }, [featureFlags, isFeatureFlagsLoading, user]);

    useEffect(() => {
        if (!isNilOrEmpty(entitySettings)) {
            const userSettings = entitySettings.find(
                (setting: any) => setting.settingValue === 'userSettings'
            );

            const businessEntitySettings = entitySettings.find(
                (setting: any) =>
                    setting.settingValue === 'businessEntitySettings'
            );

            const formulationSettings = entitySettings.find(
                (setting: any) => setting.settingValue === 'formulationSettings'
            );

            const accountingSettings = entitySettings.find(
                (setting: any) => setting.settingValue === 'accountingSettings'
            );

            let tempSettings: Settings = {
                userSettings: {
                    id: userSettings?.id,
                    ...JSON.parse(userSettings?.value),
                },
                businessEntitySettings: {
                    id: businessEntitySettings?.id,
                    ...JSON.parse(businessEntitySettings?.value),
                },
                formulationSettings: {
                    id: formulationSettings?.id,
                    ...JSON.parse(formulationSettings?.value),
                },
                accountingSettings: {
                    id: accountingSettings?.id,
                    ...JSON.parse(accountingSettings?.value),
                },
            };

            setSettings(tempSettings);
        }
    }, [entitySettings]);

    useEffect(() => {
        const onLoad = async () => {
            try {
                if (!isUserLoading && !isFeatureFlagsLoading) {
                    setInitialAuthChecked(true);
                    await dispatch(refreshToken());
                    await Auth.currentSession()
                        .then((session) => {
                            dispatch(setUserDetails(userData, session));
                        })
                        .catch((error) => onError(error, false));
                    setIsLoading(false);
                }
            } catch (e) {
                onError(e.message);
            }
        };

        onLoad();
    }, [dispatch, isUserLoading, isFeatureFlagsLoading]);

    const updateFF = async () => {
        await dispatch(handleUpdateUserFeatureFlags(featureFlags));
    };

    if (
        isLoading ||
        isUserLoading ||
        isFeatureFlagsLoading ||
        isLoadingEntitySetings
    ) {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                <DatacorLogoSpinner />
            </div>
        );
    }

    const theme = createTheme({
        palette: {
            mode: settings.userSettings?.darkMode ? 'dark' : 'light',
            primary: {
                main: themeConfig.PRIMARY.main,
                light: themeConfig.PRIMARY.light,
                dark: themeConfig.PRIMARY.dark,
            },
            secondary: {
                main: themeConfig.SECONDARY.main,
                light: themeConfig.SECONDARY.light,
                dark: themeConfig.SECONDARY.dark,
            },
            headerNavIcons: {
                main: themeConfig.HEADER_NAV.MENU_ICONS.main,
                light: themeConfig.HEADER_NAV.MENU_ICONS.light,
                //@ts-ignore
                dark: themeConfig.HEADER_NAV.MENU_ICONS.dark,
            },
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        //@ts-ignore
                        backgroundColor: settings.userSettings?.darkMode
                            ? 'black'
                            : 'white',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
        },
    });

    return initialAuthChecked ? (
        <SettingsContext.Provider value={settingsValue}>
            <SingleSelectPopupsContext.Provider value={value}>
                <ThemeProvider theme={theme}>
                    <Landing />
                </ThemeProvider>
            </SingleSelectPopupsContext.Provider>
        </SettingsContext.Provider>
    ) : (
        <Login />
    );
};

export default App;
